.light {
	--main-bg: #fff;
	--body-bg: #f2f3f5;
	--card-bg: #f6f7f8;
	--modal-bg: #fff;
	--hover-bg: #f3f5f7;
	--primary-color: #1451FA;
	--primary-color-hover: #1451FA;
	--primary-font-color: #3A3E4C;
	--font-info-color: #b0b5c2;
	--font-sub-color: #7f8492;
	--border-color: rgba(226, 227, 235, 1);
	--barColor: rgb(236, 242, 255);
	--bg-shadow: rgba(0, 0, 0, 0.3);

	--light-primary-color: var(--primary-color);
	--light-primary-color-hover: var(--primary-color-hover);
	--light-primary-color-active: var(--primary-color-hover);
	--light-primary-color-outline: rgba(24, 144, 255, 0.2);
	--light-primary-1: #e6f7ff;
	--light-primary-2: #bae7ff;
	--light-primary-3: #91d5ff;
	--light-primary-4: #69c0ff;
	--light-primary-5: #40a9ff;
	--light-primary-6: #1890ff;
	--light-primary-7: #096dd9;
	--light-primary-color-deprecated-pure: ;
	--light-primary-color-deprecated-l-35: #cbe6ff;
	--light-primary-color-deprecated-l-20: #7ec1ff;
	--light-primary-color-deprecated-t-20: #46a6ff;
	--light-primary-color-deprecated-t-50: #8cc8ff;
	--light-primary-color-deprecated-f-12: rgba(24, 144, 255, 0.12);
	--light-primary-color-active-deprecated-f-30: rgba(230, 247, 255, 0.3);
	--light-primary-color-active-deprecated-d-02: #dcf4ff;
	--light-success-color: #52c41a;
	--light-success-color-hover: #73d13d;
	--light-success-color-active: #389e0d;
	--light-success-color-outline: rgba(82, 196, 26, 0.2);
	--light-success-color-deprecated-bg: #f6ffed;
	--light-success-color-deprecated-border: #b7eb8f;
	--light-error-color: #ff4d4f;
	--light-error-color-hover: #ff7875;
	--light-error-color-active: #d9363e;
	--light-error-color-outline: rgba(255, 77, 79, 0.2);
	--light-error-color-deprecated-bg: #fff2f0;
	--light-error-color-deprecated-border: #ffccc7;
	--light-warning-color: #faad14;
	--light-warning-color-hover: #ffc53d;
	--light-warning-color-active: #d48806;
	--light-warning-color-outline: rgba(250, 173, 20, 0.2);
	--light-warning-color-deprecated-bg: #fffbe6;
	--light-warning-color-deprecated-border: #ffe58f;
	--light-info-color: #1890ff;
	--light-info-color-deprecated-bg: #e6f7ff;
	--light-info-color-deprecated-border: #91d5ff;
}

[class^="light-"]::-ms-clear,
[class*="light-"]::-ms-clear,
[class^="light-"] input::-ms-clear,
[class*="light-"] input::-ms-clear,
[class^="light-"] input::-ms-reveal,
[class*="light-"] input::-ms-reveal {
	display: none;
}

.light input::placeholder {
	color: #bfbfbf;
}

.light-fade-enter,
.light-fade-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-fade-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-fade-enter.light-fade-enter-active,
.light-fade-appear.light-fade-appear-active {
	animation-name: antFadeIn;
	animation-play-state: running;
}

.light-fade-leave.light-fade-leave-active {
	animation-name: antFadeOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-fade-enter,
.light-fade-appear {
	opacity: 0;
	animation-timing-function: linear;
}

.light-fade-leave {
	animation-timing-function: linear;
}

@keyframes antFadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes antFadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.light-move-up-enter,
.light-move-up-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-move-up-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-move-up-enter.light-move-up-enter-active,
.light-move-up-appear.light-move-up-appear-active {
	animation-name: antMoveUpIn;
	animation-play-state: running;
}

.light-move-up-leave.light-move-up-leave-active {
	animation-name: antMoveUpOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-move-up-enter,
.light-move-up-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.light-move-up-leave {
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.light-move-down-enter,
.light-move-down-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-move-down-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-move-down-enter.light-move-down-enter-active,
.light-move-down-appear.light-move-down-appear-active {
	animation-name: antMoveDownIn;
	animation-play-state: running;
}

.light-move-down-leave.light-move-down-leave-active {
	animation-name: antMoveDownOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-move-down-enter,
.light-move-down-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.light-move-down-leave {
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.light-move-left-enter,
.light-move-left-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-move-left-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-move-left-enter.light-move-left-enter-active,
.light-move-left-appear.light-move-left-appear-active {
	animation-name: antMoveLeftIn;
	animation-play-state: running;
}

.light-move-left-leave.light-move-left-leave-active {
	animation-name: antMoveLeftOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-move-left-enter,
.light-move-left-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.light-move-left-leave {
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.light-move-right-enter,
.light-move-right-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-move-right-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-move-right-enter.light-move-right-enter-active,
.light-move-right-appear.light-move-right-appear-active {
	animation-name: antMoveRightIn;
	animation-play-state: running;
}

.light-move-right-leave.light-move-right-leave-active {
	animation-name: antMoveRightOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-move-right-enter,
.light-move-right-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.light-move-right-leave {
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

@keyframes antMoveDownIn {
	0% {
		transform: translateY(100%);
		transform-origin: 0 0;
		opacity: 0;
	}

	100% {
		transform: translateY(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
}

@keyframes antMoveDownOut {
	0% {
		transform: translateY(0%);
		transform-origin: 0 0;
		opacity: 1;
	}

	100% {
		transform: translateY(100%);
		transform-origin: 0 0;
		opacity: 0;
	}
}

@keyframes antMoveLeftIn {
	0% {
		transform: translateX(-100%);
		transform-origin: 0 0;
		opacity: 0;
	}

	100% {
		transform: translateX(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
}

@keyframes antMoveLeftOut {
	0% {
		transform: translateX(0%);
		transform-origin: 0 0;
		opacity: 1;
	}

	100% {
		transform: translateX(-100%);
		transform-origin: 0 0;
		opacity: 0;
	}
}

@keyframes antMoveRightIn {
	0% {
		transform: translateX(100%);
		transform-origin: 0 0;
		opacity: 0;
	}

	100% {
		transform: translateX(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
}

@keyframes antMoveRightOut {
	0% {
		transform: translateX(0%);
		transform-origin: 0 0;
		opacity: 1;
	}

	100% {
		transform: translateX(100%);
		transform-origin: 0 0;
		opacity: 0;
	}
}

@keyframes antMoveUpIn {
	0% {
		transform: translateY(-100%);
		transform-origin: 0 0;
		opacity: 0;
	}

	100% {
		transform: translateY(0%);
		transform-origin: 0 0;
		opacity: 1;
	}
}

@keyframes antMoveUpOut {
	0% {
		transform: translateY(0%);
		transform-origin: 0 0;
		opacity: 1;
	}

	100% {
		transform: translateY(-100%);
		transform-origin: 0 0;
		opacity: 0;
	}
}

@keyframes loadingCircle {
	100% {
		transform: rotate(360deg);
	}
}

[light-click-animating="true"],
[light-click-animating-without-extra-node="true"] {
	position: relative;
}

html {
	--antd-wave-shadow-color: var(--light-primary-color);
	--scroll-bar: 0;
}

[light-click-animating-without-extra-node="true"]::after,
.light-click-animating-node {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	border-radius: inherit;
	box-shadow: 0 0 0 0 var(--light-primary-color);
	box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
	opacity: 0.2;
	animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1),
		waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-fill-mode: forwards;
	content: "";
	pointer-events: none;
}

@keyframes waveEffect {
	100% {
		box-shadow: 0 0 0 var(--light-primary-color);
		box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
	}
}

@keyframes fadeEffect {
	100% {
		opacity: 0;
	}
}

.light-slide-up-enter,
.light-slide-up-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-slide-up-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-slide-up-enter.light-slide-up-enter-active,
.light-slide-up-appear.light-slide-up-appear-active {
	animation-name: antSlideUpIn;
	animation-play-state: running;
}

.light-slide-up-leave.light-slide-up-leave-active {
	animation-name: antSlideUpOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-slide-up-enter,
.light-slide-up-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.light-slide-up-leave {
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.light-slide-down-enter,
.light-slide-down-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-slide-down-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-slide-down-enter.light-slide-down-enter-active,
.light-slide-down-appear.light-slide-down-appear-active {
	animation-name: antSlideDownIn;
	animation-play-state: running;
}

.light-slide-down-leave.light-slide-down-leave-active {
	animation-name: antSlideDownOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-slide-down-enter,
.light-slide-down-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.light-slide-down-leave {
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.light-slide-left-enter,
.light-slide-left-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-slide-left-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-slide-left-enter.light-slide-left-enter-active,
.light-slide-left-appear.light-slide-left-appear-active {
	animation-name: antSlideLeftIn;
	animation-play-state: running;
}

.light-slide-left-leave.light-slide-left-leave-active {
	animation-name: antSlideLeftOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-slide-left-enter,
.light-slide-left-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.light-slide-left-leave {
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.light-slide-right-enter,
.light-slide-right-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-slide-right-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-slide-right-enter.light-slide-right-enter-active,
.light-slide-right-appear.light-slide-right-appear-active {
	animation-name: antSlideRightIn;
	animation-play-state: running;
}

.light-slide-right-leave.light-slide-right-leave-active {
	animation-name: antSlideRightOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-slide-right-enter,
.light-slide-right-appear {
	opacity: 0;
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}

.light-slide-right-leave {
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@keyframes antSlideUpIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}

	100% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
}

@keyframes antSlideUpOut {
	0% {
		transform: scaleY(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}

	100% {
		transform: scaleY(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
}

@keyframes antSlideDownIn {
	0% {
		transform: scaleY(0.8);
		transform-origin: 100% 100%;
		opacity: 0;
	}

	100% {
		transform: scaleY(1);
		transform-origin: 100% 100%;
		opacity: 1;
	}
}

@keyframes antSlideDownOut {
	0% {
		transform: scaleY(1);
		transform-origin: 100% 100%;
		opacity: 1;
	}

	100% {
		transform: scaleY(0.8);
		transform-origin: 100% 100%;
		opacity: 0;
	}
}

@keyframes antSlideLeftIn {
	0% {
		transform: scaleX(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}

	100% {
		transform: scaleX(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}
}

@keyframes antSlideLeftOut {
	0% {
		transform: scaleX(1);
		transform-origin: 0% 0%;
		opacity: 1;
	}

	100% {
		transform: scaleX(0.8);
		transform-origin: 0% 0%;
		opacity: 0;
	}
}

@keyframes antSlideRightIn {
	0% {
		transform: scaleX(0.8);
		transform-origin: 100% 0%;
		opacity: 0;
	}

	100% {
		transform: scaleX(1);
		transform-origin: 100% 0%;
		opacity: 1;
	}
}

@keyframes antSlideRightOut {
	0% {
		transform: scaleX(1);
		transform-origin: 100% 0%;
		opacity: 1;
	}

	100% {
		transform: scaleX(0.8);
		transform-origin: 100% 0%;
		opacity: 0;
	}
}

.light-zoom-enter,
.light-zoom-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-enter.light-zoom-enter-active,
.light-zoom-appear.light-zoom-appear-active {
	animation-name: antZoomIn;
	animation-play-state: running;
}

.light-zoom-leave.light-zoom-leave-active {
	animation-name: antZoomOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-zoom-enter,
.light-zoom-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.light-zoom-enter-prepare,
.light-zoom-appear-prepare {
	transform: none;
}

.light-zoom-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.light-zoom-big-enter,
.light-zoom-big-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-big-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-big-enter.light-zoom-big-enter-active,
.light-zoom-big-appear.light-zoom-big-appear-active {
	animation-name: antZoomBigIn;
	animation-play-state: running;
}

.light-zoom-big-leave.light-zoom-big-leave-active {
	animation-name: antZoomBigOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-zoom-big-enter,
.light-zoom-big-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.light-zoom-big-enter-prepare,
.light-zoom-big-appear-prepare {
	transform: none;
}

.light-zoom-big-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.light-zoom-big-fast-enter,
.light-zoom-big-fast-appear {
	animation-duration: 0.1s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-big-fast-leave {
	animation-duration: 0.1s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-big-fast-enter.light-zoom-big-fast-enter-active,
.light-zoom-big-fast-appear.light-zoom-big-fast-appear-active {
	animation-name: antZoomBigIn;
	animation-play-state: running;
}

.light-zoom-big-fast-leave.light-zoom-big-fast-leave-active {
	animation-name: antZoomBigOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-zoom-big-fast-enter,
.light-zoom-big-fast-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.light-zoom-big-fast-enter-prepare,
.light-zoom-big-fast-appear-prepare {
	transform: none;
}

.light-zoom-big-fast-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.light-zoom-up-enter,
.light-zoom-up-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-up-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-up-enter.light-zoom-up-enter-active,
.light-zoom-up-appear.light-zoom-up-appear-active {
	animation-name: antZoomUpIn;
	animation-play-state: running;
}

.light-zoom-up-leave.light-zoom-up-leave-active {
	animation-name: antZoomUpOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-zoom-up-enter,
.light-zoom-up-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.light-zoom-up-enter-prepare,
.light-zoom-up-appear-prepare {
	transform: none;
}

.light-zoom-up-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.light-zoom-down-enter,
.light-zoom-down-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-down-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-down-enter.light-zoom-down-enter-active,
.light-zoom-down-appear.light-zoom-down-appear-active {
	animation-name: antZoomDownIn;
	animation-play-state: running;
}

.light-zoom-down-leave.light-zoom-down-leave-active {
	animation-name: antZoomDownOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-zoom-down-enter,
.light-zoom-down-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.light-zoom-down-enter-prepare,
.light-zoom-down-appear-prepare {
	transform: none;
}

.light-zoom-down-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.light-zoom-left-enter,
.light-zoom-left-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-left-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-left-enter.light-zoom-left-enter-active,
.light-zoom-left-appear.light-zoom-left-appear-active {
	animation-name: antZoomLeftIn;
	animation-play-state: running;
}

.light-zoom-left-leave.light-zoom-left-leave-active {
	animation-name: antZoomLeftOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-zoom-left-enter,
.light-zoom-left-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.light-zoom-left-enter-prepare,
.light-zoom-left-appear-prepare {
	transform: none;
}

.light-zoom-left-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.light-zoom-right-enter,
.light-zoom-right-appear {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-right-leave {
	animation-duration: 0.2s;
	animation-fill-mode: both;
	animation-play-state: paused;
}

.light-zoom-right-enter.light-zoom-right-enter-active,
.light-zoom-right-appear.light-zoom-right-appear-active {
	animation-name: antZoomRightIn;
	animation-play-state: running;
}

.light-zoom-right-leave.light-zoom-right-leave-active {
	animation-name: antZoomRightOut;
	animation-play-state: running;
	pointer-events: none;
}

.light-zoom-right-enter,
.light-zoom-right-appear {
	transform: scale(0);
	opacity: 0;
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}

.light-zoom-right-enter-prepare,
.light-zoom-right-appear-prepare {
	transform: none;
}

.light-zoom-right-leave {
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@keyframes antZoomIn {
	0% {
		transform: scale(0.2);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes antZoomOut {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.2);
		opacity: 0;
	}
}

@keyframes antZoomBigIn {
	0% {
		transform: scale(0.8);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes antZoomBigOut {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.8);
		opacity: 0;
	}
}

@keyframes antZoomUpIn {
	0% {
		transform: scale(0.8);
		transform-origin: 50% 0%;
		opacity: 0;
	}

	100% {
		transform: scale(1);
		transform-origin: 50% 0%;
	}
}

@keyframes antZoomUpOut {
	0% {
		transform: scale(1);
		transform-origin: 50% 0%;
	}

	100% {
		transform: scale(0.8);
		transform-origin: 50% 0%;
		opacity: 0;
	}
}

@keyframes antZoomLeftIn {
	0% {
		transform: scale(0.8);
		transform-origin: 0% 50%;
		opacity: 0;
	}

	100% {
		transform: scale(1);
		transform-origin: 0% 50%;
	}
}

@keyframes antZoomLeftOut {
	0% {
		transform: scale(1);
		transform-origin: 0% 50%;
	}

	100% {
		transform: scale(0.8);
		transform-origin: 0% 50%;
		opacity: 0;
	}
}

@keyframes antZoomRightIn {
	0% {
		transform: scale(0.8);
		transform-origin: 100% 50%;
		opacity: 0;
	}

	100% {
		transform: scale(1);
		transform-origin: 100% 50%;
	}
}

@keyframes antZoomRightOut {
	0% {
		transform: scale(1);
		transform-origin: 100% 50%;
	}

	100% {
		transform: scale(0.8);
		transform-origin: 100% 50%;
		opacity: 0;
	}
}

@keyframes antZoomDownIn {
	0% {
		transform: scale(0.8);
		transform-origin: 50% 100%;
		opacity: 0;
	}

	100% {
		transform: scale(1);
		transform-origin: 50% 100%;
	}
}

@keyframes antZoomDownOut {
	0% {
		transform: scale(1);
		transform-origin: 50% 100%;
	}

	100% {
		transform: scale(0.8);
		transform-origin: 50% 100%;
		opacity: 0;
	}
}

.ant-motion-collapse-legacy {
	overflow: hidden;
}

.ant-motion-collapse-legacy-active {
	transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
		opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-motion-collapse {
	overflow: hidden;
	transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
		opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.light-affix {
	position: fixed;
	z-index: 10;
}

.light-alert {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: flex;
	align-items: center;
	padding: 8px 15px;
	word-wrap: break-word;
	border-radius: 2px;
}

.light-alert-content {
	flex: 1;
	min-width: 0;
}

.light-alert-icon {
	margin-right: 8px;
}

.light-alert-description {
	display: none;
	font-size: 14px;
	line-height: 22px;
}

.light-alert-success {
	background-color: var(--light-success-color-deprecated-bg);
	border: 1px solid var(--light-success-color-deprecated-border);
}

.light-alert-success .light-alert-icon {
	color: var(--light-success-color);
}

.light-alert-info {
	background-color: var(--light-info-color-deprecated-bg);
	border: 1px solid var(--light-info-color-deprecated-border);
}

.light-alert-info .light-alert-icon {
	color: var(--light-info-color);
}

.light-alert-warning {
	background-color: var(--light-warning-color-deprecated-bg);
	border: 1px solid var(--light-warning-color-deprecated-border);
}

.light-alert-warning .light-alert-icon {
	color: var(--light-warning-color);
}

.light-alert-error {
	background-color: var(--light-error-color-deprecated-bg);
	border: 1px solid var(--light-error-color-deprecated-border);
}

.light-alert-error .light-alert-icon {
	color: var(--light-error-color);
}

.light-alert-error .light-alert-description>pre {
	margin: 0;
	padding: 0;
}

.light-alert-action {
	margin-left: 8px;
}

.light-alert-close-icon {
	margin-left: 8px;
	padding: 0;
	overflow: hidden;
	font-size: 12px;
	line-height: 12px;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
}

.light-alert-close-icon .anticon-close {
	color: rgba(0, 0, 0, 0.45);
	transition: color 0.3s;
}

.light-alert-close-icon .anticon-close:hover {
	color: rgba(0, 0, 0, 0.75);
}

.light-alert-close-text {
	color: rgba(0, 0, 0, 0.45);
	transition: color 0.3s;
}

.light-alert-close-text:hover {
	color: rgba(0, 0, 0, 0.75);
}

.light-alert-with-description {
	align-items: flex-start;
	padding: 15px 15px 15px 24px;
}

.light-alert-with-description.light-alert-no-icon {
	padding: 15px 15px;
}

.light-alert-with-description .light-alert-icon {
	margin-right: 15px;
	font-size: 24px;
}

.light-alert-with-description .light-alert-message {
	display: block;
	margin-bottom: 4px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
}

.light-alert-message {
	color: rgba(0, 0, 0, 0.85);
}

.light-alert-with-description .light-alert-description {
	display: block;
}

.light-alert.light-alert-motion-leave {
	overflow: hidden;
	opacity: 1;
	transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.light-alert.light-alert-motion-leave-active {
	max-height: 0;
	margin-bottom: 0 !important;
	padding-top: 0;
	padding-bottom: 0;
	opacity: 0;
}

.light-alert-banner {
	margin-bottom: 0;
	border: 0;
	border-radius: 0;
}

.light-alert.light-alert-rtl {
	direction: rtl;
}

.light-alert-rtl .light-alert-icon {
	margin-right: auto;
	margin-left: 8px;
}

.light-alert-rtl .light-alert-action {
	margin-right: 8px;
	margin-left: auto;
}

.light-alert-rtl .light-alert-close-icon {
	margin-right: 8px;
	margin-left: auto;
}

.light-alert-rtl.light-alert-with-description {
	padding-right: 24px;
	padding-left: 15px;
}

.light-alert-rtl.light-alert-with-description .light-alert-icon {
	margin-right: auto;
	margin-left: 15px;
}

.light-anchor {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	padding-left: 2px;
}

.light-anchor-wrapper {
	margin-left: -4px;
	padding-left: 4px;
	overflow: auto;
	background-color: transparent;
}

.light-anchor-ink {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
}

.light-anchor-ink::before {
	position: relative;
	display: block;
	width: 2px;
	height: 100%;
	margin: 0 auto;
	background-color: var(--border-color);
	content: " ";
}

.light-anchor-ink-ball {
	position: absolute;
	left: 50%;
	display: none;
	width: 8px;
	height: 8px;
	background-color: #fff;
	border: 2px solid var(--light-primary-color);
	border-radius: 8px;
	transform: translateX(-50%);
	transition: top 0.3s ease-in-out;
}

.light-anchor-ink-ball.visible {
	display: inline-block;
}

.light-anchor-fixed .light-anchor-ink .light-anchor-ink-ball {
	display: none;
}

.light-anchor-link {
	padding: 4px 0 4px 16px;
}

.light-anchor-link-title {
	position: relative;
	display: block;
	margin-bottom: 3px;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.85);
	white-space: nowrap;
	text-overflow: ellipsis;
	transition: all 0.3s;
}

.light-anchor-link-title:only-child {
	margin-bottom: 0;
}

.light-anchor-link-active>.light-anchor-link-title {
	color: var(--light-primary-color);
}

.light-anchor-link .light-anchor-link {
	padding-top: 2px;
	padding-bottom: 2px;
}

.light-anchor-rtl {
	direction: rtl;
}

.light-anchor-rtl.light-anchor-wrapper {
	margin-right: -4px;
	margin-left: 0;
	padding-right: 4px;
	padding-left: 0;
}

.light-anchor-rtl .light-anchor-ink {
	right: 0;
	left: auto;
}

.light-anchor-rtl .light-anchor-ink-ball {
	right: 50%;
	left: 0;
	transform: translateX(50%);
}

.light-anchor-rtl .light-anchor-link {
	padding: 4px 16px 4px 0;
}

.light-select-auto-complete {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
}

.light-select-auto-complete .light-select-clear {
	right: 13px;
}

.light-avatar {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	overflow: hidden;
	color: #fff;
	white-space: nowrap;
	text-align: center;
	vertical-align: middle;
	background: #ccc;
	width: 32px;
	height: 32px;
	line-height: 32px;
	border-radius: 50%;
}

.light-avatar-image {
	background: transparent;
}

.light-avatar .light-image-img {
	display: block;
}

.light-avatar-string {
	position: absolute;
	left: 50%;
	transform-origin: 0 center;
}

.light-avatar.light-avatar-icon {
	font-size: 18px;
}

.light-avatar.light-avatar-icon>.anticon {
	margin: 0;
}

.light-avatar-lg {
	width: 40px;
	height: 40px;
	line-height: 40px;
	border-radius: 50%;
}

.light-avatar-lg-string {
	position: absolute;
	left: 50%;
	transform-origin: 0 center;
}

.light-avatar-lg.light-avatar-icon {
	font-size: 24px;
}

.light-avatar-lg.light-avatar-icon>.anticon {
	margin: 0;
}

.light-avatar-sm {
	width: 24px;
	height: 24px;
	line-height: 24px;
	border-radius: 50%;
}

.light-avatar-sm-string {
	position: absolute;
	left: 50%;
	transform-origin: 0 center;
}

.light-avatar-sm.light-avatar-icon {
	font-size: 14px;
}

.light-avatar-sm.light-avatar-icon>.anticon {
	margin: 0;
}

.light-avatar-square {
	border-radius: 2px;
}

.light-avatar>img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.light-avatar-group {
	display: inline-flex;
}

.light-avatar-group .light-avatar {
	border: 1px solid #fff;
}

.light-avatar-group .light-avatar:not(:first-child) {
	margin-left: -8px;
}

.light-avatar-group-popover .light-avatar+.light-avatar {
	margin-left: 3px;
}

.light-avatar-group-rtl .light-avatar:not(:first-child) {
	margin-right: -8px;
	margin-left: 0;
}

.light-avatar-group-popover.light-popover-rtl .light-avatar+.light-avatar {
	margin-right: 3px;
	margin-left: 0;
}

.light-back-top {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: fixed;
	right: 100px;
	bottom: 50px;
	z-index: 10;
	width: 40px;
	height: 40px;
	cursor: pointer;
}

.light-back-top:empty {
	display: none;
}

.light-back-top-rtl {
	right: auto;
	left: 100px;
	direction: rtl;
}

.light-back-top-content {
	width: 40px;
	height: 40px;
	overflow: hidden;
	color: #fff;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.45);
	border-radius: 20px;
	transition: all 0.3s;
}

.light-back-top-content:hover {
	background-color: rgba(0, 0, 0, 0.85);
	transition: all 0.3s;
}

.light-back-top-icon {
	font-size: 24px;
	line-height: 40px;
}

@media screen and (max-width: 768px) {
	.light-back-top {
		right: 60px;
	}

	.light-back-top-rtl {
		right: auto;
		left: 60px;
	}
}

@media screen and (max-width: 480px) {
	.light-back-top {
		right: 20px;
	}

	.light-back-top-rtl {
		right: auto;
		left: 20px;
	}
}

.light-badge {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	line-height: 1;
}

.light-badge-count {
	z-index: auto;
	min-width: 20px;
	height: 20px;
	padding: 0 6px;
	color: #fff;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;
	white-space: nowrap;
	text-align: center;
	background: #ff4d4f;
	border-radius: 10px;
	box-shadow: 0 0 0 1px #fff;
}

.light-badge-count a,
.light-badge-count a:hover {
	color: #fff;
}

.light-badge-count-sm {
	min-width: 14px;
	height: 14px;
	padding: 0;
	font-size: 12px;
	line-height: 14px;
	border-radius: 7px;
}

.light-badge-multiple-words {
	padding: 0 8px;
}

.light-badge-dot {
	z-index: auto;
	width: 6px;
	min-width: 6px;
	height: 6px;
	background: #ff4d4f;
	border-radius: 100%;
	box-shadow: 0 0 0 1px #fff;
}

.light-badge-dot.light-scroll-number {
	transition: background 1.5s;
}

.light-badge-count,
.light-badge-dot,
.light-badge .light-scroll-number-custom-component {
	position: absolute;
	top: 0;
	right: 0;
	transform: translate(50%, -50%);
	transform-origin: 100% 0%;
}

.light-badge-count.anticon-spin,
.light-badge-dot.anticon-spin,
.light-badge .light-scroll-number-custom-component.anticon-spin {
	animation: antBadgeLoadingCircle 1s infinite linear;
}

.light-badge-status {
	line-height: inherit;
	vertical-align: baseline;
}

.light-badge-status-dot {
	position: relative;
	top: -1px;
	display: inline-block;
	width: 6px;
	height: 6px;
	vertical-align: middle;
	border-radius: 50%;
}

.light-badge-status-success {
	background-color: var(--light-success-color);
}

.light-badge-status-processing {
	position: relative;
	background-color: var(--light-primary-color);
}

.light-badge-status-processing::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid var(--light-primary-color);
	border-radius: 50%;
	animation: antStatusProcessing 1.2s infinite ease-in-out;
	content: "";
}

.light-badge-status-default {
	background-color: #d9d9d9;
}

.light-badge-status-error {
	background-color: var(--light-error-color);
}

.light-badge-status-warning {
	background-color: var(--light-warning-color);
}

.light-badge-status-pink {
	background: #eb2f96;
}

.light-badge-status-magenta {
	background: #eb2f96;
}

.light-badge-status-red {
	background: #f5222d;
}

.light-badge-status-volcano {
	background: #fa541c;
}

.light-badge-status-orange {
	background: #fa8c16;
}

.light-badge-status-yellow {
	background: #fadb14;
}

.light-badge-status-gold {
	background: #faad14;
}

.light-badge-status-cyan {
	background: #13c2c2;
}

.light-badge-status-lime {
	background: #a0d911;
}

.light-badge-status-green {
	background: #52c41a;
}

.light-badge-status-blue {
	background: #1890ff;
}

.light-badge-status-geekblue {
	background: #2f54eb;
}

.light-badge-status-purple {
	background: #722ed1;
}

.light-badge-status-text {
	margin-left: 8px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
}

.light-badge-zoom-appear,
.light-badge-zoom-enter {
	animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
	animation-fill-mode: both;
}

.light-badge-zoom-leave {
	animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
	animation-fill-mode: both;
}

.light-badge-not-a-wrapper .light-badge-zoom-appear,
.light-badge-not-a-wrapper .light-badge-zoom-enter {
	animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.light-badge-not-a-wrapper .light-badge-zoom-leave {
	animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}

.light-badge-not-a-wrapper:not(.light-badge-status) {
	vertical-align: middle;
}

.light-badge-not-a-wrapper .light-scroll-number-custom-component,
.light-badge-not-a-wrapper .light-badge-count {
	transform: none;
}

.light-badge-not-a-wrapper .light-scroll-number-custom-component,
.light-badge-not-a-wrapper .light-scroll-number {
	position: relative;
	top: auto;
	display: block;
	transform-origin: 50% 50%;
}

@keyframes antStatusProcessing {
	0% {
		transform: scale(0.8);
		opacity: 0.5;
	}

	100% {
		transform: scale(2.4);
		opacity: 0;
	}
}

.light-scroll-number {
	overflow: hidden;
	direction: ltr;
}

.light-scroll-number-only {
	position: relative;
	display: inline-block;
	height: 20px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	/* stylelint-disable property-no-vendor-prefix */
	-webkit-transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	/* stylelint-enable property-no-vendor-prefix */
}

.light-scroll-number-only>p.light-scroll-number-only-unit {
	height: 20px;
	margin: 0;
	/* stylelint-disable property-no-vendor-prefix */
	-webkit-transform-style: preserve-3d;
	-webkit-backface-visibility: hidden;
	/* stylelint-enable property-no-vendor-prefix */
}

.light-scroll-number-symbol {
	vertical-align: top;
}

@keyframes antZoomBadgeIn {
	0% {
		transform: scale(0) translate(50%, -50%);
		opacity: 0;
	}

	100% {
		transform: scale(1) translate(50%, -50%);
	}
}

@keyframes antZoomBadgeOut {
	0% {
		transform: scale(1) translate(50%, -50%);
	}

	100% {
		transform: scale(0) translate(50%, -50%);
		opacity: 0;
	}
}

@keyframes antNoWrapperZoomBadgeIn {
	0% {
		transform: scale(0);
		opacity: 0;
	}

	100% {
		transform: scale(1);
	}
}

@keyframes antNoWrapperZoomBadgeOut {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(0);
		opacity: 0;
	}
}

@keyframes antBadgeLoadingCircle {
	0% {
		transform-origin: 50%;
	}

	100% {
		transform: translate(50%, -50%) rotate(360deg);
		transform-origin: 50%;
	}
}

.light-ribbon-wrapper {
	position: relative;
}

.light-ribbon {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: absolute;
	top: 8px;
	height: 22px;
	padding: 0 8px;
	color: #fff;
	line-height: 22px;
	white-space: nowrap;
	background-color: var(--light-primary-color);
	border-radius: 2px;
}

.light-ribbon-text {
	color: #fff;
}

.light-ribbon-corner {
	position: absolute;
	top: 100%;
	width: 8px;
	height: 8px;
	color: currentcolor;
	border: 4px solid;
	transform: scaleY(0.75);
	transform-origin: top;
}

.light-ribbon-corner::after {
	position: absolute;
	top: -4px;
	left: -4px;
	width: inherit;
	height: inherit;
	color: rgba(0, 0, 0, 0.25);
	border: inherit;
	content: "";
}

.light-ribbon-color-pink {
	color: #eb2f96;
	background: #eb2f96;
}

.light-ribbon-color-magenta {
	color: #eb2f96;
	background: #eb2f96;
}

.light-ribbon-color-red {
	color: #f5222d;
	background: #f5222d;
}

.light-ribbon-color-volcano {
	color: #fa541c;
	background: #fa541c;
}

.light-ribbon-color-orange {
	color: #fa8c16;
	background: #fa8c16;
}

.light-ribbon-color-yellow {
	color: #fadb14;
	background: #fadb14;
}

.light-ribbon-color-gold {
	color: #faad14;
	background: #faad14;
}

.light-ribbon-color-cyan {
	color: #13c2c2;
	background: #13c2c2;
}

.light-ribbon-color-lime {
	color: #a0d911;
	background: #a0d911;
}

.light-ribbon-color-green {
	color: #52c41a;
	background: #52c41a;
}

.light-ribbon-color-blue {
	color: #1890ff;
	background: #1890ff;
}

.light-ribbon-color-geekblue {
	color: #2f54eb;
	background: #2f54eb;
}

.light-ribbon-color-purple {
	color: #722ed1;
	background: #722ed1;
}

.light-ribbon.light-ribbon-placement-end {
	right: -8px;
	border-bottom-right-radius: 0;
}

.light-ribbon.light-ribbon-placement-end .light-ribbon-corner {
	right: 0;
	border-color: currentcolor transparent transparent currentcolor;
}

.light-ribbon.light-ribbon-placement-start {
	left: -8px;
	border-bottom-left-radius: 0;
}

.light-ribbon.light-ribbon-placement-start .light-ribbon-corner {
	left: 0;
	border-color: currentcolor currentcolor transparent transparent;
}

.light-badge-rtl {
	direction: rtl;
}

.light-badge-rtl.light-badge:not(.light-badge-not-a-wrapper) .light-badge-count,
.light-badge-rtl.light-badge:not(.light-badge-not-a-wrapper) .light-badge-dot,
.light-badge-rtl.light-badge:not(.light-badge-not-a-wrapper) .light-scroll-number-custom-component {
	right: auto;
	left: 0;
	direction: ltr;
	transform: translate(-50%, -50%);
	transform-origin: 0% 0%;
}

.light-badge-rtl.light-badge:not(.light-badge-not-a-wrapper) .light-scroll-number-custom-component {
	right: auto;
	left: 0;
	transform: translate(-50%, -50%);
	transform-origin: 0% 0%;
}

.light-badge-rtl .light-badge-status-text {
	margin-right: 8px;
	margin-left: 0;
}

.light-badge:not(.light-badge-not-a-wrapper).light-badge-rtl .light-badge-zoom-appear,
.light-badge:not(.light-badge-not-a-wrapper).light-badge-rtl .light-badge-zoom-enter {
	animation-name: antZoomBadgeInRtl;
}

.light-badge:not(.light-badge-not-a-wrapper).light-badge-rtl .light-badge-zoom-leave {
	animation-name: antZoomBadgeOutRtl;
}

.light-ribbon-rtl {
	direction: rtl;
}

.light-ribbon-rtl.light-ribbon-placement-end {
	right: unset;
	left: -8px;
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 0;
}

.light-ribbon-rtl.light-ribbon-placement-end .light-ribbon-corner {
	right: unset;
	left: 0;
	border-color: currentcolor currentcolor transparent transparent;
}

.light-ribbon-rtl.light-ribbon-placement-end .light-ribbon-corner::after {
	border-color: currentcolor currentcolor transparent transparent;
}

.light-ribbon-rtl.light-ribbon-placement-start {
	right: -8px;
	left: unset;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 2px;
}

.light-ribbon-rtl.light-ribbon-placement-start .light-ribbon-corner {
	right: 0;
	left: unset;
	border-color: currentcolor transparent transparent currentcolor;
}

.light-ribbon-rtl.light-ribbon-placement-start .light-ribbon-corner::after {
	border-color: currentcolor transparent transparent currentcolor;
}

@keyframes antZoomBadgeInRtl {
	0% {
		transform: scale(0) translate(-50%, -50%);
		opacity: 0;
	}

	100% {
		transform: scale(1) translate(-50%, -50%);
	}
}

@keyframes antZoomBadgeOutRtl {
	0% {
		transform: scale(1) translate(-50%, -50%);
	}

	100% {
		transform: scale(0) translate(-50%, -50%);
		opacity: 0;
	}
}

.light-breadcrumb {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
}

.light-breadcrumb .anticon {
	font-size: 14px;
}

.light-breadcrumb ol {
	display: flex;
	flex-wrap: wrap;
	margin: 0;
	padding: 0;
	list-style: none;
}

.light-breadcrumb a {
	color: rgba(0, 0, 0, 0.45);
	transition: color 0.3s;
}

.light-breadcrumb a:hover {
	color: rgba(0, 0, 0, 0.85);
}

.light-breadcrumb li:last-child {
	color: rgba(0, 0, 0, 0.85);
}

.light-breadcrumb li:last-child a {
	color: rgba(0, 0, 0, 0.85);
}

li:last-child>.light-breadcrumb-separator {
	display: none;
}

.light-breadcrumb-separator {
	margin: 0 8px;
	color: rgba(0, 0, 0, 0.45);
}

.light-breadcrumb-link>.anticon+span,
.light-breadcrumb-link>.anticon+a {
	margin-left: 4px;
}

.light-breadcrumb-overlay-link>.anticon {
	margin-left: 4px;
}

.light-breadcrumb-rtl {
	direction: rtl;
}

.light-breadcrumb-rtl::before {
	display: table;
	content: "";
}

.light-breadcrumb-rtl::after {
	display: table;
	clear: both;
	content: "";
}

.light-breadcrumb-rtl::before {
	display: table;
	content: "";
}

.light-breadcrumb-rtl::after {
	display: table;
	clear: both;
	content: "";
}

.light-breadcrumb-rtl>span {
	float: right;
}

.light-breadcrumb-rtl .light-breadcrumb-link>.anticon+span,
.light-breadcrumb-rtl .light-breadcrumb-link>.anticon+a {
	margin-right: 4px;
	margin-left: 0;
}

.light-breadcrumb-rtl .light-breadcrumb-overlay-link>.anticon {
	margin-right: 4px;
	margin-left: 0;
}

.light-btn {
	line-height: 1.5715;
	position: relative;
	display: inline-block;
	font-weight: 400;
	white-space: nowrap;
	text-align: center;
	background-image: none;
	border: 1px solid transparent;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
	cursor: pointer;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	user-select: none;
	touch-action: manipulation;
	height: 32px;
	padding: 4px 15px;
	font-size: 14px;
	border-radius: 4px;
	color: rgba(0, 0, 0, 0.85);
	border-color: #d9d9d9;
	background: #fff;
}

.light-btn>.anticon {
	line-height: 1;
}

.light-btn,
.light-btn:active,
.light-btn:focus {
	outline: 0;
}

.light-btn:not([disabled]):hover {
	text-decoration: none;
}

.light-btn:not([disabled]):active {
	outline: 0;
	box-shadow: none;
}

.light-btn[disabled] {
	cursor: not-allowed;
}

.light-btn[disabled]>* {
	pointer-events: none;
}

.light-btn-lg {
	height: 40px;
	padding: 6.4px 15px;
	font-size: 16px;
	border-radius: 2px;
}

.light-btn-sm {
	height: 24px;
	padding: 0px 7px;
	font-size: 14px;
	border-radius: 2px;
}

.light-btn>a:only-child {
	color: currentcolor;
}

.light-btn>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn:hover,
.light-btn:focus {
	color: var(--light-primary-color-hover);
	border-color: var(--light-primary-color-hover);
	background: #fff;
}

.light-btn:hover>a:only-child,
.light-btn:focus>a:only-child {
	color: currentcolor;
}

.light-btn:hover>a:only-child::after,
.light-btn:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn:active {
	color: var(--light-primary-color-active);
	border-color: var(--light-primary-color-active);
	background: #fff;
}

.light-btn:active>a:only-child {
	color: currentcolor;
}

.light-btn:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn[disabled],
.light-btn[disabled]:hover,
.light-btn[disabled]:focus,
.light-btn[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn[disabled]>a:only-child,
.light-btn[disabled]:hover>a:only-child,
.light-btn[disabled]:focus>a:only-child,
.light-btn[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn[disabled]>a:only-child::after,
.light-btn[disabled]:hover>a:only-child::after,
.light-btn[disabled]:focus>a:only-child::after,
.light-btn[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn:hover,
.light-btn:focus,
.light-btn:active {
	text-decoration: none;
	background: #fff;
}

.light-btn>span {
	display: inline-block;
}

.light-btn-primary {
	color: #fff;
	border-color: var(--light-primary-color);
	background: var(--light-primary-color);
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.light-btn-primary>a:only-child {
	color: currentcolor;
}

.light-btn-primary>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-primary:hover,
.light-btn-primary:focus {
	color: #fff;
	border-color: var(--light-primary-color-hover);
	background: var(--light-primary-color-hover);
}

.light-btn-primary:hover>a:only-child,
.light-btn-primary:focus>a:only-child {
	color: currentcolor;
}

.light-btn-primary:hover>a:only-child::after,
.light-btn-primary:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-primary:active {
	color: #fff;
	border-color: var(--light-primary-color-active);
	background: var(--light-primary-color-active);
}

.light-btn-primary:active>a:only-child {
	color: currentcolor;
}

.light-btn-primary:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-primary[disabled],
.light-btn-primary[disabled]:hover,
.light-btn-primary[disabled]:focus,
.light-btn-primary[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-primary[disabled]>a:only-child,
.light-btn-primary[disabled]:hover>a:only-child,
.light-btn-primary[disabled]:focus>a:only-child,
.light-btn-primary[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-primary[disabled]>a:only-child::after,
.light-btn-primary[disabled]:hover>a:only-child::after,
.light-btn-primary[disabled]:focus>a:only-child::after,
.light-btn-primary[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-group .light-btn-primary:not(:first-child):not(:last-child) {
	border-right-color: var(--light-primary-5);
	border-left-color: var(--light-primary-5);
}

.light-btn-group .light-btn-primary:not(:first-child):not(:last-child):disabled {
	border-color: #d9d9d9;
}

.light-btn-group .light-btn-primary:first-child:not(:last-child) {
	border-right-color: var(--light-primary-5);
}

.light-btn-group .light-btn-primary:first-child:not(:last-child)[disabled] {
	border-right-color: #d9d9d9;
}

.light-btn-group .light-btn-primary:last-child:not(:first-child),
.light-btn-group .light-btn-primary+.light-btn-primary {
	border-left-color: var(--light-primary-5);
}

.light-btn-group .light-btn-primary:last-child:not(:first-child)[disabled],
.light-btn-group .light-btn-primary+.light-btn-primary[disabled] {
	border-left-color: #d9d9d9;
}

.light-btn-ghost {
	color: rgba(0, 0, 0, 0.85);
	border-color: #d9d9d9;
	background: transparent;
}

.light-btn-ghost>a:only-child {
	color: currentcolor;
}

.light-btn-ghost>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-ghost:hover,
.light-btn-ghost:focus {
	color: var(--light-primary-color-hover);
	border-color: var(--light-primary-color-hover);
	background: transparent;
}

.light-btn-ghost:hover>a:only-child,
.light-btn-ghost:focus>a:only-child {
	color: currentcolor;
}

.light-btn-ghost:hover>a:only-child::after,
.light-btn-ghost:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-ghost:active {
	color: var(--light-primary-color-active);
	border-color: var(--light-primary-color-active);
	background: transparent;
}

.light-btn-ghost:active>a:only-child {
	color: currentcolor;
}

.light-btn-ghost:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-ghost[disabled],
.light-btn-ghost[disabled]:hover,
.light-btn-ghost[disabled]:focus,
.light-btn-ghost[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-ghost[disabled]>a:only-child,
.light-btn-ghost[disabled]:hover>a:only-child,
.light-btn-ghost[disabled]:focus>a:only-child,
.light-btn-ghost[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-ghost[disabled]>a:only-child::after,
.light-btn-ghost[disabled]:hover>a:only-child::after,
.light-btn-ghost[disabled]:focus>a:only-child::after,
.light-btn-ghost[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dashed {
	color: rgba(0, 0, 0, 0.85);
	border-color: #d9d9d9;
	background: #fff;
	border-style: dashed;
}

.light-btn-dashed>a:only-child {
	color: currentcolor;
}

.light-btn-dashed>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dashed:hover,
.light-btn-dashed:focus {
	color: var(--light-primary-color-hover);
	border-color: var(--light-primary-color-hover);
	background: #fff;
}

.light-btn-dashed:hover>a:only-child,
.light-btn-dashed:focus>a:only-child {
	color: currentcolor;
}

.light-btn-dashed:hover>a:only-child::after,
.light-btn-dashed:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dashed:active {
	color: var(--light-primary-color-active);
	border-color: var(--light-primary-color-active);
	background: #fff;
}

.light-btn-dashed:active>a:only-child {
	color: currentcolor;
}

.light-btn-dashed:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dashed[disabled],
.light-btn-dashed[disabled]:hover,
.light-btn-dashed[disabled]:focus,
.light-btn-dashed[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-dashed[disabled]>a:only-child,
.light-btn-dashed[disabled]:hover>a:only-child,
.light-btn-dashed[disabled]:focus>a:only-child,
.light-btn-dashed[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-dashed[disabled]>a:only-child::after,
.light-btn-dashed[disabled]:hover>a:only-child::after,
.light-btn-dashed[disabled]:focus>a:only-child::after,
.light-btn-dashed[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-danger {
	color: #fff;
	border-color: var(--light-error-color);
	background: var(--light-error-color);
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.light-btn-danger>a:only-child {
	color: currentcolor;
}

.light-btn-danger>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-danger:hover,
.light-btn-danger:focus {
	color: #fff;
	border-color: var(--light-error-color-hover);
	background: var(--light-error-color-hover);
}

.light-btn-danger:hover>a:only-child,
.light-btn-danger:focus>a:only-child {
	color: currentcolor;
}

.light-btn-danger:hover>a:only-child::after,
.light-btn-danger:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-danger:active {
	color: #fff;
	border-color: var(--light-error-color-active);
	background: var(--light-error-color-active);
}

.light-btn-danger:active>a:only-child {
	color: currentcolor;
}

.light-btn-danger:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-danger[disabled],
.light-btn-danger[disabled]:hover,
.light-btn-danger[disabled]:focus,
.light-btn-danger[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-danger[disabled]>a:only-child,
.light-btn-danger[disabled]:hover>a:only-child,
.light-btn-danger[disabled]:focus>a:only-child,
.light-btn-danger[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-danger[disabled]>a:only-child::after,
.light-btn-danger[disabled]:hover>a:only-child::after,
.light-btn-danger[disabled]:focus>a:only-child::after,
.light-btn-danger[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-link {
	color: var(--light-primary-color);
	border-color: transparent;
	background: transparent;
	box-shadow: none;
}

.light-btn-link>a:only-child {
	color: currentcolor;
}

.light-btn-link>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-link:hover,
.light-btn-link:focus {
	color: var(--light-primary-color-hover);
	border-color: var(--light-primary-color-hover);
	background: transparent;
}

.light-btn-link:hover>a:only-child,
.light-btn-link:focus>a:only-child {
	color: currentcolor;
}

.light-btn-link:hover>a:only-child::after,
.light-btn-link:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-link:active {
	color: var(--light-primary-color-active);
	border-color: var(--light-primary-color-active);
	background: transparent;
}

.light-btn-link:active>a:only-child {
	color: currentcolor;
}

.light-btn-link:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-link[disabled],
.light-btn-link[disabled]:hover,
.light-btn-link[disabled]:focus,
.light-btn-link[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-link[disabled]>a:only-child,
.light-btn-link[disabled]:hover>a:only-child,
.light-btn-link[disabled]:focus>a:only-child,
.light-btn-link[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-link[disabled]>a:only-child::after,
.light-btn-link[disabled]:hover>a:only-child::after,
.light-btn-link[disabled]:focus>a:only-child::after,
.light-btn-link[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-link:hover {
	background: transparent;
}

.light-btn-link:hover,
.light-btn-link:focus,
.light-btn-link:active {
	border-color: transparent;
}

.light-btn-link[disabled],
.light-btn-link[disabled]:hover,
.light-btn-link[disabled]:focus,
.light-btn-link[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: transparent;
	background: transparent;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-link[disabled]>a:only-child,
.light-btn-link[disabled]:hover>a:only-child,
.light-btn-link[disabled]:focus>a:only-child,
.light-btn-link[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-link[disabled]>a:only-child::after,
.light-btn-link[disabled]:hover>a:only-child::after,
.light-btn-link[disabled]:focus>a:only-child::after,
.light-btn-link[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-text {
	color: rgba(0, 0, 0, 0.85);
	border-color: transparent;
	background: transparent;
	box-shadow: none;
}

.light-btn-text>a:only-child {
	color: currentcolor;
}

.light-btn-text>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-text:hover,
.light-btn-text:focus {
	color: var(--light-primary-color-hover);
	border-color: var(--light-primary-color-hover);
	background: transparent;
}

.light-btn-text:hover>a:only-child,
.light-btn-text:focus>a:only-child {
	color: currentcolor;
}

.light-btn-text:hover>a:only-child::after,
.light-btn-text:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-text:active {
	color: var(--light-primary-color-active);
	border-color: var(--light-primary-color-active);
	background: transparent;
}

.light-btn-text:active>a:only-child {
	color: currentcolor;
}

.light-btn-text:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-text[disabled],
.light-btn-text[disabled]:hover,
.light-btn-text[disabled]:focus,
.light-btn-text[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-text[disabled]>a:only-child,
.light-btn-text[disabled]:hover>a:only-child,
.light-btn-text[disabled]:focus>a:only-child,
.light-btn-text[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-text[disabled]>a:only-child::after,
.light-btn-text[disabled]:hover>a:only-child::after,
.light-btn-text[disabled]:focus>a:only-child::after,
.light-btn-text[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-text:hover,
.light-btn-text:focus {
	color: rgba(0, 0, 0, 0.85);
	background: rgba(0, 0, 0, 0.018);
	border-color: transparent;
}

.light-btn-text:active {
	color: rgba(0, 0, 0, 0.85);
	background: rgba(0, 0, 0, 0.028);
	border-color: transparent;
}

.light-btn-text[disabled],
.light-btn-text[disabled]:hover,
.light-btn-text[disabled]:focus,
.light-btn-text[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: transparent;
	background: transparent;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-text[disabled]>a:only-child,
.light-btn-text[disabled]:hover>a:only-child,
.light-btn-text[disabled]:focus>a:only-child,
.light-btn-text[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-text[disabled]>a:only-child::after,
.light-btn-text[disabled]:hover>a:only-child::after,
.light-btn-text[disabled]:focus>a:only-child::after,
.light-btn-text[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous {
	color: var(--light-error-color);
	border-color: var(--light-error-color);
	background: #fff;
}

.light-btn-dangerous>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous:hover,
.light-btn-dangerous:focus {
	color: var(--light-error-color-hover);
	border-color: var(--light-error-color-hover);
	background: #fff;
}

.light-btn-dangerous:hover>a:only-child,
.light-btn-dangerous:focus>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous:hover>a:only-child::after,
.light-btn-dangerous:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous:active {
	color: var(--light-error-color-active);
	border-color: var(--light-error-color-active);
	background: #fff;
}

.light-btn-dangerous:active>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous[disabled],
.light-btn-dangerous[disabled]:hover,
.light-btn-dangerous[disabled]:focus,
.light-btn-dangerous[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-dangerous[disabled]>a:only-child,
.light-btn-dangerous[disabled]:hover>a:only-child,
.light-btn-dangerous[disabled]:focus>a:only-child,
.light-btn-dangerous[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous[disabled]>a:only-child::after,
.light-btn-dangerous[disabled]:hover>a:only-child::after,
.light-btn-dangerous[disabled]:focus>a:only-child::after,
.light-btn-dangerous[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-primary {
	color: #fff;
	border-color: var(--light-error-color);
	background: var(--light-error-color);
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.light-btn-dangerous.light-btn-primary>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-primary>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-primary:hover,
.light-btn-dangerous.light-btn-primary:focus {
	color: #fff;
	border-color: var(--light-error-color-hover);
	background: var(--light-error-color-hover);
}

.light-btn-dangerous.light-btn-primary:hover>a:only-child,
.light-btn-dangerous.light-btn-primary:focus>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-primary:hover>a:only-child::after,
.light-btn-dangerous.light-btn-primary:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-primary:active {
	color: #fff;
	border-color: var(--light-error-color-active);
	background: var(--light-error-color-active);
}

.light-btn-dangerous.light-btn-primary:active>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-primary:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-primary[disabled],
.light-btn-dangerous.light-btn-primary[disabled]:hover,
.light-btn-dangerous.light-btn-primary[disabled]:focus,
.light-btn-dangerous.light-btn-primary[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-dangerous.light-btn-primary[disabled]>a:only-child,
.light-btn-dangerous.light-btn-primary[disabled]:hover>a:only-child,
.light-btn-dangerous.light-btn-primary[disabled]:focus>a:only-child,
.light-btn-dangerous.light-btn-primary[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-primary[disabled]>a:only-child::after,
.light-btn-dangerous.light-btn-primary[disabled]:hover>a:only-child::after,
.light-btn-dangerous.light-btn-primary[disabled]:focus>a:only-child::after,
.light-btn-dangerous.light-btn-primary[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-link {
	color: var(--light-error-color);
	border-color: transparent;
	background: transparent;
	box-shadow: none;
}

.light-btn-dangerous.light-btn-link>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-link>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-link:hover,
.light-btn-dangerous.light-btn-link:focus {
	color: var(--light-primary-color-hover);
	border-color: var(--light-primary-color-hover);
	background: transparent;
}

.light-btn-dangerous.light-btn-link:hover>a:only-child,
.light-btn-dangerous.light-btn-link:focus>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-link:hover>a:only-child::after,
.light-btn-dangerous.light-btn-link:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-link:active {
	color: var(--light-primary-color-active);
	border-color: var(--light-primary-color-active);
	background: transparent;
}

.light-btn-dangerous.light-btn-link:active>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-link:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-link[disabled],
.light-btn-dangerous.light-btn-link[disabled]:hover,
.light-btn-dangerous.light-btn-link[disabled]:focus,
.light-btn-dangerous.light-btn-link[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-dangerous.light-btn-link[disabled]>a:only-child,
.light-btn-dangerous.light-btn-link[disabled]:hover>a:only-child,
.light-btn-dangerous.light-btn-link[disabled]:focus>a:only-child,
.light-btn-dangerous.light-btn-link[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-link[disabled]>a:only-child::after,
.light-btn-dangerous.light-btn-link[disabled]:hover>a:only-child::after,
.light-btn-dangerous.light-btn-link[disabled]:focus>a:only-child::after,
.light-btn-dangerous.light-btn-link[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-link:hover,
.light-btn-dangerous.light-btn-link:focus {
	color: var(--light-error-color-hover);
	border-color: transparent;
	background: transparent;
}

.light-btn-dangerous.light-btn-link:hover>a:only-child,
.light-btn-dangerous.light-btn-link:focus>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-link:hover>a:only-child::after,
.light-btn-dangerous.light-btn-link:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-link:active {
	color: var(--light-error-color-active);
	border-color: transparent;
	background: transparent;
}

.light-btn-dangerous.light-btn-link:active>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-link:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-link[disabled],
.light-btn-dangerous.light-btn-link[disabled]:hover,
.light-btn-dangerous.light-btn-link[disabled]:focus,
.light-btn-dangerous.light-btn-link[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: transparent;
	background: transparent;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-dangerous.light-btn-link[disabled]>a:only-child,
.light-btn-dangerous.light-btn-link[disabled]:hover>a:only-child,
.light-btn-dangerous.light-btn-link[disabled]:focus>a:only-child,
.light-btn-dangerous.light-btn-link[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-link[disabled]>a:only-child::after,
.light-btn-dangerous.light-btn-link[disabled]:hover>a:only-child::after,
.light-btn-dangerous.light-btn-link[disabled]:focus>a:only-child::after,
.light-btn-dangerous.light-btn-link[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-text {
	color: var(--light-error-color);
	border-color: transparent;
	background: transparent;
	box-shadow: none;
}

.light-btn-dangerous.light-btn-text>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-text>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-text:hover,
.light-btn-dangerous.light-btn-text:focus {
	color: var(--light-primary-color-hover);
	border-color: var(--light-primary-color-hover);
	background: transparent;
}

.light-btn-dangerous.light-btn-text:hover>a:only-child,
.light-btn-dangerous.light-btn-text:focus>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-text:hover>a:only-child::after,
.light-btn-dangerous.light-btn-text:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-text:active {
	color: var(--light-primary-color-active);
	border-color: var(--light-primary-color-active);
	background: transparent;
}

.light-btn-dangerous.light-btn-text:active>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-text:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-text[disabled],
.light-btn-dangerous.light-btn-text[disabled]:hover,
.light-btn-dangerous.light-btn-text[disabled]:focus,
.light-btn-dangerous.light-btn-text[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-dangerous.light-btn-text[disabled]>a:only-child,
.light-btn-dangerous.light-btn-text[disabled]:hover>a:only-child,
.light-btn-dangerous.light-btn-text[disabled]:focus>a:only-child,
.light-btn-dangerous.light-btn-text[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-text[disabled]>a:only-child::after,
.light-btn-dangerous.light-btn-text[disabled]:hover>a:only-child::after,
.light-btn-dangerous.light-btn-text[disabled]:focus>a:only-child::after,
.light-btn-dangerous.light-btn-text[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-text:hover,
.light-btn-dangerous.light-btn-text:focus {
	color: var(--light-error-color-hover);
	border-color: transparent;
	background: rgba(0, 0, 0, 0.018);
}

.light-btn-dangerous.light-btn-text:hover>a:only-child,
.light-btn-dangerous.light-btn-text:focus>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-text:hover>a:only-child::after,
.light-btn-dangerous.light-btn-text:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-text:active {
	color: var(--light-error-color-active);
	border-color: transparent;
	background: rgba(0, 0, 0, 0.028);
}

.light-btn-dangerous.light-btn-text:active>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-text:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-dangerous.light-btn-text[disabled],
.light-btn-dangerous.light-btn-text[disabled]:hover,
.light-btn-dangerous.light-btn-text[disabled]:focus,
.light-btn-dangerous.light-btn-text[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: transparent;
	background: transparent;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-dangerous.light-btn-text[disabled]>a:only-child,
.light-btn-dangerous.light-btn-text[disabled]:hover>a:only-child,
.light-btn-dangerous.light-btn-text[disabled]:focus>a:only-child,
.light-btn-dangerous.light-btn-text[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-dangerous.light-btn-text[disabled]>a:only-child::after,
.light-btn-dangerous.light-btn-text[disabled]:hover>a:only-child::after,
.light-btn-dangerous.light-btn-text[disabled]:focus>a:only-child::after,
.light-btn-dangerous.light-btn-text[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-icon-only {
	width: 32px;
	height: 32px;
	padding: 2.4px 0;
	font-size: 16px;
	border-radius: 2px;
	vertical-align: -3px;
}

.light-btn-icon-only>* {
	font-size: 16px;
}

.light-btn-icon-only.light-btn-lg {
	width: 40px;
	height: 40px;
	padding: 4.9px 0;
	font-size: 18px;
	border-radius: 2px;
}

.light-btn-icon-only.light-btn-lg>* {
	font-size: 18px;
}

.light-btn-icon-only.light-btn-sm {
	width: 24px;
	height: 24px;
	padding: 0px 0;
	font-size: 14px;
	border-radius: 2px;
}

.light-btn-icon-only.light-btn-sm>* {
	font-size: 14px;
}

.light-btn-icon-only>.anticon {
	display: flex;
	justify-content: center;
}

.light-btn-icon-only .anticon-loading {
	padding: 0 !important;
}

a.light-btn-icon-only {
	vertical-align: -1px;
}

a.light-btn-icon-only>.anticon {
	display: inline;
}

.light-btn-round {
	height: 32px;
	padding: 4px 16px;
	font-size: 14px;
	border-radius: 32px;
}

.light-btn-round.light-btn-lg {
	height: 40px;
	padding: 6.4px 20px;
	font-size: 16px;
	border-radius: 40px;
}

.light-btn-round.light-btn-sm {
	height: 24px;
	padding: 0px 12px;
	font-size: 14px;
	border-radius: 24px;
}

.light-btn-round.light-btn-icon-only {
	width: auto;
}

.light-btn-circle {
	min-width: 32px;
	padding-right: 0;
	padding-left: 0;
	text-align: center;
	border-radius: 50%;
}

.light-btn-circle.light-btn-lg {
	min-width: 40px;
	border-radius: 50%;
}

.light-btn-circle.light-btn-sm {
	min-width: 24px;
	border-radius: 50%;
}

.light-btn::before {
	position: absolute;
	top: -1px;
	right: -1px;
	bottom: -1px;
	left: -1px;
	z-index: 1;
	display: none;
	background: #fff;
	border-radius: inherit;
	opacity: 0.35;
	transition: opacity 0.2s;
	content: "";
	pointer-events: none;
}

.light-btn .anticon {
	transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-btn .anticon.anticon-plus>svg,
.light-btn .anticon.anticon-minus>svg {
	shape-rendering: optimizespeed;
}

.light-btn.light-btn-loading {
	position: relative;
	cursor: default;
}

.light-btn.light-btn-loading::before {
	display: block;
}

.light-btn>.light-btn-loading-icon {
	transition: width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-btn>.light-btn-loading-icon .anticon {
	padding-right: 8px;
	animation: none;
}

.light-btn>.light-btn-loading-icon .anticon svg {
	animation: loadingCircle 1s infinite linear;
}

.light-btn-group {
	position: relative;
	display: inline-flex;
}

.light-btn-group>.light-btn,
.light-btn-group>span>.light-btn {
	position: relative;
}

.light-btn-group>.light-btn:hover,
.light-btn-group>span>.light-btn:hover,
.light-btn-group>.light-btn:focus,
.light-btn-group>span>.light-btn:focus,
.light-btn-group>.light-btn:active,
.light-btn-group>span>.light-btn:active {
	z-index: 2;
}

.light-btn-group>.light-btn[disabled],
.light-btn-group>span>.light-btn[disabled] {
	z-index: 0;
}

.light-btn-group .light-btn-icon-only {
	font-size: 14px;
}

.light-btn-group .light-btn+.light-btn,
.light-btn+.light-btn-group,
.light-btn-group span+.light-btn,
.light-btn-group .light-btn+span,
.light-btn-group>span+span,
.light-btn-group+.light-btn,
.light-btn-group+.light-btn-group {
	margin-left: -1px;
}

.light-btn-group .light-btn-primary+.light-btn:not(.light-btn-primary):not([disabled]) {
	border-left-color: transparent;
}

.light-btn-group .light-btn {
	border-radius: 0;
}

.light-btn-group>.light-btn:first-child,
.light-btn-group>span:first-child>.light-btn {
	margin-left: 0;
}

.light-btn-group>.light-btn:only-child {
	border-radius: 2px;
}

.light-btn-group>span:only-child>.light-btn {
	border-radius: 2px;
}

.light-btn-group>.light-btn:first-child:not(:last-child),
.light-btn-group>span:first-child:not(:last-child)>.light-btn {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.light-btn-group>.light-btn:last-child:not(:first-child),
.light-btn-group>span:last-child:not(:first-child)>.light-btn {
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.light-btn-group-sm>.light-btn:only-child {
	border-radius: 2px;
}

.light-btn-group-sm>span:only-child>.light-btn {
	border-radius: 2px;
}

.light-btn-group-sm>.light-btn:first-child:not(:last-child),
.light-btn-group-sm>span:first-child:not(:last-child)>.light-btn {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.light-btn-group-sm>.light-btn:last-child:not(:first-child),
.light-btn-group-sm>span:last-child:not(:first-child)>.light-btn {
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.light-btn-group>.light-btn-group {
	float: left;
}

.light-btn-group>.light-btn-group:not(:first-child):not(:last-child)>.light-btn {
	border-radius: 0;
}

.light-btn-group>.light-btn-group:first-child:not(:last-child)>.light-btn:last-child {
	padding-right: 8px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.light-btn-group>.light-btn-group:last-child:not(:first-child)>.light-btn:first-child {
	padding-left: 8px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.light-btn-rtl.light-btn-group .light-btn+.light-btn,
.light-btn-rtl.light-btn+.light-btn-group,
.light-btn-rtl.light-btn-group span+.light-btn,
.light-btn-rtl.light-btn-group .light-btn+span,
.light-btn-rtl.light-btn-group>span+span,
.light-btn-rtl.light-btn-group+.light-btn,
.light-btn-rtl.light-btn-group+.light-btn-group,
.light-btn-group-rtl.light-btn-group .light-btn+.light-btn,
.light-btn-group-rtl.light-btn+.light-btn-group,
.light-btn-group-rtl.light-btn-group span+.light-btn,
.light-btn-group-rtl.light-btn-group .light-btn+span,
.light-btn-group-rtl.light-btn-group>span+span,
.light-btn-group-rtl.light-btn-group+.light-btn,
.light-btn-group-rtl.light-btn-group+.light-btn-group {
	margin-right: -1px;
	margin-left: auto;
}

.light-btn-group.light-btn-group-rtl {
	direction: rtl;
}

.light-btn-group-rtl.light-btn-group>.light-btn:first-child:not(:last-child),
.light-btn-group-rtl.light-btn-group>span:first-child:not(:last-child)>.light-btn {
	border-radius: 0 2px 2px 0;
}

.light-btn-group-rtl.light-btn-group>.light-btn:last-child:not(:first-child),
.light-btn-group-rtl.light-btn-group>span:last-child:not(:first-child)>.light-btn {
	border-radius: 2px 0 0 2px;
}

.light-btn-group-rtl.light-btn-group-sm>.light-btn:first-child:not(:last-child),
.light-btn-group-rtl.light-btn-group-sm>span:first-child:not(:last-child)>.light-btn {
	border-radius: 0 2px 2px 0;
}

.light-btn-group-rtl.light-btn-group-sm>.light-btn:last-child:not(:first-child),
.light-btn-group-rtl.light-btn-group-sm>span:last-child:not(:first-child)>.light-btn {
	border-radius: 2px 0 0 2px;
}

.light-btn:focus>span,
.light-btn:active>span {
	position: relative;
}

.light-btn>.anticon+span,
.light-btn>span+.anticon {
	margin-left: 8px;
}

.light-btn.light-btn-background-ghost {
	color: #fff;
	border-color: #fff;
}

.light-btn.light-btn-background-ghost,
.light-btn.light-btn-background-ghost:hover,
.light-btn.light-btn-background-ghost:active,
.light-btn.light-btn-background-ghost:focus {
	background: transparent;
}

.light-btn.light-btn-background-ghost:hover,
.light-btn.light-btn-background-ghost:focus {
	color: var(--light-primary-color-hover);
	border-color: var(--light-primary-color-hover);
}

.light-btn.light-btn-background-ghost:active {
	color: var(--light-primary-color-active);
	border-color: var(--light-primary-color-active);
}

.light-btn.light-btn-background-ghost[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background: transparent;
	border-color: #d9d9d9;
}

.light-btn-background-ghost.light-btn-primary {
	color: var(--light-primary-color);
	border-color: var(--light-primary-color);
	text-shadow: none;
}

.light-btn-background-ghost.light-btn-primary>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-primary>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-primary:hover,
.light-btn-background-ghost.light-btn-primary:focus {
	color: var(--light-primary-color-hover);
	border-color: var(--light-primary-color-hover);
	background: transparent;
}

.light-btn-background-ghost.light-btn-primary:hover>a:only-child,
.light-btn-background-ghost.light-btn-primary:focus>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-primary:hover>a:only-child::after,
.light-btn-background-ghost.light-btn-primary:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-primary:active {
	color: var(--light-primary-color-active);
	border-color: var(--light-primary-color-active);
	background: transparent;
}

.light-btn-background-ghost.light-btn-primary:active>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-primary:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-primary[disabled],
.light-btn-background-ghost.light-btn-primary[disabled]:hover,
.light-btn-background-ghost.light-btn-primary[disabled]:focus,
.light-btn-background-ghost.light-btn-primary[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-background-ghost.light-btn-primary[disabled]>a:only-child,
.light-btn-background-ghost.light-btn-primary[disabled]:hover>a:only-child,
.light-btn-background-ghost.light-btn-primary[disabled]:focus>a:only-child,
.light-btn-background-ghost.light-btn-primary[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-primary[disabled]>a:only-child::after,
.light-btn-background-ghost.light-btn-primary[disabled]:hover>a:only-child::after,
.light-btn-background-ghost.light-btn-primary[disabled]:focus>a:only-child::after,
.light-btn-background-ghost.light-btn-primary[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-danger {
	color: var(--light-error-color);
	border-color: var(--light-error-color);
	text-shadow: none;
}

.light-btn-background-ghost.light-btn-danger>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-danger>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-danger:hover,
.light-btn-background-ghost.light-btn-danger:focus {
	color: var(--light-error-color-hover);
	border-color: var(--light-error-color-hover);
	background: transparent;
}

.light-btn-background-ghost.light-btn-danger:hover>a:only-child,
.light-btn-background-ghost.light-btn-danger:focus>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-danger:hover>a:only-child::after,
.light-btn-background-ghost.light-btn-danger:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-danger:active {
	color: var(--light-error-color-active);
	border-color: var(--light-error-color-active);
	background: transparent;
}

.light-btn-background-ghost.light-btn-danger:active>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-danger:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-danger[disabled],
.light-btn-background-ghost.light-btn-danger[disabled]:hover,
.light-btn-background-ghost.light-btn-danger[disabled]:focus,
.light-btn-background-ghost.light-btn-danger[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-background-ghost.light-btn-danger[disabled]>a:only-child,
.light-btn-background-ghost.light-btn-danger[disabled]:hover>a:only-child,
.light-btn-background-ghost.light-btn-danger[disabled]:focus>a:only-child,
.light-btn-background-ghost.light-btn-danger[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-danger[disabled]>a:only-child::after,
.light-btn-background-ghost.light-btn-danger[disabled]:hover>a:only-child::after,
.light-btn-background-ghost.light-btn-danger[disabled]:focus>a:only-child::after,
.light-btn-background-ghost.light-btn-danger[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-dangerous {
	color: var(--light-error-color);
	border-color: var(--light-error-color);
	text-shadow: none;
}

.light-btn-background-ghost.light-btn-dangerous>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-dangerous>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-dangerous:hover,
.light-btn-background-ghost.light-btn-dangerous:focus {
	color: var(--light-error-color-hover);
	border-color: var(--light-error-color-hover);
	background: transparent;
}

.light-btn-background-ghost.light-btn-dangerous:hover>a:only-child,
.light-btn-background-ghost.light-btn-dangerous:focus>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-dangerous:hover>a:only-child::after,
.light-btn-background-ghost.light-btn-dangerous:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-dangerous:active {
	color: var(--light-error-color-active);
	border-color: var(--light-error-color-active);
	background: transparent;
}

.light-btn-background-ghost.light-btn-dangerous:active>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-dangerous:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-dangerous[disabled],
.light-btn-background-ghost.light-btn-dangerous[disabled]:hover,
.light-btn-background-ghost.light-btn-dangerous[disabled]:focus,
.light-btn-background-ghost.light-btn-dangerous[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-background-ghost.light-btn-dangerous[disabled]>a:only-child,
.light-btn-background-ghost.light-btn-dangerous[disabled]:hover>a:only-child,
.light-btn-background-ghost.light-btn-dangerous[disabled]:focus>a:only-child,
.light-btn-background-ghost.light-btn-dangerous[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-dangerous[disabled]>a:only-child::after,
.light-btn-background-ghost.light-btn-dangerous[disabled]:hover>a:only-child::after,
.light-btn-background-ghost.light-btn-dangerous[disabled]:focus>a:only-child::after,
.light-btn-background-ghost.light-btn-dangerous[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-dangerous.light-btn-link {
	color: var(--light-error-color);
	border-color: transparent;
	text-shadow: none;
}

.light-btn-background-ghost.light-btn-dangerous.light-btn-link>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-dangerous.light-btn-link>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-dangerous.light-btn-link:hover,
.light-btn-background-ghost.light-btn-dangerous.light-btn-link:focus {
	color: var(--light-error-color-active);
	border-color: transparent;
	background: transparent;
}

.light-btn-background-ghost.light-btn-dangerous.light-btn-link:hover>a:only-child,
.light-btn-background-ghost.light-btn-dangerous.light-btn-link:focus>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-dangerous.light-btn-link:hover>a:only-child::after,
.light-btn-background-ghost.light-btn-dangerous.light-btn-link:focus>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-dangerous.light-btn-link:active {
	color: var(--light-error-color-active);
	border-color: transparent;
	background: transparent;
}

.light-btn-background-ghost.light-btn-dangerous.light-btn-link:active>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-dangerous.light-btn-link:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-background-ghost.light-btn-dangerous.light-btn-link[disabled],
.light-btn-background-ghost.light-btn-dangerous.light-btn-link[disabled]:hover,
.light-btn-background-ghost.light-btn-dangerous.light-btn-link[disabled]:focus,
.light-btn-background-ghost.light-btn-dangerous.light-btn-link[disabled]:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	background: #f5f5f5;
	text-shadow: none;
	box-shadow: none;
}

.light-btn-background-ghost.light-btn-dangerous.light-btn-link[disabled]>a:only-child,
.light-btn-background-ghost.light-btn-dangerous.light-btn-link[disabled]:hover>a:only-child,
.light-btn-background-ghost.light-btn-dangerous.light-btn-link[disabled]:focus>a:only-child,
.light-btn-background-ghost.light-btn-dangerous.light-btn-link[disabled]:active>a:only-child {
	color: currentcolor;
}

.light-btn-background-ghost.light-btn-dangerous.light-btn-link[disabled]>a:only-child::after,
.light-btn-background-ghost.light-btn-dangerous.light-btn-link[disabled]:hover>a:only-child::after,
.light-btn-background-ghost.light-btn-dangerous.light-btn-link[disabled]:focus>a:only-child::after,
.light-btn-background-ghost.light-btn-dangerous.light-btn-link[disabled]:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

.light-btn-two-chinese-chars::first-letter {
	letter-spacing: 0.34em;
}

.light-btn-two-chinese-chars>*:not(.anticon) {
	margin-right: -0.34em;
	letter-spacing: 0.34em;
}

.light-btn.light-btn-block {
	width: 100%;
}

.light-btn:empty {
	display: inline-block;
	width: 0;
	visibility: hidden;
	content: "\a0";
}

a.light-btn {
	padding-top: 0.01px !important;
	line-height: 30px;
}

a.light-btn-disabled {
	cursor: not-allowed;
}

a.light-btn-disabled>* {
	pointer-events: none;
}

a.light-btn-disabled,
a.light-btn-disabled:hover,
a.light-btn-disabled:focus,
a.light-btn-disabled:active {
	color: rgba(0, 0, 0, 0.25);
	border-color: transparent;
	background: transparent;
	text-shadow: none;
	box-shadow: none;
}

a.light-btn-disabled>a:only-child,
a.light-btn-disabled:hover>a:only-child,
a.light-btn-disabled:focus>a:only-child,
a.light-btn-disabled:active>a:only-child {
	color: currentcolor;
}

a.light-btn-disabled>a:only-child::after,
a.light-btn-disabled:hover>a:only-child::after,
a.light-btn-disabled:focus>a:only-child::after,
a.light-btn-disabled:active>a:only-child::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	content: "";
}

a.light-btn-lg {
	line-height: 38px;
}

a.light-btn-sm {
	line-height: 22px;
}

.light-btn-rtl {
	direction: rtl;
}

.light-btn-group-rtl.light-btn-group .light-btn-primary:last-child:not(:first-child),
.light-btn-group-rtl.light-btn-group .light-btn-primary+.light-btn-primary {
	border-right-color: var(--light-primary-5);
	border-left-color: #d9d9d9;
}

.light-btn-group-rtl.light-btn-group .light-btn-primary:last-child:not(:first-child)[disabled],
.light-btn-group-rtl.light-btn-group .light-btn-primary+.light-btn-primary[disabled] {
	border-right-color: #d9d9d9;
	border-left-color: var(--light-primary-5);
}

.light-btn-rtl.light-btn>.light-btn-loading-icon .anticon {
	padding-right: 0;
	padding-left: 8px;
}

.light-btn-rtl.light-btn>.anticon+span,
.light-btn-rtl.light-btn>span+.anticon {
	margin-right: 8px;
	margin-left: 0;
}

.light-picker-calendar {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	background: #fff;
}

.light-picker-calendar-header {
	display: flex;
	justify-content: flex-end;
	padding: 12px 0;
}

.light-picker-calendar-header .light-picker-calendar-year-select {
	min-width: 80px;
}

.light-picker-calendar-header .light-picker-calendar-month-select {
	min-width: 70px;
	margin-left: 8px;
}

.light-picker-calendar-header .light-picker-calendar-mode-switch {
	margin-left: 8px;
}

.light-picker-calendar .light-picker-panel {
	background: #fff;
	border: 0;
	border-top: 1px solid var(--border-color);
	border-radius: 0;
}

.light-picker-calendar .light-picker-panel .light-picker-month-panel,
.light-picker-calendar .light-picker-panel .light-picker-date-panel {
	width: auto;
}

.light-picker-calendar .light-picker-panel .light-picker-body {
	padding: 8px 0;
}

.light-picker-calendar .light-picker-panel .light-picker-content {
	width: 100%;
}

.light-picker-calendar-mini {
	border-radius: 2px;
}

.light-picker-calendar-mini .light-picker-calendar-header {
	padding-right: 8px;
	padding-left: 8px;
}

.light-picker-calendar-mini .light-picker-panel {
	border-radius: 0 0 2px 2px;
}

.light-picker-calendar-mini .light-picker-content {
	height: 256px;
}

.light-picker-calendar-mini .light-picker-content th {
	height: auto;
	padding: 0;
	line-height: 18px;
}

.light-picker-calendar-mini .light-picker-cell::before {
	pointer-events: none;
}

.light-picker-calendar-full .light-picker-panel {
	display: block;
	width: 100%;
	text-align: right;
	background: #fff;
	border: 0;
}

.light-picker-calendar-full .light-picker-panel .light-picker-body th,
.light-picker-calendar-full .light-picker-panel .light-picker-body td {
	padding: 0;
}

.light-picker-calendar-full .light-picker-panel .light-picker-body th {
	height: auto;
	padding: 0 12px 5px 0;
	line-height: 18px;
}

.light-picker-calendar-full .light-picker-panel .light-picker-cell::before {
	display: none;
}

.light-picker-calendar-full .light-picker-panel .light-picker-cell:hover .light-picker-calendar-date {
	background: #f5f5f5;
}

.light-picker-calendar-full .light-picker-panel .light-picker-cell .light-picker-calendar-date-today::before {
	display: none;
}

.light-picker-calendar-full .light-picker-panel .light-picker-cell-selected .light-picker-calendar-date,
.light-picker-calendar-full .light-picker-panel .light-picker-cell-selected:hover .light-picker-calendar-date,
.light-picker-calendar-full .light-picker-panel .light-picker-cell-selected .light-picker-calendar-date-today,
.light-picker-calendar-full .light-picker-panel .light-picker-cell-selected:hover .light-picker-calendar-date-today {
	background: var(--light-primary-1);
}

.light-picker-calendar-full .light-picker-panel .light-picker-cell-selected .light-picker-calendar-date .light-picker-calendar-date-value,
.light-picker-calendar-full .light-picker-panel .light-picker-cell-selected:hover .light-picker-calendar-date .light-picker-calendar-date-value,
.light-picker-calendar-full .light-picker-panel .light-picker-cell-selected .light-picker-calendar-date-today .light-picker-calendar-date-value,
.light-picker-calendar-full .light-picker-panel .light-picker-cell-selected:hover .light-picker-calendar-date-today .light-picker-calendar-date-value {
	color: var(--light-primary-color);
}

.light-picker-calendar-full .light-picker-panel .light-picker-calendar-date {
	display: block;
	width: auto;
	height: auto;
	margin: 0 4px;
	padding: 4px 8px 0;
	border: 0;
	border-top: 2px solid var(--border-color);
	border-radius: 0;
	transition: background 0.3s;
}

.light-picker-calendar-full .light-picker-panel .light-picker-calendar-date-value {
	line-height: 24px;
	transition: color 0.3s;
}

.light-picker-calendar-full .light-picker-panel .light-picker-calendar-date-content {
	position: static;
	width: auto;
	height: 86px;
	overflow-y: auto;
	color: rgba(0, 0, 0, 0.85);
	line-height: 1.5715;
	text-align: left;
}

.light-picker-calendar-full .light-picker-panel .light-picker-calendar-date-today {
	border-color: var(--light-primary-color);
}

.light-picker-calendar-full .light-picker-panel .light-picker-calendar-date-today .light-picker-calendar-date-value {
	color: rgba(0, 0, 0, 0.85);
}

@media only screen and (max-width: 480px) {
	.light-picker-calendar-header {
		display: block;
	}

	.light-picker-calendar-header .light-picker-calendar-year-select {
		width: 50%;
	}

	.light-picker-calendar-header .light-picker-calendar-month-select {
		width: calc(50% - 8px);
	}

	.light-picker-calendar-header .light-picker-calendar-mode-switch {
		width: 100%;
		margin-top: 8px;
		margin-left: 0;
	}

	.light-picker-calendar-header .light-picker-calendar-mode-switch>label {
		width: 50%;
		text-align: center;
	}
}

.light-picker-calendar-rtl {
	direction: rtl;
}

.light-picker-calendar-rtl .light-picker-calendar-header .light-picker-calendar-month-select {
	margin-right: 8px;
	margin-left: 0;
}

.light-picker-calendar-rtl .light-picker-calendar-header .light-picker-calendar-mode-switch {
	margin-right: 8px;
	margin-left: 0;
}

.light-picker-calendar-rtl.light-picker-calendar-full .light-picker-panel {
	text-align: left;
}

.light-picker-calendar-rtl.light-picker-calendar-full .light-picker-panel .light-picker-body th {
	padding: 0 0 5px 12px;
}

.light-picker-calendar-rtl.light-picker-calendar-full .light-picker-panel .light-picker-calendar-date-content {
	text-align: right;
}

.light-card {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	background: #fff;
	border-radius: 2px;
}

.light-card-rtl {
	direction: rtl;
}

.light-card-hoverable {
	cursor: pointer;
	transition: box-shadow 0.3s, border-color 0.3s;
}

.light-card-hoverable:hover {
	border-color: transparent;
	box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
		0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.light-card-bordered {
	border: 1px solid var(--border-color);
}

.light-card-head {
	min-height: 48px;
	margin-bottom: -1px;
	padding: 0 24px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	background: transparent;
	border-bottom: 1px solid var(--border-color);
	border-radius: 2px 2px 0 0;
}

.light-card-head::before {
	display: table;
	content: "";
}

.light-card-head::after {
	display: table;
	clear: both;
	content: "";
}

.light-card-head::before {
	display: table;
	content: "";
}

.light-card-head::after {
	display: table;
	clear: both;
	content: "";
}

.light-card-head-wrapper {
	display: flex;
	align-items: center;
}

.light-card-head-title {
	display: inline-block;
	flex: 1;
	padding: 16px 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.light-card-head-title>.light-typography,
.light-card-head-title>.light-typography-edit-content {
	left: 0;
	margin-top: 0;
	margin-bottom: 0;
}

.light-card-head .light-tabs-top {
	clear: both;
	margin-bottom: -17px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	font-size: 14px;
}

.light-card-head .light-tabs-top-bar {
	border-bottom: 1px solid var(--border-color);
}

.light-card-extra {
	margin-left: auto;
	padding: 16px 0;
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	font-size: 14px;
}

.light-card-rtl .light-card-extra {
	margin-right: auto;
	margin-left: 0;
}

.light-card-body {
	padding: 24px;
}

.light-card-body::before {
	display: table;
	content: "";
}

.light-card-body::after {
	display: table;
	clear: both;
	content: "";
}

.light-card-body::before {
	display: table;
	content: "";
}

.light-card-body::after {
	display: table;
	clear: both;
	content: "";
}

.light-card-contain-grid .light-card-body {
	display: flex;
	flex-wrap: wrap;
}

.light-card-contain-grid:not(.light-card-loading) .light-card-body {
	margin: -1px 0 0 -1px;
	padding: 0;
}

.light-card-grid {
	width: 33.33%;
	padding: 24px;
	border: 0;
	border-radius: 0;
	box-shadow: 1px 0 0 0 var(--border-color), 0 1px 0 0 var(--border-color),
		1px 1px 0 0 var(--border-color), 1px 0 0 0 var(--border-color) inset,
		0 1px 0 0 var(--border-color) inset;
	transition: all 0.3s;
}

.light-card-grid-hoverable:hover {
	position: relative;
	z-index: 1;
	box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
		0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}

.light-card-contain-tabs>.light-card-head .light-card-head-title {
	min-height: 32px;
	padding-bottom: 0;
}

.light-card-contain-tabs>.light-card-head .light-card-extra {
	padding-bottom: 0;
}

.light-card-bordered .light-card-cover {
	margin-top: -1px;
	margin-right: -1px;
	margin-left: -1px;
}

.light-card-cover>* {
	display: block;
	width: 100%;
}

.light-card-cover img {
	border-radius: 2px 2px 0 0;
}

.light-card-actions {
	display: flex;
	margin: 0;
	padding: 0;
	list-style: none;
	background: #fff;
	border-top: 1px solid var(--border-color);
}

.light-card-actions::before {
	display: table;
	content: "";
}

.light-card-actions::after {
	display: table;
	clear: both;
	content: "";
}

.light-card-actions::before {
	display: table;
	content: "";
}

.light-card-actions::after {
	display: table;
	clear: both;
	content: "";
}

.light-card-actions>li {
	margin: 12px 0;
	color: rgba(0, 0, 0, 0.45);
	text-align: center;
}

.light-card-actions>li>span {
	position: relative;
	display: block;
	min-width: 32px;
	font-size: 14px;
	line-height: 1.5715;
	cursor: pointer;
}

.light-card-actions>li>span:hover {
	color: var(--light-primary-color);
	transition: color 0.3s;
}

.light-card-actions>li>span a:not(.light-btn),
.light-card-actions>li>span>.anticon {
	display: inline-block;
	width: 100%;
	color: rgba(0, 0, 0, 0.45);
	line-height: 22px;
	transition: color 0.3s;
}

.light-card-actions>li>span a:not(.light-btn):hover,
.light-card-actions>li>span>.anticon:hover {
	color: var(--light-primary-color);
}

.light-card-actions>li>span>.anticon {
	font-size: 16px;
	line-height: 22px;
}

.light-card-actions>li:not(:last-child) {
	border-right: 1px solid var(--border-color);
}

.light-card-rtl .light-card-actions>li:not(:last-child) {
	border-right: none;
	border-left: 1px solid var(--border-color);
}

.light-card-type-inner .light-card-head {
	padding: 0 24px;
	background: #fafafa;
}

.light-card-type-inner .light-card-head-title {
	padding: 12px 0;
	font-size: 14px;
}

.light-card-type-inner .light-card-body {
	padding: 16px 24px;
}

.light-card-type-inner .light-card-extra {
	padding: 13.5px 0;
}

.light-card-meta {
	display: flex;
	margin: -4px 0;
}

.light-card-meta::before {
	display: table;
	content: "";
}

.light-card-meta::after {
	display: table;
	clear: both;
	content: "";
}

.light-card-meta::before {
	display: table;
	content: "";
}

.light-card-meta::after {
	display: table;
	clear: both;
	content: "";
}

.light-card-meta-avatar {
	padding-right: 16px;
}

.light-card-rtl .light-card-meta-avatar {
	padding-right: 0;
	padding-left: 16px;
}

.light-card-meta-detail {
	overflow: hidden;
}

.light-card-meta-detail>div:not(:last-child) {
	margin-bottom: 8px;
}

.light-card-meta-title {
	overflow: hidden;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.light-card-meta-description {
	color: rgba(0, 0, 0, 0.45);
}

.light-card-loading {
	overflow: hidden;
}

.light-card-loading .light-card-body {
	user-select: none;
}

.light-card-small>.light-card-head {
	min-height: 36px;
	padding: 0 12px;
	font-size: 14px;
}

.light-card-small>.light-card-head>.light-card-head-wrapper>.light-card-head-title {
	padding: 8px 0;
}

.light-card-small>.light-card-head>.light-card-head-wrapper>.light-card-extra {
	padding: 8px 0;
	font-size: 14px;
}

.light-card-small>.light-card-body {
	padding: 12px;
}

.light-carousel {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
}

.light-carousel .slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	touch-action: pan-y;
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
}

.light-carousel .slick-list {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
	overflow: hidden;
}

.light-carousel .slick-list:focus {
	outline: none;
}

.light-carousel .slick-list.dragging {
	cursor: pointer;
}

.light-carousel .slick-list .slick-slide {
	pointer-events: none;
}

.light-carousel .slick-list .slick-slide input.light-radio-input,
.light-carousel .slick-list .slick-slide input.light-checkbox-input {
	visibility: hidden;
}

.light-carousel .slick-list .slick-slide.slick-active {
	pointer-events: auto;
}

.light-carousel .slick-list .slick-slide.slick-active input.light-radio-input,
.light-carousel .slick-list .slick-slide.slick-active input.light-checkbox-input {
	visibility: visible;
}

.light-carousel .slick-list .slick-slide>div>div {
	vertical-align: bottom;
}

.light-carousel .slick-slider .slick-track,
.light-carousel .slick-slider .slick-list {
	transform: translate3d(0, 0, 0);
	touch-action: pan-y;
}

.light-carousel .slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block;
}

.light-carousel .slick-track::before,
.light-carousel .slick-track::after {
	display: table;
	content: "";
}

.light-carousel .slick-track::after {
	clear: both;
}

.slick-loading .light-carousel .slick-track {
	visibility: hidden;
}

.light-carousel .slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}

.light-carousel .slick-slide img {
	display: block;
}

.light-carousel .slick-slide.slick-loading img {
	display: none;
}

.light-carousel .slick-slide.dragging img {
	pointer-events: none;
}

.light-carousel .slick-initialized .slick-slide {
	display: block;
}

.light-carousel .slick-loading .slick-slide {
	visibility: hidden;
}

.light-carousel .slick-vertical .slick-slide {
	display: block;
	height: auto;
}

.light-carousel .slick-arrow.slick-hidden {
	display: none;
}

.light-carousel .slick-prev,
.light-carousel .slick-next {
	position: absolute;
	top: 50%;
	display: block;
	width: 20px;
	height: 20px;
	margin-top: -10px;
	padding: 0;
	color: transparent;
	font-size: 0;
	line-height: 0;
	background: transparent;
	border: 0;
	outline: none;
	cursor: pointer;
}

.light-carousel .slick-prev:hover,
.light-carousel .slick-next:hover,
.light-carousel .slick-prev:focus,
.light-carousel .slick-next:focus {
	color: transparent;
	background: transparent;
	outline: none;
}

.light-carousel .slick-prev:hover::before,
.light-carousel .slick-next:hover::before,
.light-carousel .slick-prev:focus::before,
.light-carousel .slick-next:focus::before {
	opacity: 1;
}

.light-carousel .slick-prev.slick-disabled::before,
.light-carousel .slick-next.slick-disabled::before {
	opacity: 0.25;
}

.light-carousel .slick-prev {
	left: -25px;
}

.light-carousel .slick-prev::before {
	content: "←";
}

.light-carousel .slick-next {
	right: -25px;
}

.light-carousel .slick-next::before {
	content: "→";
}

.light-carousel .slick-dots {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 15;
	display: flex !important;
	justify-content: center;
	margin-right: 15%;
	margin-left: 15%;
	padding-left: 0;
	list-style: none;
}

.light-carousel .slick-dots-bottom {
	bottom: 12px;
}

.light-carousel .slick-dots-top {
	top: 12px;
	bottom: auto;
}

.light-carousel .slick-dots li {
	position: relative;
	display: inline-block;
	flex: 0 1 auto;
	box-sizing: content-box;
	width: 16px;
	height: 3px;
	margin: 0 2px;
	margin-right: 3px;
	margin-left: 3px;
	padding: 0;
	text-align: center;
	text-indent: -999px;
	vertical-align: top;
	transition: all 0.5s;
}

.light-carousel .slick-dots li button {
	display: block;
	width: 100%;
	height: 3px;
	padding: 0;
	color: transparent;
	font-size: 0;
	background: #fff;
	border: 0;
	border-radius: 1px;
	outline: none;
	cursor: pointer;
	opacity: 0.3;
	transition: all 0.5s;
}

.light-carousel .slick-dots li button:hover,
.light-carousel .slick-dots li button:focus {
	opacity: 0.75;
}

.light-carousel .slick-dots li.slick-active {
	width: 24px;
}

.light-carousel .slick-dots li.slick-active button {
	background: #fff;
	opacity: 1;
}

.light-carousel .slick-dots li.slick-active:hover,
.light-carousel .slick-dots li.slick-active:focus {
	opacity: 1;
}

.light-carousel-vertical .slick-dots {
	top: 50%;
	bottom: auto;
	flex-direction: column;
	width: 3px;
	height: auto;
	margin: 0;
	transform: translateY(-50%);
}

.light-carousel-vertical .slick-dots-left {
	right: auto;
	left: 12px;
}

.light-carousel-vertical .slick-dots-right {
	right: 12px;
	left: auto;
}

.light-carousel-vertical .slick-dots li {
	width: 3px;
	height: 16px;
	margin: 4px 2px;
	vertical-align: baseline;
}

.light-carousel-vertical .slick-dots li button {
	width: 3px;
	height: 16px;
}

.light-carousel-vertical .slick-dots li.slick-active {
	width: 3px;
	height: 24px;
}

.light-carousel-vertical .slick-dots li.slick-active button {
	width: 3px;
	height: 24px;
}

.light-carousel-rtl {
	direction: rtl;
}

.light-carousel-rtl .light-carousel .slick-track {
	right: 0;
	left: auto;
}

.light-carousel-rtl .light-carousel .slick-prev {
	right: -25px;
	left: auto;
}

.light-carousel-rtl .light-carousel .slick-prev::before {
	content: "→";
}

.light-carousel-rtl .light-carousel .slick-next {
	right: auto;
	left: -25px;
}

.light-carousel-rtl .light-carousel .slick-next::before {
	content: "←";
}

.light-carousel-rtl.light-carousel .slick-dots {
	flex-direction: row-reverse;
}

.light-carousel-rtl.light-carousel-vertical .slick-dots {
	flex-direction: column;
}

@keyframes antCheckboxEffect {
	0% {
		transform: scale(1);
		opacity: 0.5;
	}

	100% {
		transform: scale(1.6);
		opacity: 0;
	}
}

.light-cascader-checkbox {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	top: 0.2em;
	line-height: 1;
	white-space: nowrap;
	outline: none;
	cursor: pointer;
}

.light-cascader-checkbox-wrapper:hover .light-cascader-checkbox-inner,
.light-cascader-checkbox:hover .light-cascader-checkbox-inner,
.light-cascader-checkbox-input:focus+.light-cascader-checkbox-inner {
	border-color: var(--light-primary-color);
}

.light-cascader-checkbox-checked::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid var(--light-primary-color);
	border-radius: 2px;
	visibility: hidden;
	animation: antCheckboxEffect 0.36s ease-in-out;
	animation-fill-mode: backwards;
	content: "";
}

.light-cascader-checkbox:hover::after,
.light-cascader-checkbox-wrapper:hover .light-cascader-checkbox::after {
	visibility: visible;
}

.light-cascader-checkbox-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 16px;
	height: 16px;
	direction: ltr;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	border-collapse: separate;
	transition: all 0.3s;
}

.light-cascader-checkbox-inner::after {
	position: absolute;
	top: 50%;
	left: 21.5%;
	display: table;
	width: 5.71428571px;
	height: 9.14285714px;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(0) translate(-50%, -50%);
	opacity: 0;
	transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
	content: " ";
}

.light-cascader-checkbox-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
}

.light-cascader-checkbox-checked .light-cascader-checkbox-inner::after {
	position: absolute;
	display: table;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(1) translate(-50%, -50%);
	opacity: 1;
	transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
	content: " ";
}

.light-cascader-checkbox-checked .light-cascader-checkbox-inner {
	background-color: var(--light-primary-color);
	border-color: var(--light-primary-color);
}

.light-cascader-checkbox-disabled {
	cursor: not-allowed;
}

.light-cascader-checkbox-disabled.light-cascader-checkbox-checked .light-cascader-checkbox-inner::after {
	border-color: rgba(0, 0, 0, 0.25);
	animation-name: none;
}

.light-cascader-checkbox-disabled .light-cascader-checkbox-input {
	cursor: not-allowed;
	pointer-events: none;
}

.light-cascader-checkbox-disabled .light-cascader-checkbox-inner {
	background-color: #f5f5f5;
	border-color: #d9d9d9 !important;
}

.light-cascader-checkbox-disabled .light-cascader-checkbox-inner::after {
	border-color: #f5f5f5;
	border-collapse: separate;
	animation-name: none;
}

.light-cascader-checkbox-disabled+span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-cascader-checkbox-disabled:hover::after,
.light-cascader-checkbox-wrapper:hover .light-cascader-checkbox-disabled::after {
	visibility: hidden;
}

.light-cascader-checkbox-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: inline-flex;
	align-items: baseline;
	line-height: unset;
	cursor: pointer;
}

.light-cascader-checkbox-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: "\a0";
}

.light-cascader-checkbox-wrapper.light-cascader-checkbox-wrapper-disabled {
	cursor: not-allowed;
}

.light-cascader-checkbox-wrapper+.light-cascader-checkbox-wrapper {
	margin-left: 8px;
}

.light-cascader-checkbox-wrapper.light-cascader-checkbox-wrapper-in-form-item input[type="checkbox"] {
	width: 14px;
	height: 14px;
}

.light-cascader-checkbox+span {
	padding-right: 8px;
	padding-left: 8px;
}

.light-cascader-checkbox-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: inline-block;
}

.light-cascader-checkbox-group-item {
	margin-right: 8px;
}

.light-cascader-checkbox-group-item:last-child {
	margin-right: 0;
}

.light-cascader-checkbox-group-item+.light-cascader-checkbox-group-item {
	margin-left: 0;
}

.light-cascader-checkbox-indeterminate .light-cascader-checkbox-inner {
	background-color: #fff;
	border-color: #d9d9d9;
}

.light-cascader-checkbox-indeterminate .light-cascader-checkbox-inner::after {
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	background-color: var(--light-primary-color);
	border: 0;
	transform: translate(-50%, -50%) scale(1);
	opacity: 1;
	content: " ";
}

.light-cascader-checkbox-indeterminate.light-cascader-checkbox-disabled .light-cascader-checkbox-inner::after {
	background-color: rgba(0, 0, 0, 0.25);
	border-color: rgba(0, 0, 0, 0.25);
}

.light-cascader-checkbox-rtl {
	direction: rtl;
}

.light-cascader-checkbox-group-rtl .light-cascader-checkbox-group-item {
	margin-right: 0;
	margin-left: 8px;
}

.light-cascader-checkbox-group-rtl .light-cascader-checkbox-group-item:last-child {
	margin-left: 0 !important;
}

.light-cascader-checkbox-group-rtl .light-cascader-checkbox-group-item+.light-cascader-checkbox-group-item {
	margin-left: 8px;
}

.light-cascader {
	width: 184px;
}

.light-cascader-checkbox {
	top: 0;
	margin-right: 8px;
}

.light-cascader-menus {
	display: flex;
	flex-wrap: nowrap;
	align-items: flex-start;
}

.light-cascader-menus.light-cascader-menu-empty .light-cascader-menu {
	width: 100%;
	height: auto;
}

.light-cascader-menu {
	flex-grow: 1;
	min-width: 111px;
	height: 180px;
	margin: 0;
	margin: -4px 0;
	padding: 4px 0;
	overflow: auto;
	vertical-align: top;
	list-style: none;
	border-right: 1px solid var(--border-color);
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

.light-cascader-menu-item {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	padding: 5px 12px;
	overflow: hidden;
	line-height: 22px;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	transition: all 0.3s;
}

.light-cascader-menu-item:hover {
	background: #f5f5f5;
}

.light-cascader-menu-item-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-cascader-menu-item-disabled:hover {
	background: transparent;
}

.light-cascader-menu-empty .light-cascader-menu-item {
	color: rgba(0, 0, 0, 0.25);
	cursor: default;
	pointer-events: none;
}

.light-cascader-menu-item-active:not(.light-cascader-menu-item-disabled),
.light-cascader-menu-item-active:not(.light-cascader-menu-item-disabled):hover {
	font-weight: 600;
	background-color: var(--light-primary-1);
}

.light-cascader-menu-item-content {
	flex: auto;
}

.light-cascader-menu-item-expand .light-cascader-menu-item-expand-icon,
.light-cascader-menu-item-loading-icon {
	margin-left: 4px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 10px;
}

.light-cascader-menu-item-disabled.light-cascader-menu-item-expand .light-cascader-menu-item-expand-icon,
.light-cascader-menu-item-disabled.light-cascader-menu-item-loading-icon {
	color: rgba(0, 0, 0, 0.25);
}

.light-cascader-menu-item-keyword {
	color: #ff4d4f;
}

.light-cascader-rtl .light-cascader-menu-item-expand-icon,
.light-cascader-rtl .light-cascader-menu-item-loading-icon {
	margin-right: 4px;
	margin-left: 0;
}

.light-cascader-rtl .light-cascader-checkbox {
	top: 0;
	margin-right: 0;
	margin-left: 8px;
}

.light-checkbox {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	top: 0.2em;
	line-height: 1;
	white-space: nowrap;
	outline: none;
	cursor: pointer;
}

.light-checkbox-wrapper:hover .light-checkbox-inner,
.light-checkbox:hover .light-checkbox-inner,
.light-checkbox-input:focus+.light-checkbox-inner {
	border-color: var(--light-primary-color);
}

.light-checkbox-checked::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid var(--light-primary-color);
	border-radius: 2px;
	visibility: hidden;
	animation: antCheckboxEffect 0.36s ease-in-out;
	animation-fill-mode: backwards;
	content: "";
}

.light-checkbox:hover::after,
.light-checkbox-wrapper:hover .light-checkbox::after {
	visibility: visible;
}

.light-checkbox-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 16px;
	height: 16px;
	direction: ltr;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	border-collapse: separate;
	transition: all 0.3s;
}

.light-checkbox-inner::after {
	position: absolute;
	top: 50%;
	left: 21.5%;
	display: table;
	width: 5.71428571px;
	height: 9.14285714px;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(0) translate(-50%, -50%);
	opacity: 0;
	transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
	content: " ";
}

.light-checkbox-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
}

.light-checkbox-checked .light-checkbox-inner::after {
	position: absolute;
	display: table;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(1) translate(-50%, -50%);
	opacity: 1;
	transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
	content: " ";
}

.light-checkbox-checked .light-checkbox-inner {
	background-color: var(--light-primary-color);
	border-color: var(--light-primary-color);
}

.light-checkbox-disabled {
	cursor: not-allowed;
}

.light-checkbox-disabled.light-checkbox-checked .light-checkbox-inner::after {
	border-color: rgba(0, 0, 0, 0.25);
	animation-name: none;
}

.light-checkbox-disabled .light-checkbox-input {
	cursor: not-allowed;
	pointer-events: none;
}

.light-checkbox-disabled .light-checkbox-inner {
	background-color: #f5f5f5;
	border-color: #d9d9d9 !important;
}

.light-checkbox-disabled .light-checkbox-inner::after {
	border-color: #f5f5f5;
	border-collapse: separate;
	animation-name: none;
}

.light-checkbox-disabled+span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-checkbox-disabled:hover::after,
.light-checkbox-wrapper:hover .light-checkbox-disabled::after {
	visibility: hidden;
}

.light-checkbox-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: inline-flex;
	align-items: baseline;
	line-height: unset;
	cursor: pointer;
}

.light-checkbox-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: "\a0";
}

.light-checkbox-wrapper.light-checkbox-wrapper-disabled {
	cursor: not-allowed;
}

.light-checkbox-wrapper+.light-checkbox-wrapper {
	margin-left: 8px;
}

.light-checkbox-wrapper.light-checkbox-wrapper-in-form-item input[type="checkbox"] {
	width: 14px;
	height: 14px;
}

.light-checkbox+span {
	padding-right: 8px;
	padding-left: 8px;
}

.light-checkbox-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: inline-block;
}

.light-checkbox-group-item {
	margin-right: 8px;
}

.light-checkbox-group-item:last-child {
	margin-right: 0;
}

.light-checkbox-group-item+.light-checkbox-group-item {
	margin-left: 0;
}

.light-checkbox-indeterminate .light-checkbox-inner {
	background-color: #fff;
	border-color: #d9d9d9;
}

.light-checkbox-indeterminate .light-checkbox-inner::after {
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	background-color: var(--light-primary-color);
	border: 0;
	transform: translate(-50%, -50%) scale(1);
	opacity: 1;
	content: " ";
}

.light-checkbox-indeterminate.light-checkbox-disabled .light-checkbox-inner::after {
	background-color: rgba(0, 0, 0, 0.25);
	border-color: rgba(0, 0, 0, 0.25);
}

.light-checkbox-rtl {
	direction: rtl;
}

.light-checkbox-group-rtl .light-checkbox-group-item {
	margin-right: 0;
	margin-left: 8px;
}

.light-checkbox-group-rtl .light-checkbox-group-item:last-child {
	margin-left: 0 !important;
}

.light-checkbox-group-rtl .light-checkbox-group-item+.light-checkbox-group-item {
	margin-left: 8px;
}

.light-collapse {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	background-color: #fafafa;
	border: 1px solid #d9d9d9;
	border-bottom: 0;
	border-radius: 2px;
}

.light-collapse>.light-collapse-item {
	border-bottom: 1px solid #d9d9d9;
}

.light-collapse>.light-collapse-item:last-child,
.light-collapse>.light-collapse-item:last-child>.light-collapse-header {
	border-radius: 0 0 2px 2px;
}

.light-collapse>.light-collapse-item>.light-collapse-header {
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	align-items: flex-start;
	padding: 12px 16px;
	color: rgba(0, 0, 0, 0.85);
	line-height: 1.5715;
	cursor: pointer;
	transition: all 0.3s, visibility 0s;
}

.light-collapse>.light-collapse-item>.light-collapse-header .light-collapse-arrow {
	display: inline-block;
	margin-right: 12px;
	font-size: 12px;
	vertical-align: -1px;
}

.light-collapse>.light-collapse-item>.light-collapse-header .light-collapse-arrow svg {
	transition: transform 0.24s;
}

.light-collapse>.light-collapse-item>.light-collapse-header .light-collapse-header-text {
	flex: auto;
}

.light-collapse>.light-collapse-item>.light-collapse-header .light-collapse-extra {
	margin-left: auto;
}

.light-collapse>.light-collapse-item>.light-collapse-header:focus {
	outline: none;
}

.light-collapse>.light-collapse-item .light-collapse-header-collapsible-only {
	cursor: default;
}

.light-collapse>.light-collapse-item .light-collapse-header-collapsible-only .light-collapse-header-text {
	flex: none;
	cursor: pointer;
}

.light-collapse>.light-collapse-item.light-collapse-no-arrow>.light-collapse-header {
	padding-left: 12px;
}

.light-collapse-icon-position-end>.light-collapse-item>.light-collapse-header {
	position: relative;
	padding: 12px 16px;
	padding-right: 40px;
}

.light-collapse-icon-position-end>.light-collapse-item>.light-collapse-header .light-collapse-arrow {
	position: absolute;
	top: 50%;
	right: 16px;
	left: auto;
	margin: 0;
	transform: translateY(-50%);
}

.light-collapse-content {
	color: rgba(0, 0, 0, 0.85);
	background-color: #fff;
	border-top: 1px solid #d9d9d9;
}

.light-collapse-content>.light-collapse-content-box {
	padding: 16px;
}

.light-collapse-content-hidden {
	display: none;
}

.light-collapse-item:last-child>.light-collapse-content {
	border-radius: 0 0 2px 2px;
}

.light-collapse-borderless {
	background-color: #fafafa;
	border: 0;
}

.light-collapse-borderless>.light-collapse-item {
	border-bottom: 1px solid #d9d9d9;
}

.light-collapse-borderless>.light-collapse-item:last-child,
.light-collapse-borderless>.light-collapse-item:last-child .light-collapse-header {
	border-radius: 0;
}

.light-collapse-borderless>.light-collapse-item:last-child {
	border-bottom: 0;
}

.light-collapse-borderless>.light-collapse-item>.light-collapse-content {
	background-color: transparent;
	border-top: 0;
}

.light-collapse-borderless>.light-collapse-item>.light-collapse-content>.light-collapse-content-box {
	padding-top: 4px;
}

.light-collapse-ghost {
	background-color: transparent;
	border: 0;
}

.light-collapse-ghost>.light-collapse-item {
	border-bottom: 0;
}

.light-collapse-ghost>.light-collapse-item>.light-collapse-content {
	background-color: transparent;
	border-top: 0;
}

.light-collapse-ghost>.light-collapse-item>.light-collapse-content>.light-collapse-content-box {
	padding-top: 12px;
	padding-bottom: 12px;
}

.light-collapse .light-collapse-item-disabled>.light-collapse-header,
.light-collapse .light-collapse-item-disabled>.light-collapse-header>.arrow {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-collapse-rtl {
	direction: rtl;
}

.light-collapse-rtl.light-collapse.light-collapse-icon-position-end>.light-collapse-item>.light-collapse-header {
	position: relative;
	padding: 12px 16px;
	padding-left: 40px;
}

.light-collapse-rtl.light-collapse.light-collapse-icon-position-end>.light-collapse-item>.light-collapse-header .light-collapse-arrow {
	position: absolute;
	top: 50%;
	right: auto;
	left: 16px;
	margin: 0;
	transform: translateY(-50%);
}

.light-collapse-rtl .light-collapse>.light-collapse-item>.light-collapse-header {
	padding: 12px 16px;
	padding-right: 40px;
}

.light-collapse-rtl.light-collapse>.light-collapse-item>.light-collapse-header .light-collapse-arrow {
	margin-right: 0;
	margin-left: 12px;
}

.light-collapse-rtl.light-collapse>.light-collapse-item>.light-collapse-header .light-collapse-arrow svg {
	transform: rotate(180deg);
}

.light-collapse-rtl.light-collapse>.light-collapse-item>.light-collapse-header .light-collapse-extra {
	margin-right: auto;
	margin-left: 0;
}

.light-collapse-rtl.light-collapse>.light-collapse-item.light-collapse-no-arrow>.light-collapse-header {
	padding-right: 12px;
	padding-left: 0;
}

.light-comment {
	position: relative;
	background-color: inherit;
}

.light-comment-inner {
	display: flex;
	padding: 16px 0;
}

.light-comment-avatar {
	position: relative;
	flex-shrink: 0;
	margin-right: 12px;
	cursor: pointer;
}

.light-comment-avatar img {
	width: 32px;
	height: 32px;
	border-radius: 50%;
}

.light-comment-content {
	position: relative;
	flex: 1 1 auto;
	min-width: 1px;
	font-size: 14px;
	word-wrap: break-word;
}

.light-comment-content-author {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-bottom: 4px;
	font-size: 14px;
}

.light-comment-content-author>a,
.light-comment-content-author>span {
	padding-right: 8px;
	font-size: 12px;
	line-height: 18px;
}

.light-comment-content-author-name {
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	transition: color 0.3s;
}

.light-comment-content-author-name>* {
	color: rgba(0, 0, 0, 0.45);
}

.light-comment-content-author-name>*:hover {
	color: rgba(0, 0, 0, 0.45);
}

.light-comment-content-author-time {
	color: #ccc;
	white-space: nowrap;
	cursor: auto;
}

.light-comment-content-detail p {
	margin-bottom: inherit;
	white-space: pre-wrap;
}

.light-comment-actions {
	margin-top: 12px;
	margin-bottom: inherit;
	padding-left: 0;
}

.light-comment-actions>li {
	display: inline-block;
	color: rgba(0, 0, 0, 0.45);
}

.light-comment-actions>li>span {
	margin-right: 10px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	cursor: pointer;
	transition: color 0.3s;
	user-select: none;
}

.light-comment-actions>li>span:hover {
	color: #595959;
}

.light-comment-nested {
	margin-left: 44px;
}

.light-comment-rtl {
	direction: rtl;
}

.light-comment-rtl .light-comment-avatar {
	margin-right: 0;
	margin-left: 12px;
}

.light-comment-rtl .light-comment-content-author>a,
.light-comment-rtl .light-comment-content-author>span {
	padding-right: 0;
	padding-left: 8px;
}

.light-comment-rtl .light-comment-actions {
	padding-right: 0;
}

.light-comment-rtl .light-comment-actions>li>span {
	margin-right: 0;
	margin-left: 10px;
}

.light-comment-rtl .light-comment-nested {
	margin-right: 44px;
	margin-left: 0;
}

.light-picker-status-error.light-picker,
.light-picker-status-error.light-picker:not([disabled]):hover {
	background-color: #fff;
	border-color: var(--light-error-color);
}

.light-picker-status-error.light-picker-focused,
.light-picker-status-error.light-picker:focus {
	border-color: var(--light-error-color-hover);
	box-shadow: 0 0 0 2px var(--light-error-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-picker-status-error.light-picker .light-picker-active-bar {
	background: var(--light-error-color-hover);
}

.light-picker-status-warning.light-picker,
.light-picker-status-warning.light-picker:not([disabled]):hover {
	background-color: #fff;
	border-color: var(--light-warning-color);
}

.light-picker-status-warning.light-picker-focused,
.light-picker-status-warning.light-picker:focus {
	border-color: var(--light-warning-color-hover);
	box-shadow: 0 0 0 2px var(--light-warning-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-picker-status-warning.light-picker .light-picker-active-bar {
	background: var(--light-warning-color-hover);
}

.light-picker {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	padding: 4px 11px 4px;
	position: relative;
	display: inline-flex;
	align-items: center;
	background: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: border 0.3s, box-shadow 0.3s;
}

.light-picker:hover,
.light-picker-focused {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
}

.light-input-rtl .light-picker:hover,
.light-input-rtl .light-picker-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-picker-focused {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-rtl .light-picker-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-picker.light-picker-disabled {
	background: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}

.light-picker.light-picker-disabled .light-picker-suffix {
	color: rgba(0, 0, 0, 0.25);
}

.light-picker.light-picker-borderless {
	background-color: transparent !important;
	border-color: transparent !important;
	box-shadow: none !important;
}

.light-picker-input {
	position: relative;
	display: inline-flex;
	align-items: center;
	width: 100%;
}

.light-picker-input>input {
	position: relative;
	display: inline-block;
	width: 100%;
	min-width: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
	flex: auto;
	min-width: 1px;
	height: auto;
	padding: 0;
	background: transparent;
	border: 0;
}

.light-picker-input>input::placeholder {
	color: #bfbfbf;
	user-select: none;
}

.light-picker-input>input:placeholder-shown {
	text-overflow: ellipsis;
}

.light-picker-input>input:hover {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
}

.light-input-rtl .light-picker-input>input:hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-picker-input>input:focus,
.light-picker-input>input-focused {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-rtl .light-picker-input>input:focus,
.light-input-rtl .light-picker-input>input-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-picker-input>input-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-picker-input>input-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-picker-input>input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-picker-input>input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-picker-input>input-borderless,
.light-picker-input>input-borderless:hover,
.light-picker-input>input-borderless:focus,
.light-picker-input>input-borderless-focused,
.light-picker-input>input-borderless-disabled,
.light-picker-input>input-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

textarea.light-picker-input>input {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5715;
	vertical-align: bottom;
	transition: all 0.3s, height 0s;
}

.light-picker-input>input-lg {
	padding: 6.5px 11px;
	font-size: 16px;
}

.light-picker-input>input-sm {
	padding: 0px 7px;
}

.light-picker-input>input-rtl {
	direction: rtl;
}

.light-picker-input>input:focus {
	box-shadow: none;
}

.light-picker-input>input[disabled] {
	background: transparent;
}

.light-picker-input:hover .light-picker-clear {
	opacity: 1;
}

.light-picker-input-placeholder>input {
	color: #bfbfbf;
}

.light-picker-large {
	padding: 6.5px 11px 6.5px;
}

.light-picker-large .light-picker-input>input {
	font-size: 16px;
}

.light-picker-small {
	padding: 0px 7px 0px;
}

.light-picker-suffix {
	display: flex;
	flex: none;
	align-self: center;
	margin-left: 4px;
	color: rgba(0, 0, 0, 0.25);
	line-height: 1;
	pointer-events: none;
}

.light-picker-suffix>* {
	vertical-align: top;
}

.light-picker-suffix>*:not(:last-child) {
	margin-right: 8px;
}

.light-picker-clear {
	position: absolute;
	top: 50%;
	right: 0;
	color: rgba(0, 0, 0, 0.25);
	line-height: 1;
	background: #fff;
	transform: translateY(-50%);
	cursor: pointer;
	opacity: 0;
	transition: opacity 0.3s, color 0.3s;
}

.light-picker-clear>* {
	vertical-align: top;
}

.light-picker-clear:hover {
	color: rgba(0, 0, 0, 0.45);
}

.light-picker-separator {
	position: relative;
	display: inline-block;
	width: 1em;
	height: 16px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 16px;
	vertical-align: top;
	cursor: default;
}

.light-picker-focused .light-picker-separator {
	color: rgba(0, 0, 0, 0.45);
}

.light-picker-disabled .light-picker-range-separator .light-picker-separator {
	cursor: not-allowed;
}

.light-picker-range {
	position: relative;
	display: inline-flex;
}

.light-picker-range .light-picker-clear {
	right: 11px;
}

.light-picker-range:hover .light-picker-clear {
	opacity: 1;
}

.light-picker-range .light-picker-active-bar {
	bottom: -1px;
	height: 2px;
	margin-left: 11px;
	background: var(--light-primary-color);
	opacity: 0;
	transition: all 0.3s ease-out;
	pointer-events: none;
}

.light-picker-range.light-picker-focused .light-picker-active-bar {
	opacity: 1;
}

.light-picker-range-separator {
	align-items: center;
	padding: 0 8px;
	line-height: 1;
}

.light-picker-range.light-picker-small .light-picker-clear {
	right: 7px;
}

.light-picker-range.light-picker-small .light-picker-active-bar {
	margin-left: 7px;
}

.light-picker-dropdown {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
}

.light-picker-dropdown-hidden {
	display: none;
}

.light-picker-dropdown-placement-bottomLeft .light-picker-range-arrow {
	top: 2.58561808px;
	display: block;
	transform: rotate(-135deg) translateY(1px);
}

.light-picker-dropdown-placement-topLeft .light-picker-range-arrow {
	bottom: 2.58561808px;
	display: block;
	transform: rotate(45deg);
}

.light-picker-dropdown.light-slide-up-enter.light-slide-up-enter-active.light-picker-dropdown-placement-topLeft,
.light-picker-dropdown.light-slide-up-enter.light-slide-up-enter-active.light-picker-dropdown-placement-topRight,
.light-picker-dropdown.light-slide-up-appear.light-slide-up-appear-active.light-picker-dropdown-placement-topLeft,
.light-picker-dropdown.light-slide-up-appear.light-slide-up-appear-active.light-picker-dropdown-placement-topRight {
	animation-name: antSlideDownIn;
}

.light-picker-dropdown.light-slide-up-enter.light-slide-up-enter-active.light-picker-dropdown-placement-bottomLeft,
.light-picker-dropdown.light-slide-up-enter.light-slide-up-enter-active.light-picker-dropdown-placement-bottomRight,
.light-picker-dropdown.light-slide-up-appear.light-slide-up-appear-active.light-picker-dropdown-placement-bottomLeft,
.light-picker-dropdown.light-slide-up-appear.light-slide-up-appear-active.light-picker-dropdown-placement-bottomRight {
	animation-name: antSlideUpIn;
}

.light-picker-dropdown.light-slide-up-leave.light-slide-up-leave-active.light-picker-dropdown-placement-topLeft,
.light-picker-dropdown.light-slide-up-leave.light-slide-up-leave-active.light-picker-dropdown-placement-topRight {
	animation-name: antSlideDownOut;
}

.light-picker-dropdown.light-slide-up-leave.light-slide-up-leave-active.light-picker-dropdown-placement-bottomLeft,
.light-picker-dropdown.light-slide-up-leave.light-slide-up-leave-active.light-picker-dropdown-placement-bottomRight {
	animation-name: antSlideUpOut;
}

.light-picker-dropdown-range {
	padding: 7.54247233px 0;
}

.light-picker-dropdown-range-hidden {
	display: none;
}

.light-picker-dropdown .light-picker-panel>.light-picker-time-panel {
	padding-top: 4px;
}

.light-picker-ranges {
	margin-bottom: 0;
	padding: 4px 12px;
	overflow: hidden;
	line-height: 34px;
	text-align: left;
	list-style: none;
}

.light-picker-ranges>li {
	display: inline-block;
}

.light-picker-ranges .light-picker-preset>.light-tag-blue {
	color: var(--light-primary-color);
	background: var(--light-primary-1);
	border-color: var(--light-primary-3);
	cursor: pointer;
}

.light-picker-ranges .light-picker-ok {
	float: right;
	margin-left: 8px;
}

.light-picker-range-wrapper {
	display: flex;
}

.light-picker-range-arrow {
	position: absolute;
	z-index: 1;
	display: none;
	width: 11.3137085px;
	height: 11.3137085px;
	margin-left: 16.5px;
	box-shadow: 2px 2px 6px -2px rgba(0, 0, 0, 0.1);
	transition: left 0.3s ease-out;
	border-radius: 0 0 2px;
	pointer-events: none;
}

.light-picker-range-arrow::before {
	position: absolute;
	top: -11.3137085px;
	left: -11.3137085px;
	width: 33.9411255px;
	height: 33.9411255px;
	background: #fff;
	background-repeat: no-repeat;
	background-position: -10px -10px;
	content: "";
	clip-path: inset(33% 33%);
	clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z"
		);
}

.light-picker-panel-container {
	overflow: hidden;
	vertical-align: top;
	background: #fff;
	border-radius: 2px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	transition: margin 0.3s;
}

.light-picker-panel-container .light-picker-panels {
	display: inline-flex;
	flex-wrap: nowrap;
	direction: ltr;
}

.light-picker-panel-container .light-picker-panel {
	vertical-align: top;
	background: transparent;
	border-width: 0 0 1px 0;
	border-radius: 0;
}

.light-picker-panel-container .light-picker-panel .light-picker-content,
.light-picker-panel-container .light-picker-panel table {
	text-align: center;
}

.light-picker-panel-container .light-picker-panel-focused {
	border-color: var(--border-color);
}

.light-picker-panel {
	display: inline-flex;
	flex-direction: column;
	text-align: center;
	background: #fff;
	border: 1px solid var(--border-color);
	border-radius: 2px;
	outline: none;
}

.light-picker-panel-focused {
	border-color: var(--light-primary-color);
}

.light-picker-decade-panel,
.light-picker-year-panel,
.light-picker-quarter-panel,
.light-picker-month-panel,
.light-picker-week-panel,
.light-picker-date-panel,
.light-picker-time-panel {
	display: flex;
	flex-direction: column;
	width: 280px;
}

.light-picker-header {
	display: flex;
	padding: 0 8px;
	color: rgba(0, 0, 0, 0.85);
	border-bottom: 1px solid var(--border-color);
}

.light-picker-header>* {
	flex: none;
}

.light-picker-header button {
	padding: 0;
	color: rgba(0, 0, 0, 0.25);
	line-height: 40px;
	background: transparent;
	border: 0;
	cursor: pointer;
	transition: color 0.3s;
}

.light-picker-header>button {
	min-width: 1.6em;
	font-size: 14px;
}

.light-picker-header>button:hover {
	color: rgba(0, 0, 0, 0.85);
}

.light-picker-header-view {
	flex: auto;
	font-weight: 500;
	line-height: 40px;
}

.light-picker-header-view button {
	color: inherit;
	font-weight: inherit;
}

.light-picker-header-view button:not(:first-child) {
	margin-left: 8px;
}

.light-picker-header-view button:hover {
	color: var(--light-primary-color);
}

.light-picker-prev-icon,
.light-picker-next-icon,
.light-picker-super-prev-icon,
.light-picker-super-next-icon {
	position: relative;
	display: inline-block;
	width: 7px;
	height: 7px;
}

.light-picker-prev-icon::before,
.light-picker-next-icon::before,
.light-picker-super-prev-icon::before,
.light-picker-super-next-icon::before {
	position: absolute;
	top: 0;
	left: 0;
	display: inline-block;
	width: 7px;
	height: 7px;
	border: 0 solid currentcolor;
	border-width: 1.5px 0 0 1.5px;
	content: "";
}

.light-picker-super-prev-icon::after,
.light-picker-super-next-icon::after {
	position: absolute;
	top: 4px;
	left: 4px;
	display: inline-block;
	width: 7px;
	height: 7px;
	border: 0 solid currentcolor;
	border-width: 1.5px 0 0 1.5px;
	content: "";
}

.light-picker-prev-icon,
.light-picker-super-prev-icon {
	transform: rotate(-45deg);
}

.light-picker-next-icon,
.light-picker-super-next-icon {
	transform: rotate(135deg);
}

.light-picker-content {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
}

.light-picker-content th,
.light-picker-content td {
	position: relative;
	min-width: 24px;
	font-weight: 400;
}

.light-picker-content th {
	height: 30px;
	color: rgba(0, 0, 0, 0.85);
	line-height: 30px;
}

.light-picker-cell {
	padding: 3px 0;
	color: rgba(0, 0, 0, 0.25);
	cursor: pointer;
}

.light-picker-cell-in-view {
	color: rgba(0, 0, 0, 0.85);
}

.light-picker-cell::before {
	position: absolute;
	top: 50%;
	right: 0;
	left: 0;
	z-index: 1;
	height: 24px;
	transform: translateY(-50%);
	transition: all 0.3s;
	content: "";
}

.light-picker-cell .light-picker-cell-inner {
	position: relative;
	z-index: 2;
	display: inline-block;
	min-width: 24px;
	height: 24px;
	line-height: 24px;
	border-radius: 2px;
	transition: background 0.3s, border 0.3s;
}

.light-picker-cell:hover:not(.light-picker-cell-in-view) .light-picker-cell-inner,
.light-picker-cell:hover:not(.light-picker-cell-selected):not(.light-picker-cell-range-start):not(.light-picker-cell-range-end):not(.light-picker-cell-range-hover-start):not(.light-picker-cell-range-hover-end) .light-picker-cell-inner {
	background: #f5f5f5;
}

.light-picker-cell-in-view.light-picker-cell-today .light-picker-cell-inner::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	border: 1px solid var(--light-primary-color);
	border-radius: 2px;
	content: "";
}

.light-picker-cell-in-view.light-picker-cell-in-range {
	position: relative;
}

.light-picker-cell-in-view.light-picker-cell-in-range::before {
	background: var(--light-primary-1);
}

.light-picker-cell-in-view.light-picker-cell-selected .light-picker-cell-inner,
.light-picker-cell-in-view.light-picker-cell-range-start .light-picker-cell-inner,
.light-picker-cell-in-view.light-picker-cell-range-end .light-picker-cell-inner {
	color: #fff;
	background: var(--light-primary-color);
}

.light-picker-cell-in-view.light-picker-cell-range-start:not(.light-picker-cell-range-start-single)::before,
.light-picker-cell-in-view.light-picker-cell-range-end:not(.light-picker-cell-range-end-single)::before {
	background: var(--light-primary-1);
}

.light-picker-cell-in-view.light-picker-cell-range-start::before {
	left: 50%;
}

.light-picker-cell-in-view.light-picker-cell-range-end::before {
	right: 50%;
}

.light-picker-cell-in-view.light-picker-cell-range-hover-start:not(.light-picker-cell-in-range):not(.light-picker-cell-range-start):not(.light-picker-cell-range-end)::after,
.light-picker-cell-in-view.light-picker-cell-range-hover-end:not(.light-picker-cell-in-range):not(.light-picker-cell-range-start):not(.light-picker-cell-range-end)::after,
.light-picker-cell-in-view.light-picker-cell-range-hover-start.light-picker-cell-range-start-single::after,
.light-picker-cell-in-view.light-picker-cell-range-hover-start.light-picker-cell-range-start.light-picker-cell-range-end.light-picker-cell-range-end-near-hover::after,
.light-picker-cell-in-view.light-picker-cell-range-hover-end.light-picker-cell-range-start.light-picker-cell-range-end.light-picker-cell-range-start-near-hover::after,
.light-picker-cell-in-view.light-picker-cell-range-hover-end.light-picker-cell-range-end-single::after,
.light-picker-cell-in-view.light-picker-cell-range-hover:not(.light-picker-cell-in-range)::after {
	position: absolute;
	top: 50%;
	z-index: 0;
	height: 24px;
	border-top: 1px dashed var(--light-primary-color-deprecated-l-20);
	border-bottom: 1px dashed var(--light-primary-color-deprecated-l-20);
	transform: translateY(-50%);
	transition: all 0.3s;
	content: "";
}

.light-picker-cell-range-hover-start::after,
.light-picker-cell-range-hover-end::after,
.light-picker-cell-range-hover::after {
	right: 0;
	left: 2px;
}

.light-picker-cell-in-view.light-picker-cell-in-range.light-picker-cell-range-hover::before,
.light-picker-cell-in-view.light-picker-cell-range-start.light-picker-cell-range-hover::before,
.light-picker-cell-in-view.light-picker-cell-range-end.light-picker-cell-range-hover::before,
.light-picker-cell-in-view.light-picker-cell-range-start:not(.light-picker-cell-range-start-single).light-picker-cell-range-hover-start::before,
.light-picker-cell-in-view.light-picker-cell-range-end:not(.light-picker-cell-range-end-single).light-picker-cell-range-hover-end::before,
.light-picker-panel> :not(.light-picker-date-panel) .light-picker-cell-in-view.light-picker-cell-in-range.light-picker-cell-range-hover-start::before,
.light-picker-panel> :not(.light-picker-date-panel) .light-picker-cell-in-view.light-picker-cell-in-range.light-picker-cell-range-hover-end::before {
	background: var(--light-primary-color-deprecated-l-35);
}

.light-picker-cell-in-view.light-picker-cell-range-start:not(.light-picker-cell-range-start-single):not(.light-picker-cell-range-end) .light-picker-cell-inner {
	border-radius: 2px 0 0 2px;
}

.light-picker-cell-in-view.light-picker-cell-range-end:not(.light-picker-cell-range-end-single):not(.light-picker-cell-range-start) .light-picker-cell-inner {
	border-radius: 0 2px 2px 0;
}

.light-picker-date-panel .light-picker-cell-in-view.light-picker-cell-in-range.light-picker-cell-range-hover-start .light-picker-cell-inner::after,
.light-picker-date-panel .light-picker-cell-in-view.light-picker-cell-in-range.light-picker-cell-range-hover-end .light-picker-cell-inner::after {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: -1;
	background: var(--light-primary-color-deprecated-l-35);
	transition: all 0.3s;
	content: "";
}

.light-picker-date-panel .light-picker-cell-in-view.light-picker-cell-in-range.light-picker-cell-range-hover-start .light-picker-cell-inner::after {
	right: -6px;
	left: 0;
}

.light-picker-date-panel .light-picker-cell-in-view.light-picker-cell-in-range.light-picker-cell-range-hover-end .light-picker-cell-inner::after {
	right: 0;
	left: -6px;
}

.light-picker-cell-range-hover.light-picker-cell-range-start::after {
	right: 50%;
}

.light-picker-cell-range-hover.light-picker-cell-range-end::after {
	left: 50%;
}

tr>.light-picker-cell-in-view.light-picker-cell-range-hover:first-child::after,
tr>.light-picker-cell-in-view.light-picker-cell-range-hover-end:first-child::after,
.light-picker-cell-in-view.light-picker-cell-start.light-picker-cell-range-hover-edge-start.light-picker-cell-range-hover-edge-start-near-range::after,
.light-picker-cell-in-view.light-picker-cell-range-hover-edge-start:not(.light-picker-cell-range-hover-edge-start-near-range)::after,
.light-picker-cell-in-view.light-picker-cell-range-hover-start::after {
	left: 6px;
	border-left: 1px dashed var(--light-primary-color-deprecated-l-20);
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

tr>.light-picker-cell-in-view.light-picker-cell-range-hover:last-child::after,
tr>.light-picker-cell-in-view.light-picker-cell-range-hover-start:last-child::after,
.light-picker-cell-in-view.light-picker-cell-end.light-picker-cell-range-hover-edge-end.light-picker-cell-range-hover-edge-end-near-range::after,
.light-picker-cell-in-view.light-picker-cell-range-hover-edge-end:not(.light-picker-cell-range-hover-edge-end-near-range)::after,
.light-picker-cell-in-view.light-picker-cell-range-hover-end::after {
	right: 6px;
	border-right: 1px dashed var(--light-primary-color-deprecated-l-20);
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.light-picker-cell-disabled {
	color: rgba(0, 0, 0, 0.25);
	pointer-events: none;
}

.light-picker-cell-disabled .light-picker-cell-inner {
	background: transparent;
}

.light-picker-cell-disabled::before {
	background: rgba(0, 0, 0, 0.04);
}

.light-picker-cell-disabled.light-picker-cell-today .light-picker-cell-inner::before {
	border-color: rgba(0, 0, 0, 0.25);
}

.light-picker-decade-panel .light-picker-content,
.light-picker-year-panel .light-picker-content,
.light-picker-quarter-panel .light-picker-content,
.light-picker-month-panel .light-picker-content {
	height: 264px;
}

.light-picker-decade-panel .light-picker-cell-inner,
.light-picker-year-panel .light-picker-cell-inner,
.light-picker-quarter-panel .light-picker-cell-inner,
.light-picker-month-panel .light-picker-cell-inner {
	padding: 0 8px;
}

.light-picker-quarter-panel .light-picker-content {
	height: 56px;
}

.light-picker-footer {
	width: min-content;
	min-width: 100%;
	line-height: 38px;
	text-align: center;
	border-bottom: 1px solid transparent;
}

.light-picker-panel .light-picker-footer {
	border-top: 1px solid var(--border-color);
}

.light-picker-footer-extra {
	padding: 0 12px;
	line-height: 38px;
	text-align: left;
}

.light-picker-footer-extra:not(:last-child) {
	border-bottom: 1px solid var(--border-color);
}

.light-picker-now {
	text-align: left;
}

.light-picker-today-btn {
	color: var(--light-primary-color);
}

.light-picker-today-btn:hover {
	color: var(--light-primary-color-hover);
}

.light-picker-today-btn:active {
	color: var(--light-primary-color-active);
}

.light-picker-today-btn.light-picker-today-btn-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-picker-decade-panel .light-picker-cell-inner {
	padding: 0 4px;
}

.light-picker-decade-panel .light-picker-cell::before {
	display: none;
}

.light-picker-year-panel .light-picker-body,
.light-picker-quarter-panel .light-picker-body,
.light-picker-month-panel .light-picker-body {
	padding: 0 8px;
}

.light-picker-year-panel .light-picker-cell-inner,
.light-picker-quarter-panel .light-picker-cell-inner,
.light-picker-month-panel .light-picker-cell-inner {
	width: 60px;
}

.light-picker-year-panel .light-picker-cell-range-hover-start::after,
.light-picker-quarter-panel .light-picker-cell-range-hover-start::after,
.light-picker-month-panel .light-picker-cell-range-hover-start::after {
	left: 14px;
	border-left: 1px dashed var(--light-primary-color-deprecated-l-20);
	border-radius: 2px 0 0 2px;
}

.light-picker-panel-rtl .light-picker-year-panel .light-picker-cell-range-hover-start::after,
.light-picker-panel-rtl .light-picker-quarter-panel .light-picker-cell-range-hover-start::after,
.light-picker-panel-rtl .light-picker-month-panel .light-picker-cell-range-hover-start::after {
	right: 14px;
	border-right: 1px dashed var(--light-primary-color-deprecated-l-20);
	border-radius: 0 2px 2px 0;
}

.light-picker-year-panel .light-picker-cell-range-hover-end::after,
.light-picker-quarter-panel .light-picker-cell-range-hover-end::after,
.light-picker-month-panel .light-picker-cell-range-hover-end::after {
	right: 14px;
	border-right: 1px dashed var(--light-primary-color-deprecated-l-20);
	border-radius: 0 2px 2px 0;
}

.light-picker-panel-rtl .light-picker-year-panel .light-picker-cell-range-hover-end::after,
.light-picker-panel-rtl .light-picker-quarter-panel .light-picker-cell-range-hover-end::after,
.light-picker-panel-rtl .light-picker-month-panel .light-picker-cell-range-hover-end::after {
	left: 14px;
	border-left: 1px dashed var(--light-primary-color-deprecated-l-20);
	border-radius: 2px 0 0 2px;
}

.light-picker-week-panel .light-picker-body {
	padding: 8px 12px;
}

.light-picker-week-panel .light-picker-cell:hover .light-picker-cell-inner,
.light-picker-week-panel .light-picker-cell-selected .light-picker-cell-inner,
.light-picker-week-panel .light-picker-cell .light-picker-cell-inner {
	background: transparent !important;
}

.light-picker-week-panel-row td {
	transition: background 0.3s;
}

.light-picker-week-panel-row:hover td {
	background: #f5f5f5;
}

.light-picker-week-panel-row-selected td,
.light-picker-week-panel-row-selected:hover td {
	background: var(--light-primary-color);
}

.light-picker-week-panel-row-selected td.light-picker-cell-week,
.light-picker-week-panel-row-selected:hover td.light-picker-cell-week {
	color: rgba(255, 255, 255, 0.5);
}

.light-picker-week-panel-row-selected td.light-picker-cell-today .light-picker-cell-inner::before,
.light-picker-week-panel-row-selected:hover td.light-picker-cell-today .light-picker-cell-inner::before {
	border-color: #fff;
}

.light-picker-week-panel-row-selected td .light-picker-cell-inner,
.light-picker-week-panel-row-selected:hover td .light-picker-cell-inner {
	color: #fff;
}

.light-picker-date-panel .light-picker-body {
	padding: 8px 12px;
}

.light-picker-date-panel .light-picker-content {
	width: 252px;
}

.light-picker-date-panel .light-picker-content th {
	width: 36px;
}

.light-picker-datetime-panel {
	display: flex;
}

.light-picker-datetime-panel .light-picker-time-panel {
	border-left: 1px solid var(--border-color);
}

.light-picker-datetime-panel .light-picker-date-panel,
.light-picker-datetime-panel .light-picker-time-panel {
	transition: opacity 0.3s;
}

.light-picker-datetime-panel-active .light-picker-date-panel,
.light-picker-datetime-panel-active .light-picker-time-panel {
	opacity: 0.3;
}

.light-picker-datetime-panel-active .light-picker-date-panel-active,
.light-picker-datetime-panel-active .light-picker-time-panel-active {
	opacity: 1;
}

.light-picker-time-panel {
	width: auto;
	min-width: auto;
}

.light-picker-time-panel .light-picker-content {
	display: flex;
	flex: auto;
	height: 224px;
}

.light-picker-time-panel-column {
	flex: 1 0 auto;
	width: 56px;
	margin: 0;
	padding: 0;
	overflow-y: hidden;
	text-align: left;
	list-style: none;
	transition: background 0.3s;
}

.light-picker-time-panel-column::after {
	display: block;
	height: 196px;
	content: "";
}

.light-picker-datetime-panel .light-picker-time-panel-column::after {
	height: 198px;
}

.light-picker-time-panel-column:not(:first-child) {
	border-left: 1px solid var(--border-color);
}

.light-picker-time-panel-column-active {
	background: var(--light-primary-color-active-deprecated-f-30);
}

.light-picker-time-panel-column:hover {
	-webkit-overflow-scrolling: touch;
	overflow-y: auto;
	overflow-x: hidden;
	margin-bottom: -0.2rem;
	overflow: -moz-scrollbars-none;
}

.light-picker-time-panel-column::-webkit-scrollbar {
	display: none;
}

.light-picker-time-panel-column>li {
	margin: 0;
	padding: 0;
}

.light-picker-time-panel-column>li.light-picker-time-panel-cell .light-picker-time-panel-cell-inner {
	display: block;
	width: 100%;
	height: 28px;
	margin: 0;
	padding: 0 0 0 14px;
	color: rgba(0, 0, 0, 0.85);
	line-height: 28px;
	border-radius: 0;
	cursor: pointer;
	transition: background 0.3s;
}

.light-picker-time-panel-column>li.light-picker-time-panel-cell .light-picker-time-panel-cell-inner:hover {
	background: #f5f5f5;
}

.light-picker-time-panel-column>li.light-picker-time-panel-cell-selected .light-picker-time-panel-cell-inner {
	background: var(--light-primary-1);
}

.light-picker-time-panel-column>li.light-picker-time-panel-cell-disabled .light-picker-time-panel-cell-inner {
	color: rgba(0, 0, 0, 0.25);
	background: transparent;
	cursor: not-allowed;
}

/* stylelint-disable selector-type-no-unknown,selector-no-vendor-prefix */
_:-ms-fullscreen .light-picker-range-wrapper .light-picker-month-panel .light-picker-cell,
:root .light-picker-range-wrapper .light-picker-month-panel .light-picker-cell,
_:-ms-fullscreen .light-picker-range-wrapper .light-picker-year-panel .light-picker-cell,
:root .light-picker-range-wrapper .light-picker-year-panel .light-picker-cell {
	padding: 21px 0;
}

.light-picker-rtl {
	direction: rtl;
}

.light-picker-rtl .light-picker-suffix {
	margin-right: 4px;
	margin-left: 0;
}

.light-picker-rtl .light-picker-clear {
	right: auto;
	left: 0;
}

.light-picker-rtl .light-picker-separator {
	transform: rotate(180deg);
}

.light-picker-panel-rtl .light-picker-header-view button:not(:first-child) {
	margin-right: 8px;
	margin-left: 0;
}

.light-picker-rtl.light-picker-range .light-picker-clear {
	right: auto;
	left: 11px;
}

.light-picker-rtl.light-picker-range .light-picker-active-bar {
	margin-right: 11px;
	margin-left: 0;
}

.light-picker-rtl.light-picker-range.light-picker-small .light-picker-active-bar {
	margin-right: 7px;
}

.light-picker-dropdown-rtl .light-picker-ranges {
	text-align: right;
}

.light-picker-dropdown-rtl .light-picker-ranges .light-picker-ok {
	float: left;
	margin-right: 8px;
	margin-left: 0;
}

.light-picker-panel-rtl {
	direction: rtl;
}

.light-picker-panel-rtl .light-picker-prev-icon,
.light-picker-panel-rtl .light-picker-super-prev-icon {
	transform: rotate(135deg);
}

.light-picker-panel-rtl .light-picker-next-icon,
.light-picker-panel-rtl .light-picker-super-next-icon {
	transform: rotate(-45deg);
}

.light-picker-cell .light-picker-cell-inner {
	position: relative;
	z-index: 2;
	display: inline-block;
	min-width: 24px;
	height: 24px;
	line-height: 24px;
	border-radius: 2px;
	transition: background 0.3s, border 0.3s;
}

.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-range-start::before {
	right: 50%;
	left: 0;
}

.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-range-end::before {
	right: 0;
	left: 50%;
}

.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-range-start.light-picker-cell-range-end::before {
	right: 50%;
	left: 50%;
}

.light-picker-panel-rtl .light-picker-date-panel .light-picker-cell-in-view.light-picker-cell-in-range.light-picker-cell-range-hover-start .light-picker-cell-inner::after {
	right: 0;
	left: -6px;
}

.light-picker-panel-rtl .light-picker-date-panel .light-picker-cell-in-view.light-picker-cell-in-range.light-picker-cell-range-hover-end .light-picker-cell-inner::after {
	right: -6px;
	left: 0;
}

.light-picker-panel-rtl .light-picker-cell-range-hover.light-picker-cell-range-start::after {
	right: 0;
	left: 50%;
}

.light-picker-panel-rtl .light-picker-cell-range-hover.light-picker-cell-range-end::after {
	right: 50%;
	left: 0;
}

.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-range-start:not(.light-picker-cell-range-start-single):not(.light-picker-cell-range-end) .light-picker-cell-inner {
	border-radius: 0 2px 2px 0;
}

.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-range-end:not(.light-picker-cell-range-end-single):not(.light-picker-cell-range-start) .light-picker-cell-inner {
	border-radius: 2px 0 0 2px;
}

.light-picker-panel-rtl tr>.light-picker-cell-in-view.light-picker-cell-range-hover:not(.light-picker-cell-selected):first-child::after,
.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-start.light-picker-cell-range-hover-edge-start.light-picker-cell-range-hover-edge-start-near-range::after,
.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-range-hover-edge-start:not(.light-picker-cell-range-hover-edge-start-near-range)::after,
.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-range-hover-start::after {
	right: 6px;
	left: 0;
	border-right: 1px dashed var(--light-primary-color-deprecated-l-20);
	border-left: none;
	border-radius: 0 2px 2px 0;
}

.light-picker-panel-rtl tr>.light-picker-cell-in-view.light-picker-cell-range-hover:not(.light-picker-cell-selected):last-child::after,
.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-end.light-picker-cell-range-hover-edge-end.light-picker-cell-range-hover-edge-end-near-range::after,
.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-range-hover-edge-end:not(.light-picker-cell-range-hover-edge-end-near-range)::after,
.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-range-hover-end::after {
	right: 0;
	left: 6px;
	border-right: none;
	border-left: 1px dashed var(--light-primary-color-deprecated-l-20);
	border-radius: 2px 0 0 2px;
}

.light-picker-panel-rtl tr>.light-picker-cell-in-view.light-picker-cell-range-hover-start:last-child::after,
.light-picker-panel-rtl tr>.light-picker-cell-in-view.light-picker-cell-range-hover-end:first-child::after,
.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-start.light-picker-cell-range-hover-edge-start:not(.light-picker-cell-range-hover)::after,
.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-start.light-picker-cell-range-hover-end.light-picker-cell-range-hover-edge-start:not(.light-picker-cell-range-hover)::after,
.light-picker-panel-rtl .light-picker-cell-in-view.light-picker-cell-end.light-picker-cell-range-hover-start.light-picker-cell-range-hover-edge-end:not(.light-picker-cell-range-hover)::after,
.light-picker-panel-rtl tr>.light-picker-cell-in-view.light-picker-cell-start.light-picker-cell-range-hover.light-picker-cell-range-hover-edge-start:last-child::after,
.light-picker-panel-rtl tr>.light-picker-cell-in-view.light-picker-cell-end.light-picker-cell-range-hover.light-picker-cell-range-hover-edge-end:first-child::after {
	right: 6px;
	left: 6px;
	border-right: 1px dashed var(--light-primary-color-deprecated-l-20);
	border-left: 1px dashed var(--light-primary-color-deprecated-l-20);
	border-radius: 2px;
}

.light-picker-dropdown-rtl .light-picker-footer-extra {
	direction: rtl;
	text-align: right;
}

.light-picker-panel-rtl .light-picker-time-panel {
	direction: ltr;
}

.light-descriptions-header {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
}

.light-descriptions-title {
	flex: auto;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.85);
	font-weight: bold;
	font-size: 16px;
	line-height: 1.5715;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.light-descriptions-extra {
	margin-left: auto;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
}

.light-descriptions-view {
	width: 100%;
	border-radius: 2px;
}

.light-descriptions-view table {
	width: 100%;
	table-layout: fixed;
}

.light-descriptions-row>th,
.light-descriptions-row>td {
	padding-bottom: 16px;
}

.light-descriptions-row:last-child {
	border-bottom: none;
}

.light-descriptions-item-label {
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	font-size: 14px;
	line-height: 1.5715;
	text-align: start;
}

.light-descriptions-item-label::after {
	content: ":";
	position: relative;
	top: -0.5px;
	margin: 0 8px 0 2px;
}

.light-descriptions-item-label.light-descriptions-item-no-colon::after {
	content: " ";
}

.light-descriptions-item-no-label::after {
	margin: 0;
	content: "";
}

.light-descriptions-item-content {
	display: table-cell;
	flex: 1;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 1.5715;
	word-break: break-word;
	overflow-wrap: break-word;
}

.light-descriptions-item {
	padding-bottom: 0;
	vertical-align: top;
}

.light-descriptions-item-container {
	display: flex;
}

.light-descriptions-item-container .light-descriptions-item-label,
.light-descriptions-item-container .light-descriptions-item-content {
	display: inline-flex;
	align-items: baseline;
}

.light-descriptions-middle .light-descriptions-row>th,
.light-descriptions-middle .light-descriptions-row>td {
	padding-bottom: 12px;
}

.light-descriptions-small .light-descriptions-row>th,
.light-descriptions-small .light-descriptions-row>td {
	padding-bottom: 8px;
}

.light-descriptions-bordered .light-descriptions-view {
	border: 1px solid var(--border-color);
}

.light-descriptions-bordered .light-descriptions-view>table {
	table-layout: auto;
	border-collapse: collapse;
}

.light-descriptions-bordered .light-descriptions-item-label,
.light-descriptions-bordered .light-descriptions-item-content {
	padding: 16px 24px;
	border-right: 1px solid var(--border-color);
}

.light-descriptions-bordered .light-descriptions-item-label:last-child,
.light-descriptions-bordered .light-descriptions-item-content:last-child {
	border-right: none;
}

.light-descriptions-bordered .light-descriptions-item-label {
	background-color: #fafafa;
}

.light-descriptions-bordered .light-descriptions-item-label::after {
	display: none;
}

.light-descriptions-bordered .light-descriptions-row {
	border-bottom: 1px solid var(--border-color);
}

.light-descriptions-bordered .light-descriptions-row:last-child {
	border-bottom: none;
}

.light-descriptions-bordered.light-descriptions-middle .light-descriptions-item-label,
.light-descriptions-bordered.light-descriptions-middle .light-descriptions-item-content {
	padding: 12px 24px;
}

.light-descriptions-bordered.light-descriptions-small .light-descriptions-item-label,
.light-descriptions-bordered.light-descriptions-small .light-descriptions-item-content {
	padding: 8px 16px;
}

.light-descriptions-rtl {
	direction: rtl;
}

.light-descriptions-rtl .light-descriptions-item-label::after {
	margin: 0 2px 0 8px;
}

.light-descriptions-rtl.light-descriptions-bordered .light-descriptions-item-label,
.light-descriptions-rtl.light-descriptions-bordered .light-descriptions-item-content {
	border-right: none;
	border-left: 1px solid var(--border-color);
}

.light-descriptions-rtl.light-descriptions-bordered .light-descriptions-item-label:last-child,
.light-descriptions-rtl.light-descriptions-bordered .light-descriptions-item-content:last-child {
	border-left: none;
}

.light-divider {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	border-top: 1px solid var(--border-color);
}

.light-divider-vertical {
	position: relative;
	top: -0.06em;
	display: inline-block;
	height: 0.9em;
	margin: 0 8px;
	vertical-align: middle;
	border-top: 0;
	border-left: 1px solid var(--border-color);
}

.light-divider-horizontal {
	display: flex;
	clear: both;
	width: 100%;
	min-width: 100%;
	margin: 24px 0;
}

.light-divider-horizontal.light-divider-with-text {
	display: flex;
	margin: 16px 0;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	white-space: nowrap;
	text-align: center;
	border-top: 0;
	border-top-color: var(--border-color);
}

.light-divider-horizontal.light-divider-with-text::before,
.light-divider-horizontal.light-divider-with-text::after {
	position: relative;
	top: 50%;
	width: 50%;
	border-top: 1px solid transparent;
	border-top-color: inherit;
	border-bottom: 0;
	transform: translateY(50%);
	content: "";
}

.light-divider-horizontal.light-divider-with-text-left::before {
	top: 50%;
	width: 5%;
}

.light-divider-horizontal.light-divider-with-text-left::after {
	top: 50%;
	width: 95%;
}

.light-divider-horizontal.light-divider-with-text-right::before {
	top: 50%;
	width: 95%;
}

.light-divider-horizontal.light-divider-with-text-right::after {
	top: 50%;
	width: 5%;
}

.light-divider-inner-text {
	display: inline-block;
	padding: 0 1em;
}

.light-divider-dashed {
	background: none;
	border-color: var(--border-color);
	border-style: dashed;
	border-width: 1px 0 0;
}

.light-divider-horizontal.light-divider-with-text.light-divider-dashed::before,
.light-divider-horizontal.light-divider-with-text.light-divider-dashed::after {
	border-style: dashed none none;
}

.light-divider-vertical.light-divider-dashed {
	border-width: 0 0 0 1px;
}

.light-divider-plain.light-divider-with-text {
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	font-size: 14px;
}

.light-divider-horizontal.light-divider-with-text-left.light-divider-no-default-orientation-margin-left::before {
	width: 0;
}

.light-divider-horizontal.light-divider-with-text-left.light-divider-no-default-orientation-margin-left::after {
	width: 100%;
}

.light-divider-horizontal.light-divider-with-text-left.light-divider-no-default-orientation-margin-left .light-divider-inner-text {
	padding-left: 0;
}

.light-divider-horizontal.light-divider-with-text-right.light-divider-no-default-orientation-margin-right::before {
	width: 100%;
}

.light-divider-horizontal.light-divider-with-text-right.light-divider-no-default-orientation-margin-right::after {
	width: 0;
}

.light-divider-horizontal.light-divider-with-text-right.light-divider-no-default-orientation-margin-right .light-divider-inner-text {
	padding-right: 0;
}

.light-divider-rtl {
	direction: rtl;
}

.light-divider-rtl.light-divider-horizontal.light-divider-with-text-left::before {
	width: 95%;
}

.light-divider-rtl.light-divider-horizontal.light-divider-with-text-left::after {
	width: 5%;
}

.light-divider-rtl.light-divider-horizontal.light-divider-with-text-right::before {
	width: 5%;
}

.light-divider-rtl.light-divider-horizontal.light-divider-with-text-right::after {
	width: 95%;
}

.light-drawer {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	pointer-events: none;
}

.light-drawer-inline {
	position: absolute;
}

.light-drawer-mask {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	background: rgba(0, 0, 0, 0.45);
	pointer-events: auto;
}

.light-drawer-content-wrapper {
	position: absolute;
	z-index: 1000;
	transition: all 0.3s;
}

.light-drawer-content-wrapper-hidden {
	display: none;
}

.light-drawer-left>.light-drawer-content-wrapper {
	top: 0;
	bottom: 0;
	left: 0;
	box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08),
		9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}

.light-drawer-right>.light-drawer-content-wrapper {
	top: 0;
	right: 0;
	bottom: 0;
	box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08),
		-9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}

.light-drawer-top>.light-drawer-content-wrapper {
	top: 0;
	right: 0;
	left: 0;
	box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
		0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}

.light-drawer-bottom>.light-drawer-content-wrapper {
	right: 0;
	bottom: 0;
	left: 0;
	box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08),
		0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}

.light-drawer-content {
	width: 100%;
	height: 100%;
	overflow: auto;
	background: #fff;
	pointer-events: auto;
}

.light-drawer-wrapper-body {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.light-drawer-header {
	display: flex;
	flex: 0;
	align-items: center;
	padding: 16px 24px;
	font-size: 16px;
	line-height: 22px;
	border-bottom: 1px solid var(--border-color);
}

.light-drawer-header-title {
	display: flex;
	flex: 1;
	align-items: center;
	min-width: 0;
	min-height: 0;
}

.light-drawer-extra {
	flex: 0;
}

.light-drawer-close {
	display: inline-block;
	margin-right: 12px;
	color: rgba(0, 0, 0, 0.45);
	font-weight: 700;
	font-size: 16px;
	font-style: normal;
	line-height: 1;
	text-align: center;
	text-transform: none;
	text-decoration: none;
	background: transparent;
	border: 0;
	outline: 0;
	cursor: pointer;
	transition: color 0.3s;
	text-rendering: auto;
}

.light-drawer-close:focus,
.light-drawer-close:hover {
	color: rgba(0, 0, 0, 0.75);
	text-decoration: none;
}

.light-drawer-title {
	flex: 1;
	margin: 0;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
}

.light-drawer-body {
	flex: 1;
	min-width: 0;
	min-height: 0;
	padding: 24px;
	overflow: auto;
}

.light-drawer-footer {
	flex-shrink: 0;
	padding: 10px 16px;
	border-top: 1px solid var(--border-color);
}

.panel-motion-enter-start,
.panel-motion-appear-start,
.panel-motion-leave-start {
	transition: none;
}

.panel-motion-enter-active,
.panel-motion-appear-active,
.panel-motion-leave-active {
	transition: all 0.3s;
}

.light-drawer-mask-motion-enter-active,
.light-drawer-mask-motion-appear-active,
.light-drawer-mask-motion-leave-active {
	transition: all 0.3s;
}

.light-drawer-mask-motion-enter,
.light-drawer-mask-motion-appear {
	opacity: 0;
}

.light-drawer-mask-motion-enter-active,
.light-drawer-mask-motion-appear-active {
	opacity: 1;
}

.light-drawer-mask-motion-leave {
	opacity: 1;
}

.light-drawer-mask-motion-leave-active {
	opacity: 0;
}

.light-drawer-panel-motion-left-enter-start,
.light-drawer-panel-motion-left-appear-start,
.light-drawer-panel-motion-left-leave-start {
	transition: none;
}

.light-drawer-panel-motion-left-enter-active,
.light-drawer-panel-motion-left-appear-active,
.light-drawer-panel-motion-left-leave-active {
	transition: all 0.3s;
}

.light-drawer-panel-motion-left-enter,
.light-drawer-panel-motion-left-appear {
	transform: translateX(-100%);
}

.light-drawer-panel-motion-left-enter-active,
.light-drawer-panel-motion-left-appear-active {
	transform: translateX(0);
}

.light-drawer-panel-motion-left-leave {
	transform: translateX(0);
}

.light-drawer-panel-motion-left-leave-active {
	transform: translateX(-100%);
}

.light-drawer-panel-motion-right-enter-start,
.light-drawer-panel-motion-right-appear-start,
.light-drawer-panel-motion-right-leave-start {
	transition: none;
}

.light-drawer-panel-motion-right-enter-active,
.light-drawer-panel-motion-right-appear-active,
.light-drawer-panel-motion-right-leave-active {
	transition: all 0.3s;
}

.light-drawer-panel-motion-right-enter,
.light-drawer-panel-motion-right-appear {
	transform: translateX(100%);
}

.light-drawer-panel-motion-right-enter-active,
.light-drawer-panel-motion-right-appear-active {
	transform: translateX(0);
}

.light-drawer-panel-motion-right-leave {
	transform: translateX(0);
}

.light-drawer-panel-motion-right-leave-active {
	transform: translateX(100%);
}

.light-drawer-panel-motion-top-enter-start,
.light-drawer-panel-motion-top-appear-start,
.light-drawer-panel-motion-top-leave-start {
	transition: none;
}

.light-drawer-panel-motion-top-enter-active,
.light-drawer-panel-motion-top-appear-active,
.light-drawer-panel-motion-top-leave-active {
	transition: all 0.3s;
}

.light-drawer-panel-motion-top-enter,
.light-drawer-panel-motion-top-appear {
	transform: translateY(-100%);
}

.light-drawer-panel-motion-top-enter-active,
.light-drawer-panel-motion-top-appear-active {
	transform: translateY(0);
}

.light-drawer-panel-motion-top-leave {
	transform: translateY(0);
}

.light-drawer-panel-motion-top-leave-active {
	transform: translateY(-100%);
}

.light-drawer-panel-motion-bottom-enter-start,
.light-drawer-panel-motion-bottom-appear-start,
.light-drawer-panel-motion-bottom-leave-start {
	transition: none;
}

.light-drawer-panel-motion-bottom-enter-active,
.light-drawer-panel-motion-bottom-appear-active,
.light-drawer-panel-motion-bottom-leave-active {
	transition: all 0.3s;
}

.light-drawer-panel-motion-bottom-enter,
.light-drawer-panel-motion-bottom-appear {
	transform: translateY(100%);
}

.light-drawer-panel-motion-bottom-enter-active,
.light-drawer-panel-motion-bottom-appear-active {
	transform: translateY(0);
}

.light-drawer-panel-motion-bottom-leave {
	transform: translateY(0);
}

.light-drawer-panel-motion-bottom-leave-active {
	transform: translateY(100%);
}

.light-drawer-rtl {
	direction: rtl;
}

.light-drawer-rtl .light-drawer-close {
	margin-right: 0;
	margin-left: 12px;
}

.light-dropdown-menu-item.light-dropdown-menu-item-danger {
	color: var(--light-error-color);
}

.light-dropdown-menu-item.light-dropdown-menu-item-danger:hover {
	color: #fff;
	background-color: var(--light-error-color);
}

.light-dropdown {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	display: block;
}

.light-dropdown::before {
	position: absolute;
	top: -4px;
	right: 0;
	bottom: -4px;
	left: -7px;
	z-index: -9999;
	opacity: 0.0001;
	content: " ";
}

.light-dropdown-wrap {
	position: relative;
}

.light-dropdown-wrap .light-btn>.anticon-down {
	font-size: 10px;
}

.light-dropdown-wrap .anticon-down::before {
	transition: transform 0.2s;
}

.light-dropdown-wrap-open .anticon-down::before {
	transform: rotate(180deg);
}

.light-dropdown-hidden,
.light-dropdown-menu-hidden,
.light-dropdown-menu-submenu-hidden {
	display: none;
}

.light-dropdown-show-arrow.light-dropdown-placement-topLeft,
.light-dropdown-show-arrow.light-dropdown-placement-top,
.light-dropdown-show-arrow.light-dropdown-placement-topRight {
	padding-bottom: 0;
}

.light-dropdown-show-arrow.light-dropdown-placement-bottomLeft,
.light-dropdown-show-arrow.light-dropdown-placement-bottom,
.light-dropdown-show-arrow.light-dropdown-placement-bottomRight {
	padding-top: 0;
}

.light-dropdown-arrow {
	position: absolute;
	z-index: 1;
	display: block;
	width: 11.3137085px;
	height: 11.3137085px;
	border-radius: 0 0 2px;
	pointer-events: none;
}

.light-dropdown-arrow::before {
	position: absolute;
	top: -11.3137085px;
	left: -11.3137085px;
	width: 33.9411255px;
	height: 33.9411255px;
	background: #fff;
	background-repeat: no-repeat;
	background-position: -10px -10px;
	content: "";
	clip-path: inset(33% 33%);
	clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z"
		);
}

.light-dropdown-placement-top>.light-dropdown-arrow,
.light-dropdown-placement-topLeft>.light-dropdown-arrow,
.light-dropdown-placement-topRight>.light-dropdown-arrow {
	bottom: 10px;
	box-shadow: 3px 3px 7px -3px rgba(0, 0, 0, 0.1);
	transform: rotate(45deg);
}

.light-dropdown-placement-top>.light-dropdown-arrow {
	left: 50%;
	transform: translateX(-50%) rotate(45deg);
}

.light-dropdown-placement-topLeft>.light-dropdown-arrow {
	left: 16px;
}

.light-dropdown-placement-topRight>.light-dropdown-arrow {
	right: 16px;
}

.light-dropdown-placement-bottom>.light-dropdown-arrow,
.light-dropdown-placement-bottomLeft>.light-dropdown-arrow,
.light-dropdown-placement-bottomRight>.light-dropdown-arrow {
	top: 9.41421356px;
	box-shadow: 2px 2px 5px -2px rgba(0, 0, 0, 0.1);
	transform: rotate(-135deg) translateY(-0.5px);
}

.light-dropdown-placement-bottom>.light-dropdown-arrow {
	left: 50%;
	transform: translateX(-50%) rotate(-135deg) translateY(-0.5px);
}

.light-dropdown-placement-bottomLeft>.light-dropdown-arrow {
	left: 16px;
}

.light-dropdown-placement-bottomRight>.light-dropdown-arrow {
	right: 16px;
}

.light-dropdown-menu {
	position: relative;
	margin: 0;
	padding: 4px 0;
	text-align: left;
	list-style-type: none;
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 8px;
	outline: none;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.light-dropdown-menu-item-group-title {
	padding: 5px 12px;
	color: rgba(0, 0, 0, 0.45);
	transition: all 0.3s;
}

.light-dropdown-menu-submenu-popup {
	position: absolute;
	z-index: 1050;
	background: transparent;
	box-shadow: none;
	transform-origin: 0 0;
}

.light-dropdown-menu-submenu-popup ul,
.light-dropdown-menu-submenu-popup li {
	list-style: none;
}

.light-dropdown-menu-submenu-popup ul {
	margin-right: 0.3em;
	margin-left: 0.3em;
}

.light-dropdown-menu-item {
	position: relative;
	display: flex;
	align-items: center;
}

.light-dropdown-menu-item-icon {
	min-width: 12px;
	margin-right: 8px;
	font-size: 12px;
}

.light-dropdown-menu-title-content {
	flex: auto;
}

.light-dropdown-menu-title-content>a {
	color: inherit;
	transition: all 0.3s;
}

.light-dropdown-menu-title-content>a:hover {
	color: inherit;
}

.light-dropdown-menu-title-content>a::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	content: "";
}

.light-dropdown-menu-item,
.light-dropdown-menu-submenu-title {
	clear: both;
	margin: 0;
	padding: 5px 12px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	cursor: pointer;
	transition: all 0.3s;
}

.light-dropdown-menu-item-selected,
.light-dropdown-menu-submenu-title-selected {
	color: var(--light-primary-color);
	background-color: var(--light-primary-1);
}

.light-dropdown-menu-item:hover,
.light-dropdown-menu-submenu-title:hover,
.light-dropdown-menu-item.light-dropdown-menu-item-active,
.light-dropdown-menu-item.light-dropdown-menu-submenu-title-active,
.light-dropdown-menu-submenu-title.light-dropdown-menu-item-active,
.light-dropdown-menu-submenu-title.light-dropdown-menu-submenu-title-active {
	background-color: #f5f5f5;
}

.light-dropdown-menu-item-disabled,
.light-dropdown-menu-submenu-title-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-dropdown-menu-item-disabled:hover,
.light-dropdown-menu-submenu-title-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background-color: #fff;
	cursor: not-allowed;
}

.light-dropdown-menu-item-disabled a,
.light-dropdown-menu-submenu-title-disabled a {
	pointer-events: none;
}

.light-dropdown-menu-item-divider,
.light-dropdown-menu-submenu-title-divider {
	height: 1px;
	margin: 4px 0;
	overflow: hidden;
	line-height: 0;
	background-color: var(--border-color);
}

.light-dropdown-menu-item .light-dropdown-menu-submenu-expand-icon,
.light-dropdown-menu-submenu-title .light-dropdown-menu-submenu-expand-icon {
	position: absolute;
	right: 8px;
}

.light-dropdown-menu-item .light-dropdown-menu-submenu-expand-icon .light-dropdown-menu-submenu-arrow-icon,
.light-dropdown-menu-submenu-title .light-dropdown-menu-submenu-expand-icon .light-dropdown-menu-submenu-arrow-icon {
	margin-right: 0 !important;
	color: rgba(0, 0, 0, 0.45);
	font-size: 10px;
	font-style: normal;
}

.light-dropdown-menu-item-group-list {
	margin: 0 8px;
	padding: 0;
	list-style: none;
}

.light-dropdown-menu-submenu-title {
	padding-right: 24px;
}

.light-dropdown-menu-submenu-vertical {
	position: relative;
}

.light-dropdown-menu-submenu-vertical>.light-dropdown-menu {
	position: absolute;
	top: 0;
	left: 100%;
	min-width: 100%;
	margin-left: 4px;
	transform-origin: 0 0;
}

.light-dropdown-menu-submenu.light-dropdown-menu-submenu-disabled .light-dropdown-menu-submenu-title,
.light-dropdown-menu-submenu.light-dropdown-menu-submenu-disabled .light-dropdown-menu-submenu-title .light-dropdown-menu-submenu-arrow-icon {
	color: rgba(0, 0, 0, 0.25);
	background-color: #fff;
	cursor: not-allowed;
}

.light-dropdown-menu-submenu-selected .light-dropdown-menu-submenu-title {
	color: var(--light-primary-color);
}

.light-dropdown.light-slide-down-enter.light-slide-down-enter-active.light-dropdown-placement-bottomLeft,
.light-dropdown.light-slide-down-appear.light-slide-down-appear-active.light-dropdown-placement-bottomLeft,
.light-dropdown.light-slide-down-enter.light-slide-down-enter-active.light-dropdown-placement-bottom,
.light-dropdown.light-slide-down-appear.light-slide-down-appear-active.light-dropdown-placement-bottom,
.light-dropdown.light-slide-down-enter.light-slide-down-enter-active.light-dropdown-placement-bottomRight,
.light-dropdown.light-slide-down-appear.light-slide-down-appear-active.light-dropdown-placement-bottomRight {
	animation-name: antSlideUpIn;
}

.light-dropdown.light-slide-up-enter.light-slide-up-enter-active.light-dropdown-placement-topLeft,
.light-dropdown.light-slide-up-appear.light-slide-up-appear-active.light-dropdown-placement-topLeft,
.light-dropdown.light-slide-up-enter.light-slide-up-enter-active.light-dropdown-placement-top,
.light-dropdown.light-slide-up-appear.light-slide-up-appear-active.light-dropdown-placement-top,
.light-dropdown.light-slide-up-enter.light-slide-up-enter-active.light-dropdown-placement-topRight,
.light-dropdown.light-slide-up-appear.light-slide-up-appear-active.light-dropdown-placement-topRight {
	animation-name: antSlideDownIn;
}

.light-dropdown.light-slide-down-leave.light-slide-down-leave-active.light-dropdown-placement-bottomLeft,
.light-dropdown.light-slide-down-leave.light-slide-down-leave-active.light-dropdown-placement-bottom,
.light-dropdown.light-slide-down-leave.light-slide-down-leave-active.light-dropdown-placement-bottomRight {
	animation-name: antSlideUpOut;
}

.light-dropdown.light-slide-up-leave.light-slide-up-leave-active.light-dropdown-placement-topLeft,
.light-dropdown.light-slide-up-leave.light-slide-up-leave-active.light-dropdown-placement-top,
.light-dropdown.light-slide-up-leave.light-slide-up-leave-active.light-dropdown-placement-topRight {
	animation-name: antSlideDownOut;
}

.light-dropdown-trigger>.anticon.anticon-down,
.light-dropdown-link>.anticon.anticon-down,
.light-dropdown-button>.anticon.anticon-down {
	font-size: 10px;
	vertical-align: baseline;
}

.light-dropdown-button {
	white-space: nowrap;
}

.light-dropdown-button.light-btn-group>.light-btn-loading,
.light-dropdown-button.light-btn-group>.light-btn-loading+.light-btn {
	cursor: default;
	pointer-events: none;
}

.light-dropdown-button.light-btn-group>.light-btn-loading+.light-btn::before {
	display: block;
}

.light-dropdown-button.light-btn-group>.light-btn:last-child:not(:first-child):not(.light-btn-icon-only) {
	padding-right: 8px;
	padding-left: 8px;
}

.light-dropdown-menu-dark,
.light-dropdown-menu-dark .light-dropdown-menu {
	background: #001529;
}

.light-dropdown-menu-dark .light-dropdown-menu-item,
.light-dropdown-menu-dark .light-dropdown-menu-submenu-title,
.light-dropdown-menu-dark .light-dropdown-menu-item>a,
.light-dropdown-menu-dark .light-dropdown-menu-item>.anticon+span>a {
	color: rgba(255, 255, 255, 0.65);
}

.light-dropdown-menu-dark .light-dropdown-menu-item .light-dropdown-menu-submenu-arrow::after,
.light-dropdown-menu-dark .light-dropdown-menu-submenu-title .light-dropdown-menu-submenu-arrow::after,
.light-dropdown-menu-dark .light-dropdown-menu-item>a .light-dropdown-menu-submenu-arrow::after,
.light-dropdown-menu-dark .light-dropdown-menu-item>.anticon+span>a .light-dropdown-menu-submenu-arrow::after {
	color: rgba(255, 255, 255, 0.65);
}

.light-dropdown-menu-dark .light-dropdown-menu-item:hover,
.light-dropdown-menu-dark .light-dropdown-menu-submenu-title:hover,
.light-dropdown-menu-dark .light-dropdown-menu-item>a:hover,
.light-dropdown-menu-dark .light-dropdown-menu-item>.anticon+span>a:hover {
	color: #fff;
	background: transparent;
}

.light-dropdown-menu-dark .light-dropdown-menu-item-selected,
.light-dropdown-menu-dark .light-dropdown-menu-item-selected:hover,
.light-dropdown-menu-dark .light-dropdown-menu-item-selected>a {
	color: #fff;
	background: var(--light-primary-color);
}

.light-dropdown-rtl {
	direction: rtl;
}

.light-dropdown-rtl.light-dropdown::before {
	right: -7px;
	left: 0;
}

.light-dropdown-menu.light-dropdown-menu-rtl {
	direction: rtl;
	text-align: right;
}

.light-dropdown-rtl .light-dropdown-menu-item-group-title,
.light-dropdown-menu-submenu-rtl .light-dropdown-menu-item-group-title {
	direction: rtl;
	text-align: right;
}

.light-dropdown-menu-submenu-popup.light-dropdown-menu-submenu-rtl {
	transform-origin: 100% 0;
}

.light-dropdown-rtl .light-dropdown-menu-submenu-popup ul,
.light-dropdown-rtl .light-dropdown-menu-submenu-popup li {
	text-align: right;
}

.light-dropdown-rtl .light-dropdown-menu-item,
.light-dropdown-rtl .light-dropdown-menu-submenu-title {
	text-align: right;
}

.light-dropdown-rtl .light-dropdown-menu-item>.anticon:first-child,
.light-dropdown-rtl .light-dropdown-menu-submenu-title>.anticon:first-child,
.light-dropdown-rtl .light-dropdown-menu-item>span>.anticon:first-child,
.light-dropdown-rtl .light-dropdown-menu-submenu-title>span>.anticon:first-child {
	margin-right: 0;
	margin-left: 8px;
}

.light-dropdown-rtl .light-dropdown-menu-item .light-dropdown-menu-submenu-expand-icon,
.light-dropdown-rtl .light-dropdown-menu-submenu-title .light-dropdown-menu-submenu-expand-icon {
	right: auto;
	left: 8px;
}

.light-dropdown-rtl .light-dropdown-menu-item .light-dropdown-menu-submenu-expand-icon .light-dropdown-menu-submenu-arrow-icon,
.light-dropdown-rtl .light-dropdown-menu-submenu-title .light-dropdown-menu-submenu-expand-icon .light-dropdown-menu-submenu-arrow-icon {
	margin-left: 0 !important;
	transform: scaleX(-1);
}

.light-dropdown-rtl .light-dropdown-menu-submenu-title {
	padding-right: 12px;
	padding-left: 24px;
}

.light-dropdown-rtl .light-dropdown-menu-submenu-vertical>.light-dropdown-menu {
	right: 100%;
	left: 0;
	margin-right: 4px;
	margin-left: 0;
}

.light-empty {
	margin: 0 8px;
	font-size: 14px;
	line-height: 1.5715;
	text-align: center;
}

.light-empty-image {
	height: 100px;
	margin-bottom: 8px;
}

.light-empty-image img {
	height: 100%;
}

.light-empty-image svg {
	height: 100%;
	margin: auto;
}

.light-empty-footer {
	margin-top: 16px;
}

.light-empty-normal {
	margin: 32px 0;
	color: rgba(0, 0, 0, 0.25);
}

.light-empty-normal .light-empty-image {
	height: 40px;
}

.light-empty-small {
	margin: 8px 0;
	color: rgba(0, 0, 0, 0.25);
}

.light-empty-small .light-empty-image {
	height: 35px;
}

.light-empty-img-default-ellipse {
	fill: #f5f5f5;
	fill-opacity: 0.8;
}

.light-empty-img-default-path-1 {
	fill: #aeb8c2;
}

.light-empty-img-default-path-2 {
	fill: url("#linearGradient-1");
}

.light-empty-img-default-path-3 {
	fill: #f5f5f7;
}

.light-empty-img-default-path-4 {
	fill: #dce0e6;
}

.light-empty-img-default-path-5 {
	fill: #dce0e6;
}

.light-empty-img-default-g {
	fill: #fff;
}

.light-empty-img-simple-ellipse {
	fill: #f5f5f5;
}

.light-empty-img-simple-g {
	stroke: #d9d9d9;
}

.light-empty-img-simple-path {
	fill: #fafafa;
}

.light-empty-rtl {
	direction: rtl;
}

.light-form-item .light-input-number+.light-form-text {
	margin-left: 8px;
}

.light-form-inline {
	display: flex;
	flex-wrap: wrap;
}

.light-form-inline .light-form-item {
	flex: none;
	flex-wrap: nowrap;
	margin-right: 16px;
	margin-bottom: 0;
}

.light-form-inline .light-form-item-with-help {
	margin-bottom: 24px;
}

.light-form-inline .light-form-item>.light-form-item-label,
.light-form-inline .light-form-item>.light-form-item-control {
	display: inline-block;
	vertical-align: top;
}

.light-form-inline .light-form-item>.light-form-item-label {
	flex: none;
}

.light-form-inline .light-form-item .light-form-text {
	display: inline-block;
}

.light-form-inline .light-form-item .light-form-item-has-feedback {
	display: inline-block;
}

.light-form-horizontal .light-form-item-label {
	flex-grow: 0;
}

.light-form-horizontal .light-form-item-control {
	flex: 1 1 0;
	min-width: 0;
}

.light-form-horizontal .light-form-item-label[class$="-24"]+.light-form-item-control,
.light-form-horizontal .light-form-item-label[class*="-24 "]+.light-form-item-control {
	min-width: unset;
}

.light-form-vertical .light-form-item-row {
	flex-direction: column;
}

.light-form-vertical .light-form-item-label>label {
	height: auto;
}

.light-form-vertical .light-form-item .light-form-item-control {
	width: 100%;
}

.light-form-vertical .light-form-item-label,
.light-col-24.light-form-item-label,
.light-col-xl-24.light-form-item-label {
	padding: 0 0 8px;
	line-height: 1.5715;
	white-space: initial;
	text-align: left;
}

.light-form-vertical .light-form-item-label>label,
.light-col-24.light-form-item-label>label,
.light-col-xl-24.light-form-item-label>label {
	margin: 0;
}

.light-form-vertical .light-form-item-label>label::after,
.light-col-24.light-form-item-label>label::after,
.light-col-xl-24.light-form-item-label>label::after {
	display: none;
}

.light-form-rtl.light-form-vertical .light-form-item-label,
.light-form-rtl.light-col-24.light-form-item-label,
.light-form-rtl.light-col-xl-24.light-form-item-label {
	text-align: right;
}

@media (max-width: 575px) {
	.light-form-item .light-form-item-label {
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}

	.light-form-item .light-form-item-label>label {
		margin: 0;
	}

	.light-form-item .light-form-item-label>label::after {
		display: none;
	}

	.light-form-rtl.light-form-item .light-form-item-label {
		text-align: right;
	}

	.light-form .light-form-item {
		flex-wrap: wrap;
	}

	.light-form .light-form-item .light-form-item-label,
	.light-form .light-form-item .light-form-item-control {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.light-col-xs-24.light-form-item-label {
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}

	.light-col-xs-24.light-form-item-label>label {
		margin: 0;
	}

	.light-col-xs-24.light-form-item-label>label::after {
		display: none;
	}

	.light-form-rtl.light-col-xs-24.light-form-item-label {
		text-align: right;
	}
}

@media (max-width: 767px) {
	.light-col-sm-24.light-form-item-label {
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}

	.light-col-sm-24.light-form-item-label>label {
		margin: 0;
	}

	.light-col-sm-24.light-form-item-label>label::after {
		display: none;
	}

	.light-form-rtl.light-col-sm-24.light-form-item-label {
		text-align: right;
	}
}

@media (max-width: 991px) {
	.light-col-md-24.light-form-item-label {
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}

	.light-col-md-24.light-form-item-label>label {
		margin: 0;
	}

	.light-col-md-24.light-form-item-label>label::after {
		display: none;
	}

	.light-form-rtl.light-col-md-24.light-form-item-label {
		text-align: right;
	}
}

@media (max-width: 1199px) {
	.light-col-lg-24.light-form-item-label {
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}

	.light-col-lg-24.light-form-item-label>label {
		margin: 0;
	}

	.light-col-lg-24.light-form-item-label>label::after {
		display: none;
	}

	.light-form-rtl.light-col-lg-24.light-form-item-label {
		text-align: right;
	}
}

@media (max-width: 1599px) {
	.light-col-xl-24.light-form-item-label {
		padding: 0 0 8px;
		line-height: 1.5715;
		white-space: initial;
		text-align: left;
	}

	.light-col-xl-24.light-form-item-label>label {
		margin: 0;
	}

	.light-col-xl-24.light-form-item-label>label::after {
		display: none;
	}

	.light-form-rtl.light-col-xl-24.light-form-item-label {
		text-align: right;
	}
}

.light-form-item {
	/* Some non-status related component style is in `components.less` */
	/* To support leave along ErrorList. We add additional className to handle explain style */
}

.light-form-item-explain-error {
	color: var(--light-error-color);
}

.light-form-item-explain-warning {
	color: var(--light-warning-color);
}

.light-form-item-has-feedback .light-switch {
	margin: 2px 0 4px;
}

.light-form-item-has-warning .light-form-item-split {
	color: var(--light-warning-color);
}

.light-form-item-has-error .light-form-item-split {
	color: var(--light-error-color);
}

.light-form {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
}

.light-form legend {
	display: block;
	width: 100%;
	margin-bottom: 20px;
	padding: 0;
	color: rgba(0, 0, 0, 0.45);
	font-size: 16px;
	line-height: inherit;
	border: 0;
	border-bottom: 1px solid #d9d9d9;
}

.light-form label {
	font-size: 14px;
}

.light-form input[type="search"] {
	box-sizing: border-box;
}

.light-form input[type="radio"],
.light-form input[type="checkbox"] {
	line-height: normal;
}

.light-form input[type="file"] {
	display: block;
}

.light-form input[type="range"] {
	display: block;
	width: 100%;
}

.light-form select[multiple],
.light-form select[size] {
	height: auto;
}

.light-form input[type="file"]:focus,
.light-form input[type="radio"]:focus,
.light-form input[type="checkbox"]:focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

.light-form output {
	display: block;
	padding-top: 15px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 1.5715;
}

.light-form .light-form-text {
	display: inline-block;
	padding-right: 8px;
}

.light-form-small .light-form-item-label>label {
	height: 24px;
}

.light-form-small .light-form-item-control-input {
	min-height: 24px;
}

.light-form-large .light-form-item-label>label {
	height: 40px;
}

.light-form-large .light-form-item-control-input {
	min-height: 40px;
}

.light-form-item {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	vertical-align: top;
}

.light-form-item-with-help {
	transition: none;
}

.light-form-item-hidden,
.light-form-item-hidden.light-row {
	display: none;
}

.light-form-item-label {
	display: inline-block;
	flex-grow: 0;
	overflow: hidden;
	white-space: nowrap;
	text-align: right;
	vertical-align: middle;
}

.light-form-item-label-left {
	text-align: left;
}

.light-form-item-label-wrap {
	overflow: unset;
	line-height: 1.3215em;
	white-space: unset;
}

.light-form-item-label>label {
	position: relative;
	display: inline-flex;
	align-items: center;
	max-width: 100%;
	height: 32px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
}

.light-form-item-label>label>.anticon {
	font-size: 14px;
	vertical-align: top;
}

.light-form-item-label>label.light-form-item-required:not(.light-form-item-required-mark-optional)::before {
	display: inline-block;
	margin-right: 4px;
	color: #ff4d4f;
	font-size: 14px;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: "*";
}

.light-form-hide-required-mark .light-form-item-label>label.light-form-item-required:not(.light-form-item-required-mark-optional)::before {
	display: none;
}

.light-form-item-label>label .light-form-item-optional {
	display: inline-block;
	margin-left: 4px;
	color: rgba(0, 0, 0, 0.45);
}

.light-form-hide-required-mark .light-form-item-label>label .light-form-item-optional {
	display: none;
}

.light-form-item-label>label .light-form-item-tooltip {
	color: rgba(0, 0, 0, 0.45);
	cursor: help;
	writing-mode: horizontal-tb;
	margin-inline-start: 4px;
}

.light-form-item-label>label::after {
	content: ":";
	position: relative;
	top: -0.5px;
	margin: 0 8px 0 2px;
}

.light-form-item-label>label.light-form-item-no-colon::after {
	content: " ";
}

.light-form-item-control {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.light-form-item-control:first-child:not([class^="light-col-"]):not([class*=" light-col-"]) {
	width: 100%;
}

.light-form-item-control-input {
	position: relative;
	display: flex;
	align-items: center;
	min-height: 32px;
}

.light-form-item-control-input-content {
	flex: auto;
	max-width: 100%;
}

.light-form-item-explain,
.light-form-item-extra {
	clear: both;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.5715;
	transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.light-form-item-explain-connected {
	width: 100%;
}

.light-form-item-extra {
	min-height: 24px;
}

.light-form-item-with-help .light-form-item-explain {
	height: auto;
	opacity: 1;
}

.light-form-item-feedback-icon {
	font-size: 14px;
	text-align: center;
	visibility: visible;
	animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
	pointer-events: none;
}

.light-form-item-feedback-icon-success {
	color: var(--light-success-color);
}

.light-form-item-feedback-icon-error {
	color: var(--light-error-color);
}

.light-form-item-feedback-icon-warning {
	color: var(--light-warning-color);
}

.light-form-item-feedback-icon-validating {
	color: var(--light-primary-color);
}

.light-show-help {
	transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-show-help-appear,
.light-show-help-enter {
	opacity: 0;
}

.light-show-help-appear-active,
.light-show-help-enter-active {
	opacity: 1;
}

.light-show-help-leave {
	opacity: 1;
}

.light-show-help-leave-active {
	opacity: 0;
}

.light-show-help-item {
	overflow: hidden;
	transition: height 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.light-show-help-item-appear,
.light-show-help-item-enter {
	transform: translateY(-5px);
	opacity: 0;
}

.light-show-help-item-appear-active,
.light-show-help-item-enter-active {
	transform: translateY(0);
	opacity: 1;
}

.light-show-help-item-leave {
	transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
		opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
		transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.light-show-help-item-leave-active {
	transform: translateY(-5px);
}

@keyframes diffZoomIn1 {
	0% {
		transform: scale(0);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes diffZoomIn2 {
	0% {
		transform: scale(0);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes diffZoomIn3 {
	0% {
		transform: scale(0);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}

.light-form-rtl {
	direction: rtl;
}

.light-form-rtl .light-form-item-label {
	text-align: left;
}

.light-form-rtl .light-form-item-label>label.light-form-item-required::before {
	margin-right: 0;
	margin-left: 4px;
}

.light-form-rtl .light-form-item-label>label::after {
	margin: 0 2px 0 8px;
}

.light-form-rtl .light-form-item-label>label .light-form-item-optional {
	margin-right: 4px;
	margin-left: 0;
}

.light-col-rtl .light-form-item-control:first-child {
	width: 100%;
}

.light-form-rtl .light-form-item-has-feedback .light-input {
	padding-right: 11px;
	padding-left: 24px;
}

.light-form-rtl .light-form-item-has-feedback .light-input-affix-wrapper .light-input-suffix {
	padding-right: 11px;
	padding-left: 18px;
}

.light-form-rtl .light-form-item-has-feedback .light-input-affix-wrapper .light-input {
	padding: 0;
}

.light-form-rtl .light-form-item-has-feedback .light-input-number-affix-wrapper .light-input-number {
	padding: 0;
}

.light-form-rtl .light-form-item-has-feedback .light-input-search:not(.light-input-search-enter-button) .light-input-suffix {
	right: auto;
	left: 28px;
}

.light-form-rtl .light-form-item-has-feedback .light-input-number {
	padding-left: 18px;
}

.light-form-rtl .light-form-item-has-feedback>.light-select .light-select-arrow,
.light-form-rtl .light-form-item-has-feedback>.light-select .light-select-clear,
.light-form-rtl .light-form-item-has-feedback:not(.light-input-group-addon)>.light-select .light-select-arrow,
.light-form-rtl .light-form-item-has-feedback:not(.light-input-group-addon)>.light-select .light-select-clear,
.light-form-rtl .light-form-item-has-feedback:not(.light-input-number-group-addon)>.light-select .light-select-arrow,
.light-form-rtl .light-form-item-has-feedback:not(.light-input-number-group-addon)>.light-select .light-select-clear {
	right: auto;
	left: 32px;
}

.light-form-rtl .light-form-item-has-feedback>.light-select .light-select-selection-selected-value,
.light-form-rtl .light-form-item-has-feedback:not(.light-input-group-addon)>.light-select .light-select-selection-selected-value,
.light-form-rtl .light-form-item-has-feedback:not(.light-input-number-group-addon)>.light-select .light-select-selection-selected-value {
	padding-right: 0;
	padding-left: 42px;
}

.light-form-rtl .light-form-item-has-feedback .light-cascader-picker-arrow {
	margin-right: 0;
	margin-left: 19px;
}

.light-form-rtl .light-form-item-has-feedback .light-cascader-picker-clear {
	right: auto;
	left: 32px;
}

.light-form-rtl .light-form-item-has-feedback .light-picker {
	padding-right: 11px;
	padding-left: 29.2px;
}

.light-form-rtl .light-form-item-has-feedback .light-picker-large {
	padding-right: 11px;
	padding-left: 29.2px;
}

.light-form-rtl .light-form-item-has-feedback .light-picker-small {
	padding-right: 7px;
	padding-left: 25.2px;
}

.light-form-rtl .light-form-item-has-feedback.light-form-item-has-success .light-form-item-children-icon,
.light-form-rtl .light-form-item-has-feedback.light-form-item-has-warning .light-form-item-children-icon,
.light-form-rtl .light-form-item-has-feedback.light-form-item-has-error .light-form-item-children-icon,
.light-form-rtl .light-form-item-has-feedback.light-form-item-is-validating .light-form-item-children-icon {
	right: auto;
	left: 0;
}

.light-form-rtl.light-form-inline .light-form-item {
	margin-right: 0;
	margin-left: 16px;
}

.light-row {
	display: flex;
	flex-flow: row wrap;
	min-width: 0;
}

.light-row::before,
.light-row::after {
	display: flex;
}

.light-row-no-wrap {
	flex-wrap: nowrap;
}

.light-row-start {
	justify-content: flex-start;
}

.light-row-center {
	justify-content: center;
}

.light-row-end {
	justify-content: flex-end;
}

.light-row-space-between {
	justify-content: space-between;
}

.light-row-space-around {
	justify-content: space-around;
}

.light-row-space-evenly {
	justify-content: space-evenly;
}

.light-row-top {
	align-items: flex-start;
}

.light-row-middle {
	align-items: center;
}

.light-row-bottom {
	align-items: flex-end;
}

.light-col {
	position: relative;
	max-width: 100%;
	min-height: 1px;
}

.light-col-24 {
	display: block;
	flex: 0 0 100%;
	max-width: 100%;
}

.light-col-push-24 {
	left: 100%;
}

.light-col-pull-24 {
	right: 100%;
}

.light-col-offset-24 {
	margin-left: 100%;
}

.light-col-order-24 {
	order: 24;
}

.light-col-23 {
	display: block;
	flex: 0 0 95.83333333%;
	max-width: 95.83333333%;
}

.light-col-push-23 {
	left: 95.83333333%;
}

.light-col-pull-23 {
	right: 95.83333333%;
}

.light-col-offset-23 {
	margin-left: 95.83333333%;
}

.light-col-order-23 {
	order: 23;
}

.light-col-22 {
	display: block;
	flex: 0 0 91.66666667%;
	max-width: 91.66666667%;
}

.light-col-push-22 {
	left: 91.66666667%;
}

.light-col-pull-22 {
	right: 91.66666667%;
}

.light-col-offset-22 {
	margin-left: 91.66666667%;
}

.light-col-order-22 {
	order: 22;
}

.light-col-21 {
	display: block;
	flex: 0 0 87.5%;
	max-width: 87.5%;
}

.light-col-push-21 {
	left: 87.5%;
}

.light-col-pull-21 {
	right: 87.5%;
}

.light-col-offset-21 {
	margin-left: 87.5%;
}

.light-col-order-21 {
	order: 21;
}

.light-col-20 {
	display: block;
	flex: 0 0 83.33333333%;
	max-width: 83.33333333%;
}

.light-col-push-20 {
	left: 83.33333333%;
}

.light-col-pull-20 {
	right: 83.33333333%;
}

.light-col-offset-20 {
	margin-left: 83.33333333%;
}

.light-col-order-20 {
	order: 20;
}

.light-col-19 {
	display: block;
	flex: 0 0 79.16666667%;
	max-width: 79.16666667%;
}

.light-col-push-19 {
	left: 79.16666667%;
}

.light-col-pull-19 {
	right: 79.16666667%;
}

.light-col-offset-19 {
	margin-left: 79.16666667%;
}

.light-col-order-19 {
	order: 19;
}

.light-col-18 {
	display: block;
	flex: 0 0 75%;
	max-width: 75%;
}

.light-col-push-18 {
	left: 75%;
}

.light-col-pull-18 {
	right: 75%;
}

.light-col-offset-18 {
	margin-left: 75%;
}

.light-col-order-18 {
	order: 18;
}

.light-col-17 {
	display: block;
	flex: 0 0 70.83333333%;
	max-width: 70.83333333%;
}

.light-col-push-17 {
	left: 70.83333333%;
}

.light-col-pull-17 {
	right: 70.83333333%;
}

.light-col-offset-17 {
	margin-left: 70.83333333%;
}

.light-col-order-17 {
	order: 17;
}

.light-col-16 {
	display: block;
	flex: 0 0 66.66666667%;
	max-width: 66.66666667%;
}

.light-col-push-16 {
	left: 66.66666667%;
}

.light-col-pull-16 {
	right: 66.66666667%;
}

.light-col-offset-16 {
	margin-left: 66.66666667%;
}

.light-col-order-16 {
	order: 16;
}

.light-col-15 {
	display: block;
	flex: 0 0 62.5%;
	max-width: 62.5%;
}

.light-col-push-15 {
	left: 62.5%;
}

.light-col-pull-15 {
	right: 62.5%;
}

.light-col-offset-15 {
	margin-left: 62.5%;
}

.light-col-order-15 {
	order: 15;
}

.light-col-14 {
	display: block;
	flex: 0 0 58.33333333%;
	max-width: 58.33333333%;
}

.light-col-push-14 {
	left: 58.33333333%;
}

.light-col-pull-14 {
	right: 58.33333333%;
}

.light-col-offset-14 {
	margin-left: 58.33333333%;
}

.light-col-order-14 {
	order: 14;
}

.light-col-13 {
	display: block;
	flex: 0 0 54.16666667%;
	max-width: 54.16666667%;
}

.light-col-push-13 {
	left: 54.16666667%;
}

.light-col-pull-13 {
	right: 54.16666667%;
}

.light-col-offset-13 {
	margin-left: 54.16666667%;
}

.light-col-order-13 {
	order: 13;
}

.light-col-12 {
	display: block;
	flex: 0 0 50%;
	max-width: 50%;
}

.light-col-push-12 {
	left: 50%;
}

.light-col-pull-12 {
	right: 50%;
}

.light-col-offset-12 {
	margin-left: 50%;
}

.light-col-order-12 {
	order: 12;
}

.light-col-11 {
	display: block;
	flex: 0 0 45.83333333%;
	max-width: 45.83333333%;
}

.light-col-push-11 {
	left: 45.83333333%;
}

.light-col-pull-11 {
	right: 45.83333333%;
}

.light-col-offset-11 {
	margin-left: 45.83333333%;
}

.light-col-order-11 {
	order: 11;
}

.light-col-10 {
	display: block;
	flex: 0 0 41.66666667%;
	max-width: 41.66666667%;
}

.light-col-push-10 {
	left: 41.66666667%;
}

.light-col-pull-10 {
	right: 41.66666667%;
}

.light-col-offset-10 {
	margin-left: 41.66666667%;
}

.light-col-order-10 {
	order: 10;
}

.light-col-9 {
	display: block;
	flex: 0 0 37.5%;
	max-width: 37.5%;
}

.light-col-push-9 {
	left: 37.5%;
}

.light-col-pull-9 {
	right: 37.5%;
}

.light-col-offset-9 {
	margin-left: 37.5%;
}

.light-col-order-9 {
	order: 9;
}

.light-col-8 {
	display: block;
	flex: 0 0 33.33333333%;
	max-width: 33.33333333%;
}

.light-col-push-8 {
	left: 33.33333333%;
}

.light-col-pull-8 {
	right: 33.33333333%;
}

.light-col-offset-8 {
	margin-left: 33.33333333%;
}

.light-col-order-8 {
	order: 8;
}

.light-col-7 {
	display: block;
	flex: 0 0 29.16666667%;
	max-width: 29.16666667%;
}

.light-col-push-7 {
	left: 29.16666667%;
}

.light-col-pull-7 {
	right: 29.16666667%;
}

.light-col-offset-7 {
	margin-left: 29.16666667%;
}

.light-col-order-7 {
	order: 7;
}

.light-col-6 {
	display: block;
	flex: 0 0 25%;
	max-width: 25%;
}

.light-col-push-6 {
	left: 25%;
}

.light-col-pull-6 {
	right: 25%;
}

.light-col-offset-6 {
	margin-left: 25%;
}

.light-col-order-6 {
	order: 6;
}

.light-col-5 {
	display: block;
	flex: 0 0 20.83333333%;
	max-width: 20.83333333%;
}

.light-col-push-5 {
	left: 20.83333333%;
}

.light-col-pull-5 {
	right: 20.83333333%;
}

.light-col-offset-5 {
	margin-left: 20.83333333%;
}

.light-col-order-5 {
	order: 5;
}

.light-col-4 {
	display: block;
	flex: 0 0 16.66666667%;
	max-width: 16.66666667%;
}

.light-col-push-4 {
	left: 16.66666667%;
}

.light-col-pull-4 {
	right: 16.66666667%;
}

.light-col-offset-4 {
	margin-left: 16.66666667%;
}

.light-col-order-4 {
	order: 4;
}

.light-col-3 {
	display: block;
	flex: 0 0 12.5%;
	max-width: 12.5%;
}

.light-col-push-3 {
	left: 12.5%;
}

.light-col-pull-3 {
	right: 12.5%;
}

.light-col-offset-3 {
	margin-left: 12.5%;
}

.light-col-order-3 {
	order: 3;
}

.light-col-2 {
	display: block;
	flex: 0 0 8.33333333%;
	max-width: 8.33333333%;
}

.light-col-push-2 {
	left: 8.33333333%;
}

.light-col-pull-2 {
	right: 8.33333333%;
}

.light-col-offset-2 {
	margin-left: 8.33333333%;
}

.light-col-order-2 {
	order: 2;
}

.light-col-1 {
	display: block;
	flex: 0 0 4.16666667%;
	max-width: 4.16666667%;
}

.light-col-push-1 {
	left: 4.16666667%;
}

.light-col-pull-1 {
	right: 4.16666667%;
}

.light-col-offset-1 {
	margin-left: 4.16666667%;
}

.light-col-order-1 {
	order: 1;
}

.light-col-0 {
	display: none;
}

.light-col-push-0 {
	left: auto;
}

.light-col-pull-0 {
	right: auto;
}

.light-col-push-0 {
	left: auto;
}

.light-col-pull-0 {
	right: auto;
}

.light-col-offset-0 {
	margin-left: 0;
}

.light-col-order-0 {
	order: 0;
}

.light-col-push-0.light-col-rtl {
	right: auto;
}

.light-col-pull-0.light-col-rtl {
	left: auto;
}

.light-col-push-0.light-col-rtl {
	right: auto;
}

.light-col-pull-0.light-col-rtl {
	left: auto;
}

.light-col-offset-0.light-col-rtl {
	margin-right: 0;
}

.light-col-push-1.light-col-rtl {
	right: 4.16666667%;
	left: auto;
}

.light-col-pull-1.light-col-rtl {
	right: auto;
	left: 4.16666667%;
}

.light-col-offset-1.light-col-rtl {
	margin-right: 4.16666667%;
	margin-left: 0;
}

.light-col-push-2.light-col-rtl {
	right: 8.33333333%;
	left: auto;
}

.light-col-pull-2.light-col-rtl {
	right: auto;
	left: 8.33333333%;
}

.light-col-offset-2.light-col-rtl {
	margin-right: 8.33333333%;
	margin-left: 0;
}

.light-col-push-3.light-col-rtl {
	right: 12.5%;
	left: auto;
}

.light-col-pull-3.light-col-rtl {
	right: auto;
	left: 12.5%;
}

.light-col-offset-3.light-col-rtl {
	margin-right: 12.5%;
	margin-left: 0;
}

.light-col-push-4.light-col-rtl {
	right: 16.66666667%;
	left: auto;
}

.light-col-pull-4.light-col-rtl {
	right: auto;
	left: 16.66666667%;
}

.light-col-offset-4.light-col-rtl {
	margin-right: 16.66666667%;
	margin-left: 0;
}

.light-col-push-5.light-col-rtl {
	right: 20.83333333%;
	left: auto;
}

.light-col-pull-5.light-col-rtl {
	right: auto;
	left: 20.83333333%;
}

.light-col-offset-5.light-col-rtl {
	margin-right: 20.83333333%;
	margin-left: 0;
}

.light-col-push-6.light-col-rtl {
	right: 25%;
	left: auto;
}

.light-col-pull-6.light-col-rtl {
	right: auto;
	left: 25%;
}

.light-col-offset-6.light-col-rtl {
	margin-right: 25%;
	margin-left: 0;
}

.light-col-push-7.light-col-rtl {
	right: 29.16666667%;
	left: auto;
}

.light-col-pull-7.light-col-rtl {
	right: auto;
	left: 29.16666667%;
}

.light-col-offset-7.light-col-rtl {
	margin-right: 29.16666667%;
	margin-left: 0;
}

.light-col-push-8.light-col-rtl {
	right: 33.33333333%;
	left: auto;
}

.light-col-pull-8.light-col-rtl {
	right: auto;
	left: 33.33333333%;
}

.light-col-offset-8.light-col-rtl {
	margin-right: 33.33333333%;
	margin-left: 0;
}

.light-col-push-9.light-col-rtl {
	right: 37.5%;
	left: auto;
}

.light-col-pull-9.light-col-rtl {
	right: auto;
	left: 37.5%;
}

.light-col-offset-9.light-col-rtl {
	margin-right: 37.5%;
	margin-left: 0;
}

.light-col-push-10.light-col-rtl {
	right: 41.66666667%;
	left: auto;
}

.light-col-pull-10.light-col-rtl {
	right: auto;
	left: 41.66666667%;
}

.light-col-offset-10.light-col-rtl {
	margin-right: 41.66666667%;
	margin-left: 0;
}

.light-col-push-11.light-col-rtl {
	right: 45.83333333%;
	left: auto;
}

.light-col-pull-11.light-col-rtl {
	right: auto;
	left: 45.83333333%;
}

.light-col-offset-11.light-col-rtl {
	margin-right: 45.83333333%;
	margin-left: 0;
}

.light-col-push-12.light-col-rtl {
	right: 50%;
	left: auto;
}

.light-col-pull-12.light-col-rtl {
	right: auto;
	left: 50%;
}

.light-col-offset-12.light-col-rtl {
	margin-right: 50%;
	margin-left: 0;
}

.light-col-push-13.light-col-rtl {
	right: 54.16666667%;
	left: auto;
}

.light-col-pull-13.light-col-rtl {
	right: auto;
	left: 54.16666667%;
}

.light-col-offset-13.light-col-rtl {
	margin-right: 54.16666667%;
	margin-left: 0;
}

.light-col-push-14.light-col-rtl {
	right: 58.33333333%;
	left: auto;
}

.light-col-pull-14.light-col-rtl {
	right: auto;
	left: 58.33333333%;
}

.light-col-offset-14.light-col-rtl {
	margin-right: 58.33333333%;
	margin-left: 0;
}

.light-col-push-15.light-col-rtl {
	right: 62.5%;
	left: auto;
}

.light-col-pull-15.light-col-rtl {
	right: auto;
	left: 62.5%;
}

.light-col-offset-15.light-col-rtl {
	margin-right: 62.5%;
	margin-left: 0;
}

.light-col-push-16.light-col-rtl {
	right: 66.66666667%;
	left: auto;
}

.light-col-pull-16.light-col-rtl {
	right: auto;
	left: 66.66666667%;
}

.light-col-offset-16.light-col-rtl {
	margin-right: 66.66666667%;
	margin-left: 0;
}

.light-col-push-17.light-col-rtl {
	right: 70.83333333%;
	left: auto;
}

.light-col-pull-17.light-col-rtl {
	right: auto;
	left: 70.83333333%;
}

.light-col-offset-17.light-col-rtl {
	margin-right: 70.83333333%;
	margin-left: 0;
}

.light-col-push-18.light-col-rtl {
	right: 75%;
	left: auto;
}

.light-col-pull-18.light-col-rtl {
	right: auto;
	left: 75%;
}

.light-col-offset-18.light-col-rtl {
	margin-right: 75%;
	margin-left: 0;
}

.light-col-push-19.light-col-rtl {
	right: 79.16666667%;
	left: auto;
}

.light-col-pull-19.light-col-rtl {
	right: auto;
	left: 79.16666667%;
}

.light-col-offset-19.light-col-rtl {
	margin-right: 79.16666667%;
	margin-left: 0;
}

.light-col-push-20.light-col-rtl {
	right: 83.33333333%;
	left: auto;
}

.light-col-pull-20.light-col-rtl {
	right: auto;
	left: 83.33333333%;
}

.light-col-offset-20.light-col-rtl {
	margin-right: 83.33333333%;
	margin-left: 0;
}

.light-col-push-21.light-col-rtl {
	right: 87.5%;
	left: auto;
}

.light-col-pull-21.light-col-rtl {
	right: auto;
	left: 87.5%;
}

.light-col-offset-21.light-col-rtl {
	margin-right: 87.5%;
	margin-left: 0;
}

.light-col-push-22.light-col-rtl {
	right: 91.66666667%;
	left: auto;
}

.light-col-pull-22.light-col-rtl {
	right: auto;
	left: 91.66666667%;
}

.light-col-offset-22.light-col-rtl {
	margin-right: 91.66666667%;
	margin-left: 0;
}

.light-col-push-23.light-col-rtl {
	right: 95.83333333%;
	left: auto;
}

.light-col-pull-23.light-col-rtl {
	right: auto;
	left: 95.83333333%;
}

.light-col-offset-23.light-col-rtl {
	margin-right: 95.83333333%;
	margin-left: 0;
}

.light-col-push-24.light-col-rtl {
	right: 100%;
	left: auto;
}

.light-col-pull-24.light-col-rtl {
	right: auto;
	left: 100%;
}

.light-col-offset-24.light-col-rtl {
	margin-right: 100%;
	margin-left: 0;
}

.light-col-xs-24 {
	display: block;
	flex: 0 0 100%;
	max-width: 100%;
}

.light-col-xs-push-24 {
	left: 100%;
}

.light-col-xs-pull-24 {
	right: 100%;
}

.light-col-xs-offset-24 {
	margin-left: 100%;
}

.light-col-xs-order-24 {
	order: 24;
}

.light-col-xs-23 {
	display: block;
	flex: 0 0 95.83333333%;
	max-width: 95.83333333%;
}

.light-col-xs-push-23 {
	left: 95.83333333%;
}

.light-col-xs-pull-23 {
	right: 95.83333333%;
}

.light-col-xs-offset-23 {
	margin-left: 95.83333333%;
}

.light-col-xs-order-23 {
	order: 23;
}

.light-col-xs-22 {
	display: block;
	flex: 0 0 91.66666667%;
	max-width: 91.66666667%;
}

.light-col-xs-push-22 {
	left: 91.66666667%;
}

.light-col-xs-pull-22 {
	right: 91.66666667%;
}

.light-col-xs-offset-22 {
	margin-left: 91.66666667%;
}

.light-col-xs-order-22 {
	order: 22;
}

.light-col-xs-21 {
	display: block;
	flex: 0 0 87.5%;
	max-width: 87.5%;
}

.light-col-xs-push-21 {
	left: 87.5%;
}

.light-col-xs-pull-21 {
	right: 87.5%;
}

.light-col-xs-offset-21 {
	margin-left: 87.5%;
}

.light-col-xs-order-21 {
	order: 21;
}

.light-col-xs-20 {
	display: block;
	flex: 0 0 83.33333333%;
	max-width: 83.33333333%;
}

.light-col-xs-push-20 {
	left: 83.33333333%;
}

.light-col-xs-pull-20 {
	right: 83.33333333%;
}

.light-col-xs-offset-20 {
	margin-left: 83.33333333%;
}

.light-col-xs-order-20 {
	order: 20;
}

.light-col-xs-19 {
	display: block;
	flex: 0 0 79.16666667%;
	max-width: 79.16666667%;
}

.light-col-xs-push-19 {
	left: 79.16666667%;
}

.light-col-xs-pull-19 {
	right: 79.16666667%;
}

.light-col-xs-offset-19 {
	margin-left: 79.16666667%;
}

.light-col-xs-order-19 {
	order: 19;
}

.light-col-xs-18 {
	display: block;
	flex: 0 0 75%;
	max-width: 75%;
}

.light-col-xs-push-18 {
	left: 75%;
}

.light-col-xs-pull-18 {
	right: 75%;
}

.light-col-xs-offset-18 {
	margin-left: 75%;
}

.light-col-xs-order-18 {
	order: 18;
}

.light-col-xs-17 {
	display: block;
	flex: 0 0 70.83333333%;
	max-width: 70.83333333%;
}

.light-col-xs-push-17 {
	left: 70.83333333%;
}

.light-col-xs-pull-17 {
	right: 70.83333333%;
}

.light-col-xs-offset-17 {
	margin-left: 70.83333333%;
}

.light-col-xs-order-17 {
	order: 17;
}

.light-col-xs-16 {
	display: block;
	flex: 0 0 66.66666667%;
	max-width: 66.66666667%;
}

.light-col-xs-push-16 {
	left: 66.66666667%;
}

.light-col-xs-pull-16 {
	right: 66.66666667%;
}

.light-col-xs-offset-16 {
	margin-left: 66.66666667%;
}

.light-col-xs-order-16 {
	order: 16;
}

.light-col-xs-15 {
	display: block;
	flex: 0 0 62.5%;
	max-width: 62.5%;
}

.light-col-xs-push-15 {
	left: 62.5%;
}

.light-col-xs-pull-15 {
	right: 62.5%;
}

.light-col-xs-offset-15 {
	margin-left: 62.5%;
}

.light-col-xs-order-15 {
	order: 15;
}

.light-col-xs-14 {
	display: block;
	flex: 0 0 58.33333333%;
	max-width: 58.33333333%;
}

.light-col-xs-push-14 {
	left: 58.33333333%;
}

.light-col-xs-pull-14 {
	right: 58.33333333%;
}

.light-col-xs-offset-14 {
	margin-left: 58.33333333%;
}

.light-col-xs-order-14 {
	order: 14;
}

.light-col-xs-13 {
	display: block;
	flex: 0 0 54.16666667%;
	max-width: 54.16666667%;
}

.light-col-xs-push-13 {
	left: 54.16666667%;
}

.light-col-xs-pull-13 {
	right: 54.16666667%;
}

.light-col-xs-offset-13 {
	margin-left: 54.16666667%;
}

.light-col-xs-order-13 {
	order: 13;
}

.light-col-xs-12 {
	display: block;
	flex: 0 0 50%;
	max-width: 50%;
}

.light-col-xs-push-12 {
	left: 50%;
}

.light-col-xs-pull-12 {
	right: 50%;
}

.light-col-xs-offset-12 {
	margin-left: 50%;
}

.light-col-xs-order-12 {
	order: 12;
}

.light-col-xs-11 {
	display: block;
	flex: 0 0 45.83333333%;
	max-width: 45.83333333%;
}

.light-col-xs-push-11 {
	left: 45.83333333%;
}

.light-col-xs-pull-11 {
	right: 45.83333333%;
}

.light-col-xs-offset-11 {
	margin-left: 45.83333333%;
}

.light-col-xs-order-11 {
	order: 11;
}

.light-col-xs-10 {
	display: block;
	flex: 0 0 41.66666667%;
	max-width: 41.66666667%;
}

.light-col-xs-push-10 {
	left: 41.66666667%;
}

.light-col-xs-pull-10 {
	right: 41.66666667%;
}

.light-col-xs-offset-10 {
	margin-left: 41.66666667%;
}

.light-col-xs-order-10 {
	order: 10;
}

.light-col-xs-9 {
	display: block;
	flex: 0 0 37.5%;
	max-width: 37.5%;
}

.light-col-xs-push-9 {
	left: 37.5%;
}

.light-col-xs-pull-9 {
	right: 37.5%;
}

.light-col-xs-offset-9 {
	margin-left: 37.5%;
}

.light-col-xs-order-9 {
	order: 9;
}

.light-col-xs-8 {
	display: block;
	flex: 0 0 33.33333333%;
	max-width: 33.33333333%;
}

.light-col-xs-push-8 {
	left: 33.33333333%;
}

.light-col-xs-pull-8 {
	right: 33.33333333%;
}

.light-col-xs-offset-8 {
	margin-left: 33.33333333%;
}

.light-col-xs-order-8 {
	order: 8;
}

.light-col-xs-7 {
	display: block;
	flex: 0 0 29.16666667%;
	max-width: 29.16666667%;
}

.light-col-xs-push-7 {
	left: 29.16666667%;
}

.light-col-xs-pull-7 {
	right: 29.16666667%;
}

.light-col-xs-offset-7 {
	margin-left: 29.16666667%;
}

.light-col-xs-order-7 {
	order: 7;
}

.light-col-xs-6 {
	display: block;
	flex: 0 0 25%;
	max-width: 25%;
}

.light-col-xs-push-6 {
	left: 25%;
}

.light-col-xs-pull-6 {
	right: 25%;
}

.light-col-xs-offset-6 {
	margin-left: 25%;
}

.light-col-xs-order-6 {
	order: 6;
}

.light-col-xs-5 {
	display: block;
	flex: 0 0 20.83333333%;
	max-width: 20.83333333%;
}

.light-col-xs-push-5 {
	left: 20.83333333%;
}

.light-col-xs-pull-5 {
	right: 20.83333333%;
}

.light-col-xs-offset-5 {
	margin-left: 20.83333333%;
}

.light-col-xs-order-5 {
	order: 5;
}

.light-col-xs-4 {
	display: block;
	flex: 0 0 16.66666667%;
	max-width: 16.66666667%;
}

.light-col-xs-push-4 {
	left: 16.66666667%;
}

.light-col-xs-pull-4 {
	right: 16.66666667%;
}

.light-col-xs-offset-4 {
	margin-left: 16.66666667%;
}

.light-col-xs-order-4 {
	order: 4;
}

.light-col-xs-3 {
	display: block;
	flex: 0 0 12.5%;
	max-width: 12.5%;
}

.light-col-xs-push-3 {
	left: 12.5%;
}

.light-col-xs-pull-3 {
	right: 12.5%;
}

.light-col-xs-offset-3 {
	margin-left: 12.5%;
}

.light-col-xs-order-3 {
	order: 3;
}

.light-col-xs-2 {
	display: block;
	flex: 0 0 8.33333333%;
	max-width: 8.33333333%;
}

.light-col-xs-push-2 {
	left: 8.33333333%;
}

.light-col-xs-pull-2 {
	right: 8.33333333%;
}

.light-col-xs-offset-2 {
	margin-left: 8.33333333%;
}

.light-col-xs-order-2 {
	order: 2;
}

.light-col-xs-1 {
	display: block;
	flex: 0 0 4.16666667%;
	max-width: 4.16666667%;
}

.light-col-xs-push-1 {
	left: 4.16666667%;
}

.light-col-xs-pull-1 {
	right: 4.16666667%;
}

.light-col-xs-offset-1 {
	margin-left: 4.16666667%;
}

.light-col-xs-order-1 {
	order: 1;
}

.light-col-xs-0 {
	display: none;
}

.light-col-push-0 {
	left: auto;
}

.light-col-pull-0 {
	right: auto;
}

.light-col-xs-push-0 {
	left: auto;
}

.light-col-xs-pull-0 {
	right: auto;
}

.light-col-xs-offset-0 {
	margin-left: 0;
}

.light-col-xs-order-0 {
	order: 0;
}

.light-col-push-0.light-col-rtl {
	right: auto;
}

.light-col-pull-0.light-col-rtl {
	left: auto;
}

.light-col-xs-push-0.light-col-rtl {
	right: auto;
}

.light-col-xs-pull-0.light-col-rtl {
	left: auto;
}

.light-col-xs-offset-0.light-col-rtl {
	margin-right: 0;
}

.light-col-xs-push-1.light-col-rtl {
	right: 4.16666667%;
	left: auto;
}

.light-col-xs-pull-1.light-col-rtl {
	right: auto;
	left: 4.16666667%;
}

.light-col-xs-offset-1.light-col-rtl {
	margin-right: 4.16666667%;
	margin-left: 0;
}

.light-col-xs-push-2.light-col-rtl {
	right: 8.33333333%;
	left: auto;
}

.light-col-xs-pull-2.light-col-rtl {
	right: auto;
	left: 8.33333333%;
}

.light-col-xs-offset-2.light-col-rtl {
	margin-right: 8.33333333%;
	margin-left: 0;
}

.light-col-xs-push-3.light-col-rtl {
	right: 12.5%;
	left: auto;
}

.light-col-xs-pull-3.light-col-rtl {
	right: auto;
	left: 12.5%;
}

.light-col-xs-offset-3.light-col-rtl {
	margin-right: 12.5%;
	margin-left: 0;
}

.light-col-xs-push-4.light-col-rtl {
	right: 16.66666667%;
	left: auto;
}

.light-col-xs-pull-4.light-col-rtl {
	right: auto;
	left: 16.66666667%;
}

.light-col-xs-offset-4.light-col-rtl {
	margin-right: 16.66666667%;
	margin-left: 0;
}

.light-col-xs-push-5.light-col-rtl {
	right: 20.83333333%;
	left: auto;
}

.light-col-xs-pull-5.light-col-rtl {
	right: auto;
	left: 20.83333333%;
}

.light-col-xs-offset-5.light-col-rtl {
	margin-right: 20.83333333%;
	margin-left: 0;
}

.light-col-xs-push-6.light-col-rtl {
	right: 25%;
	left: auto;
}

.light-col-xs-pull-6.light-col-rtl {
	right: auto;
	left: 25%;
}

.light-col-xs-offset-6.light-col-rtl {
	margin-right: 25%;
	margin-left: 0;
}

.light-col-xs-push-7.light-col-rtl {
	right: 29.16666667%;
	left: auto;
}

.light-col-xs-pull-7.light-col-rtl {
	right: auto;
	left: 29.16666667%;
}

.light-col-xs-offset-7.light-col-rtl {
	margin-right: 29.16666667%;
	margin-left: 0;
}

.light-col-xs-push-8.light-col-rtl {
	right: 33.33333333%;
	left: auto;
}

.light-col-xs-pull-8.light-col-rtl {
	right: auto;
	left: 33.33333333%;
}

.light-col-xs-offset-8.light-col-rtl {
	margin-right: 33.33333333%;
	margin-left: 0;
}

.light-col-xs-push-9.light-col-rtl {
	right: 37.5%;
	left: auto;
}

.light-col-xs-pull-9.light-col-rtl {
	right: auto;
	left: 37.5%;
}

.light-col-xs-offset-9.light-col-rtl {
	margin-right: 37.5%;
	margin-left: 0;
}

.light-col-xs-push-10.light-col-rtl {
	right: 41.66666667%;
	left: auto;
}

.light-col-xs-pull-10.light-col-rtl {
	right: auto;
	left: 41.66666667%;
}

.light-col-xs-offset-10.light-col-rtl {
	margin-right: 41.66666667%;
	margin-left: 0;
}

.light-col-xs-push-11.light-col-rtl {
	right: 45.83333333%;
	left: auto;
}

.light-col-xs-pull-11.light-col-rtl {
	right: auto;
	left: 45.83333333%;
}

.light-col-xs-offset-11.light-col-rtl {
	margin-right: 45.83333333%;
	margin-left: 0;
}

.light-col-xs-push-12.light-col-rtl {
	right: 50%;
	left: auto;
}

.light-col-xs-pull-12.light-col-rtl {
	right: auto;
	left: 50%;
}

.light-col-xs-offset-12.light-col-rtl {
	margin-right: 50%;
	margin-left: 0;
}

.light-col-xs-push-13.light-col-rtl {
	right: 54.16666667%;
	left: auto;
}

.light-col-xs-pull-13.light-col-rtl {
	right: auto;
	left: 54.16666667%;
}

.light-col-xs-offset-13.light-col-rtl {
	margin-right: 54.16666667%;
	margin-left: 0;
}

.light-col-xs-push-14.light-col-rtl {
	right: 58.33333333%;
	left: auto;
}

.light-col-xs-pull-14.light-col-rtl {
	right: auto;
	left: 58.33333333%;
}

.light-col-xs-offset-14.light-col-rtl {
	margin-right: 58.33333333%;
	margin-left: 0;
}

.light-col-xs-push-15.light-col-rtl {
	right: 62.5%;
	left: auto;
}

.light-col-xs-pull-15.light-col-rtl {
	right: auto;
	left: 62.5%;
}

.light-col-xs-offset-15.light-col-rtl {
	margin-right: 62.5%;
	margin-left: 0;
}

.light-col-xs-push-16.light-col-rtl {
	right: 66.66666667%;
	left: auto;
}

.light-col-xs-pull-16.light-col-rtl {
	right: auto;
	left: 66.66666667%;
}

.light-col-xs-offset-16.light-col-rtl {
	margin-right: 66.66666667%;
	margin-left: 0;
}

.light-col-xs-push-17.light-col-rtl {
	right: 70.83333333%;
	left: auto;
}

.light-col-xs-pull-17.light-col-rtl {
	right: auto;
	left: 70.83333333%;
}

.light-col-xs-offset-17.light-col-rtl {
	margin-right: 70.83333333%;
	margin-left: 0;
}

.light-col-xs-push-18.light-col-rtl {
	right: 75%;
	left: auto;
}

.light-col-xs-pull-18.light-col-rtl {
	right: auto;
	left: 75%;
}

.light-col-xs-offset-18.light-col-rtl {
	margin-right: 75%;
	margin-left: 0;
}

.light-col-xs-push-19.light-col-rtl {
	right: 79.16666667%;
	left: auto;
}

.light-col-xs-pull-19.light-col-rtl {
	right: auto;
	left: 79.16666667%;
}

.light-col-xs-offset-19.light-col-rtl {
	margin-right: 79.16666667%;
	margin-left: 0;
}

.light-col-xs-push-20.light-col-rtl {
	right: 83.33333333%;
	left: auto;
}

.light-col-xs-pull-20.light-col-rtl {
	right: auto;
	left: 83.33333333%;
}

.light-col-xs-offset-20.light-col-rtl {
	margin-right: 83.33333333%;
	margin-left: 0;
}

.light-col-xs-push-21.light-col-rtl {
	right: 87.5%;
	left: auto;
}

.light-col-xs-pull-21.light-col-rtl {
	right: auto;
	left: 87.5%;
}

.light-col-xs-offset-21.light-col-rtl {
	margin-right: 87.5%;
	margin-left: 0;
}

.light-col-xs-push-22.light-col-rtl {
	right: 91.66666667%;
	left: auto;
}

.light-col-xs-pull-22.light-col-rtl {
	right: auto;
	left: 91.66666667%;
}

.light-col-xs-offset-22.light-col-rtl {
	margin-right: 91.66666667%;
	margin-left: 0;
}

.light-col-xs-push-23.light-col-rtl {
	right: 95.83333333%;
	left: auto;
}

.light-col-xs-pull-23.light-col-rtl {
	right: auto;
	left: 95.83333333%;
}

.light-col-xs-offset-23.light-col-rtl {
	margin-right: 95.83333333%;
	margin-left: 0;
}

.light-col-xs-push-24.light-col-rtl {
	right: 100%;
	left: auto;
}

.light-col-xs-pull-24.light-col-rtl {
	right: auto;
	left: 100%;
}

.light-col-xs-offset-24.light-col-rtl {
	margin-right: 100%;
	margin-left: 0;
}

@media (min-width: 576px) {
	.light-col-sm-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.light-col-sm-push-24 {
		left: 100%;
	}

	.light-col-sm-pull-24 {
		right: 100%;
	}

	.light-col-sm-offset-24 {
		margin-left: 100%;
	}

	.light-col-sm-order-24 {
		order: 24;
	}

	.light-col-sm-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.light-col-sm-push-23 {
		left: 95.83333333%;
	}

	.light-col-sm-pull-23 {
		right: 95.83333333%;
	}

	.light-col-sm-offset-23 {
		margin-left: 95.83333333%;
	}

	.light-col-sm-order-23 {
		order: 23;
	}

	.light-col-sm-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.light-col-sm-push-22 {
		left: 91.66666667%;
	}

	.light-col-sm-pull-22 {
		right: 91.66666667%;
	}

	.light-col-sm-offset-22 {
		margin-left: 91.66666667%;
	}

	.light-col-sm-order-22 {
		order: 22;
	}

	.light-col-sm-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.light-col-sm-push-21 {
		left: 87.5%;
	}

	.light-col-sm-pull-21 {
		right: 87.5%;
	}

	.light-col-sm-offset-21 {
		margin-left: 87.5%;
	}

	.light-col-sm-order-21 {
		order: 21;
	}

	.light-col-sm-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.light-col-sm-push-20 {
		left: 83.33333333%;
	}

	.light-col-sm-pull-20 {
		right: 83.33333333%;
	}

	.light-col-sm-offset-20 {
		margin-left: 83.33333333%;
	}

	.light-col-sm-order-20 {
		order: 20;
	}

	.light-col-sm-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.light-col-sm-push-19 {
		left: 79.16666667%;
	}

	.light-col-sm-pull-19 {
		right: 79.16666667%;
	}

	.light-col-sm-offset-19 {
		margin-left: 79.16666667%;
	}

	.light-col-sm-order-19 {
		order: 19;
	}

	.light-col-sm-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.light-col-sm-push-18 {
		left: 75%;
	}

	.light-col-sm-pull-18 {
		right: 75%;
	}

	.light-col-sm-offset-18 {
		margin-left: 75%;
	}

	.light-col-sm-order-18 {
		order: 18;
	}

	.light-col-sm-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.light-col-sm-push-17 {
		left: 70.83333333%;
	}

	.light-col-sm-pull-17 {
		right: 70.83333333%;
	}

	.light-col-sm-offset-17 {
		margin-left: 70.83333333%;
	}

	.light-col-sm-order-17 {
		order: 17;
	}

	.light-col-sm-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.light-col-sm-push-16 {
		left: 66.66666667%;
	}

	.light-col-sm-pull-16 {
		right: 66.66666667%;
	}

	.light-col-sm-offset-16 {
		margin-left: 66.66666667%;
	}

	.light-col-sm-order-16 {
		order: 16;
	}

	.light-col-sm-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.light-col-sm-push-15 {
		left: 62.5%;
	}

	.light-col-sm-pull-15 {
		right: 62.5%;
	}

	.light-col-sm-offset-15 {
		margin-left: 62.5%;
	}

	.light-col-sm-order-15 {
		order: 15;
	}

	.light-col-sm-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.light-col-sm-push-14 {
		left: 58.33333333%;
	}

	.light-col-sm-pull-14 {
		right: 58.33333333%;
	}

	.light-col-sm-offset-14 {
		margin-left: 58.33333333%;
	}

	.light-col-sm-order-14 {
		order: 14;
	}

	.light-col-sm-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.light-col-sm-push-13 {
		left: 54.16666667%;
	}

	.light-col-sm-pull-13 {
		right: 54.16666667%;
	}

	.light-col-sm-offset-13 {
		margin-left: 54.16666667%;
	}

	.light-col-sm-order-13 {
		order: 13;
	}

	.light-col-sm-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.light-col-sm-push-12 {
		left: 50%;
	}

	.light-col-sm-pull-12 {
		right: 50%;
	}

	.light-col-sm-offset-12 {
		margin-left: 50%;
	}

	.light-col-sm-order-12 {
		order: 12;
	}

	.light-col-sm-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.light-col-sm-push-11 {
		left: 45.83333333%;
	}

	.light-col-sm-pull-11 {
		right: 45.83333333%;
	}

	.light-col-sm-offset-11 {
		margin-left: 45.83333333%;
	}

	.light-col-sm-order-11 {
		order: 11;
	}

	.light-col-sm-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.light-col-sm-push-10 {
		left: 41.66666667%;
	}

	.light-col-sm-pull-10 {
		right: 41.66666667%;
	}

	.light-col-sm-offset-10 {
		margin-left: 41.66666667%;
	}

	.light-col-sm-order-10 {
		order: 10;
	}

	.light-col-sm-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.light-col-sm-push-9 {
		left: 37.5%;
	}

	.light-col-sm-pull-9 {
		right: 37.5%;
	}

	.light-col-sm-offset-9 {
		margin-left: 37.5%;
	}

	.light-col-sm-order-9 {
		order: 9;
	}

	.light-col-sm-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.light-col-sm-push-8 {
		left: 33.33333333%;
	}

	.light-col-sm-pull-8 {
		right: 33.33333333%;
	}

	.light-col-sm-offset-8 {
		margin-left: 33.33333333%;
	}

	.light-col-sm-order-8 {
		order: 8;
	}

	.light-col-sm-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.light-col-sm-push-7 {
		left: 29.16666667%;
	}

	.light-col-sm-pull-7 {
		right: 29.16666667%;
	}

	.light-col-sm-offset-7 {
		margin-left: 29.16666667%;
	}

	.light-col-sm-order-7 {
		order: 7;
	}

	.light-col-sm-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.light-col-sm-push-6 {
		left: 25%;
	}

	.light-col-sm-pull-6 {
		right: 25%;
	}

	.light-col-sm-offset-6 {
		margin-left: 25%;
	}

	.light-col-sm-order-6 {
		order: 6;
	}

	.light-col-sm-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.light-col-sm-push-5 {
		left: 20.83333333%;
	}

	.light-col-sm-pull-5 {
		right: 20.83333333%;
	}

	.light-col-sm-offset-5 {
		margin-left: 20.83333333%;
	}

	.light-col-sm-order-5 {
		order: 5;
	}

	.light-col-sm-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.light-col-sm-push-4 {
		left: 16.66666667%;
	}

	.light-col-sm-pull-4 {
		right: 16.66666667%;
	}

	.light-col-sm-offset-4 {
		margin-left: 16.66666667%;
	}

	.light-col-sm-order-4 {
		order: 4;
	}

	.light-col-sm-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.light-col-sm-push-3 {
		left: 12.5%;
	}

	.light-col-sm-pull-3 {
		right: 12.5%;
	}

	.light-col-sm-offset-3 {
		margin-left: 12.5%;
	}

	.light-col-sm-order-3 {
		order: 3;
	}

	.light-col-sm-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.light-col-sm-push-2 {
		left: 8.33333333%;
	}

	.light-col-sm-pull-2 {
		right: 8.33333333%;
	}

	.light-col-sm-offset-2 {
		margin-left: 8.33333333%;
	}

	.light-col-sm-order-2 {
		order: 2;
	}

	.light-col-sm-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.light-col-sm-push-1 {
		left: 4.16666667%;
	}

	.light-col-sm-pull-1 {
		right: 4.16666667%;
	}

	.light-col-sm-offset-1 {
		margin-left: 4.16666667%;
	}

	.light-col-sm-order-1 {
		order: 1;
	}

	.light-col-sm-0 {
		display: none;
	}

	.light-col-push-0 {
		left: auto;
	}

	.light-col-pull-0 {
		right: auto;
	}

	.light-col-sm-push-0 {
		left: auto;
	}

	.light-col-sm-pull-0 {
		right: auto;
	}

	.light-col-sm-offset-0 {
		margin-left: 0;
	}

	.light-col-sm-order-0 {
		order: 0;
	}

	.light-col-push-0.light-col-rtl {
		right: auto;
	}

	.light-col-pull-0.light-col-rtl {
		left: auto;
	}

	.light-col-sm-push-0.light-col-rtl {
		right: auto;
	}

	.light-col-sm-pull-0.light-col-rtl {
		left: auto;
	}

	.light-col-sm-offset-0.light-col-rtl {
		margin-right: 0;
	}

	.light-col-sm-push-1.light-col-rtl {
		right: 4.16666667%;
		left: auto;
	}

	.light-col-sm-pull-1.light-col-rtl {
		right: auto;
		left: 4.16666667%;
	}

	.light-col-sm-offset-1.light-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}

	.light-col-sm-push-2.light-col-rtl {
		right: 8.33333333%;
		left: auto;
	}

	.light-col-sm-pull-2.light-col-rtl {
		right: auto;
		left: 8.33333333%;
	}

	.light-col-sm-offset-2.light-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}

	.light-col-sm-push-3.light-col-rtl {
		right: 12.5%;
		left: auto;
	}

	.light-col-sm-pull-3.light-col-rtl {
		right: auto;
		left: 12.5%;
	}

	.light-col-sm-offset-3.light-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}

	.light-col-sm-push-4.light-col-rtl {
		right: 16.66666667%;
		left: auto;
	}

	.light-col-sm-pull-4.light-col-rtl {
		right: auto;
		left: 16.66666667%;
	}

	.light-col-sm-offset-4.light-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}

	.light-col-sm-push-5.light-col-rtl {
		right: 20.83333333%;
		left: auto;
	}

	.light-col-sm-pull-5.light-col-rtl {
		right: auto;
		left: 20.83333333%;
	}

	.light-col-sm-offset-5.light-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}

	.light-col-sm-push-6.light-col-rtl {
		right: 25%;
		left: auto;
	}

	.light-col-sm-pull-6.light-col-rtl {
		right: auto;
		left: 25%;
	}

	.light-col-sm-offset-6.light-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}

	.light-col-sm-push-7.light-col-rtl {
		right: 29.16666667%;
		left: auto;
	}

	.light-col-sm-pull-7.light-col-rtl {
		right: auto;
		left: 29.16666667%;
	}

	.light-col-sm-offset-7.light-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}

	.light-col-sm-push-8.light-col-rtl {
		right: 33.33333333%;
		left: auto;
	}

	.light-col-sm-pull-8.light-col-rtl {
		right: auto;
		left: 33.33333333%;
	}

	.light-col-sm-offset-8.light-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}

	.light-col-sm-push-9.light-col-rtl {
		right: 37.5%;
		left: auto;
	}

	.light-col-sm-pull-9.light-col-rtl {
		right: auto;
		left: 37.5%;
	}

	.light-col-sm-offset-9.light-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}

	.light-col-sm-push-10.light-col-rtl {
		right: 41.66666667%;
		left: auto;
	}

	.light-col-sm-pull-10.light-col-rtl {
		right: auto;
		left: 41.66666667%;
	}

	.light-col-sm-offset-10.light-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}

	.light-col-sm-push-11.light-col-rtl {
		right: 45.83333333%;
		left: auto;
	}

	.light-col-sm-pull-11.light-col-rtl {
		right: auto;
		left: 45.83333333%;
	}

	.light-col-sm-offset-11.light-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}

	.light-col-sm-push-12.light-col-rtl {
		right: 50%;
		left: auto;
	}

	.light-col-sm-pull-12.light-col-rtl {
		right: auto;
		left: 50%;
	}

	.light-col-sm-offset-12.light-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}

	.light-col-sm-push-13.light-col-rtl {
		right: 54.16666667%;
		left: auto;
	}

	.light-col-sm-pull-13.light-col-rtl {
		right: auto;
		left: 54.16666667%;
	}

	.light-col-sm-offset-13.light-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}

	.light-col-sm-push-14.light-col-rtl {
		right: 58.33333333%;
		left: auto;
	}

	.light-col-sm-pull-14.light-col-rtl {
		right: auto;
		left: 58.33333333%;
	}

	.light-col-sm-offset-14.light-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}

	.light-col-sm-push-15.light-col-rtl {
		right: 62.5%;
		left: auto;
	}

	.light-col-sm-pull-15.light-col-rtl {
		right: auto;
		left: 62.5%;
	}

	.light-col-sm-offset-15.light-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}

	.light-col-sm-push-16.light-col-rtl {
		right: 66.66666667%;
		left: auto;
	}

	.light-col-sm-pull-16.light-col-rtl {
		right: auto;
		left: 66.66666667%;
	}

	.light-col-sm-offset-16.light-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}

	.light-col-sm-push-17.light-col-rtl {
		right: 70.83333333%;
		left: auto;
	}

	.light-col-sm-pull-17.light-col-rtl {
		right: auto;
		left: 70.83333333%;
	}

	.light-col-sm-offset-17.light-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}

	.light-col-sm-push-18.light-col-rtl {
		right: 75%;
		left: auto;
	}

	.light-col-sm-pull-18.light-col-rtl {
		right: auto;
		left: 75%;
	}

	.light-col-sm-offset-18.light-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}

	.light-col-sm-push-19.light-col-rtl {
		right: 79.16666667%;
		left: auto;
	}

	.light-col-sm-pull-19.light-col-rtl {
		right: auto;
		left: 79.16666667%;
	}

	.light-col-sm-offset-19.light-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}

	.light-col-sm-push-20.light-col-rtl {
		right: 83.33333333%;
		left: auto;
	}

	.light-col-sm-pull-20.light-col-rtl {
		right: auto;
		left: 83.33333333%;
	}

	.light-col-sm-offset-20.light-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}

	.light-col-sm-push-21.light-col-rtl {
		right: 87.5%;
		left: auto;
	}

	.light-col-sm-pull-21.light-col-rtl {
		right: auto;
		left: 87.5%;
	}

	.light-col-sm-offset-21.light-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}

	.light-col-sm-push-22.light-col-rtl {
		right: 91.66666667%;
		left: auto;
	}

	.light-col-sm-pull-22.light-col-rtl {
		right: auto;
		left: 91.66666667%;
	}

	.light-col-sm-offset-22.light-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}

	.light-col-sm-push-23.light-col-rtl {
		right: 95.83333333%;
		left: auto;
	}

	.light-col-sm-pull-23.light-col-rtl {
		right: auto;
		left: 95.83333333%;
	}

	.light-col-sm-offset-23.light-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}

	.light-col-sm-push-24.light-col-rtl {
		right: 100%;
		left: auto;
	}

	.light-col-sm-pull-24.light-col-rtl {
		right: auto;
		left: 100%;
	}

	.light-col-sm-offset-24.light-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}

@media (min-width: 768px) {
	.light-col-md-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.light-col-md-push-24 {
		left: 100%;
	}

	.light-col-md-pull-24 {
		right: 100%;
	}

	.light-col-md-offset-24 {
		margin-left: 100%;
	}

	.light-col-md-order-24 {
		order: 24;
	}

	.light-col-md-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.light-col-md-push-23 {
		left: 95.83333333%;
	}

	.light-col-md-pull-23 {
		right: 95.83333333%;
	}

	.light-col-md-offset-23 {
		margin-left: 95.83333333%;
	}

	.light-col-md-order-23 {
		order: 23;
	}

	.light-col-md-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.light-col-md-push-22 {
		left: 91.66666667%;
	}

	.light-col-md-pull-22 {
		right: 91.66666667%;
	}

	.light-col-md-offset-22 {
		margin-left: 91.66666667%;
	}

	.light-col-md-order-22 {
		order: 22;
	}

	.light-col-md-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.light-col-md-push-21 {
		left: 87.5%;
	}

	.light-col-md-pull-21 {
		right: 87.5%;
	}

	.light-col-md-offset-21 {
		margin-left: 87.5%;
	}

	.light-col-md-order-21 {
		order: 21;
	}

	.light-col-md-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.light-col-md-push-20 {
		left: 83.33333333%;
	}

	.light-col-md-pull-20 {
		right: 83.33333333%;
	}

	.light-col-md-offset-20 {
		margin-left: 83.33333333%;
	}

	.light-col-md-order-20 {
		order: 20;
	}

	.light-col-md-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.light-col-md-push-19 {
		left: 79.16666667%;
	}

	.light-col-md-pull-19 {
		right: 79.16666667%;
	}

	.light-col-md-offset-19 {
		margin-left: 79.16666667%;
	}

	.light-col-md-order-19 {
		order: 19;
	}

	.light-col-md-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.light-col-md-push-18 {
		left: 75%;
	}

	.light-col-md-pull-18 {
		right: 75%;
	}

	.light-col-md-offset-18 {
		margin-left: 75%;
	}

	.light-col-md-order-18 {
		order: 18;
	}

	.light-col-md-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.light-col-md-push-17 {
		left: 70.83333333%;
	}

	.light-col-md-pull-17 {
		right: 70.83333333%;
	}

	.light-col-md-offset-17 {
		margin-left: 70.83333333%;
	}

	.light-col-md-order-17 {
		order: 17;
	}

	.light-col-md-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.light-col-md-push-16 {
		left: 66.66666667%;
	}

	.light-col-md-pull-16 {
		right: 66.66666667%;
	}

	.light-col-md-offset-16 {
		margin-left: 66.66666667%;
	}

	.light-col-md-order-16 {
		order: 16;
	}

	.light-col-md-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.light-col-md-push-15 {
		left: 62.5%;
	}

	.light-col-md-pull-15 {
		right: 62.5%;
	}

	.light-col-md-offset-15 {
		margin-left: 62.5%;
	}

	.light-col-md-order-15 {
		order: 15;
	}

	.light-col-md-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.light-col-md-push-14 {
		left: 58.33333333%;
	}

	.light-col-md-pull-14 {
		right: 58.33333333%;
	}

	.light-col-md-offset-14 {
		margin-left: 58.33333333%;
	}

	.light-col-md-order-14 {
		order: 14;
	}

	.light-col-md-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.light-col-md-push-13 {
		left: 54.16666667%;
	}

	.light-col-md-pull-13 {
		right: 54.16666667%;
	}

	.light-col-md-offset-13 {
		margin-left: 54.16666667%;
	}

	.light-col-md-order-13 {
		order: 13;
	}

	.light-col-md-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.light-col-md-push-12 {
		left: 50%;
	}

	.light-col-md-pull-12 {
		right: 50%;
	}

	.light-col-md-offset-12 {
		margin-left: 50%;
	}

	.light-col-md-order-12 {
		order: 12;
	}

	.light-col-md-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.light-col-md-push-11 {
		left: 45.83333333%;
	}

	.light-col-md-pull-11 {
		right: 45.83333333%;
	}

	.light-col-md-offset-11 {
		margin-left: 45.83333333%;
	}

	.light-col-md-order-11 {
		order: 11;
	}

	.light-col-md-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.light-col-md-push-10 {
		left: 41.66666667%;
	}

	.light-col-md-pull-10 {
		right: 41.66666667%;
	}

	.light-col-md-offset-10 {
		margin-left: 41.66666667%;
	}

	.light-col-md-order-10 {
		order: 10;
	}

	.light-col-md-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.light-col-md-push-9 {
		left: 37.5%;
	}

	.light-col-md-pull-9 {
		right: 37.5%;
	}

	.light-col-md-offset-9 {
		margin-left: 37.5%;
	}

	.light-col-md-order-9 {
		order: 9;
	}

	.light-col-md-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.light-col-md-push-8 {
		left: 33.33333333%;
	}

	.light-col-md-pull-8 {
		right: 33.33333333%;
	}

	.light-col-md-offset-8 {
		margin-left: 33.33333333%;
	}

	.light-col-md-order-8 {
		order: 8;
	}

	.light-col-md-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.light-col-md-push-7 {
		left: 29.16666667%;
	}

	.light-col-md-pull-7 {
		right: 29.16666667%;
	}

	.light-col-md-offset-7 {
		margin-left: 29.16666667%;
	}

	.light-col-md-order-7 {
		order: 7;
	}

	.light-col-md-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.light-col-md-push-6 {
		left: 25%;
	}

	.light-col-md-pull-6 {
		right: 25%;
	}

	.light-col-md-offset-6 {
		margin-left: 25%;
	}

	.light-col-md-order-6 {
		order: 6;
	}

	.light-col-md-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.light-col-md-push-5 {
		left: 20.83333333%;
	}

	.light-col-md-pull-5 {
		right: 20.83333333%;
	}

	.light-col-md-offset-5 {
		margin-left: 20.83333333%;
	}

	.light-col-md-order-5 {
		order: 5;
	}

	.light-col-md-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.light-col-md-push-4 {
		left: 16.66666667%;
	}

	.light-col-md-pull-4 {
		right: 16.66666667%;
	}

	.light-col-md-offset-4 {
		margin-left: 16.66666667%;
	}

	.light-col-md-order-4 {
		order: 4;
	}

	.light-col-md-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.light-col-md-push-3 {
		left: 12.5%;
	}

	.light-col-md-pull-3 {
		right: 12.5%;
	}

	.light-col-md-offset-3 {
		margin-left: 12.5%;
	}

	.light-col-md-order-3 {
		order: 3;
	}

	.light-col-md-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.light-col-md-push-2 {
		left: 8.33333333%;
	}

	.light-col-md-pull-2 {
		right: 8.33333333%;
	}

	.light-col-md-offset-2 {
		margin-left: 8.33333333%;
	}

	.light-col-md-order-2 {
		order: 2;
	}

	.light-col-md-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.light-col-md-push-1 {
		left: 4.16666667%;
	}

	.light-col-md-pull-1 {
		right: 4.16666667%;
	}

	.light-col-md-offset-1 {
		margin-left: 4.16666667%;
	}

	.light-col-md-order-1 {
		order: 1;
	}

	.light-col-md-0 {
		display: none;
	}

	.light-col-push-0 {
		left: auto;
	}

	.light-col-pull-0 {
		right: auto;
	}

	.light-col-md-push-0 {
		left: auto;
	}

	.light-col-md-pull-0 {
		right: auto;
	}

	.light-col-md-offset-0 {
		margin-left: 0;
	}

	.light-col-md-order-0 {
		order: 0;
	}

	.light-col-push-0.light-col-rtl {
		right: auto;
	}

	.light-col-pull-0.light-col-rtl {
		left: auto;
	}

	.light-col-md-push-0.light-col-rtl {
		right: auto;
	}

	.light-col-md-pull-0.light-col-rtl {
		left: auto;
	}

	.light-col-md-offset-0.light-col-rtl {
		margin-right: 0;
	}

	.light-col-md-push-1.light-col-rtl {
		right: 4.16666667%;
		left: auto;
	}

	.light-col-md-pull-1.light-col-rtl {
		right: auto;
		left: 4.16666667%;
	}

	.light-col-md-offset-1.light-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}

	.light-col-md-push-2.light-col-rtl {
		right: 8.33333333%;
		left: auto;
	}

	.light-col-md-pull-2.light-col-rtl {
		right: auto;
		left: 8.33333333%;
	}

	.light-col-md-offset-2.light-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}

	.light-col-md-push-3.light-col-rtl {
		right: 12.5%;
		left: auto;
	}

	.light-col-md-pull-3.light-col-rtl {
		right: auto;
		left: 12.5%;
	}

	.light-col-md-offset-3.light-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}

	.light-col-md-push-4.light-col-rtl {
		right: 16.66666667%;
		left: auto;
	}

	.light-col-md-pull-4.light-col-rtl {
		right: auto;
		left: 16.66666667%;
	}

	.light-col-md-offset-4.light-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}

	.light-col-md-push-5.light-col-rtl {
		right: 20.83333333%;
		left: auto;
	}

	.light-col-md-pull-5.light-col-rtl {
		right: auto;
		left: 20.83333333%;
	}

	.light-col-md-offset-5.light-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}

	.light-col-md-push-6.light-col-rtl {
		right: 25%;
		left: auto;
	}

	.light-col-md-pull-6.light-col-rtl {
		right: auto;
		left: 25%;
	}

	.light-col-md-offset-6.light-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}

	.light-col-md-push-7.light-col-rtl {
		right: 29.16666667%;
		left: auto;
	}

	.light-col-md-pull-7.light-col-rtl {
		right: auto;
		left: 29.16666667%;
	}

	.light-col-md-offset-7.light-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}

	.light-col-md-push-8.light-col-rtl {
		right: 33.33333333%;
		left: auto;
	}

	.light-col-md-pull-8.light-col-rtl {
		right: auto;
		left: 33.33333333%;
	}

	.light-col-md-offset-8.light-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}

	.light-col-md-push-9.light-col-rtl {
		right: 37.5%;
		left: auto;
	}

	.light-col-md-pull-9.light-col-rtl {
		right: auto;
		left: 37.5%;
	}

	.light-col-md-offset-9.light-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}

	.light-col-md-push-10.light-col-rtl {
		right: 41.66666667%;
		left: auto;
	}

	.light-col-md-pull-10.light-col-rtl {
		right: auto;
		left: 41.66666667%;
	}

	.light-col-md-offset-10.light-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}

	.light-col-md-push-11.light-col-rtl {
		right: 45.83333333%;
		left: auto;
	}

	.light-col-md-pull-11.light-col-rtl {
		right: auto;
		left: 45.83333333%;
	}

	.light-col-md-offset-11.light-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}

	.light-col-md-push-12.light-col-rtl {
		right: 50%;
		left: auto;
	}

	.light-col-md-pull-12.light-col-rtl {
		right: auto;
		left: 50%;
	}

	.light-col-md-offset-12.light-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}

	.light-col-md-push-13.light-col-rtl {
		right: 54.16666667%;
		left: auto;
	}

	.light-col-md-pull-13.light-col-rtl {
		right: auto;
		left: 54.16666667%;
	}

	.light-col-md-offset-13.light-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}

	.light-col-md-push-14.light-col-rtl {
		right: 58.33333333%;
		left: auto;
	}

	.light-col-md-pull-14.light-col-rtl {
		right: auto;
		left: 58.33333333%;
	}

	.light-col-md-offset-14.light-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}

	.light-col-md-push-15.light-col-rtl {
		right: 62.5%;
		left: auto;
	}

	.light-col-md-pull-15.light-col-rtl {
		right: auto;
		left: 62.5%;
	}

	.light-col-md-offset-15.light-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}

	.light-col-md-push-16.light-col-rtl {
		right: 66.66666667%;
		left: auto;
	}

	.light-col-md-pull-16.light-col-rtl {
		right: auto;
		left: 66.66666667%;
	}

	.light-col-md-offset-16.light-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}

	.light-col-md-push-17.light-col-rtl {
		right: 70.83333333%;
		left: auto;
	}

	.light-col-md-pull-17.light-col-rtl {
		right: auto;
		left: 70.83333333%;
	}

	.light-col-md-offset-17.light-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}

	.light-col-md-push-18.light-col-rtl {
		right: 75%;
		left: auto;
	}

	.light-col-md-pull-18.light-col-rtl {
		right: auto;
		left: 75%;
	}

	.light-col-md-offset-18.light-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}

	.light-col-md-push-19.light-col-rtl {
		right: 79.16666667%;
		left: auto;
	}

	.light-col-md-pull-19.light-col-rtl {
		right: auto;
		left: 79.16666667%;
	}

	.light-col-md-offset-19.light-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}

	.light-col-md-push-20.light-col-rtl {
		right: 83.33333333%;
		left: auto;
	}

	.light-col-md-pull-20.light-col-rtl {
		right: auto;
		left: 83.33333333%;
	}

	.light-col-md-offset-20.light-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}

	.light-col-md-push-21.light-col-rtl {
		right: 87.5%;
		left: auto;
	}

	.light-col-md-pull-21.light-col-rtl {
		right: auto;
		left: 87.5%;
	}

	.light-col-md-offset-21.light-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}

	.light-col-md-push-22.light-col-rtl {
		right: 91.66666667%;
		left: auto;
	}

	.light-col-md-pull-22.light-col-rtl {
		right: auto;
		left: 91.66666667%;
	}

	.light-col-md-offset-22.light-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}

	.light-col-md-push-23.light-col-rtl {
		right: 95.83333333%;
		left: auto;
	}

	.light-col-md-pull-23.light-col-rtl {
		right: auto;
		left: 95.83333333%;
	}

	.light-col-md-offset-23.light-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}

	.light-col-md-push-24.light-col-rtl {
		right: 100%;
		left: auto;
	}

	.light-col-md-pull-24.light-col-rtl {
		right: auto;
		left: 100%;
	}

	.light-col-md-offset-24.light-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}

@media (min-width: 992px) {
	.light-col-lg-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.light-col-lg-push-24 {
		left: 100%;
	}

	.light-col-lg-pull-24 {
		right: 100%;
	}

	.light-col-lg-offset-24 {
		margin-left: 100%;
	}

	.light-col-lg-order-24 {
		order: 24;
	}

	.light-col-lg-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.light-col-lg-push-23 {
		left: 95.83333333%;
	}

	.light-col-lg-pull-23 {
		right: 95.83333333%;
	}

	.light-col-lg-offset-23 {
		margin-left: 95.83333333%;
	}

	.light-col-lg-order-23 {
		order: 23;
	}

	.light-col-lg-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.light-col-lg-push-22 {
		left: 91.66666667%;
	}

	.light-col-lg-pull-22 {
		right: 91.66666667%;
	}

	.light-col-lg-offset-22 {
		margin-left: 91.66666667%;
	}

	.light-col-lg-order-22 {
		order: 22;
	}

	.light-col-lg-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.light-col-lg-push-21 {
		left: 87.5%;
	}

	.light-col-lg-pull-21 {
		right: 87.5%;
	}

	.light-col-lg-offset-21 {
		margin-left: 87.5%;
	}

	.light-col-lg-order-21 {
		order: 21;
	}

	.light-col-lg-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.light-col-lg-push-20 {
		left: 83.33333333%;
	}

	.light-col-lg-pull-20 {
		right: 83.33333333%;
	}

	.light-col-lg-offset-20 {
		margin-left: 83.33333333%;
	}

	.light-col-lg-order-20 {
		order: 20;
	}

	.light-col-lg-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.light-col-lg-push-19 {
		left: 79.16666667%;
	}

	.light-col-lg-pull-19 {
		right: 79.16666667%;
	}

	.light-col-lg-offset-19 {
		margin-left: 79.16666667%;
	}

	.light-col-lg-order-19 {
		order: 19;
	}

	.light-col-lg-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.light-col-lg-push-18 {
		left: 75%;
	}

	.light-col-lg-pull-18 {
		right: 75%;
	}

	.light-col-lg-offset-18 {
		margin-left: 75%;
	}

	.light-col-lg-order-18 {
		order: 18;
	}

	.light-col-lg-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.light-col-lg-push-17 {
		left: 70.83333333%;
	}

	.light-col-lg-pull-17 {
		right: 70.83333333%;
	}

	.light-col-lg-offset-17 {
		margin-left: 70.83333333%;
	}

	.light-col-lg-order-17 {
		order: 17;
	}

	.light-col-lg-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.light-col-lg-push-16 {
		left: 66.66666667%;
	}

	.light-col-lg-pull-16 {
		right: 66.66666667%;
	}

	.light-col-lg-offset-16 {
		margin-left: 66.66666667%;
	}

	.light-col-lg-order-16 {
		order: 16;
	}

	.light-col-lg-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.light-col-lg-push-15 {
		left: 62.5%;
	}

	.light-col-lg-pull-15 {
		right: 62.5%;
	}

	.light-col-lg-offset-15 {
		margin-left: 62.5%;
	}

	.light-col-lg-order-15 {
		order: 15;
	}

	.light-col-lg-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.light-col-lg-push-14 {
		left: 58.33333333%;
	}

	.light-col-lg-pull-14 {
		right: 58.33333333%;
	}

	.light-col-lg-offset-14 {
		margin-left: 58.33333333%;
	}

	.light-col-lg-order-14 {
		order: 14;
	}

	.light-col-lg-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.light-col-lg-push-13 {
		left: 54.16666667%;
	}

	.light-col-lg-pull-13 {
		right: 54.16666667%;
	}

	.light-col-lg-offset-13 {
		margin-left: 54.16666667%;
	}

	.light-col-lg-order-13 {
		order: 13;
	}

	.light-col-lg-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.light-col-lg-push-12 {
		left: 50%;
	}

	.light-col-lg-pull-12 {
		right: 50%;
	}

	.light-col-lg-offset-12 {
		margin-left: 50%;
	}

	.light-col-lg-order-12 {
		order: 12;
	}

	.light-col-lg-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.light-col-lg-push-11 {
		left: 45.83333333%;
	}

	.light-col-lg-pull-11 {
		right: 45.83333333%;
	}

	.light-col-lg-offset-11 {
		margin-left: 45.83333333%;
	}

	.light-col-lg-order-11 {
		order: 11;
	}

	.light-col-lg-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.light-col-lg-push-10 {
		left: 41.66666667%;
	}

	.light-col-lg-pull-10 {
		right: 41.66666667%;
	}

	.light-col-lg-offset-10 {
		margin-left: 41.66666667%;
	}

	.light-col-lg-order-10 {
		order: 10;
	}

	.light-col-lg-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.light-col-lg-push-9 {
		left: 37.5%;
	}

	.light-col-lg-pull-9 {
		right: 37.5%;
	}

	.light-col-lg-offset-9 {
		margin-left: 37.5%;
	}

	.light-col-lg-order-9 {
		order: 9;
	}

	.light-col-lg-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.light-col-lg-push-8 {
		left: 33.33333333%;
	}

	.light-col-lg-pull-8 {
		right: 33.33333333%;
	}

	.light-col-lg-offset-8 {
		margin-left: 33.33333333%;
	}

	.light-col-lg-order-8 {
		order: 8;
	}

	.light-col-lg-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.light-col-lg-push-7 {
		left: 29.16666667%;
	}

	.light-col-lg-pull-7 {
		right: 29.16666667%;
	}

	.light-col-lg-offset-7 {
		margin-left: 29.16666667%;
	}

	.light-col-lg-order-7 {
		order: 7;
	}

	.light-col-lg-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.light-col-lg-push-6 {
		left: 25%;
	}

	.light-col-lg-pull-6 {
		right: 25%;
	}

	.light-col-lg-offset-6 {
		margin-left: 25%;
	}

	.light-col-lg-order-6 {
		order: 6;
	}

	.light-col-lg-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.light-col-lg-push-5 {
		left: 20.83333333%;
	}

	.light-col-lg-pull-5 {
		right: 20.83333333%;
	}

	.light-col-lg-offset-5 {
		margin-left: 20.83333333%;
	}

	.light-col-lg-order-5 {
		order: 5;
	}

	.light-col-lg-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.light-col-lg-push-4 {
		left: 16.66666667%;
	}

	.light-col-lg-pull-4 {
		right: 16.66666667%;
	}

	.light-col-lg-offset-4 {
		margin-left: 16.66666667%;
	}

	.light-col-lg-order-4 {
		order: 4;
	}

	.light-col-lg-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.light-col-lg-push-3 {
		left: 12.5%;
	}

	.light-col-lg-pull-3 {
		right: 12.5%;
	}

	.light-col-lg-offset-3 {
		margin-left: 12.5%;
	}

	.light-col-lg-order-3 {
		order: 3;
	}

	.light-col-lg-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.light-col-lg-push-2 {
		left: 8.33333333%;
	}

	.light-col-lg-pull-2 {
		right: 8.33333333%;
	}

	.light-col-lg-offset-2 {
		margin-left: 8.33333333%;
	}

	.light-col-lg-order-2 {
		order: 2;
	}

	.light-col-lg-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.light-col-lg-push-1 {
		left: 4.16666667%;
	}

	.light-col-lg-pull-1 {
		right: 4.16666667%;
	}

	.light-col-lg-offset-1 {
		margin-left: 4.16666667%;
	}

	.light-col-lg-order-1 {
		order: 1;
	}

	.light-col-lg-0 {
		display: none;
	}

	.light-col-push-0 {
		left: auto;
	}

	.light-col-pull-0 {
		right: auto;
	}

	.light-col-lg-push-0 {
		left: auto;
	}

	.light-col-lg-pull-0 {
		right: auto;
	}

	.light-col-lg-offset-0 {
		margin-left: 0;
	}

	.light-col-lg-order-0 {
		order: 0;
	}

	.light-col-push-0.light-col-rtl {
		right: auto;
	}

	.light-col-pull-0.light-col-rtl {
		left: auto;
	}

	.light-col-lg-push-0.light-col-rtl {
		right: auto;
	}

	.light-col-lg-pull-0.light-col-rtl {
		left: auto;
	}

	.light-col-lg-offset-0.light-col-rtl {
		margin-right: 0;
	}

	.light-col-lg-push-1.light-col-rtl {
		right: 4.16666667%;
		left: auto;
	}

	.light-col-lg-pull-1.light-col-rtl {
		right: auto;
		left: 4.16666667%;
	}

	.light-col-lg-offset-1.light-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}

	.light-col-lg-push-2.light-col-rtl {
		right: 8.33333333%;
		left: auto;
	}

	.light-col-lg-pull-2.light-col-rtl {
		right: auto;
		left: 8.33333333%;
	}

	.light-col-lg-offset-2.light-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}

	.light-col-lg-push-3.light-col-rtl {
		right: 12.5%;
		left: auto;
	}

	.light-col-lg-pull-3.light-col-rtl {
		right: auto;
		left: 12.5%;
	}

	.light-col-lg-offset-3.light-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}

	.light-col-lg-push-4.light-col-rtl {
		right: 16.66666667%;
		left: auto;
	}

	.light-col-lg-pull-4.light-col-rtl {
		right: auto;
		left: 16.66666667%;
	}

	.light-col-lg-offset-4.light-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}

	.light-col-lg-push-5.light-col-rtl {
		right: 20.83333333%;
		left: auto;
	}

	.light-col-lg-pull-5.light-col-rtl {
		right: auto;
		left: 20.83333333%;
	}

	.light-col-lg-offset-5.light-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}

	.light-col-lg-push-6.light-col-rtl {
		right: 25%;
		left: auto;
	}

	.light-col-lg-pull-6.light-col-rtl {
		right: auto;
		left: 25%;
	}

	.light-col-lg-offset-6.light-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}

	.light-col-lg-push-7.light-col-rtl {
		right: 29.16666667%;
		left: auto;
	}

	.light-col-lg-pull-7.light-col-rtl {
		right: auto;
		left: 29.16666667%;
	}

	.light-col-lg-offset-7.light-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}

	.light-col-lg-push-8.light-col-rtl {
		right: 33.33333333%;
		left: auto;
	}

	.light-col-lg-pull-8.light-col-rtl {
		right: auto;
		left: 33.33333333%;
	}

	.light-col-lg-offset-8.light-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}

	.light-col-lg-push-9.light-col-rtl {
		right: 37.5%;
		left: auto;
	}

	.light-col-lg-pull-9.light-col-rtl {
		right: auto;
		left: 37.5%;
	}

	.light-col-lg-offset-9.light-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}

	.light-col-lg-push-10.light-col-rtl {
		right: 41.66666667%;
		left: auto;
	}

	.light-col-lg-pull-10.light-col-rtl {
		right: auto;
		left: 41.66666667%;
	}

	.light-col-lg-offset-10.light-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}

	.light-col-lg-push-11.light-col-rtl {
		right: 45.83333333%;
		left: auto;
	}

	.light-col-lg-pull-11.light-col-rtl {
		right: auto;
		left: 45.83333333%;
	}

	.light-col-lg-offset-11.light-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}

	.light-col-lg-push-12.light-col-rtl {
		right: 50%;
		left: auto;
	}

	.light-col-lg-pull-12.light-col-rtl {
		right: auto;
		left: 50%;
	}

	.light-col-lg-offset-12.light-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}

	.light-col-lg-push-13.light-col-rtl {
		right: 54.16666667%;
		left: auto;
	}

	.light-col-lg-pull-13.light-col-rtl {
		right: auto;
		left: 54.16666667%;
	}

	.light-col-lg-offset-13.light-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}

	.light-col-lg-push-14.light-col-rtl {
		right: 58.33333333%;
		left: auto;
	}

	.light-col-lg-pull-14.light-col-rtl {
		right: auto;
		left: 58.33333333%;
	}

	.light-col-lg-offset-14.light-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}

	.light-col-lg-push-15.light-col-rtl {
		right: 62.5%;
		left: auto;
	}

	.light-col-lg-pull-15.light-col-rtl {
		right: auto;
		left: 62.5%;
	}

	.light-col-lg-offset-15.light-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}

	.light-col-lg-push-16.light-col-rtl {
		right: 66.66666667%;
		left: auto;
	}

	.light-col-lg-pull-16.light-col-rtl {
		right: auto;
		left: 66.66666667%;
	}

	.light-col-lg-offset-16.light-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}

	.light-col-lg-push-17.light-col-rtl {
		right: 70.83333333%;
		left: auto;
	}

	.light-col-lg-pull-17.light-col-rtl {
		right: auto;
		left: 70.83333333%;
	}

	.light-col-lg-offset-17.light-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}

	.light-col-lg-push-18.light-col-rtl {
		right: 75%;
		left: auto;
	}

	.light-col-lg-pull-18.light-col-rtl {
		right: auto;
		left: 75%;
	}

	.light-col-lg-offset-18.light-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}

	.light-col-lg-push-19.light-col-rtl {
		right: 79.16666667%;
		left: auto;
	}

	.light-col-lg-pull-19.light-col-rtl {
		right: auto;
		left: 79.16666667%;
	}

	.light-col-lg-offset-19.light-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}

	.light-col-lg-push-20.light-col-rtl {
		right: 83.33333333%;
		left: auto;
	}

	.light-col-lg-pull-20.light-col-rtl {
		right: auto;
		left: 83.33333333%;
	}

	.light-col-lg-offset-20.light-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}

	.light-col-lg-push-21.light-col-rtl {
		right: 87.5%;
		left: auto;
	}

	.light-col-lg-pull-21.light-col-rtl {
		right: auto;
		left: 87.5%;
	}

	.light-col-lg-offset-21.light-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}

	.light-col-lg-push-22.light-col-rtl {
		right: 91.66666667%;
		left: auto;
	}

	.light-col-lg-pull-22.light-col-rtl {
		right: auto;
		left: 91.66666667%;
	}

	.light-col-lg-offset-22.light-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}

	.light-col-lg-push-23.light-col-rtl {
		right: 95.83333333%;
		left: auto;
	}

	.light-col-lg-pull-23.light-col-rtl {
		right: auto;
		left: 95.83333333%;
	}

	.light-col-lg-offset-23.light-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}

	.light-col-lg-push-24.light-col-rtl {
		right: 100%;
		left: auto;
	}

	.light-col-lg-pull-24.light-col-rtl {
		right: auto;
		left: 100%;
	}

	.light-col-lg-offset-24.light-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}

@media (min-width: 1200px) {
	.light-col-xl-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.light-col-xl-push-24 {
		left: 100%;
	}

	.light-col-xl-pull-24 {
		right: 100%;
	}

	.light-col-xl-offset-24 {
		margin-left: 100%;
	}

	.light-col-xl-order-24 {
		order: 24;
	}

	.light-col-xl-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.light-col-xl-push-23 {
		left: 95.83333333%;
	}

	.light-col-xl-pull-23 {
		right: 95.83333333%;
	}

	.light-col-xl-offset-23 {
		margin-left: 95.83333333%;
	}

	.light-col-xl-order-23 {
		order: 23;
	}

	.light-col-xl-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.light-col-xl-push-22 {
		left: 91.66666667%;
	}

	.light-col-xl-pull-22 {
		right: 91.66666667%;
	}

	.light-col-xl-offset-22 {
		margin-left: 91.66666667%;
	}

	.light-col-xl-order-22 {
		order: 22;
	}

	.light-col-xl-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.light-col-xl-push-21 {
		left: 87.5%;
	}

	.light-col-xl-pull-21 {
		right: 87.5%;
	}

	.light-col-xl-offset-21 {
		margin-left: 87.5%;
	}

	.light-col-xl-order-21 {
		order: 21;
	}

	.light-col-xl-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.light-col-xl-push-20 {
		left: 83.33333333%;
	}

	.light-col-xl-pull-20 {
		right: 83.33333333%;
	}

	.light-col-xl-offset-20 {
		margin-left: 83.33333333%;
	}

	.light-col-xl-order-20 {
		order: 20;
	}

	.light-col-xl-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.light-col-xl-push-19 {
		left: 79.16666667%;
	}

	.light-col-xl-pull-19 {
		right: 79.16666667%;
	}

	.light-col-xl-offset-19 {
		margin-left: 79.16666667%;
	}

	.light-col-xl-order-19 {
		order: 19;
	}

	.light-col-xl-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.light-col-xl-push-18 {
		left: 75%;
	}

	.light-col-xl-pull-18 {
		right: 75%;
	}

	.light-col-xl-offset-18 {
		margin-left: 75%;
	}

	.light-col-xl-order-18 {
		order: 18;
	}

	.light-col-xl-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.light-col-xl-push-17 {
		left: 70.83333333%;
	}

	.light-col-xl-pull-17 {
		right: 70.83333333%;
	}

	.light-col-xl-offset-17 {
		margin-left: 70.83333333%;
	}

	.light-col-xl-order-17 {
		order: 17;
	}

	.light-col-xl-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.light-col-xl-push-16 {
		left: 66.66666667%;
	}

	.light-col-xl-pull-16 {
		right: 66.66666667%;
	}

	.light-col-xl-offset-16 {
		margin-left: 66.66666667%;
	}

	.light-col-xl-order-16 {
		order: 16;
	}

	.light-col-xl-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.light-col-xl-push-15 {
		left: 62.5%;
	}

	.light-col-xl-pull-15 {
		right: 62.5%;
	}

	.light-col-xl-offset-15 {
		margin-left: 62.5%;
	}

	.light-col-xl-order-15 {
		order: 15;
	}

	.light-col-xl-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.light-col-xl-push-14 {
		left: 58.33333333%;
	}

	.light-col-xl-pull-14 {
		right: 58.33333333%;
	}

	.light-col-xl-offset-14 {
		margin-left: 58.33333333%;
	}

	.light-col-xl-order-14 {
		order: 14;
	}

	.light-col-xl-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.light-col-xl-push-13 {
		left: 54.16666667%;
	}

	.light-col-xl-pull-13 {
		right: 54.16666667%;
	}

	.light-col-xl-offset-13 {
		margin-left: 54.16666667%;
	}

	.light-col-xl-order-13 {
		order: 13;
	}

	.light-col-xl-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.light-col-xl-push-12 {
		left: 50%;
	}

	.light-col-xl-pull-12 {
		right: 50%;
	}

	.light-col-xl-offset-12 {
		margin-left: 50%;
	}

	.light-col-xl-order-12 {
		order: 12;
	}

	.light-col-xl-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.light-col-xl-push-11 {
		left: 45.83333333%;
	}

	.light-col-xl-pull-11 {
		right: 45.83333333%;
	}

	.light-col-xl-offset-11 {
		margin-left: 45.83333333%;
	}

	.light-col-xl-order-11 {
		order: 11;
	}

	.light-col-xl-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.light-col-xl-push-10 {
		left: 41.66666667%;
	}

	.light-col-xl-pull-10 {
		right: 41.66666667%;
	}

	.light-col-xl-offset-10 {
		margin-left: 41.66666667%;
	}

	.light-col-xl-order-10 {
		order: 10;
	}

	.light-col-xl-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.light-col-xl-push-9 {
		left: 37.5%;
	}

	.light-col-xl-pull-9 {
		right: 37.5%;
	}

	.light-col-xl-offset-9 {
		margin-left: 37.5%;
	}

	.light-col-xl-order-9 {
		order: 9;
	}

	.light-col-xl-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.light-col-xl-push-8 {
		left: 33.33333333%;
	}

	.light-col-xl-pull-8 {
		right: 33.33333333%;
	}

	.light-col-xl-offset-8 {
		margin-left: 33.33333333%;
	}

	.light-col-xl-order-8 {
		order: 8;
	}

	.light-col-xl-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.light-col-xl-push-7 {
		left: 29.16666667%;
	}

	.light-col-xl-pull-7 {
		right: 29.16666667%;
	}

	.light-col-xl-offset-7 {
		margin-left: 29.16666667%;
	}

	.light-col-xl-order-7 {
		order: 7;
	}

	.light-col-xl-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.light-col-xl-push-6 {
		left: 25%;
	}

	.light-col-xl-pull-6 {
		right: 25%;
	}

	.light-col-xl-offset-6 {
		margin-left: 25%;
	}

	.light-col-xl-order-6 {
		order: 6;
	}

	.light-col-xl-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.light-col-xl-push-5 {
		left: 20.83333333%;
	}

	.light-col-xl-pull-5 {
		right: 20.83333333%;
	}

	.light-col-xl-offset-5 {
		margin-left: 20.83333333%;
	}

	.light-col-xl-order-5 {
		order: 5;
	}

	.light-col-xl-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.light-col-xl-push-4 {
		left: 16.66666667%;
	}

	.light-col-xl-pull-4 {
		right: 16.66666667%;
	}

	.light-col-xl-offset-4 {
		margin-left: 16.66666667%;
	}

	.light-col-xl-order-4 {
		order: 4;
	}

	.light-col-xl-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.light-col-xl-push-3 {
		left: 12.5%;
	}

	.light-col-xl-pull-3 {
		right: 12.5%;
	}

	.light-col-xl-offset-3 {
		margin-left: 12.5%;
	}

	.light-col-xl-order-3 {
		order: 3;
	}

	.light-col-xl-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.light-col-xl-push-2 {
		left: 8.33333333%;
	}

	.light-col-xl-pull-2 {
		right: 8.33333333%;
	}

	.light-col-xl-offset-2 {
		margin-left: 8.33333333%;
	}

	.light-col-xl-order-2 {
		order: 2;
	}

	.light-col-xl-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.light-col-xl-push-1 {
		left: 4.16666667%;
	}

	.light-col-xl-pull-1 {
		right: 4.16666667%;
	}

	.light-col-xl-offset-1 {
		margin-left: 4.16666667%;
	}

	.light-col-xl-order-1 {
		order: 1;
	}

	.light-col-xl-0 {
		display: none;
	}

	.light-col-push-0 {
		left: auto;
	}

	.light-col-pull-0 {
		right: auto;
	}

	.light-col-xl-push-0 {
		left: auto;
	}

	.light-col-xl-pull-0 {
		right: auto;
	}

	.light-col-xl-offset-0 {
		margin-left: 0;
	}

	.light-col-xl-order-0 {
		order: 0;
	}

	.light-col-push-0.light-col-rtl {
		right: auto;
	}

	.light-col-pull-0.light-col-rtl {
		left: auto;
	}

	.light-col-xl-push-0.light-col-rtl {
		right: auto;
	}

	.light-col-xl-pull-0.light-col-rtl {
		left: auto;
	}

	.light-col-xl-offset-0.light-col-rtl {
		margin-right: 0;
	}

	.light-col-xl-push-1.light-col-rtl {
		right: 4.16666667%;
		left: auto;
	}

	.light-col-xl-pull-1.light-col-rtl {
		right: auto;
		left: 4.16666667%;
	}

	.light-col-xl-offset-1.light-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}

	.light-col-xl-push-2.light-col-rtl {
		right: 8.33333333%;
		left: auto;
	}

	.light-col-xl-pull-2.light-col-rtl {
		right: auto;
		left: 8.33333333%;
	}

	.light-col-xl-offset-2.light-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}

	.light-col-xl-push-3.light-col-rtl {
		right: 12.5%;
		left: auto;
	}

	.light-col-xl-pull-3.light-col-rtl {
		right: auto;
		left: 12.5%;
	}

	.light-col-xl-offset-3.light-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}

	.light-col-xl-push-4.light-col-rtl {
		right: 16.66666667%;
		left: auto;
	}

	.light-col-xl-pull-4.light-col-rtl {
		right: auto;
		left: 16.66666667%;
	}

	.light-col-xl-offset-4.light-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}

	.light-col-xl-push-5.light-col-rtl {
		right: 20.83333333%;
		left: auto;
	}

	.light-col-xl-pull-5.light-col-rtl {
		right: auto;
		left: 20.83333333%;
	}

	.light-col-xl-offset-5.light-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}

	.light-col-xl-push-6.light-col-rtl {
		right: 25%;
		left: auto;
	}

	.light-col-xl-pull-6.light-col-rtl {
		right: auto;
		left: 25%;
	}

	.light-col-xl-offset-6.light-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}

	.light-col-xl-push-7.light-col-rtl {
		right: 29.16666667%;
		left: auto;
	}

	.light-col-xl-pull-7.light-col-rtl {
		right: auto;
		left: 29.16666667%;
	}

	.light-col-xl-offset-7.light-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}

	.light-col-xl-push-8.light-col-rtl {
		right: 33.33333333%;
		left: auto;
	}

	.light-col-xl-pull-8.light-col-rtl {
		right: auto;
		left: 33.33333333%;
	}

	.light-col-xl-offset-8.light-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}

	.light-col-xl-push-9.light-col-rtl {
		right: 37.5%;
		left: auto;
	}

	.light-col-xl-pull-9.light-col-rtl {
		right: auto;
		left: 37.5%;
	}

	.light-col-xl-offset-9.light-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}

	.light-col-xl-push-10.light-col-rtl {
		right: 41.66666667%;
		left: auto;
	}

	.light-col-xl-pull-10.light-col-rtl {
		right: auto;
		left: 41.66666667%;
	}

	.light-col-xl-offset-10.light-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}

	.light-col-xl-push-11.light-col-rtl {
		right: 45.83333333%;
		left: auto;
	}

	.light-col-xl-pull-11.light-col-rtl {
		right: auto;
		left: 45.83333333%;
	}

	.light-col-xl-offset-11.light-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}

	.light-col-xl-push-12.light-col-rtl {
		right: 50%;
		left: auto;
	}

	.light-col-xl-pull-12.light-col-rtl {
		right: auto;
		left: 50%;
	}

	.light-col-xl-offset-12.light-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}

	.light-col-xl-push-13.light-col-rtl {
		right: 54.16666667%;
		left: auto;
	}

	.light-col-xl-pull-13.light-col-rtl {
		right: auto;
		left: 54.16666667%;
	}

	.light-col-xl-offset-13.light-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}

	.light-col-xl-push-14.light-col-rtl {
		right: 58.33333333%;
		left: auto;
	}

	.light-col-xl-pull-14.light-col-rtl {
		right: auto;
		left: 58.33333333%;
	}

	.light-col-xl-offset-14.light-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}

	.light-col-xl-push-15.light-col-rtl {
		right: 62.5%;
		left: auto;
	}

	.light-col-xl-pull-15.light-col-rtl {
		right: auto;
		left: 62.5%;
	}

	.light-col-xl-offset-15.light-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}

	.light-col-xl-push-16.light-col-rtl {
		right: 66.66666667%;
		left: auto;
	}

	.light-col-xl-pull-16.light-col-rtl {
		right: auto;
		left: 66.66666667%;
	}

	.light-col-xl-offset-16.light-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}

	.light-col-xl-push-17.light-col-rtl {
		right: 70.83333333%;
		left: auto;
	}

	.light-col-xl-pull-17.light-col-rtl {
		right: auto;
		left: 70.83333333%;
	}

	.light-col-xl-offset-17.light-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}

	.light-col-xl-push-18.light-col-rtl {
		right: 75%;
		left: auto;
	}

	.light-col-xl-pull-18.light-col-rtl {
		right: auto;
		left: 75%;
	}

	.light-col-xl-offset-18.light-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}

	.light-col-xl-push-19.light-col-rtl {
		right: 79.16666667%;
		left: auto;
	}

	.light-col-xl-pull-19.light-col-rtl {
		right: auto;
		left: 79.16666667%;
	}

	.light-col-xl-offset-19.light-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}

	.light-col-xl-push-20.light-col-rtl {
		right: 83.33333333%;
		left: auto;
	}

	.light-col-xl-pull-20.light-col-rtl {
		right: auto;
		left: 83.33333333%;
	}

	.light-col-xl-offset-20.light-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}

	.light-col-xl-push-21.light-col-rtl {
		right: 87.5%;
		left: auto;
	}

	.light-col-xl-pull-21.light-col-rtl {
		right: auto;
		left: 87.5%;
	}

	.light-col-xl-offset-21.light-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}

	.light-col-xl-push-22.light-col-rtl {
		right: 91.66666667%;
		left: auto;
	}

	.light-col-xl-pull-22.light-col-rtl {
		right: auto;
		left: 91.66666667%;
	}

	.light-col-xl-offset-22.light-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}

	.light-col-xl-push-23.light-col-rtl {
		right: 95.83333333%;
		left: auto;
	}

	.light-col-xl-pull-23.light-col-rtl {
		right: auto;
		left: 95.83333333%;
	}

	.light-col-xl-offset-23.light-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}

	.light-col-xl-push-24.light-col-rtl {
		right: 100%;
		left: auto;
	}

	.light-col-xl-pull-24.light-col-rtl {
		right: auto;
		left: 100%;
	}

	.light-col-xl-offset-24.light-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}

@media (min-width: 1600px) {
	.light-col-xxl-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.light-col-xxl-push-24 {
		left: 100%;
	}

	.light-col-xxl-pull-24 {
		right: 100%;
	}

	.light-col-xxl-offset-24 {
		margin-left: 100%;
	}

	.light-col-xxl-order-24 {
		order: 24;
	}

	.light-col-xxl-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.light-col-xxl-push-23 {
		left: 95.83333333%;
	}

	.light-col-xxl-pull-23 {
		right: 95.83333333%;
	}

	.light-col-xxl-offset-23 {
		margin-left: 95.83333333%;
	}

	.light-col-xxl-order-23 {
		order: 23;
	}

	.light-col-xxl-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.light-col-xxl-push-22 {
		left: 91.66666667%;
	}

	.light-col-xxl-pull-22 {
		right: 91.66666667%;
	}

	.light-col-xxl-offset-22 {
		margin-left: 91.66666667%;
	}

	.light-col-xxl-order-22 {
		order: 22;
	}

	.light-col-xxl-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.light-col-xxl-push-21 {
		left: 87.5%;
	}

	.light-col-xxl-pull-21 {
		right: 87.5%;
	}

	.light-col-xxl-offset-21 {
		margin-left: 87.5%;
	}

	.light-col-xxl-order-21 {
		order: 21;
	}

	.light-col-xxl-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.light-col-xxl-push-20 {
		left: 83.33333333%;
	}

	.light-col-xxl-pull-20 {
		right: 83.33333333%;
	}

	.light-col-xxl-offset-20 {
		margin-left: 83.33333333%;
	}

	.light-col-xxl-order-20 {
		order: 20;
	}

	.light-col-xxl-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.light-col-xxl-push-19 {
		left: 79.16666667%;
	}

	.light-col-xxl-pull-19 {
		right: 79.16666667%;
	}

	.light-col-xxl-offset-19 {
		margin-left: 79.16666667%;
	}

	.light-col-xxl-order-19 {
		order: 19;
	}

	.light-col-xxl-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.light-col-xxl-push-18 {
		left: 75%;
	}

	.light-col-xxl-pull-18 {
		right: 75%;
	}

	.light-col-xxl-offset-18 {
		margin-left: 75%;
	}

	.light-col-xxl-order-18 {
		order: 18;
	}

	.light-col-xxl-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.light-col-xxl-push-17 {
		left: 70.83333333%;
	}

	.light-col-xxl-pull-17 {
		right: 70.83333333%;
	}

	.light-col-xxl-offset-17 {
		margin-left: 70.83333333%;
	}

	.light-col-xxl-order-17 {
		order: 17;
	}

	.light-col-xxl-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.light-col-xxl-push-16 {
		left: 66.66666667%;
	}

	.light-col-xxl-pull-16 {
		right: 66.66666667%;
	}

	.light-col-xxl-offset-16 {
		margin-left: 66.66666667%;
	}

	.light-col-xxl-order-16 {
		order: 16;
	}

	.light-col-xxl-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.light-col-xxl-push-15 {
		left: 62.5%;
	}

	.light-col-xxl-pull-15 {
		right: 62.5%;
	}

	.light-col-xxl-offset-15 {
		margin-left: 62.5%;
	}

	.light-col-xxl-order-15 {
		order: 15;
	}

	.light-col-xxl-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.light-col-xxl-push-14 {
		left: 58.33333333%;
	}

	.light-col-xxl-pull-14 {
		right: 58.33333333%;
	}

	.light-col-xxl-offset-14 {
		margin-left: 58.33333333%;
	}

	.light-col-xxl-order-14 {
		order: 14;
	}

	.light-col-xxl-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.light-col-xxl-push-13 {
		left: 54.16666667%;
	}

	.light-col-xxl-pull-13 {
		right: 54.16666667%;
	}

	.light-col-xxl-offset-13 {
		margin-left: 54.16666667%;
	}

	.light-col-xxl-order-13 {
		order: 13;
	}

	.light-col-xxl-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.light-col-xxl-push-12 {
		left: 50%;
	}

	.light-col-xxl-pull-12 {
		right: 50%;
	}

	.light-col-xxl-offset-12 {
		margin-left: 50%;
	}

	.light-col-xxl-order-12 {
		order: 12;
	}

	.light-col-xxl-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.light-col-xxl-push-11 {
		left: 45.83333333%;
	}

	.light-col-xxl-pull-11 {
		right: 45.83333333%;
	}

	.light-col-xxl-offset-11 {
		margin-left: 45.83333333%;
	}

	.light-col-xxl-order-11 {
		order: 11;
	}

	.light-col-xxl-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.light-col-xxl-push-10 {
		left: 41.66666667%;
	}

	.light-col-xxl-pull-10 {
		right: 41.66666667%;
	}

	.light-col-xxl-offset-10 {
		margin-left: 41.66666667%;
	}

	.light-col-xxl-order-10 {
		order: 10;
	}

	.light-col-xxl-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.light-col-xxl-push-9 {
		left: 37.5%;
	}

	.light-col-xxl-pull-9 {
		right: 37.5%;
	}

	.light-col-xxl-offset-9 {
		margin-left: 37.5%;
	}

	.light-col-xxl-order-9 {
		order: 9;
	}

	.light-col-xxl-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.light-col-xxl-push-8 {
		left: 33.33333333%;
	}

	.light-col-xxl-pull-8 {
		right: 33.33333333%;
	}

	.light-col-xxl-offset-8 {
		margin-left: 33.33333333%;
	}

	.light-col-xxl-order-8 {
		order: 8;
	}

	.light-col-xxl-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.light-col-xxl-push-7 {
		left: 29.16666667%;
	}

	.light-col-xxl-pull-7 {
		right: 29.16666667%;
	}

	.light-col-xxl-offset-7 {
		margin-left: 29.16666667%;
	}

	.light-col-xxl-order-7 {
		order: 7;
	}

	.light-col-xxl-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.light-col-xxl-push-6 {
		left: 25%;
	}

	.light-col-xxl-pull-6 {
		right: 25%;
	}

	.light-col-xxl-offset-6 {
		margin-left: 25%;
	}

	.light-col-xxl-order-6 {
		order: 6;
	}

	.light-col-xxl-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.light-col-xxl-push-5 {
		left: 20.83333333%;
	}

	.light-col-xxl-pull-5 {
		right: 20.83333333%;
	}

	.light-col-xxl-offset-5 {
		margin-left: 20.83333333%;
	}

	.light-col-xxl-order-5 {
		order: 5;
	}

	.light-col-xxl-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.light-col-xxl-push-4 {
		left: 16.66666667%;
	}

	.light-col-xxl-pull-4 {
		right: 16.66666667%;
	}

	.light-col-xxl-offset-4 {
		margin-left: 16.66666667%;
	}

	.light-col-xxl-order-4 {
		order: 4;
	}

	.light-col-xxl-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.light-col-xxl-push-3 {
		left: 12.5%;
	}

	.light-col-xxl-pull-3 {
		right: 12.5%;
	}

	.light-col-xxl-offset-3 {
		margin-left: 12.5%;
	}

	.light-col-xxl-order-3 {
		order: 3;
	}

	.light-col-xxl-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.light-col-xxl-push-2 {
		left: 8.33333333%;
	}

	.light-col-xxl-pull-2 {
		right: 8.33333333%;
	}

	.light-col-xxl-offset-2 {
		margin-left: 8.33333333%;
	}

	.light-col-xxl-order-2 {
		order: 2;
	}

	.light-col-xxl-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.light-col-xxl-push-1 {
		left: 4.16666667%;
	}

	.light-col-xxl-pull-1 {
		right: 4.16666667%;
	}

	.light-col-xxl-offset-1 {
		margin-left: 4.16666667%;
	}

	.light-col-xxl-order-1 {
		order: 1;
	}

	.light-col-xxl-0 {
		display: none;
	}

	.light-col-push-0 {
		left: auto;
	}

	.light-col-pull-0 {
		right: auto;
	}

	.light-col-xxl-push-0 {
		left: auto;
	}

	.light-col-xxl-pull-0 {
		right: auto;
	}

	.light-col-xxl-offset-0 {
		margin-left: 0;
	}

	.light-col-xxl-order-0 {
		order: 0;
	}

	.light-col-push-0.light-col-rtl {
		right: auto;
	}

	.light-col-pull-0.light-col-rtl {
		left: auto;
	}

	.light-col-xxl-push-0.light-col-rtl {
		right: auto;
	}

	.light-col-xxl-pull-0.light-col-rtl {
		left: auto;
	}

	.light-col-xxl-offset-0.light-col-rtl {
		margin-right: 0;
	}

	.light-col-xxl-push-1.light-col-rtl {
		right: 4.16666667%;
		left: auto;
	}

	.light-col-xxl-pull-1.light-col-rtl {
		right: auto;
		left: 4.16666667%;
	}

	.light-col-xxl-offset-1.light-col-rtl {
		margin-right: 4.16666667%;
		margin-left: 0;
	}

	.light-col-xxl-push-2.light-col-rtl {
		right: 8.33333333%;
		left: auto;
	}

	.light-col-xxl-pull-2.light-col-rtl {
		right: auto;
		left: 8.33333333%;
	}

	.light-col-xxl-offset-2.light-col-rtl {
		margin-right: 8.33333333%;
		margin-left: 0;
	}

	.light-col-xxl-push-3.light-col-rtl {
		right: 12.5%;
		left: auto;
	}

	.light-col-xxl-pull-3.light-col-rtl {
		right: auto;
		left: 12.5%;
	}

	.light-col-xxl-offset-3.light-col-rtl {
		margin-right: 12.5%;
		margin-left: 0;
	}

	.light-col-xxl-push-4.light-col-rtl {
		right: 16.66666667%;
		left: auto;
	}

	.light-col-xxl-pull-4.light-col-rtl {
		right: auto;
		left: 16.66666667%;
	}

	.light-col-xxl-offset-4.light-col-rtl {
		margin-right: 16.66666667%;
		margin-left: 0;
	}

	.light-col-xxl-push-5.light-col-rtl {
		right: 20.83333333%;
		left: auto;
	}

	.light-col-xxl-pull-5.light-col-rtl {
		right: auto;
		left: 20.83333333%;
	}

	.light-col-xxl-offset-5.light-col-rtl {
		margin-right: 20.83333333%;
		margin-left: 0;
	}

	.light-col-xxl-push-6.light-col-rtl {
		right: 25%;
		left: auto;
	}

	.light-col-xxl-pull-6.light-col-rtl {
		right: auto;
		left: 25%;
	}

	.light-col-xxl-offset-6.light-col-rtl {
		margin-right: 25%;
		margin-left: 0;
	}

	.light-col-xxl-push-7.light-col-rtl {
		right: 29.16666667%;
		left: auto;
	}

	.light-col-xxl-pull-7.light-col-rtl {
		right: auto;
		left: 29.16666667%;
	}

	.light-col-xxl-offset-7.light-col-rtl {
		margin-right: 29.16666667%;
		margin-left: 0;
	}

	.light-col-xxl-push-8.light-col-rtl {
		right: 33.33333333%;
		left: auto;
	}

	.light-col-xxl-pull-8.light-col-rtl {
		right: auto;
		left: 33.33333333%;
	}

	.light-col-xxl-offset-8.light-col-rtl {
		margin-right: 33.33333333%;
		margin-left: 0;
	}

	.light-col-xxl-push-9.light-col-rtl {
		right: 37.5%;
		left: auto;
	}

	.light-col-xxl-pull-9.light-col-rtl {
		right: auto;
		left: 37.5%;
	}

	.light-col-xxl-offset-9.light-col-rtl {
		margin-right: 37.5%;
		margin-left: 0;
	}

	.light-col-xxl-push-10.light-col-rtl {
		right: 41.66666667%;
		left: auto;
	}

	.light-col-xxl-pull-10.light-col-rtl {
		right: auto;
		left: 41.66666667%;
	}

	.light-col-xxl-offset-10.light-col-rtl {
		margin-right: 41.66666667%;
		margin-left: 0;
	}

	.light-col-xxl-push-11.light-col-rtl {
		right: 45.83333333%;
		left: auto;
	}

	.light-col-xxl-pull-11.light-col-rtl {
		right: auto;
		left: 45.83333333%;
	}

	.light-col-xxl-offset-11.light-col-rtl {
		margin-right: 45.83333333%;
		margin-left: 0;
	}

	.light-col-xxl-push-12.light-col-rtl {
		right: 50%;
		left: auto;
	}

	.light-col-xxl-pull-12.light-col-rtl {
		right: auto;
		left: 50%;
	}

	.light-col-xxl-offset-12.light-col-rtl {
		margin-right: 50%;
		margin-left: 0;
	}

	.light-col-xxl-push-13.light-col-rtl {
		right: 54.16666667%;
		left: auto;
	}

	.light-col-xxl-pull-13.light-col-rtl {
		right: auto;
		left: 54.16666667%;
	}

	.light-col-xxl-offset-13.light-col-rtl {
		margin-right: 54.16666667%;
		margin-left: 0;
	}

	.light-col-xxl-push-14.light-col-rtl {
		right: 58.33333333%;
		left: auto;
	}

	.light-col-xxl-pull-14.light-col-rtl {
		right: auto;
		left: 58.33333333%;
	}

	.light-col-xxl-offset-14.light-col-rtl {
		margin-right: 58.33333333%;
		margin-left: 0;
	}

	.light-col-xxl-push-15.light-col-rtl {
		right: 62.5%;
		left: auto;
	}

	.light-col-xxl-pull-15.light-col-rtl {
		right: auto;
		left: 62.5%;
	}

	.light-col-xxl-offset-15.light-col-rtl {
		margin-right: 62.5%;
		margin-left: 0;
	}

	.light-col-xxl-push-16.light-col-rtl {
		right: 66.66666667%;
		left: auto;
	}

	.light-col-xxl-pull-16.light-col-rtl {
		right: auto;
		left: 66.66666667%;
	}

	.light-col-xxl-offset-16.light-col-rtl {
		margin-right: 66.66666667%;
		margin-left: 0;
	}

	.light-col-xxl-push-17.light-col-rtl {
		right: 70.83333333%;
		left: auto;
	}

	.light-col-xxl-pull-17.light-col-rtl {
		right: auto;
		left: 70.83333333%;
	}

	.light-col-xxl-offset-17.light-col-rtl {
		margin-right: 70.83333333%;
		margin-left: 0;
	}

	.light-col-xxl-push-18.light-col-rtl {
		right: 75%;
		left: auto;
	}

	.light-col-xxl-pull-18.light-col-rtl {
		right: auto;
		left: 75%;
	}

	.light-col-xxl-offset-18.light-col-rtl {
		margin-right: 75%;
		margin-left: 0;
	}

	.light-col-xxl-push-19.light-col-rtl {
		right: 79.16666667%;
		left: auto;
	}

	.light-col-xxl-pull-19.light-col-rtl {
		right: auto;
		left: 79.16666667%;
	}

	.light-col-xxl-offset-19.light-col-rtl {
		margin-right: 79.16666667%;
		margin-left: 0;
	}

	.light-col-xxl-push-20.light-col-rtl {
		right: 83.33333333%;
		left: auto;
	}

	.light-col-xxl-pull-20.light-col-rtl {
		right: auto;
		left: 83.33333333%;
	}

	.light-col-xxl-offset-20.light-col-rtl {
		margin-right: 83.33333333%;
		margin-left: 0;
	}

	.light-col-xxl-push-21.light-col-rtl {
		right: 87.5%;
		left: auto;
	}

	.light-col-xxl-pull-21.light-col-rtl {
		right: auto;
		left: 87.5%;
	}

	.light-col-xxl-offset-21.light-col-rtl {
		margin-right: 87.5%;
		margin-left: 0;
	}

	.light-col-xxl-push-22.light-col-rtl {
		right: 91.66666667%;
		left: auto;
	}

	.light-col-xxl-pull-22.light-col-rtl {
		right: auto;
		left: 91.66666667%;
	}

	.light-col-xxl-offset-22.light-col-rtl {
		margin-right: 91.66666667%;
		margin-left: 0;
	}

	.light-col-xxl-push-23.light-col-rtl {
		right: 95.83333333%;
		left: auto;
	}

	.light-col-xxl-pull-23.light-col-rtl {
		right: auto;
		left: 95.83333333%;
	}

	.light-col-xxl-offset-23.light-col-rtl {
		margin-right: 95.83333333%;
		margin-left: 0;
	}

	.light-col-xxl-push-24.light-col-rtl {
		right: 100%;
		left: auto;
	}

	.light-col-xxl-pull-24.light-col-rtl {
		right: auto;
		left: 100%;
	}

	.light-col-xxl-offset-24.light-col-rtl {
		margin-right: 100%;
		margin-left: 0;
	}
}

.light-row-rtl {
	direction: rtl;
}

.light-image {
	position: relative;
	display: inline-block;
}

.light-image-img {
	width: 100%;
	height: auto;
	vertical-align: middle;
}

.light-image-img-placeholder {
	background-color: #f5f5f5;
	background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=");
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 30%;
}

.light-image-mask {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	background: rgba(0, 0, 0, 0.5);
	cursor: pointer;
	opacity: 0;
	transition: opacity 0.3s;
}

.light-image-mask-info {
	padding: 0 4px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.light-image-mask-info .anticon {
	margin-inline-end: 4px;
}

.light-image-mask:hover {
	opacity: 1;
}

.light-image-placeholder {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.light-image-preview {
	pointer-events: none;
	height: 100%;
	text-align: center;
}

.light-image-preview.light-zoom-enter,
.light-image-preview.light-zoom-appear {
	transform: none;
	opacity: 0;
	animation-duration: 0.3s;
	user-select: none;
}

.light-image-preview-mask {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.45);
}

.light-image-preview-mask-hidden {
	display: none;
}

.light-image-preview-wrap {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	outline: 0;
}

.light-image-preview-body {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: hidden;
}

.light-image-preview-img {
	max-width: 100%;
	max-height: 100%;
	vertical-align: middle;
	transform: scale3d(1, 1, 1);
	cursor: grab;
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	user-select: none;
	pointer-events: auto;
}

.light-image-preview-img-wrapper {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.light-image-preview-img-wrapper::before {
	display: inline-block;
	width: 1px;
	height: 50%;
	margin-right: -1px;
	content: "";
}

.light-image-preview-moving .light-image-preview-img {
	cursor: grabbing;
}

.light-image-preview-moving .light-image-preview-img-wrapper {
	transition-duration: 0s;
}

.light-image-preview-wrap {
	z-index: 1080;
}

.light-image-preview-operations {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	font-feature-settings: "tnum";
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	width: 100%;
	color: rgba(255, 255, 255, 0.85);
	list-style: none;
	background: rgba(0, 0, 0, 0.1);
	pointer-events: auto;
}

.light-image-preview-operations-operation {
	margin-left: 12px;
	padding: 12px;
	cursor: pointer;
}

.light-image-preview-operations-operation-disabled {
	color: rgba(255, 255, 255, 0.25);
	pointer-events: none;
}

.light-image-preview-operations-operation:last-of-type {
	margin-left: 0;
}

.light-image-preview-operations-progress {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

.light-image-preview-operations-icon {
	font-size: 18px;
}

.light-image-preview-switch-left,
.light-image-preview-switch-right {
	position: absolute;
	top: 50%;
	right: 10px;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 44px;
	height: 44px;
	margin-top: -22px;
	color: rgba(255, 255, 255, 0.85);
	background: rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	cursor: pointer;
	pointer-events: auto;
}

.light-image-preview-switch-left-disabled,
.light-image-preview-switch-right-disabled {
	color: rgba(255, 255, 255, 0.25);
	cursor: not-allowed;
}

.light-image-preview-switch-left-disabled>.anticon,
.light-image-preview-switch-right-disabled>.anticon {
	cursor: not-allowed;
}

.light-image-preview-switch-left>.anticon,
.light-image-preview-switch-right>.anticon {
	font-size: 18px;
}

.light-image-preview-switch-left {
	left: 10px;
}

.light-image-preview-switch-right {
	right: 10px;
}

.light-input-affix-wrapper {
	position: relative;
	display: inline-block;
	width: 100%;
	min-width: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
	display: inline-flex;
}

.light-input-affix-wrapper::placeholder {
	color: #bfbfbf;
	user-select: none;
}

.light-input-affix-wrapper:placeholder-shown {
	text-overflow: ellipsis;
}

.light-input-affix-wrapper:hover {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
}

.light-input-rtl .light-input-affix-wrapper:hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-input-affix-wrapper:focus,
.light-input-affix-wrapper-focused {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-rtl .light-input-affix-wrapper:focus,
.light-input-rtl .light-input-affix-wrapper-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-input-affix-wrapper-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-input-affix-wrapper-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-input-affix-wrapper[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-input-affix-wrapper[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-input-affix-wrapper-borderless,
.light-input-affix-wrapper-borderless:hover,
.light-input-affix-wrapper-borderless:focus,
.light-input-affix-wrapper-borderless-focused,
.light-input-affix-wrapper-borderless-disabled,
.light-input-affix-wrapper-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

textarea.light-input-affix-wrapper {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5715;
	vertical-align: bottom;
	transition: all 0.3s, height 0s;
}

.light-input-affix-wrapper-lg {
	padding: 6.5px 11px;
	font-size: 16px;
}

.light-input-affix-wrapper-sm {
	padding: 0px 7px;
}

.light-input-affix-wrapper-rtl {
	direction: rtl;
}

.light-input-affix-wrapper:not(.light-input-affix-wrapper-disabled):hover {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
	z-index: 1;
}

.light-input-rtl .light-input-affix-wrapper:not(.light-input-affix-wrapper-disabled):hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-input-search-with-button .light-input-affix-wrapper:not(.light-input-affix-wrapper-disabled):hover {
	z-index: 0;
}

.light-input-affix-wrapper-focused,
.light-input-affix-wrapper:focus {
	z-index: 1;
}

.light-input-affix-wrapper-disabled .light-input[disabled] {
	background: transparent;
}

.light-input-affix-wrapper>input.light-input {
	padding: 0;
	border: none;
	outline: none;
}

.light-input-affix-wrapper>input.light-input:focus {
	box-shadow: none !important;
}

.light-input-affix-wrapper::before {
	width: 0;
	visibility: hidden;
	content: "\a0";
}

.light-input-prefix,
.light-input-suffix {
	display: flex;
	flex: none;
	align-items: center;
}

.light-input-prefix>*:not(:last-child),
.light-input-suffix>*:not(:last-child) {
	margin-right: 8px;
}

.light-input-show-count-suffix {
	color: rgba(0, 0, 0, 0.45);
}

.light-input-show-count-has-suffix {
	margin-right: 2px;
}

.light-input-prefix {
	margin-right: 4px;
}

.light-input-suffix {
	margin-left: 4px;
}

.anticon.light-input-clear-icon,
.light-input-clear-icon {
	margin: 0;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	vertical-align: -1px;
	cursor: pointer;
	transition: color 0.3s;
}

.anticon.light-input-clear-icon:hover,
.light-input-clear-icon:hover {
	color: rgba(0, 0, 0, 0.45);
}

.anticon.light-input-clear-icon:active,
.light-input-clear-icon:active {
	color: rgba(0, 0, 0, 0.85);
}

.anticon.light-input-clear-icon-hidden,
.light-input-clear-icon-hidden {
	visibility: hidden;
}

.anticon.light-input-clear-icon-has-suffix,
.light-input-clear-icon-has-suffix {
	margin: 0 4px;
}

.light-input-affix-wrapper-textarea-with-clear-btn {
	padding: 0 !important;
	border: 0 !important;
}

.light-input-affix-wrapper-textarea-with-clear-btn .light-input-clear-icon {
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 1;
}

.light-input-status-error:not(.light-input-disabled):not(.light-input-borderless).light-input,
.light-input-status-error:not(.light-input-disabled):not(.light-input-borderless).light-input:hover {
	background: #fff;
	border-color: var(--light-error-color);
}

.light-input-status-error:not(.light-input-disabled):not(.light-input-borderless).light-input:focus,
.light-input-status-error:not(.light-input-disabled):not(.light-input-borderless).light-input-focused {
	border-color: var(--light-error-color-hover);
	box-shadow: 0 0 0 2px var(--light-error-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-status-error .light-input-prefix {
	color: var(--light-error-color);
}

.light-input-status-warning:not(.light-input-disabled):not(.light-input-borderless).light-input,
.light-input-status-warning:not(.light-input-disabled):not(.light-input-borderless).light-input:hover {
	background: #fff;
	border-color: var(--light-warning-color);
}

.light-input-status-warning:not(.light-input-disabled):not(.light-input-borderless).light-input:focus,
.light-input-status-warning:not(.light-input-disabled):not(.light-input-borderless).light-input-focused {
	border-color: var(--light-warning-color-hover);
	box-shadow: 0 0 0 2px var(--light-warning-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-status-warning .light-input-prefix {
	color: var(--light-warning-color);
}

.light-input-affix-wrapper-status-error:not(.light-input-affix-wrapper-disabled):not(.light-input-affix-wrapper-borderless).light-input-affix-wrapper,
.light-input-affix-wrapper-status-error:not(.light-input-affix-wrapper-disabled):not(.light-input-affix-wrapper-borderless).light-input-affix-wrapper:hover {
	background: #fff;
	border-color: var(--light-error-color);
}

.light-input-affix-wrapper-status-error:not(.light-input-affix-wrapper-disabled):not(.light-input-affix-wrapper-borderless).light-input-affix-wrapper:focus,
.light-input-affix-wrapper-status-error:not(.light-input-affix-wrapper-disabled):not(.light-input-affix-wrapper-borderless).light-input-affix-wrapper-focused {
	border-color: var(--light-error-color-hover);
	box-shadow: 0 0 0 2px var(--light-error-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-affix-wrapper-status-error .light-input-prefix {
	color: var(--light-error-color);
}

.light-input-affix-wrapper-status-warning:not(.light-input-affix-wrapper-disabled):not(.light-input-affix-wrapper-borderless).light-input-affix-wrapper,
.light-input-affix-wrapper-status-warning:not(.light-input-affix-wrapper-disabled):not(.light-input-affix-wrapper-borderless).light-input-affix-wrapper:hover {
	background: #fff;
	border-color: var(--light-warning-color);
}

.light-input-affix-wrapper-status-warning:not(.light-input-affix-wrapper-disabled):not(.light-input-affix-wrapper-borderless).light-input-affix-wrapper:focus,
.light-input-affix-wrapper-status-warning:not(.light-input-affix-wrapper-disabled):not(.light-input-affix-wrapper-borderless).light-input-affix-wrapper-focused {
	border-color: var(--light-warning-color-hover);
	box-shadow: 0 0 0 2px var(--light-warning-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-affix-wrapper-status-warning .light-input-prefix {
	color: var(--light-warning-color);
}

.light-input-textarea-status-error.light-input-textarea-has-feedback .light-input,
.light-input-textarea-status-warning.light-input-textarea-has-feedback .light-input,
.light-input-textarea-status-success.light-input-textarea-has-feedback .light-input,
.light-input-textarea-status-validating.light-input-textarea-has-feedback .light-input {
	padding-right: 24px;
}

.light-input-group-wrapper-status-error .light-input-group-addon {
	color: var(--light-error-color);
	border-color: var(--light-error-color);
}

.light-input-group-wrapper-status-warning .light-input-group-addon {
	color: var(--light-warning-color);
	border-color: var(--light-warning-color);
}

.light-input {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	width: 100%;
	min-width: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
}

.light-input::placeholder {
	color: #bfbfbf;
	user-select: none;
}

.light-input:placeholder-shown {
	text-overflow: ellipsis;
}

.light-input:hover {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
}

.light-input-rtl .light-input:hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-input:focus,
.light-input-focused {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-rtl .light-input:focus,
.light-input-rtl .light-input-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-input-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-input-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-input-borderless,
.light-input-borderless:hover,
.light-input-borderless:focus,
.light-input-borderless-focused,
.light-input-borderless-disabled,
.light-input-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

textarea.light-input {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5715;
	vertical-align: bottom;
	transition: all 0.3s, height 0s;
}

.light-input-lg {
	padding: 6.5px 11px;
	font-size: 16px;
}

.light-input-sm {
	padding: 0px 7px;
}

.light-input-rtl {
	direction: rtl;
}

.light-input-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: table;
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
}

.light-input-group[class*="col-"] {
	float: none;
	padding-right: 0;
	padding-left: 0;
}

.light-input-group>[class*="col-"] {
	padding-right: 8px;
}

.light-input-group>[class*="col-"]:last-child {
	padding-right: 0;
}

.light-input-group-addon,
.light-input-group-wrap,
.light-input-group>.light-input {
	display: table-cell;
}

.light-input-group-addon:not(:first-child):not(:last-child),
.light-input-group-wrap:not(:first-child):not(:last-child),
.light-input-group>.light-input:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.light-input-group-addon,
.light-input-group-wrap {
	width: 1px;
	white-space: nowrap;
	vertical-align: middle;
}

.light-input-group-wrap>* {
	display: block !important;
}

.light-input-group .light-input {
	float: left;
	width: 100%;
	margin-bottom: 0;
	text-align: inherit;
}

.light-input-group .light-input:focus {
	z-index: 1;
	border-right-width: 1px;
}

.light-input-group .light-input:hover {
	z-index: 1;
	border-right-width: 1px;
}

.light-input-search-with-button .light-input-group .light-input:hover {
	z-index: 0;
}

.light-input-group-addon {
	position: relative;
	padding: 0 11px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	font-size: 14px;
	text-align: center;
	background-color: #fafafa;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
}

.light-input-group-addon .light-select {
	margin: -5px -11px;
}

.light-input-group-addon .light-select.light-select-single:not(.light-select-customize-input) .light-select-selector {
	background-color: inherit;
	border: 1px solid transparent;
	box-shadow: none;
}

.light-input-group-addon .light-select-open .light-select-selector,
.light-input-group-addon .light-select-focused .light-select-selector {
	color: var(--light-primary-color);
}

.light-input-group-addon .light-cascader-picker {
	margin: -9px -12px;
	background-color: transparent;
}

.light-input-group-addon .light-cascader-picker .light-cascader-input {
	text-align: left;
	border: 0;
	box-shadow: none;
}

.light-input-group>.light-input:first-child,
.light-input-group-addon:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.light-input-group>.light-input:first-child .light-select .light-select-selector,
.light-input-group-addon:first-child .light-select .light-select-selector {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.light-input-group>.light-input-affix-wrapper:not(:first-child) .light-input {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.light-input-group>.light-input-affix-wrapper:not(:last-child) .light-input {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.light-input-group-addon:first-child {
	border-right: 0;
}

.light-input-group-addon:last-child {
	border-left: 0;
}

.light-input-group>.light-input:last-child,
.light-input-group-addon:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.light-input-group>.light-input:last-child .light-select .light-select-selector,
.light-input-group-addon:last-child .light-select .light-select-selector {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.light-input-group-lg .light-input,
.light-input-group-lg>.light-input-group-addon {
	padding: 6.5px 11px;
	font-size: 16px;
}

.light-input-group-sm .light-input,
.light-input-group-sm>.light-input-group-addon {
	padding: 0px 7px;
}

.light-input-group-lg .light-select-single .light-select-selector {
	height: 40px;
}

.light-input-group-sm .light-select-single .light-select-selector {
	height: 24px;
}

.light-input-group .light-input-affix-wrapper:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.light-input-search .light-input-group .light-input-affix-wrapper:not(:last-child) {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.light-input-group .light-input-affix-wrapper:not(:first-child),
.light-input-search .light-input-group .light-input-affix-wrapper:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.light-input-group.light-input-group-compact {
	display: block;
}

.light-input-group.light-input-group-compact::before {
	display: table;
	content: "";
}

.light-input-group.light-input-group-compact::after {
	display: table;
	clear: both;
	content: "";
}

.light-input-group.light-input-group-compact::before {
	display: table;
	content: "";
}

.light-input-group.light-input-group-compact::after {
	display: table;
	clear: both;
	content: "";
}

.light-input-group.light-input-group-compact-addon:not(:first-child):not(:last-child),
.light-input-group.light-input-group-compact-wrap:not(:first-child):not(:last-child),
.light-input-group.light-input-group-compact>.light-input:not(:first-child):not(:last-child) {
	border-right-width: 1px;
}

.light-input-group.light-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.light-input-group.light-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.light-input-group.light-input-group-compact>.light-input:not(:first-child):not(:last-child):hover {
	z-index: 1;
}

.light-input-group.light-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.light-input-group.light-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.light-input-group.light-input-group-compact>.light-input:not(:first-child):not(:last-child):focus {
	z-index: 1;
}

.light-input-group.light-input-group-compact>* {
	display: inline-block;
	float: none;
	vertical-align: top;
	border-radius: 0;
}

.light-input-group.light-input-group-compact>.light-input-affix-wrapper {
	display: inline-flex;
}

.light-input-group.light-input-group-compact>.light-picker-range {
	display: inline-flex;
}

.light-input-group.light-input-group-compact>*:not(:last-child) {
	margin-right: -1px;
	border-right-width: 1px;
}

.light-input-group.light-input-group-compact .light-input {
	float: none;
}

.light-input-group.light-input-group-compact>.light-select>.light-select-selector,
.light-input-group.light-input-group-compact>.light-select-auto-complete .light-input,
.light-input-group.light-input-group-compact>.light-cascader-picker .light-input,
.light-input-group.light-input-group-compact>.light-input-group-wrapper .light-input {
	border-right-width: 1px;
	border-radius: 0;
}

.light-input-group.light-input-group-compact>.light-select>.light-select-selector:hover,
.light-input-group.light-input-group-compact>.light-select-auto-complete .light-input:hover,
.light-input-group.light-input-group-compact>.light-cascader-picker .light-input:hover,
.light-input-group.light-input-group-compact>.light-input-group-wrapper .light-input:hover {
	z-index: 1;
}

.light-input-group.light-input-group-compact>.light-select>.light-select-selector:focus,
.light-input-group.light-input-group-compact>.light-select-auto-complete .light-input:focus,
.light-input-group.light-input-group-compact>.light-cascader-picker .light-input:focus,
.light-input-group.light-input-group-compact>.light-input-group-wrapper .light-input:focus {
	z-index: 1;
}

.light-input-group.light-input-group-compact>.light-select-focused {
	z-index: 1;
}

.light-input-group.light-input-group-compact>.light-select>.light-select-arrow {
	z-index: 1;
}

.light-input-group.light-input-group-compact>*:first-child,
.light-input-group.light-input-group-compact>.light-select:first-child>.light-select-selector,
.light-input-group.light-input-group-compact>.light-select-auto-complete:first-child .light-input,
.light-input-group.light-input-group-compact>.light-cascader-picker:first-child .light-input {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.light-input-group.light-input-group-compact>*:last-child,
.light-input-group.light-input-group-compact>.light-select:last-child>.light-select-selector,
.light-input-group.light-input-group-compact>.light-cascader-picker:last-child .light-input,
.light-input-group.light-input-group-compact>.light-cascader-picker-focused:last-child .light-input {
	border-right-width: 1px;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.light-input-group.light-input-group-compact>.light-select-auto-complete .light-input {
	vertical-align: top;
}

.light-input-group.light-input-group-compact .light-input-group-wrapper+.light-input-group-wrapper {
	margin-left: -1px;
}

.light-input-group.light-input-group-compact .light-input-group-wrapper+.light-input-group-wrapper .light-input-affix-wrapper {
	border-radius: 0;
}

.light-input-group.light-input-group-compact .light-input-group-wrapper:not(:last-child).light-input-search>.light-input-group>.light-input-group-addon>.light-input-search-button {
	border-radius: 0;
}

.light-input-group.light-input-group-compact .light-input-group-wrapper:not(:last-child).light-input-search>.light-input-group>.light-input {
	border-radius: 2px 0 0 2px;
}

.light-input-group>.light-input-rtl:first-child,
.light-input-group-rtl .light-input-group-addon:first-child {
	border-radius: 0 2px 2px 0;
}

.light-input-group-rtl .light-input-group-addon:first-child {
	border-right: 1px solid #d9d9d9;
	border-left: 0;
}

.light-input-group-rtl .light-input-group-addon:last-child {
	border-right: 0;
	border-left: 1px solid #d9d9d9;
	border-radius: 2px 0 0 2px;
}

.light-input-group-rtl.light-input-group>.light-input:last-child,
.light-input-group-rtl.light-input-group-addon:last-child {
	border-radius: 2px 0 0 2px;
}

.light-input-group-rtl.light-input-group .light-input-affix-wrapper:not(:first-child) {
	border-radius: 2px 0 0 2px;
}

.light-input-group-rtl.light-input-group .light-input-affix-wrapper:not(:last-child) {
	border-radius: 0 2px 2px 0;
}

.light-input-group-rtl.light-input-group.light-input-group-compact>*:not(:last-child) {
	margin-right: 0;
	margin-left: -1px;
	border-left-width: 1px;
}

.light-input-group-rtl.light-input-group.light-input-group-compact>*:first-child,
.light-input-group-rtl.light-input-group.light-input-group-compact>.light-select:first-child>.light-select-selector,
.light-input-group-rtl.light-input-group.light-input-group-compact>.light-select-auto-complete:first-child .light-input,
.light-input-group-rtl.light-input-group.light-input-group-compact>.light-cascader-picker:first-child .light-input {
	border-radius: 0 2px 2px 0;
}

.light-input-group-rtl.light-input-group.light-input-group-compact>*:last-child,
.light-input-group-rtl.light-input-group.light-input-group-compact>.light-select:last-child>.light-select-selector,
.light-input-group-rtl.light-input-group.light-input-group-compact>.light-select-auto-complete:last-child .light-input,
.light-input-group-rtl.light-input-group.light-input-group-compact>.light-cascader-picker:last-child .light-input,
.light-input-group-rtl.light-input-group.light-input-group-compact>.light-cascader-picker-focused:last-child .light-input {
	border-left-width: 1px;
	border-radius: 2px 0 0 2px;
}

.light-input-group.light-input-group-compact .light-input-group-wrapper-rtl+.light-input-group-wrapper-rtl {
	margin-right: -1px;
	margin-left: 0;
}

.light-input-group.light-input-group-compact .light-input-group-wrapper-rtl:not(:last-child).light-input-search>.light-input-group>.light-input {
	border-radius: 0 2px 2px 0;
}

.light-input-group>.light-input-rtl:first-child {
	border-radius: 0 2px 2px 0;
}

.light-input-group>.light-input-rtl:last-child {
	border-radius: 2px 0 0 2px;
}

.light-input-group-rtl .light-input-group-addon:first-child {
	border-right: 1px solid #d9d9d9;
	border-left: 0;
	border-radius: 0 2px 2px 0;
}

.light-input-group-rtl .light-input-group-addon:last-child {
	border-right: 0;
	border-left: 1px solid #d9d9d9;
	border-radius: 2px 0 0 2px;
}

.light-input-group-wrapper {
	display: inline-block;
	width: 100%;
	text-align: start;
	vertical-align: top;
}

.light-input-password-icon.anticon {
	color: rgba(0, 0, 0, 0.45);
	cursor: pointer;
	transition: all 0.3s;
}

.light-input-password-icon.anticon:hover {
	color: rgba(0, 0, 0, 0.85);
}

.light-input[type="color"] {
	height: 32px;
}

.light-input[type="color"].light-input-lg {
	height: 40px;
}

.light-input[type="color"].light-input-sm {
	height: 24px;
	padding-top: 3px;
	padding-bottom: 3px;
}

.light-input-textarea-show-count>.light-input {
	height: 100%;
}

.light-input-textarea-show-count::after {
	float: right;
	color: rgba(0, 0, 0, 0.45);
	white-space: nowrap;
	content: attr(data-count);
	pointer-events: none;
}

.light-input-textarea-show-count.light-input-textarea-in-form-item::after {
	margin-bottom: -22px;
}

.light-input-textarea-suffix {
	position: absolute;
	top: 0;
	right: 11px;
	bottom: 0;
	z-index: 1;
	display: inline-flex;
	align-items: center;
	margin: auto;
}

.light-input-search .light-input:hover,
.light-input-search .light-input:focus {
	border-color: var(--light-primary-5);
}

.light-input-search .light-input:hover+.light-input-group-addon .light-input-search-button:not(.light-btn-primary),
.light-input-search .light-input:focus+.light-input-group-addon .light-input-search-button:not(.light-btn-primary) {
	border-left-color: var(--light-primary-5);
}

.light-input-search .light-input-affix-wrapper {
	border-radius: 0;
}

.light-input-search .light-input-lg {
	line-height: 1.5713;
}

.light-input-search>.light-input-group>.light-input-group-addon:last-child {
	left: -1px;
	padding: 0;
	border: 0;
}

.light-input-search>.light-input-group>.light-input-group-addon:last-child .light-input-search-button {
	padding-top: 0;
	padding-bottom: 0;
	border-radius: 0 2px 2px 0;
}

.light-input-search>.light-input-group>.light-input-group-addon:last-child .light-input-search-button:not(.light-btn-primary) {
	color: rgba(0, 0, 0, 0.45);
}

.light-input-search>.light-input-group>.light-input-group-addon:last-child .light-input-search-button:not(.light-btn-primary).light-btn-loading::before {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.light-input-search-button {
	height: 32px;
}

.light-input-search-button:hover,
.light-input-search-button:focus {
	z-index: 1;
}

.light-input-search-large .light-input-search-button {
	height: 40px;
}

.light-input-search-small .light-input-search-button {
	height: 24px;
}

.light-input-group-wrapper-rtl {
	direction: rtl;
}

.light-input-group-rtl {
	direction: rtl;
}

.light-input-affix-wrapper.light-input-affix-wrapper-rtl>input.light-input {
	border: none;
	outline: none;
}

.light-input-affix-wrapper-rtl .light-input-prefix {
	margin: 0 0 0 4px;
}

.light-input-affix-wrapper-rtl .light-input-suffix {
	margin: 0 4px 0 0;
}

.light-input-textarea-rtl {
	direction: rtl;
}

.light-input-textarea-rtl.light-input-textarea-show-count::after {
	text-align: left;
}

.light-input-affix-wrapper-rtl .light-input-clear-icon-has-suffix {
	margin-right: 0;
	margin-left: 4px;
}

.light-input-affix-wrapper-rtl .light-input-clear-icon {
	right: auto;
	left: 8px;
}

.light-input-search-rtl {
	direction: rtl;
}

.light-input-search-rtl .light-input:hover+.light-input-group-addon .light-input-search-button:not(.light-btn-primary),
.light-input-search-rtl .light-input:focus+.light-input-group-addon .light-input-search-button:not(.light-btn-primary) {
	border-right-color: var(--light-primary-5);
	border-left-color: #d9d9d9;
}

.light-input-search-rtl>.light-input-group>.light-input-affix-wrapper:hover,
.light-input-search-rtl>.light-input-group>.light-input-affix-wrapper-focused {
	border-right-color: var(--light-primary-5);
}

.light-input-search-rtl>.light-input-group>.light-input-group-addon {
	right: -1px;
	left: auto;
}

.light-input-search-rtl>.light-input-group>.light-input-group-addon .light-input-search-button {
	border-radius: 2px 0 0 2px;
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
	.light-input {
		height: 32px;
	}

	.light-input-lg {
		height: 40px;
	}

	.light-input-sm {
		height: 24px;
	}

	.light-input-affix-wrapper>input.light-input {
		height: auto;
	}
}

.light-input-number-affix-wrapper {
	display: inline-block;
	width: 100%;
	min-width: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
	position: relative;
	display: inline-flex;
	width: 90px;
	padding: 0;
	padding-inline-start: 11px;
}

.light-input-number-affix-wrapper::placeholder {
	color: #bfbfbf;
	user-select: none;
}

.light-input-number-affix-wrapper:placeholder-shown {
	text-overflow: ellipsis;
}

.light-input-number-affix-wrapper:hover {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
}

.light-input-rtl .light-input-number-affix-wrapper:hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-input-number-affix-wrapper:focus,
.light-input-number-affix-wrapper-focused {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-rtl .light-input-number-affix-wrapper:focus,
.light-input-rtl .light-input-number-affix-wrapper-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-input-number-affix-wrapper-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-input-number-affix-wrapper-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-input-number-affix-wrapper[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-input-number-affix-wrapper[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-input-number-affix-wrapper-borderless,
.light-input-number-affix-wrapper-borderless:hover,
.light-input-number-affix-wrapper-borderless:focus,
.light-input-number-affix-wrapper-borderless-focused,
.light-input-number-affix-wrapper-borderless-disabled,
.light-input-number-affix-wrapper-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

textarea.light-input-number-affix-wrapper {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5715;
	vertical-align: bottom;
	transition: all 0.3s, height 0s;
}

.light-input-number-affix-wrapper-lg {
	padding: 6.5px 11px;
	font-size: 16px;
}

.light-input-number-affix-wrapper-sm {
	padding: 0px 7px;
}

.light-input-number-affix-wrapper-rtl {
	direction: rtl;
}

.light-input-number-affix-wrapper:not(.light-input-number-affix-wrapper-disabled):hover {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
	z-index: 1;
}

.light-input-rtl .light-input-number-affix-wrapper:not(.light-input-number-affix-wrapper-disabled):hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-input-number-affix-wrapper-focused,
.light-input-number-affix-wrapper:focus {
	z-index: 1;
}

.light-input-number-affix-wrapper-disabled .light-input-number[disabled] {
	background: transparent;
}

.light-input-number-affix-wrapper>div.light-input-number {
	width: 100%;
	border: none;
	outline: none;
}

.light-input-number-affix-wrapper>div.light-input-number.light-input-number-focused {
	box-shadow: none !important;
}

.light-input-number-affix-wrapper input.light-input-number-input {
	padding: 0;
}

.light-input-number-affix-wrapper::before {
	width: 0;
	visibility: hidden;
	content: "\a0";
}

.light-input-number-affix-wrapper .light-input-number-handler-wrap {
	z-index: 2;
}

.light-input-number-prefix,
.light-input-number-suffix {
	display: flex;
	flex: none;
	align-items: center;
	pointer-events: none;
}

.light-input-number-prefix {
	margin-inline-end: 4px;
}

.light-input-number-suffix {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
	height: 100%;
	margin-right: 11px;
	margin-left: 4px;
}

.light-input-number-group-wrapper .light-input-number-affix-wrapper {
	width: 100%;
}

.light-input-number-status-error:not(.light-input-number-disabled):not(.light-input-number-borderless).light-input-number,
.light-input-number-status-error:not(.light-input-number-disabled):not(.light-input-number-borderless).light-input-number:hover {
	background: #fff;
	border-color: var(--light-error-color);
}

.light-input-number-status-error:not(.light-input-number-disabled):not(.light-input-number-borderless).light-input-number:focus,
.light-input-number-status-error:not(.light-input-number-disabled):not(.light-input-number-borderless).light-input-number-focused {
	border-color: var(--light-error-color-hover);
	box-shadow: 0 0 0 2px var(--light-error-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-number-status-error .light-input-number-prefix {
	color: var(--light-error-color);
}

.light-input-number-status-warning:not(.light-input-number-disabled):not(.light-input-number-borderless).light-input-number,
.light-input-number-status-warning:not(.light-input-number-disabled):not(.light-input-number-borderless).light-input-number:hover {
	background: #fff;
	border-color: var(--light-warning-color);
}

.light-input-number-status-warning:not(.light-input-number-disabled):not(.light-input-number-borderless).light-input-number:focus,
.light-input-number-status-warning:not(.light-input-number-disabled):not(.light-input-number-borderless).light-input-number-focused {
	border-color: var(--light-warning-color-hover);
	box-shadow: 0 0 0 2px var(--light-warning-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-number-status-warning .light-input-number-prefix {
	color: var(--light-warning-color);
}

.light-input-number-affix-wrapper-status-error:not(.light-input-number-affix-wrapper-disabled):not(.light-input-number-affix-wrapper-borderless).light-input-number-affix-wrapper,
.light-input-number-affix-wrapper-status-error:not(.light-input-number-affix-wrapper-disabled):not(.light-input-number-affix-wrapper-borderless).light-input-number-affix-wrapper:hover {
	background: #fff;
	border-color: var(--light-error-color);
}

.light-input-number-affix-wrapper-status-error:not(.light-input-number-affix-wrapper-disabled):not(.light-input-number-affix-wrapper-borderless).light-input-number-affix-wrapper:focus,
.light-input-number-affix-wrapper-status-error:not(.light-input-number-affix-wrapper-disabled):not(.light-input-number-affix-wrapper-borderless).light-input-number-affix-wrapper-focused {
	border-color: var(--light-error-color-hover);
	box-shadow: 0 0 0 2px var(--light-error-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-number-affix-wrapper-status-error .light-input-number-prefix {
	color: var(--light-error-color);
}

.light-input-number-affix-wrapper-status-warning:not(.light-input-number-affix-wrapper-disabled):not(.light-input-number-affix-wrapper-borderless).light-input-number-affix-wrapper,
.light-input-number-affix-wrapper-status-warning:not(.light-input-number-affix-wrapper-disabled):not(.light-input-number-affix-wrapper-borderless).light-input-number-affix-wrapper:hover {
	background: #fff;
	border-color: var(--light-warning-color);
}

.light-input-number-affix-wrapper-status-warning:not(.light-input-number-affix-wrapper-disabled):not(.light-input-number-affix-wrapper-borderless).light-input-number-affix-wrapper:focus,
.light-input-number-affix-wrapper-status-warning:not(.light-input-number-affix-wrapper-disabled):not(.light-input-number-affix-wrapper-borderless).light-input-number-affix-wrapper-focused {
	border-color: var(--light-warning-color-hover);
	box-shadow: 0 0 0 2px var(--light-warning-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-number-affix-wrapper-status-warning .light-input-number-prefix {
	color: var(--light-warning-color);
}

.light-input-number-group-wrapper-status-error .light-input-number-group-addon {
	color: var(--light-error-color);
	border-color: var(--light-error-color);
}

.light-input-number-group-wrapper-status-warning .light-input-number-group-addon {
	color: var(--light-warning-color);
	border-color: var(--light-warning-color);
}

.light-input-number {
	box-sizing: border-box;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	width: 100%;
	min-width: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff;
	background-image: none;
	transition: all 0.3s;
	display: inline-block;
	width: 90px;
	margin: 0;
	padding: 0;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
}

.light-input-number::placeholder {
	color: #bfbfbf;
	user-select: none;
}

.light-input-number:placeholder-shown {
	text-overflow: ellipsis;
}

.light-input-number:hover {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
}

.light-input-rtl .light-input-number:hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-input-number:focus,
.light-input-number-focused {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-rtl .light-input-number:focus,
.light-input-rtl .light-input-number-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-input-number-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-input-number-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-input-number[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-input-number[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-input-number-borderless,
.light-input-number-borderless:hover,
.light-input-number-borderless:focus,
.light-input-number-borderless-focused,
.light-input-number-borderless-disabled,
.light-input-number-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

textarea.light-input-number {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5715;
	vertical-align: bottom;
	transition: all 0.3s, height 0s;
}

.light-input-number-lg {
	padding: 6.5px 11px;
	font-size: 16px;
}

.light-input-number-sm {
	padding: 0px 7px;
}

.light-input-number-rtl {
	direction: rtl;
}

.light-input-number-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: table;
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
}

.light-input-number-group[class*="col-"] {
	float: none;
	padding-right: 0;
	padding-left: 0;
}

.light-input-number-group>[class*="col-"] {
	padding-right: 8px;
}

.light-input-number-group>[class*="col-"]:last-child {
	padding-right: 0;
}

.light-input-number-group-addon,
.light-input-number-group-wrap,
.light-input-number-group>.light-input-number {
	display: table-cell;
}

.light-input-number-group-addon:not(:first-child):not(:last-child),
.light-input-number-group-wrap:not(:first-child):not(:last-child),
.light-input-number-group>.light-input-number:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.light-input-number-group-addon,
.light-input-number-group-wrap {
	width: 1px;
	white-space: nowrap;
	vertical-align: middle;
}

.light-input-number-group-wrap>* {
	display: block !important;
}

.light-input-number-group .light-input-number {
	float: left;
	width: 100%;
	margin-bottom: 0;
	text-align: inherit;
}

.light-input-number-group .light-input-number:focus {
	z-index: 1;
	border-right-width: 1px;
}

.light-input-number-group .light-input-number:hover {
	z-index: 1;
	border-right-width: 1px;
}

.light-input-search-with-button .light-input-number-group .light-input-number:hover {
	z-index: 0;
}

.light-input-number-group-addon {
	position: relative;
	padding: 0 11px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	font-size: 14px;
	text-align: center;
	background-color: #fafafa;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
}

.light-input-number-group-addon .light-select {
	margin: -5px -11px;
}

.light-input-number-group-addon .light-select.light-select-single:not(.light-select-customize-input) .light-select-selector {
	background-color: inherit;
	border: 1px solid transparent;
	box-shadow: none;
}

.light-input-number-group-addon .light-select-open .light-select-selector,
.light-input-number-group-addon .light-select-focused .light-select-selector {
	color: var(--light-primary-color);
}

.light-input-number-group-addon .light-cascader-picker {
	margin: -9px -12px;
	background-color: transparent;
}

.light-input-number-group-addon .light-cascader-picker .light-cascader-input {
	text-align: left;
	border: 0;
	box-shadow: none;
}

.light-input-number-group>.light-input-number:first-child,
.light-input-number-group-addon:first-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.light-input-number-group>.light-input-number:first-child .light-select .light-select-selector,
.light-input-number-group-addon:first-child .light-select .light-select-selector {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.light-input-number-group>.light-input-number-affix-wrapper:not(:first-child) .light-input-number {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.light-input-number-group>.light-input-number-affix-wrapper:not(:last-child) .light-input-number {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.light-input-number-group-addon:first-child {
	border-right: 0;
}

.light-input-number-group-addon:last-child {
	border-left: 0;
}

.light-input-number-group>.light-input-number:last-child,
.light-input-number-group-addon:last-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.light-input-number-group>.light-input-number:last-child .light-select .light-select-selector,
.light-input-number-group-addon:last-child .light-select .light-select-selector {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.light-input-number-group-lg .light-input-number,
.light-input-number-group-lg>.light-input-number-group-addon {
	padding: 6.5px 11px;
	font-size: 16px;
}

.light-input-number-group-sm .light-input-number,
.light-input-number-group-sm>.light-input-number-group-addon {
	padding: 0px 7px;
}

.light-input-number-group-lg .light-select-single .light-select-selector {
	height: 40px;
}

.light-input-number-group-sm .light-select-single .light-select-selector {
	height: 24px;
}

.light-input-number-group .light-input-number-affix-wrapper:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.light-input-search .light-input-number-group .light-input-number-affix-wrapper:not(:last-child) {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.light-input-number-group .light-input-number-affix-wrapper:not(:first-child),
.light-input-search .light-input-number-group .light-input-number-affix-wrapper:not(:first-child) {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.light-input-number-group.light-input-number-group-compact {
	display: block;
}

.light-input-number-group.light-input-number-group-compact::before {
	display: table;
	content: "";
}

.light-input-number-group.light-input-number-group-compact::after {
	display: table;
	clear: both;
	content: "";
}

.light-input-number-group.light-input-number-group-compact::before {
	display: table;
	content: "";
}

.light-input-number-group.light-input-number-group-compact::after {
	display: table;
	clear: both;
	content: "";
}

.light-input-number-group.light-input-number-group-compact-addon:not(:first-child):not(:last-child),
.light-input-number-group.light-input-number-group-compact-wrap:not(:first-child):not(:last-child),
.light-input-number-group.light-input-number-group-compact>.light-input-number:not(:first-child):not(:last-child) {
	border-right-width: 1px;
}

.light-input-number-group.light-input-number-group-compact-addon:not(:first-child):not(:last-child):hover,
.light-input-number-group.light-input-number-group-compact-wrap:not(:first-child):not(:last-child):hover,
.light-input-number-group.light-input-number-group-compact>.light-input-number:not(:first-child):not(:last-child):hover {
	z-index: 1;
}

.light-input-number-group.light-input-number-group-compact-addon:not(:first-child):not(:last-child):focus,
.light-input-number-group.light-input-number-group-compact-wrap:not(:first-child):not(:last-child):focus,
.light-input-number-group.light-input-number-group-compact>.light-input-number:not(:first-child):not(:last-child):focus {
	z-index: 1;
}

.light-input-number-group.light-input-number-group-compact>* {
	display: inline-block;
	float: none;
	vertical-align: top;
	border-radius: 0;
}

.light-input-number-group.light-input-number-group-compact>.light-input-number-affix-wrapper {
	display: inline-flex;
}

.light-input-number-group.light-input-number-group-compact>.light-picker-range {
	display: inline-flex;
}

.light-input-number-group.light-input-number-group-compact>*:not(:last-child) {
	margin-right: -1px;
	border-right-width: 1px;
}

.light-input-number-group.light-input-number-group-compact .light-input-number {
	float: none;
}

.light-input-number-group.light-input-number-group-compact>.light-select>.light-select-selector,
.light-input-number-group.light-input-number-group-compact>.light-select-auto-complete .light-input,
.light-input-number-group.light-input-number-group-compact>.light-cascader-picker .light-input,
.light-input-number-group.light-input-number-group-compact>.light-input-group-wrapper .light-input {
	border-right-width: 1px;
	border-radius: 0;
}

.light-input-number-group.light-input-number-group-compact>.light-select>.light-select-selector:hover,
.light-input-number-group.light-input-number-group-compact>.light-select-auto-complete .light-input:hover,
.light-input-number-group.light-input-number-group-compact>.light-cascader-picker .light-input:hover,
.light-input-number-group.light-input-number-group-compact>.light-input-group-wrapper .light-input:hover {
	z-index: 1;
}

.light-input-number-group.light-input-number-group-compact>.light-select>.light-select-selector:focus,
.light-input-number-group.light-input-number-group-compact>.light-select-auto-complete .light-input:focus,
.light-input-number-group.light-input-number-group-compact>.light-cascader-picker .light-input:focus,
.light-input-number-group.light-input-number-group-compact>.light-input-group-wrapper .light-input:focus {
	z-index: 1;
}

.light-input-number-group.light-input-number-group-compact>.light-select-focused {
	z-index: 1;
}

.light-input-number-group.light-input-number-group-compact>.light-select>.light-select-arrow {
	z-index: 1;
}

.light-input-number-group.light-input-number-group-compact>*:first-child,
.light-input-number-group.light-input-number-group-compact>.light-select:first-child>.light-select-selector,
.light-input-number-group.light-input-number-group-compact>.light-select-auto-complete:first-child .light-input,
.light-input-number-group.light-input-number-group-compact>.light-cascader-picker:first-child .light-input {
	border-top-left-radius: 2px;
	border-bottom-left-radius: 2px;
}

.light-input-number-group.light-input-number-group-compact>*:last-child,
.light-input-number-group.light-input-number-group-compact>.light-select:last-child>.light-select-selector,
.light-input-number-group.light-input-number-group-compact>.light-cascader-picker:last-child .light-input,
.light-input-number-group.light-input-number-group-compact>.light-cascader-picker-focused:last-child .light-input {
	border-right-width: 1px;
	border-top-right-radius: 2px;
	border-bottom-right-radius: 2px;
}

.light-input-number-group.light-input-number-group-compact>.light-select-auto-complete .light-input {
	vertical-align: top;
}

.light-input-number-group.light-input-number-group-compact .light-input-group-wrapper+.light-input-group-wrapper {
	margin-left: -1px;
}

.light-input-number-group.light-input-number-group-compact .light-input-group-wrapper+.light-input-group-wrapper .light-input-affix-wrapper {
	border-radius: 0;
}

.light-input-number-group.light-input-number-group-compact .light-input-group-wrapper:not(:last-child).light-input-search>.light-input-group>.light-input-group-addon>.light-input-search-button {
	border-radius: 0;
}

.light-input-number-group.light-input-number-group-compact .light-input-group-wrapper:not(:last-child).light-input-search>.light-input-group>.light-input {
	border-radius: 2px 0 0 2px;
}

.light-input-number-group>.light-input-number-rtl:first-child,
.light-input-number-group-rtl .light-input-number-group-addon:first-child {
	border-radius: 0 2px 2px 0;
}

.light-input-number-group-rtl .light-input-number-group-addon:first-child {
	border-right: 1px solid #d9d9d9;
	border-left: 0;
}

.light-input-number-group-rtl .light-input-number-group-addon:last-child {
	border-right: 0;
	border-left: 1px solid #d9d9d9;
	border-radius: 2px 0 0 2px;
}

.light-input-number-group-rtl.light-input-number-group>.light-input-number:last-child,
.light-input-number-group-rtl.light-input-number-group-addon:last-child {
	border-radius: 2px 0 0 2px;
}

.light-input-number-group-rtl.light-input-number-group .light-input-number-affix-wrapper:not(:first-child) {
	border-radius: 2px 0 0 2px;
}

.light-input-number-group-rtl.light-input-number-group .light-input-number-affix-wrapper:not(:last-child) {
	border-radius: 0 2px 2px 0;
}

.light-input-number-group-rtl.light-input-number-group.light-input-number-group-compact>*:not(:last-child) {
	margin-right: 0;
	margin-left: -1px;
	border-left-width: 1px;
}

.light-input-number-group-rtl.light-input-number-group.light-input-number-group-compact>*:first-child,
.light-input-number-group-rtl.light-input-number-group.light-input-number-group-compact>.light-select:first-child>.light-select-selector,
.light-input-number-group-rtl.light-input-number-group.light-input-number-group-compact>.light-select-auto-complete:first-child .light-input,
.light-input-number-group-rtl.light-input-number-group.light-input-number-group-compact>.light-cascader-picker:first-child .light-input {
	border-radius: 0 2px 2px 0;
}

.light-input-number-group-rtl.light-input-number-group.light-input-number-group-compact>*:last-child,
.light-input-number-group-rtl.light-input-number-group.light-input-number-group-compact>.light-select:last-child>.light-select-selector,
.light-input-number-group-rtl.light-input-number-group.light-input-number-group-compact>.light-select-auto-complete:last-child .light-input,
.light-input-number-group-rtl.light-input-number-group.light-input-number-group-compact>.light-cascader-picker:last-child .light-input,
.light-input-number-group-rtl.light-input-number-group.light-input-number-group-compact>.light-cascader-picker-focused:last-child .light-input {
	border-left-width: 1px;
	border-radius: 2px 0 0 2px;
}

.light-input-number-group.light-input-number-group-compact .light-input-group-wrapper-rtl+.light-input-group-wrapper-rtl {
	margin-right: -1px;
	margin-left: 0;
}

.light-input-number-group.light-input-number-group-compact .light-input-group-wrapper-rtl:not(:last-child).light-input-search>.light-input-group>.light-input {
	border-radius: 0 2px 2px 0;
}

.light-input-number-group>.light-input-number-rtl:first-child {
	border-radius: 0 2px 2px 0;
}

.light-input-number-group>.light-input-number-rtl:last-child {
	border-radius: 2px 0 0 2px;
}

.light-input-number-group-rtl .light-input-number-group-addon:first-child {
	border-right: 1px solid #d9d9d9;
	border-left: 0;
	border-radius: 0 2px 2px 0;
}

.light-input-number-group-rtl .light-input-number-group-addon:last-child {
	border-right: 0;
	border-left: 1px solid #d9d9d9;
	border-radius: 2px 0 0 2px;
}

.light-input-number-group-wrapper {
	display: inline-block;
	text-align: start;
	vertical-align: top;
}

.light-input-number-handler {
	position: relative;
	display: block;
	width: 100%;
	height: 50%;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.45);
	font-weight: bold;
	line-height: 0;
	text-align: center;
	border-left: 1px solid #d9d9d9;
	transition: all 0.1s linear;
}

.light-input-number-handler:active {
	background: #f4f4f4;
}

.light-input-number-handler:hover .light-input-number-handler-up-inner,
.light-input-number-handler:hover .light-input-number-handler-down-inner {
	color: var(--light-primary-5);
}

.light-input-number-handler-up-inner,
.light-input-number-handler-down-inner {
	display: inline-block;
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	right: 4px;
	width: 12px;
	height: 12px;
	color: rgba(0, 0, 0, 0.45);
	line-height: 12px;
	transition: all 0.1s linear;
	user-select: none;
}

.light-input-number-handler-up-inner>*,
.light-input-number-handler-down-inner>* {
	line-height: 1;
}

.light-input-number-handler-up-inner svg,
.light-input-number-handler-down-inner svg {
	display: inline-block;
}

.light-input-number-handler-up-inner::before,
.light-input-number-handler-down-inner::before {
	display: none;
}

.light-input-number-handler-up-inner .light-input-number-handler-up-inner-icon,
.light-input-number-handler-up-inner .light-input-number-handler-down-inner-icon,
.light-input-number-handler-down-inner .light-input-number-handler-up-inner-icon,
.light-input-number-handler-down-inner .light-input-number-handler-down-inner-icon {
	display: block;
}

.light-input-number:hover {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
}

.light-input-number:hover+.light-form-item-children-icon {
	opacity: 0;
	transition: opacity 0.24s linear 0.24s;
}

.light-input-number-focused {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-rtl .light-input-number-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-input-number-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-input-number-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-input-number-disabled .light-input-number-input {
	cursor: not-allowed;
}

.light-input-number-disabled .light-input-number-handler-wrap {
	display: none;
}

.light-input-number-readonly .light-input-number-handler-wrap {
	display: none;
}

.light-input-number-input {
	width: 100%;
	height: 30px;
	padding: 0 11px;
	text-align: left;
	background-color: transparent;
	border: 0;
	border-radius: 2px;
	outline: 0;
	transition: all 0.3s linear;
	appearance: textfield !important;
}

.light-input-number-input::placeholder {
	color: #bfbfbf;
	user-select: none;
}

.light-input-number-input:placeholder-shown {
	text-overflow: ellipsis;
}

.light-input-number-input[type="number"]::-webkit-inner-spin-button,
.light-input-number-input[type="number"]::-webkit-outer-spin-button {
	margin: 0;
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-appearance: none;
	appearance: none;
}

.light-input-number-lg {
	padding: 0;
	font-size: 16px;
}

.light-input-number-lg input {
	height: 38px;
}

.light-input-number-sm {
	padding: 0;
}

.light-input-number-sm input {
	height: 22px;
	padding: 0 7px;
}

.light-input-number-handler-wrap {
	position: absolute;
	top: 0;
	right: 0;
	width: 22px;
	height: 100%;
	background: #fff;
	border-radius: 0 2px 2px 0;
	opacity: 0;
	transition: opacity 0.24s linear 0.1s;
}

.light-input-number-handler-wrap .light-input-number-handler .light-input-number-handler-up-inner,
.light-input-number-handler-wrap .light-input-number-handler .light-input-number-handler-down-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: auto;
	margin-right: 0;
	font-size: 7px;
}

.light-input-number-borderless .light-input-number-handler-wrap {
	border-left-width: 0;
}

.light-input-number-handler-wrap:hover .light-input-number-handler {
	height: 40%;
}

.light-input-number:hover .light-input-number-handler-wrap,
.light-input-number-focused .light-input-number-handler-wrap {
	opacity: 1;
}

.light-input-number-handler-up {
	border-top-right-radius: 2px;
	cursor: pointer;
}

.light-input-number-handler-up-inner {
	top: 50%;
	margin-top: -5px;
	text-align: center;
}

.light-input-number-handler-up:hover {
	height: 60% !important;
}

.light-input-number-handler-down {
	top: 0;
	border-top: 1px solid #d9d9d9;
	border-bottom-right-radius: 2px;
	cursor: pointer;
}

.light-input-number-handler-down-inner {
	top: 50%;
	text-align: center;
	transform: translateY(-50%);
}

.light-input-number-handler-down:hover {
	height: 60% !important;
}

.light-input-number-borderless .light-input-number-handler-down {
	border-top-width: 0;
}

.light-input-number:hover:not(.light-input-number-borderless) .light-input-number-handler-down,
.light-input-number-focused:not(.light-input-number-borderless) .light-input-number-handler-down {
	border-top: 1px solid #d9d9d9;
}

.light-input-number-handler-up-disabled,
.light-input-number-handler-down-disabled {
	cursor: not-allowed;
}

.light-input-number-handler-up-disabled:hover .light-input-number-handler-up-inner,
.light-input-number-handler-down-disabled:hover .light-input-number-handler-down-inner {
	color: rgba(0, 0, 0, 0.25);
}

.light-input-number-borderless {
	box-shadow: none;
}

.light-input-number-out-of-range input {
	color: var(--light-error-color);
}

.light-input-number-rtl {
	direction: rtl;
}

.light-input-number-rtl .light-input-number-handler {
	border-right: 1px solid #d9d9d9;
	border-left: 0;
}

.light-input-number-rtl .light-input-number-handler-wrap {
	right: auto;
	left: 0;
}

.light-input-number-rtl.light-input-number-borderless .light-input-number-handler-wrap {
	border-right-width: 0;
}

.light-input-number-rtl .light-input-number-handler-up {
	border-top-right-radius: 0;
}

.light-input-number-rtl .light-input-number-handler-down {
	border-bottom-right-radius: 0;
}

.light-input-number-rtl .light-input-number-input {
	direction: ltr;
	text-align: right;
}

.light-layout {
	display: flex;
	flex: auto;
	flex-direction: column;
	/* fix firefox can't set height smaller than content on flex item */
	min-height: 0;
	background: #f0f2f5;
}

.light-layout,
.light-layout * {
	box-sizing: border-box;
}

.light-layout.light-layout-has-sider {
	flex-direction: row;
}

.light-layout.light-layout-has-sider>.light-layout,
.light-layout.light-layout-has-sider>.light-layout-content {
	width: 0;
}

.light-layout-header,
.light-layout-footer {
	flex: 0 0 auto;
}

.light-layout-header {
	height: 64px;
	padding: 0 50px;
	color: rgba(0, 0, 0, 0.85);
	line-height: 64px;
	background: #001529;
}

.light-layout-footer {
	padding: 24px 50px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	background: #f0f2f5;
}

.light-layout-content {
	flex: auto;
	/* fix firefox can't set height smaller than content on flex item */
	min-height: 0;
}

.light-layout-sider {
	position: relative;
	/* fix firefox can't set width smaller than content on flex item */
	min-width: 0;
	background: #001529;
	transition: all 0.2s;
}

.light-layout-sider-children {
	height: 100%;
	margin-top: -0.1px;
	padding-top: 0.1px;
}

.light-layout-sider-children .light-menu.light-menu-inline-collapsed {
	width: auto;
}

.light-layout-sider-has-trigger {
	padding-bottom: 48px;
}

.light-layout-sider-right {
	order: 1;
}

.light-layout-sider-trigger {
	position: fixed;
	bottom: 0;
	z-index: 1;
	height: 48px;
	color: #fff;
	line-height: 48px;
	text-align: center;
	background: #002140;
	cursor: pointer;
	transition: all 0.2s;
}

.light-layout-sider-zero-width>* {
	overflow: hidden;
}

.light-layout-sider-zero-width-trigger {
	position: absolute;
	top: 64px;
	right: -36px;
	z-index: 1;
	width: 36px;
	height: 42px;
	color: #fff;
	font-size: 18px;
	line-height: 42px;
	text-align: center;
	background: #001529;
	border-radius: 0 2px 2px 0;
	cursor: pointer;
	transition: background 0.3s ease;
}

.light-layout-sider-zero-width-trigger::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: transparent;
	transition: all 0.3s;
	content: "";
}

.light-layout-sider-zero-width-trigger:hover::after {
	background: rgba(255, 255, 255, 0.1);
}

.light-layout-sider-zero-width-trigger-right {
	left: -36px;
	border-radius: 2px 0 0 2px;
}

.light-layout-sider-light {
	background: #fff;
}

.light-layout-sider-light .light-layout-sider-trigger {
	color: rgba(0, 0, 0, 0.85);
	background: #fff;
}

.light-layout-sider-light .light-layout-sider-zero-width-trigger {
	color: rgba(0, 0, 0, 0.85);
	background: #fff;
}

.light-layout-rtl {
	direction: rtl;
}

.light-list {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
}

.light-list * {
	outline: none;
}

.light-list-pagination {
	margin-top: 24px;
	text-align: right;
}

.light-list-pagination .light-pagination-options {
	text-align: left;
}

.light-list-more {
	margin-top: 12px;
	text-align: center;
}

.light-list-more button {
	padding-right: 32px;
	padding-left: 32px;
}

.light-list-spin {
	min-height: 40px;
	text-align: center;
}

.light-list-empty-text {
	padding: 16px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 14px;
	text-align: center;
}

.light-list-items {
	margin: 0;
	padding: 0;
	list-style: none;
}

.light-list-item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 0;
	color: rgba(0, 0, 0, 0.85);
}

.light-list-item-meta {
	display: flex;
	flex: 1;
	align-items: flex-start;
	max-width: 100%;
}

.light-list-item-meta-avatar {
	margin-right: 16px;
}

.light-list-item-meta-content {
	flex: 1 0;
	width: 0;
	color: rgba(0, 0, 0, 0.85);
}

.light-list-item-meta-title {
	margin-bottom: 4px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 1.5715;
}

.light-list-item-meta-title>a {
	color: rgba(0, 0, 0, 0.85);
	transition: all 0.3s;
}

.light-list-item-meta-title>a:hover {
	color: var(--light-primary-color);
}

.light-list-item-meta-description {
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.5715;
}

.light-list-item-action {
	flex: 0 0 auto;
	margin-left: 48px;
	padding: 0;
	font-size: 0;
	list-style: none;
}

.light-list-item-action>li {
	position: relative;
	display: inline-block;
	padding: 0 8px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.5715;
	text-align: center;
}

.light-list-item-action>li:first-child {
	padding-left: 0;
}

.light-list-item-action-split {
	position: absolute;
	top: 50%;
	right: 0;
	width: 1px;
	height: 14px;
	margin-top: -7px;
	background-color: var(--border-color);
}

.light-list-header {
	background: transparent;
}

.light-list-footer {
	background: transparent;
}

.light-list-header,
.light-list-footer {
	padding-top: 12px;
	padding-bottom: 12px;
}

.light-list-empty {
	padding: 16px 0;
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	text-align: center;
}

.light-list-split .light-list-item {
	border-bottom: 1px solid var(--border-color);
}

.light-list-split .light-list-item:last-child {
	border-bottom: none;
}

.light-list-split .light-list-header {
	border-bottom: 1px solid var(--border-color);
}

.light-list-split.light-list-empty .light-list-footer {
	border-top: 1px solid var(--border-color);
}

.light-list-loading .light-list-spin-nested-loading {
	min-height: 32px;
}

.light-list-split.light-list-something-after-last-item .light-spin-container>.light-list-items>.light-list-item:last-child {
	border-bottom: 1px solid var(--border-color);
}

.light-list-lg .light-list-item {
	padding: 16px 24px;
}

.light-list-sm .light-list-item {
	padding: 8px 16px;
}

.light-list-vertical .light-list-item {
	align-items: initial;
}

.light-list-vertical .light-list-item-main {
	display: block;
	flex: 1;
}

.light-list-vertical .light-list-item-extra {
	margin-left: 40px;
}

.light-list-vertical .light-list-item-meta {
	margin-bottom: 16px;
}

.light-list-vertical .light-list-item-meta-title {
	margin-bottom: 12px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
	line-height: 24px;
}

.light-list-vertical .light-list-item-action {
	margin-top: 16px;
	margin-left: auto;
}

.light-list-vertical .light-list-item-action>li {
	padding: 0 16px;
}

.light-list-vertical .light-list-item-action>li:first-child {
	padding-left: 0;
}

.light-list-grid .light-col>.light-list-item {
	display: block;
	max-width: 100%;
	margin-bottom: 16px;
	padding-top: 0;
	padding-bottom: 0;
	border-bottom: none;
}

.light-list-item-no-flex {
	display: block;
}

.light-list:not(.light-list-vertical) .light-list-item-no-flex .light-list-item-action {
	float: right;
}

.light-list-bordered {
	border: 1px solid #d9d9d9;
	border-radius: 2px;
}

.light-list-bordered .light-list-header {
	padding-right: 24px;
	padding-left: 24px;
}

.light-list-bordered .light-list-footer {
	padding-right: 24px;
	padding-left: 24px;
}

.light-list-bordered .light-list-item {
	padding-right: 24px;
	padding-left: 24px;
}

.light-list-bordered .light-list-pagination {
	margin: 16px 24px;
}

.light-list-bordered.light-list-sm .light-list-item {
	padding: 8px 16px;
}

.light-list-bordered.light-list-sm .light-list-header,
.light-list-bordered.light-list-sm .light-list-footer {
	padding: 8px 16px;
}

.light-list-bordered.light-list-lg .light-list-item {
	padding: 16px 24px;
}

.light-list-bordered.light-list-lg .light-list-header,
.light-list-bordered.light-list-lg .light-list-footer {
	padding: 16px 24px;
}

@media screen and (max-width: 768px) {
	.light-list-item-action {
		margin-left: 24px;
	}

	.light-list-vertical .light-list-item-extra {
		margin-left: 24px;
	}
}

@media screen and (max-width: 576px) {
	.light-list-item {
		flex-wrap: wrap;
	}

	.light-list-item-action {
		margin-left: 12px;
	}

	.light-list-vertical .light-list-item {
		flex-wrap: wrap-reverse;
	}

	.light-list-vertical .light-list-item-main {
		min-width: 220px;
	}

	.light-list-vertical .light-list-item-extra {
		margin: auto auto 16px;
	}
}

.light-list-rtl {
	direction: rtl;
	text-align: right;
}

.light-list-rtl .ReactVirtualized__List .light-list-item {
	direction: rtl;
}

.light-list-rtl .light-list-pagination {
	text-align: left;
}

.light-list-rtl .light-list-item-meta-avatar {
	margin-right: 0;
	margin-left: 16px;
}

.light-list-rtl .light-list-item-action {
	margin-right: 48px;
	margin-left: 0;
}

.light-list.light-list-rtl .light-list-item-action>li:first-child {
	padding-right: 0;
	padding-left: 16px;
}

.light-list-rtl .light-list-item-action-split {
	right: auto;
	left: 0;
}

.light-list-rtl.light-list-vertical .light-list-item-extra {
	margin-right: 40px;
	margin-left: 0;
}

.light-list-rtl.light-list-vertical .light-list-item-action {
	margin-right: auto;
}

.light-list-rtl .light-list-vertical .light-list-item-action>li:first-child {
	padding-right: 0;
	padding-left: 16px;
}

.light-list-rtl .light-list:not(.light-list-vertical) .light-list-item-no-flex .light-list-item-action {
	float: left;
}

@media screen and (max-width: 768px) {
	.light-list-rtl .light-list-item-action {
		margin-right: 24px;
		margin-left: 0;
	}

	.light-list-rtl .light-list-vertical .light-list-item-extra {
		margin-right: 24px;
		margin-left: 0;
	}
}

@media screen and (max-width: 576px) {
	.light-list-rtl .light-list-item-action {
		margin-right: 22px;
		margin-left: 0;
	}

	.light-list-rtl.light-list-vertical .light-list-item-extra {
		margin: auto auto 16px;
	}
}

.light-mentions-status-error:not(.light-mentions-disabled):not(.light-mentions-borderless).light-mentions,
.light-mentions-status-error:not(.light-mentions-disabled):not(.light-mentions-borderless).light-mentions:hover {
	background: #fff;
	border-color: var(--light-error-color);
}

.light-mentions-status-error:not(.light-mentions-disabled):not(.light-mentions-borderless).light-mentions:focus,
.light-mentions-status-error:not(.light-mentions-disabled):not(.light-mentions-borderless).light-mentions-focused {
	border-color: var(--light-error-color-hover);
	box-shadow: 0 0 0 2px var(--light-error-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-mentions-status-error .light-input-prefix {
	color: var(--light-error-color);
}

.light-mentions-status-warning:not(.light-mentions-disabled):not(.light-mentions-borderless).light-mentions,
.light-mentions-status-warning:not(.light-mentions-disabled):not(.light-mentions-borderless).light-mentions:hover {
	background: #fff;
	border-color: var(--light-warning-color);
}

.light-mentions-status-warning:not(.light-mentions-disabled):not(.light-mentions-borderless).light-mentions:focus,
.light-mentions-status-warning:not(.light-mentions-disabled):not(.light-mentions-borderless).light-mentions-focused {
	border-color: var(--light-warning-color-hover);
	box-shadow: 0 0 0 2px var(--light-warning-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-mentions-status-warning .light-input-prefix {
	color: var(--light-warning-color);
}

.light-mentions {
	box-sizing: border-box;
	margin: 0;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: "tnum";
	width: 100%;
	min-width: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
	position: relative;
	display: inline-block;
	height: auto;
	padding: 0;
	overflow: hidden;
	line-height: 1.5715;
	white-space: pre-wrap;
	vertical-align: bottom;
}

.light-mentions::placeholder {
	color: #bfbfbf;
	user-select: none;
}

.light-mentions:placeholder-shown {
	text-overflow: ellipsis;
}

.light-mentions:hover {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
}

.light-input-rtl .light-mentions:hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-mentions:focus,
.light-mentions-focused {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-rtl .light-mentions:focus,
.light-input-rtl .light-mentions-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-mentions-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-mentions-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-mentions[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-mentions[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-mentions-borderless,
.light-mentions-borderless:hover,
.light-mentions-borderless:focus,
.light-mentions-borderless-focused,
.light-mentions-borderless-disabled,
.light-mentions-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

textarea.light-mentions {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5715;
	vertical-align: bottom;
	transition: all 0.3s, height 0s;
}

.light-mentions-lg {
	padding: 6.5px 11px;
	font-size: 16px;
}

.light-mentions-sm {
	padding: 0px 7px;
}

.light-mentions-rtl {
	direction: rtl;
}

.light-mentions-disabled>textarea {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-mentions-disabled>textarea:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-mentions-focused {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-rtl .light-mentions-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-mentions>textarea,
.light-mentions-measure {
	min-height: 30px;
	margin: 0;
	padding: 4px 11px;
	overflow: inherit;
	overflow-x: hidden;
	overflow-y: auto;
	/* stylelint-disable declaration-block-no-redundant-longhand-properties */
	font-weight: inherit;
	font-size: inherit;
	font-family: inherit;
	font-style: inherit;
	font-variant: inherit;
	font-size-adjust: inherit;
	font-stretch: inherit;
	line-height: inherit;
	/* stylelint-enable declaration-block-no-redundant-longhand-properties */
	direction: inherit;
	letter-spacing: inherit;
	white-space: inherit;
	text-align: inherit;
	vertical-align: top;
	word-wrap: break-word;
	word-break: inherit;
	tab-size: inherit;
}

.light-mentions>textarea {
	width: 100%;
	border: none;
	outline: none;
	resize: none;
}

.light-mentions>textarea::placeholder {
	color: #bfbfbf;
	user-select: none;
}

.light-mentions>textarea:placeholder-shown {
	text-overflow: ellipsis;
}

.light-mentions-measure {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	color: transparent;
	pointer-events: none;
}

.light-mentions-measure>span {
	display: inline-block;
	min-height: 1em;
}

.light-mentions-dropdown {
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	box-sizing: border-box;
	font-size: 14px;
	font-variant: initial;
	background-color: #fff;
	border-radius: 2px;
	outline: none;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.light-mentions-dropdown-hidden {
	display: none;
}

.light-mentions-dropdown-menu {
	max-height: 250px;
	margin-bottom: 0;
	padding-left: 0;
	overflow: auto;
	list-style: none;
	outline: none;
}

.light-mentions-dropdown-menu-item {
	position: relative;
	display: block;
	min-width: 100px;
	padding: 5px 12px;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	line-height: 1.5715;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	transition: background 0.3s ease;
}

.light-mentions-dropdown-menu-item:hover {
	background-color: #f5f5f5;
}

.light-mentions-dropdown-menu-item:first-child {
	border-radius: 2px 2px 0 0;
}

.light-mentions-dropdown-menu-item:last-child {
	border-radius: 0 0 2px 2px;
}

.light-mentions-dropdown-menu-item-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-mentions-dropdown-menu-item-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background-color: #fff;
	cursor: not-allowed;
}

.light-mentions-dropdown-menu-item-selected {
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	background-color: #fafafa;
}

.light-mentions-dropdown-menu-item-active {
	background-color: #f5f5f5;
}

.light-mentions-suffix {
	position: absolute;
	top: 0;
	right: 11px;
	bottom: 0;
	z-index: 1;
	display: inline-flex;
	align-items: center;
	margin: auto;
}

.light-mentions-rtl {
	direction: rtl;
}

.light-menu-item-danger.light-menu-item {
	color: var(--light-error-color);
}

.light-menu-item-danger.light-menu-item:hover,
.light-menu-item-danger.light-menu-item-active {
	color: var(--light-error-color);
}

.light-menu-item-danger.light-menu-item:active {
	background: #fff1f0;
}

.light-menu-item-danger.light-menu-item-selected {
	color: var(--light-error-color);
}

.light-menu-item-danger.light-menu-item-selected>a,
.light-menu-item-danger.light-menu-item-selected>a:hover {
	color: var(--light-error-color);
}

.light-menu:not(.light-menu-horizontal) .light-menu-item-danger.light-menu-item-selected {
	background-color: #fff1f0;
}

.light-menu-inline .light-menu-item-danger.light-menu-item::after {
	border-right-color: var(--light-error-color);
}

.light-menu-dark .light-menu-item-danger.light-menu-item,
.light-menu-dark .light-menu-item-danger.light-menu-item:hover,
.light-menu-dark .light-menu-item-danger.light-menu-item>a {
	color: var(--light-error-color);
}

.light-menu-dark.light-menu-dark:not(.light-menu-horizontal) .light-menu-item-danger.light-menu-item-selected {
	color: #fff;
	background-color: var(--light-error-color);
}

.light-menu {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-variant: tabular-nums;
	line-height: 1.5715;
	font-feature-settings: "tnum";
	margin-bottom: 0;
	padding-left: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 0;
	text-align: left;
	list-style: none;
	background: #fff;
	outline: none;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.light-menu::before {
	display: table;
	content: "";
}

.light-menu::after {
	display: table;
	clear: both;
	content: "";
}

.light-menu::before {
	display: table;
	content: "";
}

.light-menu::after {
	display: table;
	clear: both;
	content: "";
}

.light-menu.light-menu-root:focus-visible {
	box-shadow: 0 0 0 2px var(--light-primary-2);
}

.light-menu ul,
.light-menu ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

.light-menu-overflow {
	display: flex;
}

.light-menu-overflow-item {
	flex: none;
}

.light-menu-hidden,
.light-menu-submenu-hidden {
	display: none;
}

.light-menu-item-group-title {
	height: 1.5715;
	padding: 8px 16px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.5715;
	transition: all 0.3s;
}

.light-menu-horizontal .light-menu-submenu {
	transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-menu-submenu,
.light-menu-submenu-inline {
	transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-menu-submenu-selected {
	color: var(--light-primary-color);
}

.light-menu-item:active,
.light-menu-submenu-title:active {
	background: var(--light-primary-1);
}

.light-menu-submenu .light-menu-sub {
	cursor: initial;
	transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-menu-title-content {
	transition: color 0.3s;
}

.light-menu-item a {
	color: rgba(0, 0, 0, 0.85);
}

.light-menu-item a:hover {
	color: var(--light-primary-color);
}

.light-menu-item a::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: transparent;
	content: "";
}

.light-menu-item>.light-badge a {
	color: rgba(0, 0, 0, 0.85);
}

.light-menu-item>.light-badge a:hover {
	color: var(--light-primary-color);
}

.light-menu-item-divider {
	overflow: hidden;
	line-height: 0;
	border-color: var(--border-color);
	border-style: solid;
	border-width: 1px 0 0;
}

.light-menu-item-divider-dashed {
	border-style: dashed;
}

.light-menu-horizontal .light-menu-item,
.light-menu-horizontal .light-menu-submenu {
	margin-top: -1px;
}

.light-menu-horizontal>.light-menu-item:hover,
.light-menu-horizontal>.light-menu-item-active,
.light-menu-horizontal>.light-menu-submenu .light-menu-submenu-title:hover {
	background-color: transparent;
}

.light-menu-item-selected {
	color: var(--light-primary-color);
}

.light-menu-item-selected a,
.light-menu-item-selected a:hover {
	color: var(--light-primary-color);
}

.light-menu:not(.light-menu-horizontal) .light-menu-item-selected {
	background-color: var(--light-primary-1);
}

.light-menu-inline,
.light-menu-vertical,
.light-menu-vertical-left {
	border-right: 1px solid var(--border-color);
}

.light-menu-vertical-right {
	border-left: 1px solid var(--border-color);
}

.light-menu-vertical.light-menu-sub,
.light-menu-vertical-left.light-menu-sub,
.light-menu-vertical-right.light-menu-sub {
	min-width: 160px;
	max-height: calc(100vh - 100px);
	padding: 0;
	overflow: hidden;
	border-right: 0;
}

.light-menu-vertical.light-menu-sub:not([class*="-active"]),
.light-menu-vertical-left.light-menu-sub:not([class*="-active"]),
.light-menu-vertical-right.light-menu-sub:not([class*="-active"]) {
	overflow-x: hidden;
	overflow-y: auto;
}

.light-menu-vertical.light-menu-sub .light-menu-item,
.light-menu-vertical-left.light-menu-sub .light-menu-item,
.light-menu-vertical-right.light-menu-sub .light-menu-item {
	left: 0;
	margin-left: 0;
	border-right: 0;
}

.light-menu-vertical.light-menu-sub .light-menu-item::after,
.light-menu-vertical-left.light-menu-sub .light-menu-item::after,
.light-menu-vertical-right.light-menu-sub .light-menu-item::after {
	border-right: 0;
}

.light-menu-vertical.light-menu-sub>.light-menu-item,
.light-menu-vertical-left.light-menu-sub>.light-menu-item,
.light-menu-vertical-right.light-menu-sub>.light-menu-item,
.light-menu-vertical.light-menu-sub>.light-menu-submenu,
.light-menu-vertical-left.light-menu-sub>.light-menu-submenu,
.light-menu-vertical-right.light-menu-sub>.light-menu-submenu {
	transform-origin: 0 0;
}

.light-menu-horizontal.light-menu-sub {
	min-width: 114px;
}

.light-menu-horizontal .light-menu-item,
.light-menu-horizontal .light-menu-submenu-title {
	transition: border-color 0.3s, background 0.3s;
}

.light-menu-item,
.light-menu-submenu-title {
	position: relative;
	display: block;
	margin: 0;
	padding: 0 20px;
	white-space: nowrap;
	cursor: pointer;
	transition: border-color 0.3s, background 0.3s,
		padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-menu-item .light-menu-item-icon,
.light-menu-submenu-title .light-menu-item-icon,
.light-menu-item .anticon,
.light-menu-submenu-title .anticon {
	min-width: 14px;
	font-size: 14px;
	transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
		margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}

.light-menu-item .light-menu-item-icon+span,
.light-menu-submenu-title .light-menu-item-icon+span,
.light-menu-item .anticon+span,
.light-menu-submenu-title .anticon+span {
	margin-left: 10px;
	opacity: 1;
	transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), margin 0.3s,
		color 0.3s;
}

.light-menu-item .light-menu-item-icon.svg,
.light-menu-submenu-title .light-menu-item-icon.svg {
	vertical-align: -0.125em;
}

.light-menu-item.light-menu-item-only-child>.anticon,
.light-menu-submenu-title.light-menu-item-only-child>.anticon,
.light-menu-item.light-menu-item-only-child>.light-menu-item-icon,
.light-menu-submenu-title.light-menu-item-only-child>.light-menu-item-icon {
	margin-right: 0;
}

.light-menu-item:not(.light-menu-item-disabled):focus-visible,
.light-menu-submenu-title:not(.light-menu-item-disabled):focus-visible {
	box-shadow: 0 0 0 2px var(--light-primary-2);
}

.light-menu>.light-menu-item-divider {
	margin: 1px 0;
	padding: 0;
}

.light-menu-submenu-popup {
	position: absolute;
	z-index: 1050;
	background: transparent;
	border-radius: 2px;
	box-shadow: none;
	transform-origin: 0 0;
}

.light-menu-submenu-popup::before {
	position: absolute;
	top: -7px;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	opacity: 0.0001;
	content: " ";
}

.light-menu-submenu-placement-rightTop::before {
	top: 0;
	left: -7px;
}

.light-menu-submenu>.light-menu {
	background-color: #fff;
	border-radius: 2px;
}

.light-menu-submenu>.light-menu-submenu-title::after {
	transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-menu-submenu-popup>.light-menu {
	background-color: #fff;
}

.light-menu-submenu-expand-icon,
.light-menu-submenu-arrow {
	position: absolute;
	top: 50%;
	right: 16px;
	width: 10px;
	color: rgba(0, 0, 0, 0.85);
	transform: translateY(-50%);
	transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-menu-submenu-arrow::before,
.light-menu-submenu-arrow::after {
	position: absolute;
	width: 6px;
	height: 1.5px;
	background-color: currentcolor;
	border-radius: 2px;
	transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	content: "";
}

.light-menu-submenu-arrow::before {
	transform: rotate(45deg) translateY(-2.5px);
}

.light-menu-submenu-arrow::after {
	transform: rotate(-45deg) translateY(2.5px);
}

.light-menu-submenu:hover>.light-menu-submenu-title>.light-menu-submenu-expand-icon,
.light-menu-submenu:hover>.light-menu-submenu-title>.light-menu-submenu-arrow {
	color: var(--light-primary-color);
}

.light-menu-inline-collapsed .light-menu-submenu-arrow::before,
.light-menu-submenu-inline .light-menu-submenu-arrow::before {
	transform: rotate(-45deg) translateX(2.5px);
}

.light-menu-inline-collapsed .light-menu-submenu-arrow::after,
.light-menu-submenu-inline .light-menu-submenu-arrow::after {
	transform: rotate(45deg) translateX(-2.5px);
}

.light-menu-submenu-horizontal .light-menu-submenu-arrow {
	display: none;
}

.light-menu-submenu-open.light-menu-submenu-inline>.light-menu-submenu-title>.light-menu-submenu-arrow {
	transform: translateY(-2px);
}

.light-menu-submenu-open.light-menu-submenu-inline>.light-menu-submenu-title>.light-menu-submenu-arrow::after {
	transform: rotate(-45deg) translateX(-2.5px);
}

.light-menu-submenu-open.light-menu-submenu-inline>.light-menu-submenu-title>.light-menu-submenu-arrow::before {
	transform: rotate(45deg) translateX(2.5px);
}

.light-menu-vertical .light-menu-submenu-selected,
.light-menu-vertical-left .light-menu-submenu-selected,
.light-menu-vertical-right .light-menu-submenu-selected {
	color: var(--light-primary-color);
}

.light-menu-horizontal {
	line-height: 46px;
	border: 0;
	border-bottom: 1px solid var(--border-color);
	box-shadow: none;
}

.light-menu-horizontal:not(.light-menu-dark)>.light-menu-item,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-submenu {
	margin-top: -1px;
	margin-bottom: 0;
	padding: 0 20px;
}

.light-menu-horizontal:not(.light-menu-dark)>.light-menu-item:hover,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-submenu:hover,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-item-active,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-submenu-active,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-item-open,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-submenu-open,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-item-selected,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-submenu-selected {
	color: var(--light-primary-color);
}

.light-menu-horizontal:not(.light-menu-dark)>.light-menu-item:hover::after,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-submenu:hover::after,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-item-active::after,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-submenu-active::after,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-item-open::after,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-submenu-open::after,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-item-selected::after,
.light-menu-horizontal:not(.light-menu-dark)>.light-menu-submenu-selected::after {
	border-bottom: 2px solid var(--light-primary-color);
}

.light-menu-horizontal>.light-menu-item,
.light-menu-horizontal>.light-menu-submenu {
	position: relative;
	top: 1px;
	display: inline-block;
	vertical-align: bottom;
}

.light-menu-horizontal>.light-menu-item::after,
.light-menu-horizontal>.light-menu-submenu::after {
	position: absolute;
	right: 20px;
	bottom: 0;
	left: 20px;
	border-bottom: 2px solid transparent;
	transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	content: "";
}

.light-menu-horizontal>.light-menu-submenu>.light-menu-submenu-title {
	padding: 0;
}

.light-menu-horizontal>.light-menu-item a {
	color: rgba(0, 0, 0, 0.85);
}

.light-menu-horizontal>.light-menu-item a:hover {
	color: var(--light-primary-color);
}

.light-menu-horizontal>.light-menu-item a::before {
	bottom: -2px;
}

.light-menu-horizontal>.light-menu-item-selected a {
	color: var(--light-primary-color);
}

.light-menu-horizontal::after {
	display: block;
	clear: both;
	height: 0;
	content: "\20";
}

.light-menu-vertical .light-menu-item,
.light-menu-vertical-left .light-menu-item,
.light-menu-vertical-right .light-menu-item,
.light-menu-inline .light-menu-item {
	position: relative;
}

.light-menu-vertical .light-menu-item::after,
.light-menu-vertical-left .light-menu-item::after,
.light-menu-vertical-right .light-menu-item::after,
.light-menu-inline .light-menu-item::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	border-right: 3px solid var(--light-primary-color);
	transform: scaleY(0.0001);
	opacity: 0;
	transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
		opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
	content: "";
}

.light-menu-vertical .light-menu-item,
.light-menu-vertical-left .light-menu-item,
.light-menu-vertical-right .light-menu-item,
.light-menu-inline .light-menu-item,
.light-menu-vertical .light-menu-submenu-title,
.light-menu-vertical-left .light-menu-submenu-title,
.light-menu-vertical-right .light-menu-submenu-title,
.light-menu-inline .light-menu-submenu-title {
	height: 40px;
	margin-top: 4px;
	margin-bottom: 4px;
	padding: 0 16px;
	overflow: hidden;
	line-height: 40px;
	text-overflow: ellipsis;
}

.light-menu-vertical .light-menu-submenu,
.light-menu-vertical-left .light-menu-submenu,
.light-menu-vertical-right .light-menu-submenu,
.light-menu-inline .light-menu-submenu {
	padding-bottom: 0.02px;
}

.light-menu-vertical .light-menu-item:not(:last-child),
.light-menu-vertical-left .light-menu-item:not(:last-child),
.light-menu-vertical-right .light-menu-item:not(:last-child),
.light-menu-inline .light-menu-item:not(:last-child) {
	margin-bottom: 8px;
}

.light-menu-vertical>.light-menu-item,
.light-menu-vertical-left>.light-menu-item,
.light-menu-vertical-right>.light-menu-item,
.light-menu-inline>.light-menu-item,
.light-menu-vertical>.light-menu-submenu>.light-menu-submenu-title,
.light-menu-vertical-left>.light-menu-submenu>.light-menu-submenu-title,
.light-menu-vertical-right>.light-menu-submenu>.light-menu-submenu-title,
.light-menu-inline>.light-menu-submenu>.light-menu-submenu-title {
	height: 40px;
	line-height: 40px;
}

.light-menu-vertical .light-menu-item-group-list .light-menu-submenu-title,
.light-menu-vertical .light-menu-submenu-title {
	padding-right: 34px;
}

.light-menu-inline {
	width: 100%;
}

.light-menu-inline .light-menu-selected::after,
.light-menu-inline .light-menu-item-selected::after {
	transform: scaleY(1);
	opacity: 1;
	transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
		opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-menu-inline .light-menu-item,
.light-menu-inline .light-menu-submenu-title {
	width: calc(100% + 1px);
}

.light-menu-inline .light-menu-item-group-list .light-menu-submenu-title,
.light-menu-inline .light-menu-submenu-title {
	padding-right: 34px;
}

.light-menu-inline.light-menu-root .light-menu-item,
.light-menu-inline.light-menu-root .light-menu-submenu-title {
	display: flex;
	align-items: center;
	transition: border-color 0.3s, background 0.3s,
		padding 0.1s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.light-menu-inline.light-menu-root .light-menu-item>.light-menu-title-content,
.light-menu-inline.light-menu-root .light-menu-submenu-title>.light-menu-title-content {
	flex: auto;
	min-width: 0;
	overflow: hidden;
	text-overflow: ellipsis;
}

.light-menu-inline.light-menu-root .light-menu-item>*,
.light-menu-inline.light-menu-root .light-menu-submenu-title>* {
	flex: none;
}

.light-menu.light-menu-inline-collapsed {
	width: 80px;
}

.light-menu.light-menu-inline-collapsed>.light-menu-item,
.light-menu.light-menu-inline-collapsed>.light-menu-item-group>.light-menu-item-group-list>.light-menu-item,
.light-menu.light-menu-inline-collapsed>.light-menu-item-group>.light-menu-item-group-list>.light-menu-submenu>.light-menu-submenu-title,
.light-menu.light-menu-inline-collapsed>.light-menu-submenu>.light-menu-submenu-title {
	left: 0;
	padding: 0 calc(50% - 16px / 2);
	text-overflow: clip;
}

.light-menu.light-menu-inline-collapsed>.light-menu-item .light-menu-submenu-arrow,
.light-menu.light-menu-inline-collapsed>.light-menu-item-group>.light-menu-item-group-list>.light-menu-item .light-menu-submenu-arrow,
.light-menu.light-menu-inline-collapsed>.light-menu-item-group>.light-menu-item-group-list>.light-menu-submenu>.light-menu-submenu-title .light-menu-submenu-arrow,
.light-menu.light-menu-inline-collapsed>.light-menu-submenu>.light-menu-submenu-title .light-menu-submenu-arrow {
	opacity: 0;
}

.light-menu.light-menu-inline-collapsed>.light-menu-item .light-menu-item-icon,
.light-menu.light-menu-inline-collapsed>.light-menu-item-group>.light-menu-item-group-list>.light-menu-item .light-menu-item-icon,
.light-menu.light-menu-inline-collapsed>.light-menu-item-group>.light-menu-item-group-list>.light-menu-submenu>.light-menu-submenu-title .light-menu-item-icon,
.light-menu.light-menu-inline-collapsed>.light-menu-submenu>.light-menu-submenu-title .light-menu-item-icon,
.light-menu.light-menu-inline-collapsed>.light-menu-item .anticon,
.light-menu.light-menu-inline-collapsed>.light-menu-item-group>.light-menu-item-group-list>.light-menu-item .anticon,
.light-menu.light-menu-inline-collapsed>.light-menu-item-group>.light-menu-item-group-list>.light-menu-submenu>.light-menu-submenu-title .anticon,
.light-menu.light-menu-inline-collapsed>.light-menu-submenu>.light-menu-submenu-title .anticon {
	margin: 0;
	font-size: 16px;
	line-height: 40px;
}

.light-menu.light-menu-inline-collapsed>.light-menu-item .light-menu-item-icon+span,
.light-menu.light-menu-inline-collapsed>.light-menu-item-group>.light-menu-item-group-list>.light-menu-item .light-menu-item-icon+span,
.light-menu.light-menu-inline-collapsed>.light-menu-item-group>.light-menu-item-group-list>.light-menu-submenu>.light-menu-submenu-title .light-menu-item-icon+span,
.light-menu.light-menu-inline-collapsed>.light-menu-submenu>.light-menu-submenu-title .light-menu-item-icon+span,
.light-menu.light-menu-inline-collapsed>.light-menu-item .anticon+span,
.light-menu.light-menu-inline-collapsed>.light-menu-item-group>.light-menu-item-group-list>.light-menu-item .anticon+span,
.light-menu.light-menu-inline-collapsed>.light-menu-item-group>.light-menu-item-group-list>.light-menu-submenu>.light-menu-submenu-title .anticon+span,
.light-menu.light-menu-inline-collapsed>.light-menu-submenu>.light-menu-submenu-title .anticon+span {
	display: inline-block;
	opacity: 0;
}

.light-menu.light-menu-inline-collapsed .light-menu-item-icon,
.light-menu.light-menu-inline-collapsed .anticon {
	display: inline-block;
}

.light-menu.light-menu-inline-collapsed-tooltip {
	pointer-events: none;
}

.light-menu.light-menu-inline-collapsed-tooltip .light-menu-item-icon,
.light-menu.light-menu-inline-collapsed-tooltip .anticon {
	display: none;
}

.light-menu.light-menu-inline-collapsed-tooltip a {
	color: rgba(255, 255, 255, 0.85);
}

.light-menu.light-menu-inline-collapsed .light-menu-item-group-title {
	padding-right: 4px;
	padding-left: 4px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.light-menu-item-group-list {
	margin: 0;
	padding: 0;
}

.light-menu-item-group-list .light-menu-item,
.light-menu-item-group-list .light-menu-submenu-title {
	padding: 0 16px 0 28px;
}

.light-menu-root.light-menu-vertical,
.light-menu-root.light-menu-vertical-left,
.light-menu-root.light-menu-vertical-right,
.light-menu-root.light-menu-inline {
	box-shadow: none;
}

.light-menu-root.light-menu-inline-collapsed .light-menu-item>.light-menu-inline-collapsed-noicon,
.light-menu-root.light-menu-inline-collapsed .light-menu-submenu .light-menu-submenu-title>.light-menu-inline-collapsed-noicon {
	font-size: 16px;
	text-align: center;
}

.light-menu-sub.light-menu-inline {
	padding: 0;
	background: #fafafa;
	border: 0;
	border-radius: 0;
	box-shadow: none;
}

.light-menu-sub.light-menu-inline>.light-menu-item,
.light-menu-sub.light-menu-inline>.light-menu-submenu>.light-menu-submenu-title {
	height: 40px;
	line-height: 40px;
	list-style-position: inside;
	list-style-type: disc;
}

.light-menu-sub.light-menu-inline .light-menu-item-group-title {
	padding-left: 32px;
}

.light-menu-item-disabled,
.light-menu-submenu-disabled {
	color: rgba(0, 0, 0, 0.25) !important;
	background: none;
	cursor: not-allowed;
}

.light-menu-item-disabled::after,
.light-menu-submenu-disabled::after {
	border-color: transparent !important;
}

.light-menu-item-disabled a,
.light-menu-submenu-disabled a {
	color: rgba(0, 0, 0, 0.25) !important;
	pointer-events: none;
}

.light-menu-item-disabled>.light-menu-submenu-title,
.light-menu-submenu-disabled>.light-menu-submenu-title {
	color: rgba(0, 0, 0, 0.25) !important;
	cursor: not-allowed;
}

.light-menu-item-disabled>.light-menu-submenu-title>.light-menu-submenu-arrow::before,
.light-menu-submenu-disabled>.light-menu-submenu-title>.light-menu-submenu-arrow::before,
.light-menu-item-disabled>.light-menu-submenu-title>.light-menu-submenu-arrow::after,
.light-menu-submenu-disabled>.light-menu-submenu-title>.light-menu-submenu-arrow::after {
	background: rgba(0, 0, 0, 0.25) !important;
}

.light-layout-header .light-menu {
	line-height: inherit;
}

.light-menu-inline-collapsed-tooltip a,
.light-menu-inline-collapsed-tooltip a:hover {
	color: #fff;
}

.light-menu-light .light-menu-item:hover,
.light-menu-light .light-menu-item-active,
.light-menu-light .light-menu:not(.light-menu-inline) .light-menu-submenu-open,
.light-menu-light .light-menu-submenu-active,
.light-menu-light .light-menu-submenu-title:hover {
	color: var(--light-primary-color);
}

.light-menu.light-menu-root:focus-visible {
	box-shadow: 0 0 0 2px var(--light-primary-7);
}

.light-menu-dark .light-menu-item:focus-visible,
.light-menu-dark .light-menu-submenu-title:focus-visible {
	box-shadow: 0 0 0 2px var(--light-primary-7);
}

.light-menu.light-menu-dark,
.light-menu-dark .light-menu-sub,
.light-menu.light-menu-dark .light-menu-sub {
	color: rgba(255, 255, 255, 0.65);
	background: #001529;
}

.light-menu.light-menu-dark .light-menu-submenu-title .light-menu-submenu-arrow,
.light-menu-dark .light-menu-sub .light-menu-submenu-title .light-menu-submenu-arrow,
.light-menu.light-menu-dark .light-menu-sub .light-menu-submenu-title .light-menu-submenu-arrow {
	opacity: 0.45;
	transition: all 0.3s;
}

.light-menu.light-menu-dark .light-menu-submenu-title .light-menu-submenu-arrow::after,
.light-menu-dark .light-menu-sub .light-menu-submenu-title .light-menu-submenu-arrow::after,
.light-menu.light-menu-dark .light-menu-sub .light-menu-submenu-title .light-menu-submenu-arrow::after,
.light-menu.light-menu-dark .light-menu-submenu-title .light-menu-submenu-arrow::before,
.light-menu-dark .light-menu-sub .light-menu-submenu-title .light-menu-submenu-arrow::before,
.light-menu.light-menu-dark .light-menu-sub .light-menu-submenu-title .light-menu-submenu-arrow::before {
	background: #fff;
}

.light-menu-dark.light-menu-submenu-popup {
	background: transparent;
}

.light-menu-dark .light-menu-inline.light-menu-sub {
	background: #000c17;
}

.light-menu-dark.light-menu-horizontal {
	border-bottom: 0;
}

.light-menu-dark.light-menu-horizontal>.light-menu-item,
.light-menu-dark.light-menu-horizontal>.light-menu-submenu {
	top: 0;
	margin-top: 0;
	padding: 0 20px;
	border-color: #001529;
	border-bottom: 0;
}

.light-menu-dark.light-menu-horizontal>.light-menu-item:hover {
	background-color: var(--light-primary-color);
}

.light-menu-dark.light-menu-horizontal>.light-menu-item>a::before {
	bottom: 0;
}

.light-menu-dark .light-menu-item,
.light-menu-dark .light-menu-item-group-title,
.light-menu-dark .light-menu-item>a,
.light-menu-dark .light-menu-item>span>a {
	color: rgba(255, 255, 255, 0.65);
}

.light-menu-dark.light-menu-inline,
.light-menu-dark.light-menu-vertical,
.light-menu-dark.light-menu-vertical-left,
.light-menu-dark.light-menu-vertical-right {
	border-right: 0;
}

.light-menu-dark.light-menu-inline .light-menu-item,
.light-menu-dark.light-menu-vertical .light-menu-item,
.light-menu-dark.light-menu-vertical-left .light-menu-item,
.light-menu-dark.light-menu-vertical-right .light-menu-item {
	left: 0;
	margin-left: 0;
	border-right: 0;
}

.light-menu-dark.light-menu-inline .light-menu-item::after,
.light-menu-dark.light-menu-vertical .light-menu-item::after,
.light-menu-dark.light-menu-vertical-left .light-menu-item::after,
.light-menu-dark.light-menu-vertical-right .light-menu-item::after {
	border-right: 0;
}

.light-menu-dark.light-menu-inline .light-menu-item,
.light-menu-dark.light-menu-inline .light-menu-submenu-title {
	width: 100%;
}

.light-menu-dark .light-menu-item:hover,
.light-menu-dark .light-menu-item-active,
.light-menu-dark .light-menu-submenu-active,
.light-menu-dark .light-menu-submenu-open,
.light-menu-dark .light-menu-submenu-selected,
.light-menu-dark .light-menu-submenu-title:hover {
	color: #fff;
	background-color: transparent;
}

.light-menu-dark .light-menu-item:hover>a,
.light-menu-dark .light-menu-item-active>a,
.light-menu-dark .light-menu-submenu-active>a,
.light-menu-dark .light-menu-submenu-open>a,
.light-menu-dark .light-menu-submenu-selected>a,
.light-menu-dark .light-menu-submenu-title:hover>a,
.light-menu-dark .light-menu-item:hover>span>a,
.light-menu-dark .light-menu-item-active>span>a,
.light-menu-dark .light-menu-submenu-active>span>a,
.light-menu-dark .light-menu-submenu-open>span>a,
.light-menu-dark .light-menu-submenu-selected>span>a,
.light-menu-dark .light-menu-submenu-title:hover>span>a {
	color: #fff;
}

.light-menu-dark .light-menu-item:hover>.light-menu-submenu-title>.light-menu-submenu-arrow,
.light-menu-dark .light-menu-item-active>.light-menu-submenu-title>.light-menu-submenu-arrow,
.light-menu-dark .light-menu-submenu-active>.light-menu-submenu-title>.light-menu-submenu-arrow,
.light-menu-dark .light-menu-submenu-open>.light-menu-submenu-title>.light-menu-submenu-arrow,
.light-menu-dark .light-menu-submenu-selected>.light-menu-submenu-title>.light-menu-submenu-arrow,
.light-menu-dark .light-menu-submenu-title:hover>.light-menu-submenu-title>.light-menu-submenu-arrow {
	opacity: 1;
}

.light-menu-dark .light-menu-item:hover>.light-menu-submenu-title>.light-menu-submenu-arrow::after,
.light-menu-dark .light-menu-item-active>.light-menu-submenu-title>.light-menu-submenu-arrow::after,
.light-menu-dark .light-menu-submenu-active>.light-menu-submenu-title>.light-menu-submenu-arrow::after,
.light-menu-dark .light-menu-submenu-open>.light-menu-submenu-title>.light-menu-submenu-arrow::after,
.light-menu-dark .light-menu-submenu-selected>.light-menu-submenu-title>.light-menu-submenu-arrow::after,
.light-menu-dark .light-menu-submenu-title:hover>.light-menu-submenu-title>.light-menu-submenu-arrow::after,
.light-menu-dark .light-menu-item:hover>.light-menu-submenu-title>.light-menu-submenu-arrow::before,
.light-menu-dark .light-menu-item-active>.light-menu-submenu-title>.light-menu-submenu-arrow::before,
.light-menu-dark .light-menu-submenu-active>.light-menu-submenu-title>.light-menu-submenu-arrow::before,
.light-menu-dark .light-menu-submenu-open>.light-menu-submenu-title>.light-menu-submenu-arrow::before,
.light-menu-dark .light-menu-submenu-selected>.light-menu-submenu-title>.light-menu-submenu-arrow::before,
.light-menu-dark .light-menu-submenu-title:hover>.light-menu-submenu-title>.light-menu-submenu-arrow::before {
	background: #fff;
}

.light-menu-dark .light-menu-item:hover {
	background-color: transparent;
}

.light-menu-dark.light-menu-dark:not(.light-menu-horizontal) .light-menu-item-selected {
	background-color: var(--light-primary-color);
}

.light-menu-dark .light-menu-item-selected {
	color: #fff;
	border-right: 0;
}

.light-menu-dark .light-menu-item-selected::after {
	border-right: 0;
}

.light-menu-dark .light-menu-item-selected>a,
.light-menu-dark .light-menu-item-selected>span>a,
.light-menu-dark .light-menu-item-selected>a:hover,
.light-menu-dark .light-menu-item-selected>span>a:hover {
	color: #fff;
}

.light-menu-dark .light-menu-item-selected .light-menu-item-icon,
.light-menu-dark .light-menu-item-selected .anticon {
	color: #fff;
}

.light-menu-dark .light-menu-item-selected .light-menu-item-icon+span,
.light-menu-dark .light-menu-item-selected .anticon+span {
	color: #fff;
}

.light-menu.light-menu-dark .light-menu-item-selected,
.light-menu-submenu-popup.light-menu-dark .light-menu-item-selected {
	background-color: var(--light-primary-color);
}

.light-menu-dark .light-menu-item-disabled,
.light-menu-dark .light-menu-submenu-disabled,
.light-menu-dark .light-menu-item-disabled>a,
.light-menu-dark .light-menu-submenu-disabled>a,
.light-menu-dark .light-menu-item-disabled>span>a,
.light-menu-dark .light-menu-submenu-disabled>span>a {
	color: rgba(255, 255, 255, 0.35) !important;
	opacity: 0.8;
}

.light-menu-dark .light-menu-item-disabled>.light-menu-submenu-title,
.light-menu-dark .light-menu-submenu-disabled>.light-menu-submenu-title {
	color: rgba(255, 255, 255, 0.35) !important;
}

.light-menu-dark .light-menu-item-disabled>.light-menu-submenu-title>.light-menu-submenu-arrow::before,
.light-menu-dark .light-menu-submenu-disabled>.light-menu-submenu-title>.light-menu-submenu-arrow::before,
.light-menu-dark .light-menu-item-disabled>.light-menu-submenu-title>.light-menu-submenu-arrow::after,
.light-menu-dark .light-menu-submenu-disabled>.light-menu-submenu-title>.light-menu-submenu-arrow::after {
	background: rgba(255, 255, 255, 0.35) !important;
}

.light-menu.light-menu-rtl {
	direction: rtl;
	text-align: right;
}

.light-menu-rtl .light-menu-item-group-title {
	text-align: right;
}

.light-menu-rtl.light-menu-inline,
.light-menu-rtl.light-menu-vertical {
	border-right: none;
	border-left: 1px solid var(--border-color);
}

.light-menu-rtl.light-menu-dark.light-menu-inline,
.light-menu-rtl.light-menu-dark.light-menu-vertical {
	border-left: none;
}

.light-menu-rtl.light-menu-vertical.light-menu-sub>.light-menu-item,
.light-menu-rtl.light-menu-vertical-left.light-menu-sub>.light-menu-item,
.light-menu-rtl.light-menu-vertical-right.light-menu-sub>.light-menu-item,
.light-menu-rtl.light-menu-vertical.light-menu-sub>.light-menu-submenu,
.light-menu-rtl.light-menu-vertical-left.light-menu-sub>.light-menu-submenu,
.light-menu-rtl.light-menu-vertical-right.light-menu-sub>.light-menu-submenu {
	transform-origin: top right;
}

.light-menu-rtl .light-menu-item .light-menu-item-icon,
.light-menu-rtl .light-menu-submenu-title .light-menu-item-icon,
.light-menu-rtl .light-menu-item .anticon,
.light-menu-rtl .light-menu-submenu-title .anticon {
	margin-right: auto;
	margin-left: 10px;
}

.light-menu-rtl .light-menu-item.light-menu-item-only-child>.light-menu-item-icon,
.light-menu-rtl .light-menu-submenu-title.light-menu-item-only-child>.light-menu-item-icon,
.light-menu-rtl .light-menu-item.light-menu-item-only-child>.anticon,
.light-menu-rtl .light-menu-submenu-title.light-menu-item-only-child>.anticon {
	margin-left: 0;
}

.light-menu-submenu-rtl.light-menu-submenu-popup {
	transform-origin: 100% 0;
}

.light-menu-rtl .light-menu-submenu-vertical>.light-menu-submenu-title .light-menu-submenu-arrow,
.light-menu-rtl .light-menu-submenu-vertical-left>.light-menu-submenu-title .light-menu-submenu-arrow,
.light-menu-rtl .light-menu-submenu-vertical-right>.light-menu-submenu-title .light-menu-submenu-arrow,
.light-menu-rtl .light-menu-submenu-inline>.light-menu-submenu-title .light-menu-submenu-arrow {
	right: auto;
	left: 16px;
}

.light-menu-rtl .light-menu-submenu-vertical>.light-menu-submenu-title .light-menu-submenu-arrow::before,
.light-menu-rtl .light-menu-submenu-vertical-left>.light-menu-submenu-title .light-menu-submenu-arrow::before,
.light-menu-rtl .light-menu-submenu-vertical-right>.light-menu-submenu-title .light-menu-submenu-arrow::before {
	transform: rotate(-45deg) translateY(-2px);
}

.light-menu-rtl .light-menu-submenu-vertical>.light-menu-submenu-title .light-menu-submenu-arrow::after,
.light-menu-rtl .light-menu-submenu-vertical-left>.light-menu-submenu-title .light-menu-submenu-arrow::after,
.light-menu-rtl .light-menu-submenu-vertical-right>.light-menu-submenu-title .light-menu-submenu-arrow::after {
	transform: rotate(45deg) translateY(2px);
}

.light-menu-rtl.light-menu-vertical .light-menu-item::after,
.light-menu-rtl.light-menu-vertical-left .light-menu-item::after,
.light-menu-rtl.light-menu-vertical-right .light-menu-item::after,
.light-menu-rtl.light-menu-inline .light-menu-item::after {
	right: auto;
	left: 0;
}

.light-menu-rtl.light-menu-vertical .light-menu-item,
.light-menu-rtl.light-menu-vertical-left .light-menu-item,
.light-menu-rtl.light-menu-vertical-right .light-menu-item,
.light-menu-rtl.light-menu-inline .light-menu-item,
.light-menu-rtl.light-menu-vertical .light-menu-submenu-title,
.light-menu-rtl.light-menu-vertical-left .light-menu-submenu-title,
.light-menu-rtl.light-menu-vertical-right .light-menu-submenu-title,
.light-menu-rtl.light-menu-inline .light-menu-submenu-title {
	text-align: right;
}

.light-menu-rtl.light-menu-inline .light-menu-submenu-title {
	padding-right: 0;
	padding-left: 34px;
}

.light-menu-rtl.light-menu-vertical .light-menu-submenu-title {
	padding-right: 16px;
	padding-left: 34px;
}

.light-menu-rtl.light-menu-inline-collapsed.light-menu-vertical .light-menu-submenu-title {
	padding: 0 calc(50% - 16px / 2);
}

.light-menu-rtl .light-menu-item-group-list .light-menu-item,
.light-menu-rtl .light-menu-item-group-list .light-menu-submenu-title {
	padding: 0 28px 0 16px;
}

.light-menu-sub.light-menu-inline {
	border: 0;
}

.light-menu-rtl.light-menu-sub.light-menu-inline .light-menu-item-group-title {
	padding-right: 32px;
	padding-left: 0;
}

.light-message {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: fixed;
	top: 8px;
	left: 0;
	z-index: 1010;
	width: 100%;
	pointer-events: none;
}

.light-message-notice {
	padding: 8px;
	text-align: center;
}

.light-message-notice-content {
	display: inline-block;
	padding: 10px 16px;
	background: #fff;
	border-radius: 2px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	pointer-events: all;
}

.light-message-success .anticon {
	color: var(--light-success-color);
}

.light-message-error .anticon {
	color: var(--light-error-color);
}

.light-message-warning .anticon {
	color: var(--light-warning-color);
}

.light-message-info .anticon,
.light-message-loading .anticon {
	color: var(--light-info-color);
}

.light-message .anticon {
	position: relative;
	top: 1px;
	margin-right: 8px;
	font-size: 16px;
}

.light-message-notice.light-move-up-leave.light-move-up-leave-active {
	animation-name: MessageMoveOut;
	animation-duration: 0.3s;
}

@keyframes MessageMoveOut {
	0% {
		max-height: 150px;
		padding: 8px;
		opacity: 1;
	}

	100% {
		max-height: 0;
		padding: 0;
		opacity: 0;
	}
}

.light-message-rtl {
	direction: rtl;
}

.light-message-rtl span {
	direction: rtl;
}

.light-message-rtl .anticon {
	margin-right: 0;
	margin-left: 8px;
}

.light-modal {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	pointer-events: none;
	position: relative;
	top: 100px;
	width: auto;
	max-width: calc(100vw - 32px);
	margin: 0 auto;
	border-radius: 16px;
	overflow: auto;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.light-modal.light-zoom-enter,
.light-modal.light-zoom-appear {
	transform: none;
	opacity: 0;
	animation-duration: 0.3s;
	user-select: none;
}

.light-modal-mask {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.45);
}

.light-modal-mask-hidden {
	display: none;
}

.light-modal-wrap {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	overflow: auto;
	outline: 0;
}

.light-modal-wrap {
	z-index: 1000;
}

.light-modal-title {
	margin: 0;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	word-wrap: break-word;
}

.light-modal-content {
	position: relative;
	background-color: #fff;
	background-clip: padding-box;
	border: 0;
	border-radius: 16px;
	pointer-events: auto;
}

.light-modal-close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	padding: 0;
	color: rgba(0, 0, 0, 0.45);
	font-weight: 700;
	line-height: 1;
	text-decoration: none;
	background: transparent;
	border: 0;
	outline: 0;
	cursor: pointer;
	transition: color 0.3s;
}

.light-modal-close-x {
	display: block;
	width: 54px;
	height: 54px;
	font-size: 16px;
	font-style: normal;
	line-height: 54px;
	text-align: center;
	text-transform: none;
	text-rendering: auto;
}

.light-modal-close:focus,
.light-modal-close:hover {
	color: rgba(0, 0, 0, 0.75);
	text-decoration: none;
}

.light-modal-header {
	padding: 16px 24px;
	color: rgba(0, 0, 0, 0.85);
	background: #fff;
	border-bottom: 1px solid var(--border-color);
	border-radius: 2px 2px 0 0;
}

.light-modal-body {
	padding: 24px;
	font-size: 14px;
	line-height: 1.5715;
	word-wrap: break-word;
}

.light-modal-footer {
	padding: 10px 16px;
	text-align: right;
	background: transparent;
	border-top: 1px solid var(--border-color);
	border-radius: 0 0 2px 2px;
}

.light-modal-footer .light-btn+.light-btn:not(.light-dropdown-trigger) {
	margin-bottom: 0;
	margin-left: 8px;
}

.light-modal-open {
	overflow: hidden;
}

.light-modal-centered {
	text-align: center;
}

.light-modal-centered::before {
	display: inline-block;
	width: 0;
	height: 100%;
	vertical-align: middle;
	content: "";
}

.light-modal-centered .light-modal {
	top: 0;
	display: inline-block;
	padding-bottom: 0;
	text-align: left;
	vertical-align: middle;
}

@media (max-width: 767px) {
	.light-modal {
		max-width: calc(100vw - 16px);
		margin: 8px auto;
	}

	.light-modal-centered .light-modal {
		flex: 1;
	}
}

.light-modal-confirm .light-modal-header {
	display: none;
}

.light-modal-confirm .light-modal-body {
	padding: 32px 32px 24px;
}

.light-modal-confirm-body-wrapper::before {
	display: table;
	content: "";
}

.light-modal-confirm-body-wrapper::after {
	display: table;
	clear: both;
	content: "";
}

.light-modal-confirm-body-wrapper::before {
	display: table;
	content: "";
}

.light-modal-confirm-body-wrapper::after {
	display: table;
	clear: both;
	content: "";
}

.light-modal-confirm-body .light-modal-confirm-title {
	display: block;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	font-size: 16px;
	line-height: 1.4;
}

.light-modal-confirm-body .light-modal-confirm-content {
	margin-top: 8px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
}

.light-modal-confirm-body>.anticon {
	float: left;
	margin-right: 16px;
	font-size: 22px;
}

.light-modal-confirm-body>.anticon+.light-modal-confirm-title+.light-modal-confirm-content {
	margin-left: 38px;
}

.light-modal-confirm .light-modal-confirm-btns {
	margin-top: 24px;
	text-align: right;
}

.light-modal-confirm .light-modal-confirm-btns .light-btn+.light-btn {
	margin-bottom: 0;
	margin-left: 8px;
}

.light-modal-confirm-error .light-modal-confirm-body>.anticon {
	color: var(--light-error-color);
}

.light-modal-confirm-warning .light-modal-confirm-body>.anticon,
.light-modal-confirm-confirm .light-modal-confirm-body>.anticon {
	color: var(--light-warning-color);
}

.light-modal-confirm-info .light-modal-confirm-body>.anticon {
	color: var(--light-info-color);
}

.light-modal-confirm-success .light-modal-confirm-body>.anticon {
	color: var(--light-success-color);
}

.light-modal-wrap-rtl {
	direction: rtl;
}

.light-modal-wrap-rtl .light-modal-close {
	right: initial;
	left: 0;
}

.light-modal-wrap-rtl .light-modal-footer {
	text-align: left;
}

.light-modal-wrap-rtl .light-modal-footer .light-btn+.light-btn {
	margin-right: 8px;
	margin-left: 0;
}

.light-modal-wrap-rtl .light-modal-confirm-body {
	direction: rtl;
}

.light-modal-wrap-rtl .light-modal-confirm-body>.anticon {
	float: right;
	margin-right: 0;
	margin-left: 16px;
}

.light-modal-wrap-rtl .light-modal-confirm-body>.anticon+.light-modal-confirm-title+.light-modal-confirm-content {
	margin-right: 38px;
	margin-left: 0;
}

.light-modal-wrap-rtl .light-modal-confirm-btns {
	text-align: left;
}

.light-modal-wrap-rtl .light-modal-confirm-btns .light-btn+.light-btn {
	margin-right: 8px;
	margin-left: 0;
}

.light-modal-wrap-rtl.light-modal-centered .light-modal {
	text-align: right;
}

.light-notification {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: fixed;
	z-index: 1010;
	margin-right: 24px;
}

.light-notification-close-icon {
	font-size: 14px;
	cursor: pointer;
}

.light-notification-hook-holder {
	position: relative;
}

.light-notification-notice {
	position: relative;
	width: 384px;
	max-width: calc(100vw - 24px * 2);
	margin-bottom: 16px;
	margin-left: auto;
	padding: 16px 24px;
	overflow: hidden;
	line-height: 1.5715;
	word-wrap: break-word;
	background: #fff;
	border-radius: 2px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.light-notification-top .light-notification-notice,
.light-notification-bottom .light-notification-notice {
	margin-right: auto;
	margin-left: auto;
}

.light-notification-topLeft .light-notification-notice,
.light-notification-bottomLeft .light-notification-notice {
	margin-right: auto;
	margin-left: 0;
}

.light-notification-notice-message {
	margin-bottom: 8px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
	line-height: 24px;
}

.light-notification-notice-message-single-line-auto-margin {
	display: block;
	width: calc(384px - 24px * 2 - 24px - 48px - 100%);
	max-width: 4px;
	background-color: transparent;
	pointer-events: none;
}

.light-notification-notice-message-single-line-auto-margin::before {
	display: block;
	content: "";
}

.light-notification-notice-description {
	font-size: 14px;
}

.light-notification-notice-closable .light-notification-notice-message {
	padding-right: 24px;
}

.light-notification-notice-with-icon .light-notification-notice-message {
	margin-bottom: 4px;
	margin-left: 48px;
	font-size: 16px;
}

.light-notification-notice-with-icon .light-notification-notice-description {
	margin-left: 48px;
	font-size: 14px;
}

.light-notification-notice-icon {
	position: absolute;
	margin-left: 4px;
	font-size: 24px;
	line-height: 24px;
}

.anticon.light-notification-notice-icon-success {
	color: var(--light-success-color);
}

.anticon.light-notification-notice-icon-info {
	color: var(--light-info-color);
}

.anticon.light-notification-notice-icon-warning {
	color: var(--light-warning-color);
}

.anticon.light-notification-notice-icon-error {
	color: var(--light-error-color);
}

.light-notification-notice-close {
	position: absolute;
	top: 16px;
	right: 22px;
	color: rgba(0, 0, 0, 0.45);
	outline: none;
}

.light-notification-notice-close:hover {
	color: rgba(0, 0, 0, 0.67);
}

.light-notification-notice-btn {
	float: right;
	margin-top: 16px;
}

.light-notification .notification-fade-effect {
	animation-duration: 0.24s;
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-fill-mode: both;
}

.light-notification-fade-enter,
.light-notification-fade-appear {
	animation-duration: 0.24s;
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-fill-mode: both;
	opacity: 0;
	animation-play-state: paused;
}

.light-notification-fade-leave {
	animation-duration: 0.24s;
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-fill-mode: both;
	animation-duration: 0.2s;
	animation-play-state: paused;
}

.light-notification-fade-enter.light-notification-fade-enter-active,
.light-notification-fade-appear.light-notification-fade-appear-active {
	animation-name: NotificationFadeIn;
	animation-play-state: running;
}

.light-notification-fade-leave.light-notification-fade-leave-active {
	animation-name: NotificationFadeOut;
	animation-play-state: running;
}

@keyframes NotificationFadeIn {
	0% {
		left: 384px;
		opacity: 0;
	}

	100% {
		left: 0;
		opacity: 1;
	}
}

@keyframes NotificationFadeOut {
	0% {
		max-height: 150px;
		margin-bottom: 16px;
		opacity: 1;
	}

	100% {
		max-height: 0;
		margin-bottom: 0;
		padding-top: 0;
		padding-bottom: 0;
		opacity: 0;
	}
}

.light-notification-rtl {
	direction: rtl;
}

.light-notification-rtl .light-notification-notice-closable .light-notification-notice-message {
	padding-right: 0;
	padding-left: 24px;
}

.light-notification-rtl .light-notification-notice-with-icon .light-notification-notice-message {
	margin-right: 48px;
	margin-left: 0;
}

.light-notification-rtl .light-notification-notice-with-icon .light-notification-notice-description {
	margin-right: 48px;
	margin-left: 0;
}

.light-notification-rtl .light-notification-notice-icon {
	margin-right: 4px;
	margin-left: 0;
}

.light-notification-rtl .light-notification-notice-close {
	right: auto;
	left: 22px;
}

.light-notification-rtl .light-notification-notice-btn {
	float: left;
}

.light-notification-top,
.light-notification-bottom {
	margin-right: 0;
	margin-left: 0;
}

.light-notification-top .light-notification-fade-enter.light-notification-fade-enter-active,
.light-notification-top .light-notification-fade-appear.light-notification-fade-appear-active {
	animation-name: NotificationTopFadeIn;
}

.light-notification-bottom .light-notification-fade-enter.light-notification-fade-enter-active,
.light-notification-bottom .light-notification-fade-appear.light-notification-fade-appear-active {
	animation-name: NotificationBottomFadeIn;
}

.light-notification-topLeft,
.light-notification-bottomLeft {
	margin-right: 0;
	margin-left: 24px;
}

.light-notification-topLeft .light-notification-fade-enter.light-notification-fade-enter-active,
.light-notification-bottomLeft .light-notification-fade-enter.light-notification-fade-enter-active,
.light-notification-topLeft .light-notification-fade-appear.light-notification-fade-appear-active,
.light-notification-bottomLeft .light-notification-fade-appear.light-notification-fade-appear-active {
	animation-name: NotificationLeftFadeIn;
}

@keyframes NotificationTopFadeIn {
	0% {
		margin-top: -100%;
		opacity: 0;
	}

	100% {
		margin-top: 0;
		opacity: 1;
	}
}

@keyframes NotificationBottomFadeIn {
	0% {
		margin-bottom: -100%;
		opacity: 0;
	}

	100% {
		margin-bottom: 0;
		opacity: 1;
	}
}

@keyframes NotificationLeftFadeIn {
	0% {
		right: 384px;
		opacity: 0;
	}

	100% {
		right: 0;
		opacity: 1;
	}
}

.light-page-header {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	padding: 16px 24px;
	background-color: #fff;
}

.light-page-header-ghost {
	background-color: inherit;
}

.light-page-header.has-breadcrumb {
	padding-top: 12px;
}

.light-page-header.has-footer {
	padding-bottom: 0;
}

.light-page-header-back {
	margin-right: 16px;
	font-size: 16px;
	line-height: 1;
}

.light-page-header-back-button {
	color: var(--light-primary-color);
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
	color: #000;
}

.light-page-header-back-button:focus-visible,
.light-page-header-back-button:hover {
	color: var(--light-primary-color-hover);
}

.light-page-header-back-button:active {
	color: var(--light-primary-color-active);
}

.light-page-header .light-divider-vertical {
	height: 14px;
	margin: 0 12px;
	vertical-align: middle;
}

.light-breadcrumb+.light-page-header-heading {
	margin-top: 8px;
}

.light-page-header-heading {
	display: flex;
	justify-content: space-between;
}

.light-page-header-heading-left {
	display: flex;
	align-items: center;
	margin: 4px 0;
	overflow: hidden;
}

.light-page-header-heading-title {
	margin-right: 12px;
	margin-bottom: 0;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.light-page-header-heading .light-avatar {
	margin-right: 12px;
}

.light-page-header-heading-sub-title {
	margin-right: 12px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.5715;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.light-page-header-heading-extra {
	margin: 4px 0;
	white-space: nowrap;
}

.light-page-header-heading-extra>* {
	white-space: unset;
}

.light-page-header-content {
	padding-top: 12px;
}

.light-page-header-footer {
	margin-top: 16px;
}

.light-page-header-footer .light-tabs>.light-tabs-nav {
	margin: 0;
}

.light-page-header-footer .light-tabs>.light-tabs-nav::before {
	border: none;
}

.light-page-header-footer .light-tabs .light-tabs-tab {
	padding-top: 8px;
	padding-bottom: 8px;
	font-size: 16px;
}

.light-page-header-compact .light-page-header-heading {
	flex-wrap: wrap;
}

.light-page-header-rtl {
	direction: rtl;
}

.light-page-header-rtl .light-page-header-back {
	float: right;
	margin-right: 0;
	margin-left: 16px;
}

.light-page-header-rtl .light-page-header-heading-title {
	margin-right: 0;
	margin-left: 12px;
}

.light-page-header-rtl .light-page-header-heading .light-avatar {
	margin-right: 0;
	margin-left: 12px;
}

.light-page-header-rtl .light-page-header-heading-sub-title {
	float: right;
	margin-right: 0;
	margin-left: 12px;
}

.light-page-header-rtl .light-page-header-heading-tags {
	float: right;
}

.light-page-header-rtl .light-page-header-heading-extra {
	float: left;
}

.light-page-header-rtl .light-page-header-heading-extra>* {
	margin-right: 12px;
	margin-left: 0;
}

.light-page-header-rtl .light-page-header-heading-extra>*:first-child {
	margin-right: 0;
}

.light-page-header-rtl .light-page-header-footer .light-tabs-bar .light-tabs-nav {
	float: right;
}

.light-pagination {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
}

.light-pagination ul,
.light-pagination ol {
	margin: 0;
	padding: 0;
	list-style: none;
}

.light-pagination::after {
	display: block;
	clear: both;
	height: 0;
	overflow: hidden;
	visibility: hidden;
	content: " ";
}

.light-pagination-total-text {
	display: inline-block;
	height: 32px;
	margin-right: 8px;
	line-height: 30px;
	vertical-align: middle;
}

.light-pagination-item {
	display: inline-block;
	min-width: 32px;
	height: 32px;
	margin-right: 8px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	line-height: 30px;
	text-align: center;
	vertical-align: middle;
	list-style: none;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	outline: 0;
	cursor: pointer;
	user-select: none;
}

.light-pagination-item a {
	display: block;
	padding: 0 6px;
	color: rgba(0, 0, 0, 0.85);
	transition: none;
}

.light-pagination-item a:hover {
	text-decoration: none;
}

.light-pagination-item:hover {
	border-color: var(--light-primary-color);
	transition: all 0.3s;
}

.light-pagination-item:hover a {
	color: var(--light-primary-color);
}

.light-pagination-item:focus-visible {
	border-color: var(--light-primary-color);
	transition: all 0.3s;
}

.light-pagination-item:focus-visible a {
	color: var(--light-primary-color);
}

.light-pagination-item-active {
	font-weight: 500;
	background: #fff;
	border-color: var(--light-primary-color);
}

.light-pagination-item-active a {
	color: var(--light-primary-color);
}

.light-pagination-item-active:hover {
	border-color: var(--light-primary-5);
}

.light-pagination-item-active:focus-visible {
	border-color: var(--light-primary-5);
}

.light-pagination-item-active:hover a {
	color: var(--light-primary-5);
}

.light-pagination-item-active:focus-visible a {
	color: var(--light-primary-5);
}

.light-pagination-jump-prev,
.light-pagination-jump-next {
	outline: 0;
}

.light-pagination-jump-prev .light-pagination-item-container,
.light-pagination-jump-next .light-pagination-item-container {
	position: relative;
}

.light-pagination-jump-prev .light-pagination-item-container .light-pagination-item-link-icon,
.light-pagination-jump-next .light-pagination-item-container .light-pagination-item-link-icon {
	color: var(--light-primary-color);
	font-size: 12px;
	letter-spacing: -1px;
	opacity: 0;
	transition: all 0.2s;
}

.light-pagination-jump-prev .light-pagination-item-container .light-pagination-item-link-icon-svg,
.light-pagination-jump-next .light-pagination-item-container .light-pagination-item-link-icon-svg {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
}

.light-pagination-jump-prev .light-pagination-item-container .light-pagination-item-ellipsis,
.light-pagination-jump-next .light-pagination-item-container .light-pagination-item-ellipsis {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	margin: auto;
	color: rgba(0, 0, 0, 0.25);
	font-family: Arial, Helvetica, sans-serif;
	letter-spacing: 2px;
	text-align: center;
	text-indent: 0.13em;
	opacity: 1;
	transition: all 0.2s;
}

.light-pagination-jump-prev:hover .light-pagination-item-link-icon,
.light-pagination-jump-next:hover .light-pagination-item-link-icon {
	opacity: 1;
}

.light-pagination-jump-prev:hover .light-pagination-item-ellipsis,
.light-pagination-jump-next:hover .light-pagination-item-ellipsis {
	opacity: 0;
}

.light-pagination-jump-prev:focus-visible .light-pagination-item-link-icon,
.light-pagination-jump-next:focus-visible .light-pagination-item-link-icon {
	opacity: 1;
}

.light-pagination-jump-prev:focus-visible .light-pagination-item-ellipsis,
.light-pagination-jump-next:focus-visible .light-pagination-item-ellipsis {
	opacity: 0;
}

.light-pagination-prev,
.light-pagination-jump-prev,
.light-pagination-jump-next {
	margin-right: 8px;
}

.light-pagination-prev,
.light-pagination-next,
.light-pagination-jump-prev,
.light-pagination-jump-next {
	display: inline-block;
	min-width: 32px;
	height: 32px;
	color: rgba(0, 0, 0, 0.85);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	line-height: 32px;
	text-align: center;
	vertical-align: middle;
	list-style: none;
	border-radius: 2px;
	cursor: pointer;
	transition: all 0.3s;
}

.light-pagination-prev,
.light-pagination-next {
	font-family: Arial, Helvetica, sans-serif;
	outline: 0;
}

.light-pagination-prev button,
.light-pagination-next button {
	color: rgba(0, 0, 0, 0.85);
	cursor: pointer;
	user-select: none;
}

.light-pagination-prev:hover button,
.light-pagination-next:hover button {
	border-color: var(--light-primary-5);
}

.light-pagination-prev .light-pagination-item-link,
.light-pagination-next .light-pagination-item-link {
	display: block;
	width: 100%;
	height: 100%;
	padding: 0;
	font-size: 12px;
	text-align: center;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	outline: none;
	transition: all 0.3s;
}

.light-pagination-prev:focus-visible .light-pagination-item-link,
.light-pagination-next:focus-visible .light-pagination-item-link {
	color: var(--light-primary-color);
	border-color: var(--light-primary-color);
}

.light-pagination-prev:hover .light-pagination-item-link,
.light-pagination-next:hover .light-pagination-item-link {
	color: var(--light-primary-color);
	border-color: var(--light-primary-color);
}

.light-pagination-disabled,
.light-pagination-disabled:hover {
	cursor: not-allowed;
}

.light-pagination-disabled .light-pagination-item-link,
.light-pagination-disabled:hover .light-pagination-item-link {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	cursor: not-allowed;
}

.light-pagination-disabled:focus-visible {
	cursor: not-allowed;
}

.light-pagination-disabled:focus-visible .light-pagination-item-link {
	color: rgba(0, 0, 0, 0.25);
	border-color: #d9d9d9;
	cursor: not-allowed;
}

.light-pagination-slash {
	margin: 0 10px 0 5px;
}

.light-pagination-options {
	display: inline-block;
	margin-left: 16px;
	vertical-align: middle;
}

@media all and (-ms-high-contrast: none) {

	.light-pagination-options *::-ms-backdrop,
	.light-pagination-options {
		vertical-align: top;
	}
}

.light-pagination-options-size-changer.light-select {
	display: inline-block;
	width: auto;
}

.light-pagination-options-quick-jumper {
	display: inline-block;
	height: 32px;
	margin-left: 8px;
	line-height: 32px;
	vertical-align: top;
}

.light-pagination-options-quick-jumper input {
	position: relative;
	display: inline-block;
	width: 100%;
	min-width: 0;
	padding: 4px 11px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 1.5715;
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s;
	width: 50px;
	height: 32px;
	margin: 0 8px;
}

.light-pagination-options-quick-jumper input::placeholder {
	color: #bfbfbf;
	user-select: none;
}

.light-pagination-options-quick-jumper input:placeholder-shown {
	text-overflow: ellipsis;
}

.light-pagination-options-quick-jumper input:hover {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
}

.light-input-rtl .light-pagination-options-quick-jumper input:hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-pagination-options-quick-jumper input:focus,
.light-pagination-options-quick-jumper input-focused {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-rtl .light-pagination-options-quick-jumper input:focus,
.light-input-rtl .light-pagination-options-quick-jumper input-focused {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-pagination-options-quick-jumper input-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-pagination-options-quick-jumper input-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-pagination-options-quick-jumper input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	cursor: not-allowed;
	opacity: 1;
}

.light-pagination-options-quick-jumper input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px;
}

.light-pagination-options-quick-jumper input-borderless,
.light-pagination-options-quick-jumper input-borderless:hover,
.light-pagination-options-quick-jumper input-borderless:focus,
.light-pagination-options-quick-jumper input-borderless-focused,
.light-pagination-options-quick-jumper input-borderless-disabled,
.light-pagination-options-quick-jumper input-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

textarea.light-pagination-options-quick-jumper input {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5715;
	vertical-align: bottom;
	transition: all 0.3s, height 0s;
}

.light-pagination-options-quick-jumper input-lg {
	padding: 6.5px 11px;
	font-size: 16px;
}

.light-pagination-options-quick-jumper input-sm {
	padding: 0px 7px;
}

.light-pagination-options-quick-jumper input-rtl {
	direction: rtl;
}

.light-pagination-simple .light-pagination-prev,
.light-pagination-simple .light-pagination-next {
	height: 24px;
	line-height: 24px;
	vertical-align: top;
}

.light-pagination-simple .light-pagination-prev .light-pagination-item-link,
.light-pagination-simple .light-pagination-next .light-pagination-item-link {
	height: 24px;
	background-color: transparent;
	border: 0;
}

.light-pagination-simple .light-pagination-prev .light-pagination-item-link::after,
.light-pagination-simple .light-pagination-next .light-pagination-item-link::after {
	height: 24px;
	line-height: 24px;
}

.light-pagination-simple .light-pagination-simple-pager {
	display: inline-block;
	height: 24px;
	margin-right: 8px;
}

.light-pagination-simple .light-pagination-simple-pager input {
	box-sizing: border-box;
	height: 100%;
	margin-right: 8px;
	padding: 0 6px;
	text-align: center;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	outline: none;
	transition: border-color 0.3s;
}

.light-pagination-simple .light-pagination-simple-pager input:hover {
	border-color: var(--light-primary-color);
}

.light-pagination-simple .light-pagination-simple-pager input:focus {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
}

.light-pagination-simple .light-pagination-simple-pager input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}

.light-pagination.light-pagination-mini .light-pagination-total-text,
.light-pagination.light-pagination-mini .light-pagination-simple-pager {
	height: 24px;
	line-height: 24px;
}

.light-pagination.light-pagination-mini .light-pagination-item {
	min-width: 24px;
	height: 24px;
	margin: 0;
	line-height: 22px;
}

.light-pagination.light-pagination-mini .light-pagination-item:not(.light-pagination-item-active) {
	background: transparent;
	border-color: transparent;
}

.light-pagination.light-pagination-mini .light-pagination-prev,
.light-pagination.light-pagination-mini .light-pagination-next {
	min-width: 24px;
	height: 24px;
	margin: 0;
	line-height: 24px;
}

.light-pagination.light-pagination-mini .light-pagination-prev .light-pagination-item-link,
.light-pagination.light-pagination-mini .light-pagination-next .light-pagination-item-link {
	background: transparent;
	border-color: transparent;
}

.light-pagination.light-pagination-mini .light-pagination-prev .light-pagination-item-link::after,
.light-pagination.light-pagination-mini .light-pagination-next .light-pagination-item-link::after {
	height: 24px;
	line-height: 24px;
}

.light-pagination.light-pagination-mini .light-pagination-jump-prev,
.light-pagination.light-pagination-mini .light-pagination-jump-next {
	height: 24px;
	margin-right: 0;
	line-height: 24px;
}

.light-pagination.light-pagination-mini .light-pagination-options {
	margin-left: 2px;
}

.light-pagination.light-pagination-mini .light-pagination-options-size-changer {
	top: 0px;
}

.light-pagination.light-pagination-mini .light-pagination-options-quick-jumper {
	height: 24px;
	line-height: 24px;
}

.light-pagination.light-pagination-mini .light-pagination-options-quick-jumper input {
	padding: 0px 7px;
	width: 44px;
	height: 24px;
}

.light-pagination.light-pagination-disabled {
	cursor: not-allowed;
}

.light-pagination.light-pagination-disabled .light-pagination-item {
	background: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}

.light-pagination.light-pagination-disabled .light-pagination-item a {
	color: rgba(0, 0, 0, 0.25);
	background: transparent;
	border: none;
	cursor: not-allowed;
}

.light-pagination.light-pagination-disabled .light-pagination-item-active {
	background: #e6e6e6;
}

.light-pagination.light-pagination-disabled .light-pagination-item-active a {
	color: rgba(0, 0, 0, 0.25);
}

.light-pagination.light-pagination-disabled .light-pagination-item-link {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}

.light-pagination-simple.light-pagination.light-pagination-disabled .light-pagination-item-link {
	background: transparent;
}

.light-pagination.light-pagination-disabled .light-pagination-item-link-icon {
	opacity: 0;
}

.light-pagination.light-pagination-disabled .light-pagination-item-ellipsis {
	opacity: 1;
}

.light-pagination.light-pagination-disabled .light-pagination-simple-pager {
	color: rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 992px) {

	.light-pagination-item-after-jump-prev,
	.light-pagination-item-before-jump-next {
		display: none;
	}
}

@media only screen and (max-width: 576px) {
	.light-pagination-options {
		display: none;
	}
}

.light-pagination-rtl .light-pagination-total-text {
	margin-right: 0;
	margin-left: 8px;
}

.light-pagination-rtl .light-pagination-item,
.light-pagination-rtl .light-pagination-prev,
.light-pagination-rtl .light-pagination-jump-prev,
.light-pagination-rtl .light-pagination-jump-next {
	margin-right: 0;
	margin-left: 8px;
}

.light-pagination-rtl .light-pagination-slash {
	margin: 0 5px 0 10px;
}

.light-pagination-rtl .light-pagination-options {
	margin-right: 16px;
	margin-left: 0;
}

.light-pagination-rtl .light-pagination-options .light-pagination-options-size-changer.light-select {
	margin-right: 0;
	margin-left: 8px;
}

.light-pagination-rtl .light-pagination-options .light-pagination-options-quick-jumper {
	margin-left: 0;
}

.light-pagination-rtl.light-pagination-simple .light-pagination-simple-pager {
	margin-right: 0;
	margin-left: 8px;
}

.light-pagination-rtl.light-pagination-simple .light-pagination-simple-pager input {
	margin-right: 0;
	margin-left: 8px;
}

.light-pagination-rtl.light-pagination.mini .light-pagination-options {
	margin-right: 2px;
	margin-left: 0;
}

.light-popconfirm {
	z-index: 1060;
}

.light-popover {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1030;
	font-weight: normal;
	white-space: normal;
	text-align: left;
	cursor: auto;
	user-select: text;
}

.light-popover-content {
	position: relative;
}

.light-popover::after {
	position: absolute;
	background: rgba(255, 255, 255, 0.01);
	content: "";
}

.light-popover-hidden {
	display: none;
}

.light-popover-placement-top,
.light-popover-placement-topLeft,
.light-popover-placement-topRight {
	padding-bottom: 0;
}

.light-popover-placement-right,
.light-popover-placement-rightTop,
.light-popover-placement-rightBottom {
	padding-left: 0;
}

.light-popover-placement-bottom,
.light-popover-placement-bottomLeft,
.light-popover-placement-bottomRight {
	padding-top: 0;
}

.light-popover-placement-left,
.light-popover-placement-leftTop,
.light-popover-placement-leftBottom {
	padding-right: 0;
}

.light-popover-inner {
	background-color: var(--main-bg);
	background-clip: padding-box;
	border-radius: 8px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {
	.light-popover {
		/* IE10+ */
	}

	.light-popover-inner {
		box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
			0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	}
}

.light-popover-title {
	min-width: 177px;
	min-height: 32px;
	margin: 0;
	padding: 5px 16px 4px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	border-bottom: 1px solid var(--border-color);
}

.light-popover-inner-content {
	max-width: 500px;
	padding: 12px 20px 8px 12px;
	color: rgba(0, 0, 0, 0.85);
}

.light-popover-message {
	position: relative;
	padding: 4px 0 12px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
}

.light-popover-message>.anticon {
	position: absolute;
	top: 8.0005px;
	color: var(--light-warning-color);
	font-size: 14px;
}

.light-popover-message-title {
	padding-left: 22px;
}
.light-popover-message-icon {
	position: absolute; 
	top: 3px;
}

.light-popover-buttons {
	margin-bottom: 4px;
	text-align: right;
}

.light-popover-buttons button:not(:first-child) {
	margin-left: 8px;
}

.light-popover-arrow {
	position: absolute;
	display: block;
	width: 22px;
	height: 22px;
	overflow: hidden;
	background: transparent;
	pointer-events: none;
}

.light-popover-arrow-content {
	--antd-arrow-background-color: #fff;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 11.3137085px;
	height: 11.3137085px;
	margin: auto;
	content: "";
	pointer-events: auto;
	border-radius: 0 0 2px;
	pointer-events: none;
}

.light-popover-arrow-content::before {
	position: absolute;
	top: -11.3137085px;
	left: -11.3137085px;
	width: 33.9411255px;
	height: 33.9411255px;
	background: var(--antd-arrow-background-color);
	background-repeat: no-repeat;
	background-position: -10px -10px;
	content: "";
	clip-path: inset(33% 33%);
	clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z"
		);
}

.light-popover-placement-top .light-popover-arrow,
.light-popover-placement-topLeft .light-popover-arrow,
.light-popover-placement-topRight .light-popover-arrow {
	bottom: 0;
	transform: translateY(100%);
}

.light-popover-placement-top .light-popover-arrow-content,
.light-popover-placement-topLeft .light-popover-arrow-content,
.light-popover-placement-topRight .light-popover-arrow-content {
	box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
	transform: translateY(-11px) rotate(45deg);
}

.light-popover-placement-top .light-popover-arrow {
	left: 50%;
	transform: translateY(100%) translateX(-50%);
}

.light-popover-placement-topLeft .light-popover-arrow {
	left: 16px;
}

.light-popover-placement-topRight .light-popover-arrow {
	right: 16px;
}

.light-popover-placement-right .light-popover-arrow,
.light-popover-placement-rightTop .light-popover-arrow,
.light-popover-placement-rightBottom .light-popover-arrow {
	left: 0;
	transform: translateX(-100%);
}

.light-popover-placement-right .light-popover-arrow-content,
.light-popover-placement-rightTop .light-popover-arrow-content,
.light-popover-placement-rightBottom .light-popover-arrow-content {
	box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
	transform: translateX(11px) rotate(135deg);
}

.light-popover-placement-right .light-popover-arrow {
	top: 50%;
	transform: translateX(-100%) translateY(-50%);
}

.light-popover-placement-rightTop .light-popover-arrow {
	top: 12px;
}

.light-popover-placement-rightBottom .light-popover-arrow {
	bottom: 12px;
}

.light-popover-placement-bottom .light-popover-arrow,
.light-popover-placement-bottomLeft .light-popover-arrow,
.light-popover-placement-bottomRight .light-popover-arrow {
	top: 0;
	transform: translateY(-100%);
}

.light-popover-placement-bottom .light-popover-arrow-content,
.light-popover-placement-bottomLeft .light-popover-arrow-content,
.light-popover-placement-bottomRight .light-popover-arrow-content {
	box-shadow: 2px 2px 5px var(--border-color);
	transform: translateY(11px) rotate(-135deg);
}

.light-popover-placement-bottom .light-popover-arrow {
	left: 50%;
	transform: translateY(-100%) translateX(-50%);
}

.light-popover-placement-bottomLeft .light-popover-arrow {
	left: 16px;
}

.light-popover-placement-bottomRight .light-popover-arrow {
	right: 16px;
}

.light-popover-placement-left .light-popover-arrow,
.light-popover-placement-leftTop .light-popover-arrow,
.light-popover-placement-leftBottom .light-popover-arrow {
	right: 0;
	transform: translateX(100%);
}

.light-popover-placement-left .light-popover-arrow-content,
.light-popover-placement-leftTop .light-popover-arrow-content,
.light-popover-placement-leftBottom .light-popover-arrow-content {
	box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
	transform: translateX(-11px) rotate(-45deg);
}

.light-popover-placement-left .light-popover-arrow {
	top: 50%;
	transform: translateX(100%) translateY(-50%);
}

.light-popover-placement-leftTop .light-popover-arrow {
	top: 12px;
}

.light-popover-placement-leftBottom .light-popover-arrow {
	bottom: 12px;
}

.light-popover-pink .light-popover-inner {
	background-color: #eb2f96;
}

.light-popover-pink .light-popover-arrow-content {
	background-color: #eb2f96;
}

.light-popover-magenta .light-popover-inner {
	background-color: #eb2f96;
}

.light-popover-magenta .light-popover-arrow-content {
	background-color: #eb2f96;
}

.light-popover-red .light-popover-inner {
	background-color: #f5222d;
}

.light-popover-red .light-popover-arrow-content {
	background-color: #f5222d;
}

.light-popover-volcano .light-popover-inner {
	background-color: #fa541c;
}

.light-popover-volcano .light-popover-arrow-content {
	background-color: #fa541c;
}

.light-popover-orange .light-popover-inner {
	background-color: #fa8c16;
}

.light-popover-orange .light-popover-arrow-content {
	background-color: #fa8c16;
}

.light-popover-yellow .light-popover-inner {
	background-color: #fadb14;
}

.light-popover-yellow .light-popover-arrow-content {
	background-color: #fadb14;
}

.light-popover-gold .light-popover-inner {
	background-color: #faad14;
}

.light-popover-gold .light-popover-arrow-content {
	background-color: #faad14;
}

.light-popover-cyan .light-popover-inner {
	background-color: #13c2c2;
}

.light-popover-cyan .light-popover-arrow-content {
	background-color: #13c2c2;
}

.light-popover-lime .light-popover-inner {
	background-color: #a0d911;
}

.light-popover-lime .light-popover-arrow-content {
	background-color: #a0d911;
}

.light-popover-green .light-popover-inner {
	background-color: #52c41a;
}

.light-popover-green .light-popover-arrow-content {
	background-color: #52c41a;
}

.light-popover-blue .light-popover-inner {
	background-color: #1890ff;
}

.light-popover-blue .light-popover-arrow-content {
	background-color: #1890ff;
}

.light-popover-geekblue .light-popover-inner {
	background-color: #2f54eb;
}

.light-popover-geekblue .light-popover-arrow-content {
	background-color: #2f54eb;
}

.light-popover-purple .light-popover-inner {
	background-color: #722ed1;
}

.light-popover-purple .light-popover-arrow-content {
	background-color: #722ed1;
}

.light-popover-rtl {
	direction: rtl;
	text-align: right;
}

.light-popover-rtl .light-popover-message-title {
	padding-right: 22px;
	padding-left: 16px;
}

.light-popover-rtl .light-popover-buttons {
	text-align: left;
}

.light-popover-rtl .light-popover-buttons button {
	margin-right: 8px;
	margin-left: 0;
}

.light-progress {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: inline-block;
}

.light-progress-line {
	position: relative;
	width: 100%;
	font-size: 14px;
}

.light-progress-steps {
	display: inline-block;
}

.light-progress-steps-outer {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.light-progress-steps-item {
	flex-shrink: 0;
	min-width: 2px;
	margin-right: 2px;
	background: #f3f3f3;
	transition: all 0.3s;
}

.light-progress-steps-item-active {
	background: var(--light-primary-color);
}

.light-progress-small.light-progress-line,
.light-progress-small.light-progress-line .light-progress-text .anticon {
	font-size: 12px;
}

.light-progress-outer {
	display: inline-block;
	width: 100%;
	margin-right: 0;
	padding-right: 0;
}

.light-progress-show-info .light-progress-outer {
	margin-right: calc(-2em - 8px);
	padding-right: calc(2em + 8px);
}

.light-progress-inner {
	position: relative;
	display: inline-block;
	width: 100%;
	overflow: hidden;
	vertical-align: middle;
	background-color: rgba(0, 0, 0, 0.04);
	border-radius: 100px;
}

.light-progress-circle-trail {
	stroke: rgba(0, 0, 0, 0.04);
}

.light-progress-circle-path {
	animation: light-progress-appear 0.3s;
}

.light-progress-inner:not(.light-progress-circle-gradient) .light-progress-circle-path {
	stroke: var(--light-primary-color);
}

.light-progress-success-bg,
.light-progress-bg {
	position: relative;
	background-color: var(--light-primary-color);
	border-radius: 100px;
	transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}

.light-progress-success-bg {
	position: absolute;
	top: 0;
	left: 0;
	background-color: var(--light-success-color);
}

.light-progress-text {
	display: inline-block;
	width: 2em;
	margin-left: 8px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 1em;
	line-height: 1;
	white-space: nowrap;
	text-align: left;
	vertical-align: middle;
	word-break: normal;
}

.light-progress-text .anticon {
	font-size: 14px;
}

.light-progress-status-active .light-progress-bg::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: #fff;
	border-radius: 10px;
	opacity: 0;
	animation: light-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
	content: "";
}

.light-progress-status-exception .light-progress-bg {
	background-color: var(--light-error-color);
}

.light-progress-status-exception .light-progress-text {
	color: var(--light-error-color);
}

.light-progress-status-exception .light-progress-inner:not(.light-progress-circle-gradient) .light-progress-circle-path {
	stroke: var(--light-error-color);
}

.light-progress-status-success .light-progress-bg {
	background-color: var(--light-success-color);
}

.light-progress-status-success .light-progress-text {
	color: var(--light-success-color);
}

.light-progress-status-success .light-progress-inner:not(.light-progress-circle-gradient) .light-progress-circle-path {
	stroke: var(--light-success-color);
}

.light-progress-circle .light-progress-inner {
	position: relative;
	line-height: 1;
	background-color: transparent;
}

.light-progress-circle .light-progress-text {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 1em;
	line-height: 1;
	white-space: normal;
	text-align: center;
	transform: translate(-50%, -50%);
}

.light-progress-circle .light-progress-text .anticon {
	font-size: 1.16666667em;
}

.light-progress-circle.light-progress-status-exception .light-progress-text {
	color: var(--light-error-color);
}

.light-progress-circle.light-progress-status-success .light-progress-text {
	color: var(--light-success-color);
}

@keyframes light-progress-active {
	0% {
		transform: translateX(-100%) scaleX(0);
		opacity: 0.1;
	}

	20% {
		transform: translateX(-100%) scaleX(0);
		opacity: 0.5;
	}

	100% {
		transform: translateX(0) scaleX(1);
		opacity: 0;
	}
}

.light-progress-rtl {
	direction: rtl;
}

.light-progress-rtl.light-progress-show-info .light-progress-outer {
	margin-right: 0;
	margin-left: calc(-2em - 8px);
	padding-right: 0;
	padding-left: calc(2em + 8px);
}

.light-progress-rtl .light-progress-success-bg {
	right: 0;
	left: auto;
}

.light-progress-rtl.light-progress-line .light-progress-text,
.light-progress-rtl.light-progress-steps .light-progress-text {
	margin-right: 8px;
	margin-left: 0;
	text-align: right;
}

.light-radio-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: inline-block;
	font-size: 0;
}

.light-radio-group .light-badge-count {
	z-index: 1;
}

.light-radio-group>.light-badge:not(:first-child)>.light-radio-button-wrapper {
	border-left: none;
}

.light-radio-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: inline-flex;
	align-items: baseline;
	margin-right: 8px;
	cursor: pointer;
}

.light-radio-wrapper-disabled {
	cursor: not-allowed;
}

.light-radio-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: "\a0";
}

.light-radio-wrapper.light-radio-wrapper-in-form-item input[type="radio"] {
	width: 14px;
	height: 14px;
}

.light-radio {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	top: 0.2em;
	display: inline-block;
	outline: none;
	cursor: pointer;
}

.light-radio-wrapper:hover .light-radio,
.light-radio:hover .light-radio-inner,
.light-radio-input:focus+.light-radio-inner {
	border-color: var(--light-primary-color);
}

.light-radio-input:focus+.light-radio-inner {
	box-shadow: 0 0 0 3px var(--light-primary-color-deprecated-f-12);
}

.light-radio-checked::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid var(--light-primary-color);
	border-radius: 50%;
	visibility: hidden;
	animation: antRadioEffect 0.36s ease-in-out;
	animation-fill-mode: both;
	content: "";
}

.light-radio:hover::after,
.light-radio-wrapper:hover .light-radio::after {
	visibility: visible;
}

.light-radio-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 16px;
	height: 16px;
	background-color: #fff;
	border-color: #d9d9d9;
	border-style: solid;
	border-width: 1px;
	border-radius: 50%;
	transition: all 0.3s;
}

.light-radio-inner::after {
	position: absolute;
	top: 50%;
	left: 50%;
	display: block;
	width: 16px;
	height: 16px;
	margin-top: -8px;
	margin-left: -8px;
	background-color: var(--light-primary-color);
	border-top: 0;
	border-left: 0;
	border-radius: 16px;
	transform: scale(0);
	opacity: 0;
	transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	content: " ";
}

.light-radio-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	cursor: pointer;
	opacity: 0;
}

.light-radio.light-radio-disabled .light-radio-inner {
	border-color: #d9d9d9;
}

.light-radio-checked .light-radio-inner {
	border-color: var(--light-primary-color);
}

.light-radio-checked .light-radio-inner::after {
	transform: scale(0.5);
	opacity: 1;
	transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.light-radio-disabled {
	cursor: not-allowed;
}

.light-radio-disabled .light-radio-inner {
	background-color: #f5f5f5;
	cursor: not-allowed;
}

.light-radio-disabled .light-radio-inner::after {
	background-color: rgba(0, 0, 0, 0.2);
}

.light-radio-disabled .light-radio-input {
	cursor: not-allowed;
}

.light-radio-disabled+span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

span.light-radio+* {
	padding-right: 8px;
	padding-left: 8px;
}

.light-radio-button-wrapper {
	position: relative;
	display: inline-block;
	height: 32px;
	margin: 0;
	padding: 0 15px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	line-height: 30px;
	background: #fff;
	border: 1px solid #d9d9d9;
	border-top-width: 1.02px;
	border-left-width: 0;
	cursor: pointer;
	transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.light-radio-button-wrapper a {
	color: rgba(0, 0, 0, 0.85);
}

.light-radio-button-wrapper>.light-radio-button {
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
}

.light-radio-group-large .light-radio-button-wrapper {
	height: 40px;
	font-size: 16px;
	line-height: 38px;
}

.light-radio-group-small .light-radio-button-wrapper {
	height: 24px;
	padding: 0 7px;
	line-height: 22px;
}

.light-radio-button-wrapper:not(:first-child)::before {
	position: absolute;
	top: -1px;
	left: -1px;
	display: block;
	box-sizing: content-box;
	width: 1px;
	height: 100%;
	padding: 1px 0;
	background-color: #d9d9d9;
	transition: background-color 0.3s;
	content: "";
}

.light-radio-button-wrapper:first-child {
	border-left: 1px solid #d9d9d9;
	border-radius: 2px 0 0 2px;
}

.light-radio-button-wrapper:last-child {
	border-radius: 0 2px 2px 0;
}

.light-radio-button-wrapper:first-child:last-child {
	border-radius: 2px;
}

.light-radio-button-wrapper:hover {
	position: relative;
	color: var(--light-primary-color);
}

.light-radio-button-wrapper:focus-within {
	box-shadow: 0 0 0 3px var(--light-primary-color-deprecated-f-12);
}

.light-radio-button-wrapper .light-radio-inner,
.light-radio-button-wrapper input[type="checkbox"],
.light-radio-button-wrapper input[type="radio"] {
	width: 0;
	height: 0;
	opacity: 0;
	pointer-events: none;
}

.light-radio-button-wrapper-checked:not(.light-radio-button-wrapper-disabled) {
	z-index: 1;
	color: var(--light-primary-color);
	background: #fff;
	border-color: var(--light-primary-color);
}

.light-radio-button-wrapper-checked:not(.light-radio-button-wrapper-disabled)::before {
	background-color: var(--light-primary-color);
}

.light-radio-button-wrapper-checked:not(.light-radio-button-wrapper-disabled):first-child {
	border-color: var(--light-primary-color);
}

.light-radio-button-wrapper-checked:not(.light-radio-button-wrapper-disabled):hover {
	color: var(--light-primary-5);
	border-color: var(--light-primary-5);
}

.light-radio-button-wrapper-checked:not(.light-radio-button-wrapper-disabled):hover::before {
	background-color: var(--light-primary-5);
}

.light-radio-button-wrapper-checked:not(.light-radio-button-wrapper-disabled):active {
	color: var(--light-primary-7);
	border-color: var(--light-primary-7);
}

.light-radio-button-wrapper-checked:not(.light-radio-button-wrapper-disabled):active::before {
	background-color: var(--light-primary-7);
}

.light-radio-button-wrapper-checked:not(.light-radio-button-wrapper-disabled):focus-within {
	box-shadow: 0 0 0 3px var(--light-primary-color-deprecated-f-12);
}

.light-radio-group-solid .light-radio-button-wrapper-checked:not(.light-radio-button-wrapper-disabled) {
	color: #fff;
	background: var(--light-primary-color);
	border-color: var(--light-primary-color);
}

.light-radio-group-solid .light-radio-button-wrapper-checked:not(.light-radio-button-wrapper-disabled):hover {
	color: #fff;
	background: var(--light-primary-5);
	border-color: var(--light-primary-5);
}

.light-radio-group-solid .light-radio-button-wrapper-checked:not(.light-radio-button-wrapper-disabled):active {
	color: #fff;
	background: var(--light-primary-7);
	border-color: var(--light-primary-7);
}

.light-radio-group-solid .light-radio-button-wrapper-checked:not(.light-radio-button-wrapper-disabled):focus-within {
	box-shadow: 0 0 0 3px var(--light-primary-color-deprecated-f-12);
}

.light-radio-button-wrapper-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}

.light-radio-button-wrapper-disabled:first-child,
.light-radio-button-wrapper-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	border-color: #d9d9d9;
}

.light-radio-button-wrapper-disabled:first-child {
	border-left-color: #d9d9d9;
}

.light-radio-button-wrapper-disabled.light-radio-button-wrapper-checked {
	color: rgba(0, 0, 0, 0.25);
	background-color: #e6e6e6;
	border-color: #d9d9d9;
	box-shadow: none;
}

@keyframes antRadioEffect {
	0% {
		transform: scale(1);
		opacity: 0.5;
	}

	100% {
		transform: scale(1.6);
		opacity: 0;
	}
}

.light-radio-group.light-radio-group-rtl {
	direction: rtl;
}

.light-radio-wrapper.light-radio-wrapper-rtl {
	margin-right: 0;
	margin-left: 8px;
	direction: rtl;
}

.light-radio-button-wrapper.light-radio-button-wrapper-rtl {
	border-right-width: 0;
	border-left-width: 1px;
}

.light-radio-button-wrapper.light-radio-button-wrapper-rtl.light-radio-button-wrapper:not(:first-child)::before {
	right: -1px;
	left: 0;
}

.light-radio-button-wrapper.light-radio-button-wrapper-rtl.light-radio-button-wrapper:first-child {
	border-right: 1px solid #d9d9d9;
	border-radius: 0 2px 2px 0;
}

.light-radio-button-wrapper-checked:not([class*=" light-radio-button-wrapper-disabled"]).light-radio-button-wrapper:first-child {
	border-right-color: var(--light-primary-5);
}

.light-radio-button-wrapper.light-radio-button-wrapper-rtl.light-radio-button-wrapper:last-child {
	border-radius: 2px 0 0 2px;
}

.light-radio-button-wrapper.light-radio-button-wrapper-rtl.light-radio-button-wrapper-disabled:first-child {
	border-right-color: #d9d9d9;
}

.light-rate {
	box-sizing: border-box;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	font-feature-settings: "tnum";
	display: inline-block;
	margin: 0;
	padding: 0;
	color: #fadb14;
	font-size: 20px;
	line-height: unset;
	list-style: none;
	outline: none;
}

.light-rate-disabled .light-rate-star {
	cursor: default;
}

.light-rate-disabled .light-rate-star>div:hover {
	transform: scale(1);
}

.light-rate-star {
	position: relative;
	display: inline-block;
	color: inherit;
	cursor: pointer;
}

.light-rate-star:not(:last-child) {
	margin-right: 8px;
}

.light-rate-star>div {
	transition: all 0.3s, outline 0s;
}

.light-rate-star>div:hover {
	transform: scale(1.1);
}

.light-rate-star>div:focus {
	outline: 0;
}

.light-rate-star>div:focus-visible {
	outline: 1px dashed #fadb14;
	transform: scale(1.1);
}

.light-rate-star-first,
.light-rate-star-second {
	color: var(--border-color);
	transition: all 0.3s;
	user-select: none;
}

.light-rate-star-first .anticon,
.light-rate-star-second .anticon {
	vertical-align: middle;
}

.light-rate-star-first {
	position: absolute;
	top: 0;
	left: 0;
	width: 50%;
	height: 100%;
	overflow: hidden;
	opacity: 0;
}

.light-rate-star-half .light-rate-star-first,
.light-rate-star-half .light-rate-star-second {
	opacity: 1;
}

.light-rate-star-half .light-rate-star-first,
.light-rate-star-full .light-rate-star-second {
	color: inherit;
}

.light-rate-text {
	display: inline-block;
	margin: 0 8px;
	font-size: 14px;
}

.light-rate-rtl {
	direction: rtl;
}

.light-rate-rtl .light-rate-star:not(:last-child) {
	margin-right: 0;
	margin-left: 8px;
}

.light-rate-rtl .light-rate-star-first {
	right: 0;
	left: auto;
}

.light-result {
	padding: 48px 32px;
}

.light-result-success .light-result-icon>.anticon {
	color: var(--light-success-color);
}

.light-result-error .light-result-icon>.anticon {
	color: var(--light-error-color);
}

.light-result-info .light-result-icon>.anticon {
	color: var(--light-info-color);
}

.light-result-warning .light-result-icon>.anticon {
	color: var(--light-warning-color);
}

.light-result-image {
	width: 250px;
	height: 295px;
	margin: auto;
}

.light-result-icon {
	margin-bottom: 24px;
	text-align: center;
}

.light-result-icon>.anticon {
	font-size: 72px;
}

.light-result-title {
	color: rgba(0, 0, 0, 0.85);
	font-size: 24px;
	line-height: 1.8;
	text-align: center;
}

.light-result-subtitle {
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
	line-height: 1.6;
	text-align: center;
}

.light-result-extra {
	margin: 24px 0 0 0;
	text-align: center;
}

.light-result-extra>* {
	margin-right: 8px;
}

.light-result-extra>*:last-child {
	margin-right: 0;
}

.light-result-content {
	margin-top: 24px;
	padding: 24px 40px;
	background-color: #fafafa;
}

.light-result-rtl {
	direction: rtl;
}

.light-result-rtl .light-result-extra>* {
	margin-right: 0;
	margin-left: 8px;
}

.light-result-rtl .light-result-extra>*:last-child {
	margin-left: 0;
}

.segmented-disabled-item,
.segmented-disabled-item:hover,
.segmented-disabled-item:focus {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.segmented-item-selected {
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05),
		0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
}

.segmented-text-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: keep-all;
}

.light-segmented {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: inline-block;
	padding: 2px;
	color: rgba(0, 0, 0, 0.65);
	background-color: rgba(0, 0, 0, 0.04);
	border-radius: 2px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-segmented-group {
	position: relative;
	display: flex;
	align-items: stretch;
	justify-items: flex-start;
	width: 100%;
}

.light-segmented.light-segmented-block {
	display: flex;
}

.light-segmented.light-segmented-block .light-segmented-item {
	flex: 1;
	min-width: 0;
}

.light-segmented:not(.light-segmented-disabled):hover,
.light-segmented:not(.light-segmented-disabled):focus {
	background-color: var(--border-color);
}

.light-segmented-item {
	position: relative;
	text-align: center;
	cursor: pointer;
	transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-segmented-item-selected {
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05),
		0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
	color: #262626;
}

.light-segmented-item:hover,
.light-segmented-item:focus {
	color: #262626;
}

.light-segmented-item-label {
	min-height: 28px;
	padding: 0 11px;
	line-height: 28px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: keep-all;
}

.light-segmented-item-icon+* {
	margin-left: 6px;
}

.light-segmented-item-input {
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 0;
	opacity: 0;
	pointer-events: none;
}

.light-segmented.light-segmented-lg .light-segmented-item-label {
	min-height: 36px;
	padding: 0 11px;
	font-size: 16px;
	line-height: 36px;
}

.light-segmented.light-segmented-sm .light-segmented-item-label {
	min-height: 20px;
	padding: 0 7px;
	line-height: 20px;
}

.light-segmented-item-disabled,
.light-segmented-item-disabled:hover,
.light-segmented-item-disabled:focus {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-segmented-thumb {
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px -2px rgba(0, 0, 0, 0.05),
		0 1px 4px -1px rgba(0, 0, 0, 0.07), 0 0 1px 0 rgba(0, 0, 0, 0.08);
	position: absolute;
	top: 0;
	left: 0;
	width: 0;
	height: 100%;
	padding: 4px 0;
}

.light-segmented-thumb-motion-appear-active {
	transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	will-change: transform, width;
}

.light-segmented.light-segmented-rtl {
	direction: rtl;
}

.light-segmented.light-segmented-rtl .light-segmented-item-icon {
	margin-right: 0;
	margin-left: 6px;
}

.light-select-single .light-select-selector {
	display: flex;
}

.light-select-single .light-select-selector .light-select-selection-search {
	position: absolute;
	top: 0;
	right: 11px;
	bottom: 0;
	left: 11px;
}

.light-select-single .light-select-selector .light-select-selection-search-input {
	width: 100%;
}

.light-select-single .light-select-selector .light-select-selection-item,
.light-select-single .light-select-selector .light-select-selection-placeholder {
	padding: 0;
	line-height: 30px;
	transition: all 0.3s;
}

.light-select-single .light-select-selector .light-select-selection-item {
	position: relative;
	user-select: none;
}

.light-select-single .light-select-selector .light-select-selection-placeholder {
	transition: none;
	pointer-events: none;
}

.light-select-single .light-select-selector::after,
.light-select-single .light-select-selector .light-select-selection-item::after,
.light-select-single .light-select-selector .light-select-selection-placeholder::after {
	display: inline-block;
	width: 0;
	visibility: hidden;
	content: "\a0";
}

.light-select-single.light-select-show-arrow .light-select-selection-search {
	right: 25px;
}

.light-select-single.light-select-show-arrow .light-select-selection-item,
.light-select-single.light-select-show-arrow .light-select-selection-placeholder {
	padding-right: 18px;
}

.light-select-single.light-select-open .light-select-selection-item {
	color: #bfbfbf;
}

.light-select-single:not(.light-select-customize-input) .light-select-selector {
	width: 100%;
	height: 32px;
	padding: 0 11px;
}

.light-select-single:not(.light-select-customize-input) .light-select-selector .light-select-selection-search-input {
	height: 30px;
}

.light-select-single:not(.light-select-customize-input) .light-select-selector::after {
	line-height: 30px;
}

.light-select-single.light-select-customize-input .light-select-selector::after {
	display: none;
}

.light-select-single.light-select-customize-input .light-select-selector .light-select-selection-search {
	position: static;
	width: 100%;
}

.light-select-single.light-select-customize-input .light-select-selector .light-select-selection-placeholder {
	position: absolute;
	right: 0;
	left: 0;
	padding: 0 11px;
}

.light-select-single.light-select-customize-input .light-select-selector .light-select-selection-placeholder::after {
	display: none;
}

.light-select-single.light-select-lg:not(.light-select-customize-input) .light-select-selector {
	height: 40px;
}

.light-select-single.light-select-lg:not(.light-select-customize-input) .light-select-selector::after,
.light-select-single.light-select-lg:not(.light-select-customize-input) .light-select-selector .light-select-selection-item,
.light-select-single.light-select-lg:not(.light-select-customize-input) .light-select-selector .light-select-selection-placeholder {
	line-height: 38px;
}

.light-select-single.light-select-lg:not(.light-select-customize-input):not(.light-select-customize-input) .light-select-selection-search-input {
	height: 38px;
}

.light-select-single.light-select-sm:not(.light-select-customize-input) .light-select-selector {
	height: 24px;
}

.light-select-single.light-select-sm:not(.light-select-customize-input) .light-select-selector::after,
.light-select-single.light-select-sm:not(.light-select-customize-input) .light-select-selector .light-select-selection-item,
.light-select-single.light-select-sm:not(.light-select-customize-input) .light-select-selector .light-select-selection-placeholder {
	line-height: 22px;
}

.light-select-single.light-select-sm:not(.light-select-customize-input):not(.light-select-customize-input) .light-select-selection-search-input {
	height: 22px;
}

.light-select-single.light-select-sm:not(.light-select-customize-input) .light-select-selection-search {
	right: 7px;
	left: 7px;
}

.light-select-single.light-select-sm:not(.light-select-customize-input) .light-select-selector {
	padding: 0 7px;
}

.light-select-single.light-select-sm:not(.light-select-customize-input).light-select-show-arrow .light-select-selection-search {
	right: 28px;
}

.light-select-single.light-select-sm:not(.light-select-customize-input).light-select-show-arrow .light-select-selection-item,
.light-select-single.light-select-sm:not(.light-select-customize-input).light-select-show-arrow .light-select-selection-placeholder {
	padding-right: 21px;
}

.light-select-single.light-select-lg:not(.light-select-customize-input) .light-select-selector {
	padding: 0 11px;
}

/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.light-select-selection-overflow {
	position: relative;
	display: flex;
	flex: auto;
	flex-wrap: wrap;
	max-width: 100%;
}

.light-select-selection-overflow-item {
	flex: none;
	align-self: center;
	max-width: 100%;
}

.light-select-multiple .light-select-selector {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 1px 4px;
}

.light-select-show-search.light-select-multiple .light-select-selector {
	cursor: text;
}

.light-select-disabled.light-select-multiple .light-select-selector {
	background: #f5f5f5;
	cursor: not-allowed;
}

.light-select-multiple .light-select-selector::after {
	display: inline-block;
	width: 0;
	margin: 2px 0;
	line-height: 24px;
	content: "\a0";
}

.light-select-multiple.light-select-show-arrow .light-select-selector,
.light-select-multiple.light-select-allow-clear .light-select-selector {
	padding-right: 24px;
}

.light-select-multiple .light-select-selection-item {
	position: relative;
	display: flex;
	flex: none;
	box-sizing: border-box;
	max-width: 100%;
	height: 24px;
	margin-top: 2px;
	margin-bottom: 2px;
	line-height: 22px;
	background: #f5f5f5;
	border: 1px solid var(--border-color);
	border-radius: 2px;
	cursor: default;
	transition: font-size 0.3s, line-height 0.3s, height 0.3s;
	user-select: none;
	margin-inline-end: 4px;
	padding-inline-start: 8px;
	padding-inline-end: 4px;
}

.light-select-disabled.light-select-multiple .light-select-selection-item {
	color: #bfbfbf;
	border-color: #d9d9d9;
	cursor: not-allowed;
}

.light-select-multiple .light-select-selection-item-content {
	display: inline-block;
	margin-right: 4px;
	overflow: hidden;
	white-space: pre;
	text-overflow: ellipsis;
}

.light-select-multiple .light-select-selection-item-remove {
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: inline-block;
	color: rgba(0, 0, 0, 0.45);
	font-weight: bold;
	font-size: 10px;
	line-height: inherit;
	cursor: pointer;
}

.light-select-multiple .light-select-selection-item-remove>* {
	line-height: 1;
}

.light-select-multiple .light-select-selection-item-remove svg {
	display: inline-block;
}

.light-select-multiple .light-select-selection-item-remove::before {
	display: none;
}

.light-select-multiple .light-select-selection-item-remove .light-select-multiple .light-select-selection-item-remove-icon {
	display: block;
}

.light-select-multiple .light-select-selection-item-remove>.anticon {
	vertical-align: middle;
}

.light-select-multiple .light-select-selection-item-remove:hover {
	color: rgba(0, 0, 0, 0.75);
}

.light-select-multiple .light-select-selection-overflow-item+.light-select-selection-overflow-item .light-select-selection-search {
	margin-inline-start: 0;
}

.light-select-multiple .light-select-selection-search {
	position: relative;
	max-width: 100%;
	margin-inline-start: 7px;
}

.light-select-multiple .light-select-selection-search-input,
.light-select-multiple .light-select-selection-search-mirror {
	height: 24px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	line-height: 24px;
	transition: all 0.3s;
}

.light-select-multiple .light-select-selection-search-input {
	width: 100%;
	min-width: 4.1px;
}

.light-select-multiple .light-select-selection-search-mirror {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
	white-space: pre;
	visibility: hidden;
}

.light-select-multiple .light-select-selection-placeholder {
	position: absolute;
	top: 50%;
	right: 11px;
	left: 11px;
	transform: translateY(-50%);
	transition: all 0.3s;
}

.light-select-multiple.light-select-lg .light-select-selector::after {
	line-height: 32px;
}

.light-select-multiple.light-select-lg .light-select-selection-item {
	height: 32px;
	line-height: 30px;
}

.light-select-multiple.light-select-lg .light-select-selection-search {
	height: 32px;
	line-height: 32px;
}

.light-select-multiple.light-select-lg .light-select-selection-search-input,
.light-select-multiple.light-select-lg .light-select-selection-search-mirror {
	height: 32px;
	line-height: 30px;
}

.light-select-multiple.light-select-sm .light-select-selector::after {
	line-height: 16px;
}

.light-select-multiple.light-select-sm .light-select-selection-item {
	height: 16px;
	line-height: 14px;
}

.light-select-multiple.light-select-sm .light-select-selection-search {
	height: 16px;
	line-height: 16px;
}

.light-select-multiple.light-select-sm .light-select-selection-search-input,
.light-select-multiple.light-select-sm .light-select-selection-search-mirror {
	height: 16px;
	line-height: 14px;
}

.light-select-multiple.light-select-sm .light-select-selection-placeholder {
	left: 7px;
}

.light-select-multiple.light-select-sm .light-select-selection-search {
	margin-inline-start: 3px;
}

.light-select-multiple.light-select-lg .light-select-selection-item {
	height: 32px;
	line-height: 32px;
}

.light-select-disabled .light-select-selection-item-remove {
	display: none;
}

.light-select-status-error.light-select:not(.light-select-disabled):not(.light-select-customize-input):not(.light-pagination-size-changer) .light-select-selector {
	background-color: #fff;
	border-color: var(--light-error-color) !important;
}

.light-select-status-error.light-select:not(.light-select-disabled):not(.light-select-customize-input):not(.light-pagination-size-changer).light-select-open .light-select-selector,
.light-select-status-error.light-select:not(.light-select-disabled):not(.light-select-customize-input):not(.light-pagination-size-changer).light-select-focused .light-select-selector {
	border-color: var(--light-error-color-hover);
	box-shadow: 0 0 0 2px var(--light-error-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-select-status-warning.light-select:not(.light-select-disabled):not(.light-select-customize-input):not(.light-pagination-size-changer) .light-select-selector {
	background-color: #fff;
	border-color: var(--light-warning-color) !important;
}

.light-select-status-warning.light-select:not(.light-select-disabled):not(.light-select-customize-input):not(.light-pagination-size-changer).light-select-open .light-select-selector,
.light-select-status-warning.light-select:not(.light-select-disabled):not(.light-select-customize-input):not(.light-pagination-size-changer).light-select-focused .light-select-selector {
	border-color: var(--light-warning-color-hover);
	box-shadow: 0 0 0 2px var(--light-warning-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-select-status-error.light-select-has-feedback .light-select-clear,
.light-select-status-warning.light-select-has-feedback .light-select-clear,
.light-select-status-success.light-select-has-feedback .light-select-clear,
.light-select-status-validating.light-select-has-feedback .light-select-clear {
	right: 32px;
}

.light-select-status-error.light-select-has-feedback .light-select-selection-selected-value,
.light-select-status-warning.light-select-has-feedback .light-select-selection-selected-value,
.light-select-status-success.light-select-has-feedback .light-select-selection-selected-value,
.light-select-status-validating.light-select-has-feedback .light-select-selection-selected-value {
	padding-right: 42px;
}

/* Reset search input style */
.light-select {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	cursor: pointer;
}

.light-select:not(.light-select-customize-input) .light-select-selector {
	position: relative;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-select:not(.light-select-customize-input) .light-select-selector input {
	cursor: pointer;
}

.light-select-show-search.light-select:not(.light-select-customize-input) .light-select-selector {
	cursor: text;
}

.light-select-show-search.light-select:not(.light-select-customize-input) .light-select-selector input {
	cursor: auto;
}

.light-select-focused:not(.light-select-disabled).light-select:not(.light-select-customize-input) .light-select-selector {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-rtl .light-select-focused:not(.light-select-disabled).light-select:not(.light-select-customize-input) .light-select-selector {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-select-disabled.light-select:not(.light-select-customize-input) .light-select-selector {
	color: rgba(0, 0, 0, 0.25);
	background: #f5f5f5;
	cursor: not-allowed;
}

.light-select-multiple.light-select-disabled.light-select:not(.light-select-customize-input) .light-select-selector {
	background: #f5f5f5;
}

.light-select-disabled.light-select:not(.light-select-customize-input) .light-select-selector input {
	cursor: not-allowed;
}

.light-select:not(.light-select-customize-input) .light-select-selector .light-select-selection-search-input {
	margin: 0;
	padding: 0;
	background: transparent;
	border: none;
	outline: none;
	appearance: none;
}

.light-select:not(.light-select-customize-input) .light-select-selector .light-select-selection-search-input::-webkit-search-cancel-button {
	display: none;
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-webkit-appearance: none;
}

.light-select:not(.light-select-disabled):hover .light-select-selector {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
}

.light-input-rtl .light-select:not(.light-select-disabled):hover .light-select-selector {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-select-selection-item {
	flex: 1;
	overflow: hidden;
	font-weight: normal;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@media all and (-ms-high-contrast: none) {

	.light-select-selection-item *::-ms-backdrop,
	.light-select-selection-item {
		flex: auto;
	}
}

.light-select-selection-placeholder {
	flex: 1;
	overflow: hidden;
	color: #bfbfbf;
	white-space: nowrap;
	text-overflow: ellipsis;
	pointer-events: none;
}

@media all and (-ms-high-contrast: none) {

	.light-select-selection-placeholder *::-ms-backdrop,
	.light-select-selection-placeholder {
		flex: auto;
	}
}

.light-select-arrow {
	display: inline-block;
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	position: absolute;
	top: 50%;
	right: 11px;
	display: flex;
	align-items: center;
	height: 12px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	line-height: 1;
	text-align: center;
	pointer-events: none;
}

.light-select-arrow>* {
	line-height: 1;
}

.light-select-arrow svg {
	display: inline-block;
}

.light-select-arrow::before {
	display: none;
}

.light-select-arrow .light-select-arrow-icon {
	display: block;
}

.light-select-arrow .anticon {
	vertical-align: top;
	transition: transform 0.3s;
}

.light-select-arrow .anticon>svg {
	vertical-align: top;
}

.light-select-arrow .anticon:not(.light-select-suffix) {
	pointer-events: auto;
}

.light-select-disabled .light-select-arrow {
	cursor: not-allowed;
}

.light-select-arrow>*:not(:last-child) {
	margin-inline-end: 8px;
}

.light-select-clear {
	position: absolute;
	top: 50%;
	right: 11px;
	z-index: 1;
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-top: -6px;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	font-style: normal;
	line-height: 1;
	text-align: center;
	text-transform: none;
	background: #fff;
	cursor: pointer;
	opacity: 0;
	transition: color 0.3s ease, opacity 0.15s ease;
	text-rendering: auto;
}

.light-select-clear::before {
	display: block;
}

.light-select-clear:hover {
	color: rgba(0, 0, 0, 0.45);
}

.light-select:hover .light-select-clear {
	opacity: 1;
}

.light-select-dropdown {
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	box-sizing: border-box;
	padding: 4px 0;
	overflow: hidden;
	font-size: 14px;
	font-variant: initial;
	background-color: #fff;
	border-radius: 2px;
	outline: none;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.light-select-dropdown.light-slide-up-enter.light-slide-up-enter-active.light-select-dropdown-placement-bottomLeft,
.light-select-dropdown.light-slide-up-appear.light-slide-up-appear-active.light-select-dropdown-placement-bottomLeft {
	animation-name: antSlideUpIn;
}

.light-select-dropdown.light-slide-up-enter.light-slide-up-enter-active.light-select-dropdown-placement-topLeft,
.light-select-dropdown.light-slide-up-appear.light-slide-up-appear-active.light-select-dropdown-placement-topLeft {
	animation-name: antSlideDownIn;
}

.light-select-dropdown.light-slide-up-leave.light-slide-up-leave-active.light-select-dropdown-placement-bottomLeft {
	animation-name: antSlideUpOut;
}

.light-select-dropdown.light-slide-up-leave.light-slide-up-leave-active.light-select-dropdown-placement-topLeft {
	animation-name: antSlideDownOut;
}

.light-select-dropdown-hidden {
	display: none;
}

.light-select-dropdown-empty {
	color: rgba(0, 0, 0, 0.25);
}

.light-select-item-empty {
	position: relative;
	display: block;
	min-height: 32px;
	padding: 5px 12px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	color: rgba(0, 0, 0, 0.25);
}

.light-select-item {
	position: relative;
	display: block;
	min-height: 32px;
	padding: 5px 12px;
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	cursor: pointer;
	transition: background 0.3s ease;
}

.light-select-item-group {
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	cursor: default;
}

.light-select-item-option {
	display: flex;
}

.light-select-item-option-content {
	flex: auto;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.light-select-item-option-state {
	flex: none;
}

.light-select-item-option-active:not(.light-select-item-option-disabled) {
	background-color: #f5f5f5;
}

.light-select-item-option-selected:not(.light-select-item-option-disabled) {
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	background-color: var(--light-primary-1);
}

.light-select-item-option-selected:not(.light-select-item-option-disabled) .light-select-item-option-state {
	color: var(--light-primary-color);
}

.light-select-item-option-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-select-item-option-disabled.light-select-item-option-selected {
	background-color: #f5f5f5;
}

.light-select-item-option-grouped {
	padding-left: 24px;
}

.light-select-lg {
	font-size: 16px;
}

.light-select-borderless .light-select-selector {
	background-color: transparent !important;
	border-color: transparent !important;
	box-shadow: none !important;
}

.light-select.light-select-in-form-item {
	width: 100%;
}

.light-select-rtl {
	direction: rtl;
}

.light-select-rtl .light-select-arrow {
	right: initial;
	left: 11px;
}

.light-select-rtl .light-select-clear {
	right: initial;
	left: 11px;
}

.light-select-dropdown-rtl {
	direction: rtl;
}

.light-select-dropdown-rtl .light-select-item-option-grouped {
	padding-right: 24px;
	padding-left: 12px;
}

.light-select-rtl.light-select-multiple.light-select-show-arrow .light-select-selector,
.light-select-rtl.light-select-multiple.light-select-allow-clear .light-select-selector {
	padding-right: 4px;
	padding-left: 24px;
}

.light-select-rtl.light-select-multiple .light-select-selection-item {
	text-align: right;
}

.light-select-rtl.light-select-multiple .light-select-selection-item-content {
	margin-right: 0;
	margin-left: 4px;
	text-align: right;
}

.light-select-rtl.light-select-multiple .light-select-selection-search-mirror {
	right: 0;
	left: auto;
}

.light-select-rtl.light-select-multiple .light-select-selection-placeholder {
	right: 11px;
	left: auto;
}

.light-select-rtl.light-select-multiple.light-select-sm .light-select-selection-placeholder {
	right: 7px;
}

.light-select-rtl.light-select-single .light-select-selector .light-select-selection-item,
.light-select-rtl.light-select-single .light-select-selector .light-select-selection-placeholder {
	right: 0;
	left: 9px;
	text-align: right;
}

.light-select-rtl.light-select-single.light-select-show-arrow .light-select-selection-search {
	right: 11px;
	left: 25px;
}

.light-select-rtl.light-select-single.light-select-show-arrow .light-select-selection-item,
.light-select-rtl.light-select-single.light-select-show-arrow .light-select-selection-placeholder {
	padding-right: 0;
	padding-left: 18px;
}

.light-select-rtl.light-select-single.light-select-sm:not(.light-select-customize-input).light-select-show-arrow .light-select-selection-search {
	right: 6px;
}

.light-select-rtl.light-select-single.light-select-sm:not(.light-select-customize-input).light-select-show-arrow .light-select-selection-item,
.light-select-rtl.light-select-single.light-select-sm:not(.light-select-customize-input).light-select-show-arrow .light-select-selection-placeholder {
	padding-right: 0;
	padding-left: 21px;
}

.light-skeleton {
	display: table;
	width: 100%;
}

.light-skeleton-header {
	display: table-cell;
	padding-right: 16px;
	vertical-align: top;
}

.light-skeleton-header .light-skeleton-avatar {
	display: inline-block;
	vertical-align: top;
	background: rgba(190, 190, 190, 0.2);
	width: 32px;
	height: 32px;
	line-height: 32px;
}

.light-skeleton-header .light-skeleton-avatar.light-skeleton-avatar-circle {
	border-radius: 50%;
}

.light-skeleton-header .light-skeleton-avatar-lg {
	width: 40px;
	height: 40px;
	line-height: 40px;
}

.light-skeleton-header .light-skeleton-avatar-lg.light-skeleton-avatar-circle {
	border-radius: 50%;
}

.light-skeleton-header .light-skeleton-avatar-sm {
	width: 24px;
	height: 24px;
	line-height: 24px;
}

.light-skeleton-header .light-skeleton-avatar-sm.light-skeleton-avatar-circle {
	border-radius: 50%;
}

.light-skeleton-content {
	display: table-cell;
	width: 100%;
	vertical-align: top;
}

.light-skeleton-content .light-skeleton-title {
	width: 100%;
	height: 16px;
	background: rgba(190, 190, 190, 0.2);
	border-radius: 2px;
}

.light-skeleton-content .light-skeleton-title+.light-skeleton-paragraph {
	margin-top: 24px;
}

.light-skeleton-content .light-skeleton-paragraph {
	padding: 0;
}

.light-skeleton-content .light-skeleton-paragraph>li {
	width: 100%;
	height: 16px;
	list-style: none;
	background: rgba(190, 190, 190, 0.2);
	border-radius: 2px;
}

.light-skeleton-content .light-skeleton-paragraph>li:last-child:not(:first-child):not(:nth-child(2)) {
	width: 61%;
}

.light-skeleton-content .light-skeleton-paragraph>li+li {
	margin-top: 16px;
}

.light-skeleton-with-avatar .light-skeleton-content .light-skeleton-title {
	margin-top: 12px;
}

.light-skeleton-with-avatar .light-skeleton-content .light-skeleton-title+.light-skeleton-paragraph {
	margin-top: 28px;
}

.light-skeleton-round .light-skeleton-content .light-skeleton-title,
.light-skeleton-round .light-skeleton-content .light-skeleton-paragraph>li {
	border-radius: 100px;
}

.light-skeleton-active .light-skeleton-title,
.light-skeleton-active .light-skeleton-paragraph>li,
.light-skeleton-active .light-skeleton-avatar,
.light-skeleton-active .light-skeleton-button,
.light-skeleton-active .light-skeleton-input,
.light-skeleton-active .light-skeleton-image {
	position: relative;
	/* stylelint-disable-next-line property-no-vendor-prefix,value-no-vendor-prefix */
	z-index: 0;
	overflow: hidden;
	background: transparent;
}

.light-skeleton-active .light-skeleton-title::after,
.light-skeleton-active .light-skeleton-paragraph>li::after,
.light-skeleton-active .light-skeleton-avatar::after,
.light-skeleton-active .light-skeleton-button::after,
.light-skeleton-active .light-skeleton-input::after,
.light-skeleton-active .light-skeleton-image::after {
	position: absolute;
	top: 0;
	right: -150%;
	bottom: 0;
	left: -150%;
	background: linear-gradient(90deg,
			rgba(190, 190, 190, 0.2) 25%,
			rgba(129, 129, 129, 0.24) 37%,
			rgba(190, 190, 190, 0.2) 63%);
	animation: light-skeleton-loading 1.4s ease infinite;
	content: "";
}

.light-skeleton.light-skeleton-block {
	width: 100%;
}

.light-skeleton.light-skeleton-block .light-skeleton-button {
	width: 100%;
}

.light-skeleton.light-skeleton-block .light-skeleton-input {
	width: 100%;
}

.light-skeleton-element {
	display: inline-block;
	width: auto;
}

.light-skeleton-element .light-skeleton-button {
	display: inline-block;
	vertical-align: top;
	background: rgba(190, 190, 190, 0.2);
	border-radius: 2px;
	width: 64px;
	min-width: 64px;
	height: 32px;
	line-height: 32px;
}

.light-skeleton-element .light-skeleton-button.light-skeleton-button-square {
	width: 32px;
	min-width: 32px;
}

.light-skeleton-element .light-skeleton-button.light-skeleton-button-circle {
	width: 32px;
	min-width: 32px;
	border-radius: 50%;
}

.light-skeleton-element .light-skeleton-button.light-skeleton-button-round {
	border-radius: 32px;
}

.light-skeleton-element .light-skeleton-button-lg {
	width: 80px;
	min-width: 80px;
	height: 40px;
	line-height: 40px;
}

.light-skeleton-element .light-skeleton-button-lg.light-skeleton-button-square {
	width: 40px;
	min-width: 40px;
}

.light-skeleton-element .light-skeleton-button-lg.light-skeleton-button-circle {
	width: 40px;
	min-width: 40px;
	border-radius: 50%;
}

.light-skeleton-element .light-skeleton-button-lg.light-skeleton-button-round {
	border-radius: 40px;
}

.light-skeleton-element .light-skeleton-button-sm {
	width: 48px;
	min-width: 48px;
	height: 24px;
	line-height: 24px;
}

.light-skeleton-element .light-skeleton-button-sm.light-skeleton-button-square {
	width: 24px;
	min-width: 24px;
}

.light-skeleton-element .light-skeleton-button-sm.light-skeleton-button-circle {
	width: 24px;
	min-width: 24px;
	border-radius: 50%;
}

.light-skeleton-element .light-skeleton-button-sm.light-skeleton-button-round {
	border-radius: 24px;
}

.light-skeleton-element .light-skeleton-avatar {
	display: inline-block;
	vertical-align: top;
	background: rgba(190, 190, 190, 0.2);
	width: 32px;
	height: 32px;
	line-height: 32px;
}

.light-skeleton-element .light-skeleton-avatar.light-skeleton-avatar-circle {
	border-radius: 50%;
}

.light-skeleton-element .light-skeleton-avatar-lg {
	width: 40px;
	height: 40px;
	line-height: 40px;
}

.light-skeleton-element .light-skeleton-avatar-lg.light-skeleton-avatar-circle {
	border-radius: 50%;
}

.light-skeleton-element .light-skeleton-avatar-sm {
	width: 24px;
	height: 24px;
	line-height: 24px;
}

.light-skeleton-element .light-skeleton-avatar-sm.light-skeleton-avatar-circle {
	border-radius: 50%;
}

.light-skeleton-element .light-skeleton-input {
	display: inline-block;
	vertical-align: top;
	background: rgba(190, 190, 190, 0.2);
	width: 160px;
	min-width: 160px;
	height: 32px;
	line-height: 32px;
}

.light-skeleton-element .light-skeleton-input-lg {
	width: 200px;
	min-width: 200px;
	height: 40px;
	line-height: 40px;
}

.light-skeleton-element .light-skeleton-input-sm {
	width: 120px;
	min-width: 120px;
	height: 24px;
	line-height: 24px;
}

.light-skeleton-element .light-skeleton-image {
	display: flex;
	align-items: center;
	justify-content: center;
	vertical-align: top;
	background: rgba(190, 190, 190, 0.2);
	width: 96px;
	height: 96px;
	line-height: 96px;
}

.light-skeleton-element .light-skeleton-image.light-skeleton-image-circle {
	border-radius: 50%;
}

.light-skeleton-element .light-skeleton-image-path {
	fill: #bfbfbf;
}

.light-skeleton-element .light-skeleton-image-svg {
	width: 48px;
	height: 48px;
	line-height: 48px;
	max-width: 192px;
	max-height: 192px;
}

.light-skeleton-element .light-skeleton-image-svg.light-skeleton-image-circle {
	border-radius: 50%;
}

@keyframes light-skeleton-loading {
	0% {
		transform: translateX(-37.5%);
	}

	100% {
		transform: translateX(37.5%);
	}
}

.light-skeleton-rtl {
	direction: rtl;
}

.light-skeleton-rtl .light-skeleton-header {
	padding-right: 0;
	padding-left: 16px;
}

.light-skeleton-rtl.light-skeleton.light-skeleton-active .light-skeleton-content .light-skeleton-title,
.light-skeleton-rtl.light-skeleton.light-skeleton-active .light-skeleton-content .light-skeleton-paragraph>li {
	animation-name: light-skeleton-loading-rtl;
}

.light-skeleton-rtl.light-skeleton.light-skeleton-active .light-skeleton-avatar {
	animation-name: light-skeleton-loading-rtl;
}

@keyframes light-skeleton-loading-rtl {
	0% {
		background-position: 0% 50%;
	}

	100% {
		background-position: 100% 50%;
	}
}

.light-slider {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	height: 12px;
	margin: 10px 6px 10px;
	padding: 4px 0;
	cursor: pointer;
	touch-action: none;
}

.light-slider-vertical {
	width: 12px;
	height: 100%;
	margin: 6px 10px;
	padding: 0 4px;
}

.light-slider-vertical .light-slider-rail {
	width: 4px;
	height: 100%;
}

.light-slider-vertical .light-slider-track {
	width: 4px;
}

.light-slider-vertical .light-slider-handle {
	margin-top: -6px;
	margin-left: -5px;
}

.light-slider-vertical .light-slider-mark {
	top: 0;
	left: 12px;
	width: 18px;
	height: 100%;
}

.light-slider-vertical .light-slider-mark-text {
	left: 4px;
	white-space: nowrap;
}

.light-slider-vertical .light-slider-step {
	width: 4px;
	height: 100%;
}

.light-slider-vertical .light-slider-dot {
	top: auto;
	margin-left: -2px;
}

.light-slider-tooltip .light-tooltip-inner {
	min-width: unset;
}

.light-slider-rtl.light-slider-vertical .light-slider-handle {
	margin-right: -5px;
	margin-left: 0;
}

.light-slider-rtl.light-slider-vertical .light-slider-mark {
	right: 12px;
	left: auto;
}

.light-slider-rtl.light-slider-vertical .light-slider-mark-text {
	right: 4px;
	left: auto;
}

.light-slider-rtl.light-slider-vertical .light-slider-dot {
	right: 2px;
	left: auto;
}

.light-slider-with-marks {
	margin-bottom: 28px;
}

.light-slider-rail {
	position: absolute;
	width: 100%;
	height: 4px;
	background-color: #f5f5f5;
	border-radius: 2px;
	transition: background-color 0.3s;
}

.light-slider-track {
	position: absolute;
	height: 4px;
	background-color: var(--light-primary-3);
	border-radius: 2px;
	transition: background-color 0.3s;
}

.light-slider-handle {
	position: absolute;
	width: 14px;
	height: 14px;
	margin-top: -5px;
	background-color: #fff;
	border: solid 2px var(--light-primary-3);
	border-radius: 50%;
	box-shadow: 0;
	cursor: pointer;
	transition: border-color 0.3s, box-shadow 0.6s,
		transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.light-slider-handle-dragging {
	z-index: 1;
}

.light-slider-handle:focus {
	border-color: var(--light-primary-color-deprecated-t-20);
	outline: none;
	box-shadow: 0 0 0 5px var(--light-primary-color-deprecated-f-12);
}

.light-slider-handle.light-tooltip-open {
	border-color: var(--light-primary-color);
}

.light-slider-handle::after {
	position: absolute;
	top: -6px;
	right: -6px;
	bottom: -6px;
	left: -6px;
	content: "";
}

.light-slider:hover .light-slider-rail {
	background-color: #e1e1e1;
}

.light-slider:hover .light-slider-track {
	background-color: var(--light-primary-4);
}

.light-slider:hover .light-slider-handle:not(.light-tooltip-open) {
	border-color: var(--light-primary-4);
}

.light-slider-mark {
	position: absolute;
	top: 14px;
	left: 0;
	width: 100%;
	font-size: 14px;
}

.light-slider-mark-text {
	position: absolute;
	display: inline-block;
	color: rgba(0, 0, 0, 0.45);
	text-align: center;
	word-break: keep-all;
	cursor: pointer;
	user-select: none;
}

.light-slider-mark-text-active {
	color: rgba(0, 0, 0, 0.85);
}

.light-slider-step {
	position: absolute;
	width: 100%;
	height: 4px;
	background: transparent;
	pointer-events: none;
}

.light-slider-dot {
	position: absolute;
	top: -2px;
	width: 8px;
	height: 8px;
	background-color: #fff;
	border: 2px solid var(--border-color);
	border-radius: 50%;
	cursor: pointer;
}

.light-slider-dot-active {
	border-color: var(--light-primary-color-deprecated-t-50);
}

.light-slider-disabled {
	cursor: not-allowed;
}

.light-slider-disabled .light-slider-rail {
	background-color: #f5f5f5 !important;
}

.light-slider-disabled .light-slider-track {
	background-color: rgba(0, 0, 0, 0.25) !important;
}

.light-slider-disabled .light-slider-handle,
.light-slider-disabled .light-slider-dot {
	background-color: #fff;
	border-color: rgba(0, 0, 0, 0.25) !important;
	box-shadow: none;
	cursor: not-allowed;
}

.light-slider-disabled .light-slider-mark-text,
.light-slider-disabled .light-slider-dot {
	cursor: not-allowed !important;
}

.light-slider-rtl {
	direction: rtl;
}

.light-slider-rtl .light-slider-mark {
	right: 0;
	left: auto;
}

.light-space {
	display: inline-flex;
}

.light-space-vertical {
	flex-direction: column;
}

.light-space-align-center {
	align-items: center;
}

.light-space-align-start {
	align-items: flex-start;
}

.light-space-align-end {
	align-items: flex-end;
}

.light-space-align-baseline {
	align-items: baseline;
}

.light-space-item:empty {
	display: none;
}

.light-space-rtl {
	direction: rtl;
}

.light-spin {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: absolute;
	display: none;
	color: var(--light-primary-color);
	text-align: center;
	vertical-align: middle;
	opacity: 0;
	transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.light-spin-spinning {
	position: static;
	display: inline-block;
	opacity: 1;
}

.light-spin-nested-loading {
	position: relative;
}

.light-spin-nested-loading>div>.light-spin {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4;
	display: block;
	width: 100%;
	height: 100%;
	max-height: 400px;
}

.light-spin-nested-loading>div>.light-spin .light-spin-dot {
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -10px;
}

.light-spin-nested-loading>div>.light-spin .light-spin-text {
	position: absolute;
	top: 50%;
	width: 100%;
	padding-top: 5px;
	text-shadow: 0 1px 2px #fff;
}

.light-spin-nested-loading>div>.light-spin.light-spin-show-text .light-spin-dot {
	margin-top: -20px;
}

.light-spin-nested-loading>div>.light-spin-sm .light-spin-dot {
	margin: -7px;
}

.light-spin-nested-loading>div>.light-spin-sm .light-spin-text {
	padding-top: 2px;
}

.light-spin-nested-loading>div>.light-spin-sm.light-spin-show-text .light-spin-dot {
	margin-top: -17px;
}

.light-spin-nested-loading>div>.light-spin-lg .light-spin-dot {
	margin: -16px;
}

.light-spin-nested-loading>div>.light-spin-lg .light-spin-text {
	padding-top: 11px;
}

.light-spin-nested-loading>div>.light-spin-lg.light-spin-show-text .light-spin-dot {
	margin-top: -26px;
}

.light-spin-container {
	position: relative;
	transition: opacity 0.3s;
}

.light-spin-container::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 10;
	display: none \9;
	width: 100%;
	height: 100%;
	background: #fff;
	opacity: 0;
	transition: all 0.3s;
	content: "";
	pointer-events: none;
}

.light-spin-blur {
	clear: both;
	opacity: 0.5;
	user-select: none;
	pointer-events: none;
}

.light-spin-blur::after {
	opacity: 0.4;
	pointer-events: auto;
}

.light-spin-tip {
	color: rgba(0, 0, 0, 0.45);
}

.light-spin-dot {
	position: relative;
	display: inline-block;
	font-size: 20px;
	width: 1em;
	height: 1em;
}

.light-spin-dot-item {
	position: absolute;
	display: block;
	width: 9px;
	height: 9px;
	background-color: var(--light-primary-color);
	border-radius: 100%;
	transform: scale(0.75);
	transform-origin: 50% 50%;
	opacity: 0.3;
	animation: antSpinMove 1s infinite linear alternate;
}

.light-spin-dot-item:nth-child(1) {
	top: 0;
	left: 0;
}

.light-spin-dot-item:nth-child(2) {
	top: 0;
	right: 0;
	animation-delay: 0.4s;
}

.light-spin-dot-item:nth-child(3) {
	right: 0;
	bottom: 0;
	animation-delay: 0.8s;
}

.light-spin-dot-item:nth-child(4) {
	bottom: 0;
	left: 0;
	animation-delay: 1.2s;
}

.light-spin-dot-spin {
	transform: rotate(0deg);
	animation: antRotate 1.2s infinite linear;
}

.light-spin-sm .light-spin-dot {
	font-size: 14px;
}

.light-spin-sm .light-spin-dot i {
	width: 6px;
	height: 6px;
}

.light-spin-lg .light-spin-dot {
	font-size: 32px;
}

.light-spin-lg .light-spin-dot i {
	width: 14px;
	height: 14px;
}

.light-spin.light-spin-show-text .light-spin-text {
	display: block;
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

	/* IE10+ */
	.light-spin-blur {
		background: #fff;
		opacity: 0.5;
	}
}

@keyframes antSpinMove {
	to {
		opacity: 1;
	}
}

@keyframes antRotate {
	to {
		transform: rotate(360deg);
	}
}

.light-spin-rtl {
	direction: rtl;
}

.light-spin-rtl .light-spin-dot-spin {
	transform: rotate(-45deg);
	animation-name: antRotateRtl;
}

@keyframes antRotateRtl {
	to {
		transform: rotate(-405deg);
	}
}

.light-statistic {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
}

.light-statistic-title {
	margin-bottom: 4px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
}

.light-statistic-skeleton {
	padding-top: 16px;
}

.light-statistic-content {
	color: rgba(0, 0, 0, 0.85);
	font-size: 24px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.light-statistic-content-value {
	display: inline-block;
	direction: ltr;
}

.light-statistic-content-prefix,
.light-statistic-content-suffix {
	display: inline-block;
}

.light-statistic-content-prefix {
	margin-right: 4px;
}

.light-statistic-content-suffix {
	margin-left: 4px;
}

.light-statistic-rtl {
	direction: rtl;
}

.light-statistic-rtl .light-statistic-content-prefix {
	margin-right: 0;
	margin-left: 4px;
}

.light-statistic-rtl .light-statistic-content-suffix {
	margin-right: 4px;
	margin-left: 0;
}

.light-steps {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: flex;
	width: 100%;
	font-size: 0;
	text-align: initial;
}

.light-steps-item {
	position: relative;
	display: inline-block;
	flex: 1;
	overflow: hidden;
	vertical-align: top;
}

.light-steps-item-container {
	outline: none;
}

.light-steps-item:last-child {
	flex: none;
}

.light-steps-item:last-child>.light-steps-item-container>.light-steps-item-tail,
.light-steps-item:last-child>.light-steps-item-container>.light-steps-item-content>.light-steps-item-title::after {
	display: none;
}

.light-steps-item-icon,
.light-steps-item-content {
	display: inline-block;
	vertical-align: top;
}

.light-steps-item-icon {
	width: 32px;
	height: 32px;
	margin: 0 8px 0 0;
	font-size: 16px;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	line-height: 32px;
	text-align: center;
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-radius: 32px;
	transition: background-color 0.3s, border-color 0.3s;
}

.light-steps-item-icon .light-steps-icon {
	position: relative;
	top: -0.5px;
	color: var(--light-primary-color);
	line-height: 1;
}

.light-steps-item-tail {
	position: absolute;
	top: 12px;
	left: 0;
	width: 100%;
	padding: 0 10px;
}

.light-steps-item-tail::after {
	display: inline-block;
	width: 100%;
	height: 1px;
	background: var(--border-color);
	border-radius: 1px;
	transition: background 0.3s;
	content: "";
}

.light-steps-item-title {
	position: relative;
	display: inline-block;
	padding-right: 16px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
	line-height: 32px;
}

.light-steps-item-title::after {
	position: absolute;
	top: 16px;
	left: 100%;
	display: block;
	width: 9999px;
	height: 1px;
	background: var(--border-color);
	content: "";
}

.light-steps-item-subtitle {
	display: inline;
	margin-left: 8px;
	color: rgba(0, 0, 0, 0.45);
	font-weight: normal;
	font-size: 14px;
}

.light-steps-item-description {
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
}

.light-steps-item-wait .light-steps-item-icon {
	background-color: #fff;
	border-color: rgba(0, 0, 0, 0.25);
}

.light-steps-item-wait .light-steps-item-icon>.light-steps-icon {
	color: rgba(0, 0, 0, 0.25);
}

.light-steps-item-wait .light-steps-item-icon>.light-steps-icon .light-steps-icon-dot {
	background: rgba(0, 0, 0, 0.25);
}

.light-steps-item-wait>.light-steps-item-container>.light-steps-item-content>.light-steps-item-title {
	color: rgba(0, 0, 0, 0.45);
}

.light-steps-item-wait>.light-steps-item-container>.light-steps-item-content>.light-steps-item-title::after {
	background-color: var(--border-color);
}

.light-steps-item-wait>.light-steps-item-container>.light-steps-item-content>.light-steps-item-description {
	color: rgba(0, 0, 0, 0.45);
}

.light-steps-item-wait>.light-steps-item-container>.light-steps-item-tail::after {
	background-color: var(--border-color);
}

.light-steps-item-process .light-steps-item-icon {
	background-color: #fff;
	border-color: var(--light-primary-color);
}

.light-steps-item-process .light-steps-item-icon>.light-steps-icon {
	color: var(--light-primary-color);
}

.light-steps-item-process .light-steps-item-icon>.light-steps-icon .light-steps-icon-dot {
	background: var(--light-primary-color);
}

.light-steps-item-process>.light-steps-item-container>.light-steps-item-content>.light-steps-item-title {
	color: rgba(0, 0, 0, 0.85);
}

.light-steps-item-process>.light-steps-item-container>.light-steps-item-content>.light-steps-item-title::after {
	background-color: var(--border-color);
}

.light-steps-item-process>.light-steps-item-container>.light-steps-item-content>.light-steps-item-description {
	color: rgba(0, 0, 0, 0.85);
}

.light-steps-item-process>.light-steps-item-container>.light-steps-item-tail::after {
	background-color: var(--border-color);
}

.light-steps-item-process>.light-steps-item-container>.light-steps-item-icon {
	background: var(--light-primary-color);
}

.light-steps-item-process>.light-steps-item-container>.light-steps-item-icon .light-steps-icon {
	color: #fff;
}

.light-steps-item-process>.light-steps-item-container>.light-steps-item-title {
	font-weight: 500;
}

.light-steps-item-finish .light-steps-item-icon {
	background-color: #fff;
	border-color: var(--light-primary-color);
}

.light-steps-item-finish .light-steps-item-icon>.light-steps-icon {
	color: var(--light-primary-color);
}

.light-steps-item-finish .light-steps-item-icon>.light-steps-icon .light-steps-icon-dot {
	background: var(--light-primary-color);
}

.light-steps-item-finish>.light-steps-item-container>.light-steps-item-content>.light-steps-item-title {
	color: rgba(0, 0, 0, 0.85);
}

.light-steps-item-finish>.light-steps-item-container>.light-steps-item-content>.light-steps-item-title::after {
	background-color: var(--light-primary-color);
}

.light-steps-item-finish>.light-steps-item-container>.light-steps-item-content>.light-steps-item-description {
	color: rgba(0, 0, 0, 0.45);
}

.light-steps-item-finish>.light-steps-item-container>.light-steps-item-tail::after {
	background-color: var(--light-primary-color);
}

.light-steps-item-error .light-steps-item-icon {
	background-color: #fff;
	border-color: var(--light-error-color);
}

.light-steps-item-error .light-steps-item-icon>.light-steps-icon {
	color: var(--light-error-color);
}

.light-steps-item-error .light-steps-item-icon>.light-steps-icon .light-steps-icon-dot {
	background: var(--light-error-color);
}

.light-steps-item-error>.light-steps-item-container>.light-steps-item-content>.light-steps-item-title {
	color: var(--light-error-color);
}

.light-steps-item-error>.light-steps-item-container>.light-steps-item-content>.light-steps-item-title::after {
	background-color: var(--border-color);
}

.light-steps-item-error>.light-steps-item-container>.light-steps-item-content>.light-steps-item-description {
	color: var(--light-error-color);
}

.light-steps-item-error>.light-steps-item-container>.light-steps-item-tail::after {
	background-color: var(--border-color);
}

.light-steps-item.light-steps-next-error .light-steps-item-title::after {
	background: var(--light-error-color);
}

.light-steps-item-disabled {
	cursor: not-allowed;
}

.light-steps .light-steps-item:not(.light-steps-item-active)>.light-steps-item-container[role="button"] {
	cursor: pointer;
}

.light-steps .light-steps-item:not(.light-steps-item-active)>.light-steps-item-container[role="button"] .light-steps-item-title,
.light-steps .light-steps-item:not(.light-steps-item-active)>.light-steps-item-container[role="button"] .light-steps-item-subtitle,
.light-steps .light-steps-item:not(.light-steps-item-active)>.light-steps-item-container[role="button"] .light-steps-item-description,
.light-steps .light-steps-item:not(.light-steps-item-active)>.light-steps-item-container[role="button"] .light-steps-item-icon .light-steps-icon {
	transition: color 0.3s;
}

.light-steps .light-steps-item:not(.light-steps-item-active)>.light-steps-item-container[role="button"]:hover .light-steps-item-title,
.light-steps .light-steps-item:not(.light-steps-item-active)>.light-steps-item-container[role="button"]:hover .light-steps-item-subtitle,
.light-steps .light-steps-item:not(.light-steps-item-active)>.light-steps-item-container[role="button"]:hover .light-steps-item-description {
	color: var(--light-primary-color);
}

.light-steps .light-steps-item:not(.light-steps-item-active):not(.light-steps-item-process)>.light-steps-item-container[role="button"]:hover .light-steps-item-icon {
	border-color: var(--light-primary-color);
}

.light-steps .light-steps-item:not(.light-steps-item-active):not(.light-steps-item-process)>.light-steps-item-container[role="button"]:hover .light-steps-item-icon .light-steps-icon {
	color: var(--light-primary-color);
}

.light-steps-horizontal:not(.light-steps-label-vertical) .light-steps-item {
	padding-left: 16px;
	white-space: nowrap;
}

.light-steps-horizontal:not(.light-steps-label-vertical) .light-steps-item:first-child {
	padding-left: 0;
}

.light-steps-horizontal:not(.light-steps-label-vertical) .light-steps-item:last-child .light-steps-item-title {
	padding-right: 0;
}

.light-steps-horizontal:not(.light-steps-label-vertical) .light-steps-item-tail {
	display: none;
}

.light-steps-horizontal:not(.light-steps-label-vertical) .light-steps-item-description {
	max-width: 140px;
	white-space: normal;
}

.light-steps-item-custom>.light-steps-item-container>.light-steps-item-icon {
	height: auto;
	background: none;
	border: 0;
}

.light-steps-item-custom>.light-steps-item-container>.light-steps-item-icon>.light-steps-icon {
	top: 0px;
	left: 0.5px;
	width: 32px;
	height: 32px;
	font-size: 24px;
	line-height: 32px;
}

.light-steps-item-custom.light-steps-item-process .light-steps-item-icon>.light-steps-icon {
	color: var(--light-primary-color);
}

.light-steps:not(.light-steps-vertical) .light-steps-item-custom .light-steps-item-icon {
	width: auto;
	background: none;
}

.light-steps-small.light-steps-horizontal:not(.light-steps-label-vertical) .light-steps-item {
	padding-left: 12px;
}

.light-steps-small.light-steps-horizontal:not(.light-steps-label-vertical) .light-steps-item:first-child {
	padding-left: 0;
}

.light-steps-small .light-steps-item-icon {
	width: 24px;
	height: 24px;
	margin: 0 8px 0 0;
	font-size: 12px;
	line-height: 24px;
	text-align: center;
	border-radius: 24px;
}

.light-steps-small .light-steps-item-title {
	padding-right: 12px;
	font-size: 14px;
	line-height: 24px;
}

.light-steps-small .light-steps-item-title::after {
	top: 12px;
}

.light-steps-small .light-steps-item-description {
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
}

.light-steps-small .light-steps-item-tail {
	top: 8px;
}

.light-steps-small .light-steps-item-custom .light-steps-item-icon {
	width: inherit;
	height: inherit;
	line-height: inherit;
	background: none;
	border: 0;
	border-radius: 0;
}

.light-steps-small .light-steps-item-custom .light-steps-item-icon>.light-steps-icon {
	font-size: 24px;
	line-height: 24px;
	transform: none;
}

.light-steps-vertical {
	display: flex;
	flex-direction: column;
}

.light-steps-vertical>.light-steps-item {
	display: block;
	flex: 1 0 auto;
	padding-left: 0;
	overflow: visible;
}

.light-steps-vertical>.light-steps-item .light-steps-item-icon {
	float: left;
	margin-right: 16px;
}

.light-steps-vertical>.light-steps-item .light-steps-item-content {
	display: block;
	min-height: 48px;
	overflow: hidden;
}

.light-steps-vertical>.light-steps-item .light-steps-item-title {
	line-height: 32px;
}

.light-steps-vertical>.light-steps-item .light-steps-item-description {
	padding-bottom: 12px;
}

.light-steps-vertical>.light-steps-item>.light-steps-item-container>.light-steps-item-tail {
	position: absolute;
	top: 0;
	left: 16px;
	width: 1px;
	height: 100%;
	padding: 38px 0 6px;
}

.light-steps-vertical>.light-steps-item>.light-steps-item-container>.light-steps-item-tail::after {
	width: 1px;
	height: 100%;
}

.light-steps-vertical>.light-steps-item:not(:last-child)>.light-steps-item-container>.light-steps-item-tail {
	display: block;
}

.light-steps-vertical>.light-steps-item>.light-steps-item-container>.light-steps-item-content>.light-steps-item-title::after {
	display: none;
}

.light-steps-vertical.light-steps-small .light-steps-item-container .light-steps-item-tail {
	position: absolute;
	top: 0;
	left: 12px;
	padding: 30px 0 6px;
}

.light-steps-vertical.light-steps-small .light-steps-item-container .light-steps-item-title {
	line-height: 24px;
}

.light-steps-label-vertical .light-steps-item {
	overflow: visible;
}

.light-steps-label-vertical .light-steps-item-tail {
	margin-left: 58px;
	padding: 3.5px 24px;
}

.light-steps-label-vertical .light-steps-item-content {
	display: block;
	width: 116px;
	margin-top: 8px;
	text-align: center;
}

.light-steps-label-vertical .light-steps-item-icon {
	display: inline-block;
	margin-left: 42px;
}

.light-steps-label-vertical .light-steps-item-title {
	padding-right: 0;
	padding-left: 0;
}

.light-steps-label-vertical .light-steps-item-title::after {
	display: none;
}

.light-steps-label-vertical .light-steps-item-subtitle {
	display: block;
	margin-bottom: 4px;
	margin-left: 0;
	line-height: 1.5715;
}

.light-steps-label-vertical.light-steps-small:not(.light-steps-dot) .light-steps-item-icon {
	margin-left: 46px;
}

.light-steps-dot .light-steps-item-title,
.light-steps-dot.light-steps-small .light-steps-item-title {
	line-height: 1.5715;
}

.light-steps-dot .light-steps-item-tail,
.light-steps-dot.light-steps-small .light-steps-item-tail {
	top: 2px;
	width: 100%;
	margin: 0 0 0 70px;
	padding: 0;
}

.light-steps-dot .light-steps-item-tail::after,
.light-steps-dot.light-steps-small .light-steps-item-tail::after {
	width: calc(100% - 20px);
	height: 3px;
	margin-left: 12px;
}

.light-steps-dot .light-steps-item:first-child .light-steps-icon-dot,
.light-steps-dot.light-steps-small .light-steps-item:first-child .light-steps-icon-dot {
	left: 2px;
}

.light-steps-dot .light-steps-item-icon,
.light-steps-dot.light-steps-small .light-steps-item-icon {
	width: 8px;
	height: 8px;
	margin-left: 67px;
	padding-right: 0;
	line-height: 8px;
	background: transparent;
	border: 0;
}

.light-steps-dot .light-steps-item-icon .light-steps-icon-dot,
.light-steps-dot.light-steps-small .light-steps-item-icon .light-steps-icon-dot {
	position: relative;
	float: left;
	width: 100%;
	height: 100%;
	border-radius: 100px;
	transition: all 0.3s;
	/* expand hover area */
}

.light-steps-dot .light-steps-item-icon .light-steps-icon-dot::after,
.light-steps-dot.light-steps-small .light-steps-item-icon .light-steps-icon-dot::after {
	position: absolute;
	top: -12px;
	left: -26px;
	width: 60px;
	height: 32px;
	background: rgba(0, 0, 0, 0.001);
	content: "";
}

.light-steps-dot .light-steps-item-content,
.light-steps-dot.light-steps-small .light-steps-item-content {
	width: 140px;
}

.light-steps-dot .light-steps-item-process .light-steps-item-icon,
.light-steps-dot.light-steps-small .light-steps-item-process .light-steps-item-icon {
	position: relative;
	top: -1px;
	width: 10px;
	height: 10px;
	line-height: 10px;
	background: none;
}

.light-steps-dot .light-steps-item-process .light-steps-icon:first-child .light-steps-icon-dot,
.light-steps-dot.light-steps-small .light-steps-item-process .light-steps-icon:first-child .light-steps-icon-dot {
	left: 0;
}

.light-steps-vertical.light-steps-dot .light-steps-item-icon {
	margin-top: 13px;
	margin-left: 0;
	background: none;
}

.light-steps-vertical.light-steps-dot .light-steps-item>.light-steps-item-container>.light-steps-item-tail {
	top: 6.5px;
	left: -9px;
	margin: 0;
	padding: 22px 0 4px;
}

.light-steps-vertical.light-steps-dot.light-steps-small .light-steps-item-icon {
	margin-top: 10px;
}

.light-steps-vertical.light-steps-dot.light-steps-small .light-steps-item>.light-steps-item-container>.light-steps-item-tail {
	top: 3.5px;
}

.light-steps-vertical.light-steps-dot .light-steps-item:first-child .light-steps-icon-dot {
	left: 0;
}

.light-steps-vertical.light-steps-dot .light-steps-item-content {
	width: inherit;
}

.light-steps-vertical.light-steps-dot .light-steps-item-process .light-steps-item-container .light-steps-item-icon .light-steps-icon-dot {
	top: -1px;
	left: -1px;
}

.light-steps-navigation {
	padding-top: 12px;
}

.light-steps-navigation.light-steps-small .light-steps-item-container {
	margin-left: -12px;
}

.light-steps-navigation .light-steps-item {
	overflow: visible;
	text-align: center;
}

.light-steps-navigation .light-steps-item-container {
	display: inline-block;
	height: 100%;
	margin-left: -16px;
	padding-bottom: 12px;
	text-align: left;
	transition: opacity 0.3s;
}

.light-steps-navigation .light-steps-item-container .light-steps-item-content {
	max-width: auto;
}

.light-steps-navigation .light-steps-item-container .light-steps-item-title {
	max-width: 100%;
	padding-right: 0;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.light-steps-navigation .light-steps-item-container .light-steps-item-title::after {
	display: none;
}

.light-steps-navigation .light-steps-item:not(.light-steps-item-active) .light-steps-item-container[role="button"] {
	cursor: pointer;
}

.light-steps-navigation .light-steps-item:not(.light-steps-item-active) .light-steps-item-container[role="button"]:hover {
	opacity: 0.85;
}

.light-steps-navigation .light-steps-item:last-child {
	flex: 1;
}

.light-steps-navigation .light-steps-item:last-child::after {
	display: none;
}

.light-steps-navigation .light-steps-item::after {
	position: absolute;
	top: 50%;
	left: 100%;
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-top: -14px;
	margin-left: -2px;
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-bottom: none;
	border-left: none;
	transform: rotate(45deg);
	content: "";
}

.light-steps-navigation .light-steps-item::before {
	position: absolute;
	bottom: 0;
	left: 50%;
	display: inline-block;
	width: 0;
	height: 2px;
	background-color: var(--light-primary-color);
	transition: width 0.3s, left 0.3s;
	transition-timing-function: ease-out;
	content: "";
}

.light-steps-navigation .light-steps-item.light-steps-item-active::before {
	left: 0;
	width: 100%;
}

.light-steps-navigation.light-steps-vertical>.light-steps-item {
	margin-right: 0 !important;
}

.light-steps-navigation.light-steps-vertical>.light-steps-item::before {
	display: none;
}

.light-steps-navigation.light-steps-vertical>.light-steps-item.light-steps-item-active::before {
	top: 0;
	right: 0;
	left: unset;
	display: block;
	width: 3px;
	height: calc(100% - 24px);
}

.light-steps-navigation.light-steps-vertical>.light-steps-item::after {
	position: relative;
	top: -2px;
	left: 50%;
	display: block;
	width: 8px;
	height: 8px;
	margin-bottom: 8px;
	text-align: center;
	transform: rotate(135deg);
}

.light-steps-navigation.light-steps-vertical>.light-steps-item>.light-steps-item-container>.light-steps-item-tail {
	visibility: hidden;
}

.light-steps-navigation.light-steps-horizontal>.light-steps-item>.light-steps-item-container>.light-steps-item-tail {
	visibility: hidden;
}

.light-steps-rtl {
	direction: rtl;
}

.light-steps.light-steps-rtl .light-steps-item-icon {
	margin-right: 0;
	margin-left: 8px;
}

.light-steps-rtl .light-steps-item-tail {
	right: 0;
	left: auto;
}

.light-steps-rtl .light-steps-item-title {
	padding-right: 0;
	padding-left: 16px;
}

.light-steps-rtl .light-steps-item-title .light-steps-item-subtitle {
	float: left;
	margin-right: 8px;
	margin-left: 0;
}

.light-steps-rtl .light-steps-item-title::after {
	right: 100%;
	left: auto;
}

.light-steps-rtl.light-steps-horizontal:not(.light-steps-label-vertical) .light-steps-item {
	padding-right: 16px;
	padding-left: 0;
}

.light-steps-rtl.light-steps-horizontal:not(.light-steps-label-vertical) .light-steps-item:first-child {
	padding-right: 0;
}

.light-steps-rtl.light-steps-horizontal:not(.light-steps-label-vertical) .light-steps-item:last-child .light-steps-item-title {
	padding-left: 0;
}

.light-steps-rtl .light-steps-item-custom .light-steps-item-icon>.light-steps-icon {
	right: 0.5px;
	left: auto;
}

.light-steps-rtl.light-steps-navigation.light-steps-small .light-steps-item-container {
	margin-right: -12px;
	margin-left: 0;
}

.light-steps-rtl.light-steps-navigation .light-steps-item-container {
	margin-right: -16px;
	margin-left: 0;
	text-align: right;
}

.light-steps-rtl.light-steps-navigation .light-steps-item-container .light-steps-item-title {
	padding-left: 0;
}

.light-steps-rtl.light-steps-navigation .light-steps-item::after {
	right: 100%;
	left: auto;
	margin-right: -2px;
	margin-left: 0;
	transform: rotate(225deg);
}

.light-steps-rtl.light-steps-small.light-steps-horizontal:not(.light-steps-label-vertical) .light-steps-item {
	padding-right: 12px;
	padding-left: 0;
}

.light-steps-rtl.light-steps-small.light-steps-horizontal:not(.light-steps-label-vertical) .light-steps-item:first-child {
	padding-right: 0;
}

.light-steps-rtl.light-steps-small .light-steps-item-title {
	padding-right: 0;
	padding-left: 12px;
}

.light-steps-rtl.light-steps-vertical>.light-steps-item .light-steps-item-icon {
	float: right;
	margin-right: 0;
	margin-left: 16px;
}

.light-steps-rtl.light-steps-vertical>.light-steps-item>.light-steps-item-container>.light-steps-item-tail {
	right: 16px;
	left: auto;
}

.light-steps-rtl.light-steps-vertical.light-steps-small .light-steps-item-container .light-steps-item-tail {
	right: 12px;
	left: auto;
}

.light-steps-rtl.light-steps-label-vertical .light-steps-item-title {
	padding-left: 0;
}

.light-steps-rtl.light-steps-dot .light-steps-item-tail,
.light-steps-rtl.light-steps-dot.light-steps-small .light-steps-item-tail {
	margin: 0 70px 0 0;
}

.light-steps-rtl.light-steps-dot .light-steps-item-tail::after,
.light-steps-rtl.light-steps-dot.light-steps-small .light-steps-item-tail::after {
	margin-right: 12px;
	margin-left: 0;
}

.light-steps-rtl.light-steps-dot .light-steps-item:first-child .light-steps-icon-dot,
.light-steps-rtl.light-steps-dot.light-steps-small .light-steps-item:first-child .light-steps-icon-dot {
	right: 2px;
	left: auto;
}

.light-steps-rtl.light-steps-dot .light-steps-item-icon,
.light-steps-rtl.light-steps-dot.light-steps-small .light-steps-item-icon {
	margin-right: 67px;
	margin-left: 0;
}

.light-steps-dot .light-steps-item-icon .light-steps-icon-dot,
.light-steps-dot.light-steps-small .light-steps-item-icon .light-steps-icon-dot {
	/* expand hover area */
}

.light-steps-rtl.light-steps-dot .light-steps-item-icon .light-steps-icon-dot,
.light-steps-rtl.light-steps-dot.light-steps-small .light-steps-item-icon .light-steps-icon-dot {
	float: right;
}

.light-steps-rtl.light-steps-dot .light-steps-item-icon .light-steps-icon-dot::after,
.light-steps-rtl.light-steps-dot.light-steps-small .light-steps-item-icon .light-steps-icon-dot::after {
	right: -26px;
	left: auto;
}

.light-steps-rtl.light-steps-vertical.light-steps-dot .light-steps-item-icon {
	margin-right: 0;
	margin-left: 16px;
}

.light-steps-rtl.light-steps-vertical.light-steps-dot .light-steps-item>.light-steps-item-container>.light-steps-item-tail {
	right: -9px;
	left: auto;
}

.light-steps-rtl.light-steps-vertical.light-steps-dot .light-steps-item:first-child .light-steps-icon-dot {
	right: 0;
	left: auto;
}

.light-steps-rtl.light-steps-vertical.light-steps-dot .light-steps-item-process .light-steps-icon-dot {
	right: -2px;
	left: auto;
}

.light-steps-rtl.light-steps-with-progress.light-steps-horizontal.light-steps-label-horizontal .light-steps-item:first-child {
	padding-right: 4px;
	padding-left: 0;
}

.light-steps-rtl.light-steps-with-progress.light-steps-horizontal.light-steps-label-horizontal .light-steps-item:first-child.light-steps-item-active {
	padding-right: 4px;
}

.light-steps-with-progress .light-steps-item {
	padding-top: 4px;
}

.light-steps-with-progress .light-steps-item .light-steps-item-tail {
	top: 4px !important;
}

.light-steps-with-progress.light-steps-horizontal .light-steps-item:first-child {
	padding-bottom: 4px;
	padding-left: 4px;
}

.light-steps-with-progress.light-steps-label-vertical .light-steps-item .light-steps-item-tail {
	top: 14px !important;
}

.light-steps-with-progress .light-steps-item-icon {
	position: relative;
}

.light-steps-with-progress .light-steps-item-icon .light-progress {
	position: absolute;
	top: -5px;
	right: -5px;
	bottom: -5px;
	left: -5px;
}

.light-switch {
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	min-width: 44px;
	height: 22px;
	line-height: 22px;
	vertical-align: middle;
	background-color: rgba(0, 0, 0, 0.25);
	border: 0;
	border-radius: 100px;
	cursor: pointer;
	transition: all 0.2s;
	user-select: none;
}

.light-switch:focus {
	outline: 0;
	box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.light-switch-checked:focus {
	box-shadow: 0 0 0 2px var(--light-primary-1);
}

.light-switch:focus:hover {
	box-shadow: none;
}

.light-switch-checked {
	background-color: var(--light-primary-color);
}

.light-switch-loading,
.light-switch-disabled {
	cursor: not-allowed;
	opacity: 0.4;
}

.light-switch-loading *,
.light-switch-disabled * {
	box-shadow: none;
	cursor: not-allowed;
}

.light-switch-inner {
	display: block;
	margin: 0 7px 0 25px;
	color: #fff;
	font-size: 12px;
	transition: margin 0.2s;
}

.light-switch-checked .light-switch-inner {
	margin: 0 25px 0 7px;
}

.light-switch-handle {
	position: absolute;
	top: 2px;
	left: 2px;
	width: 18px;
	height: 18px;
	transition: all 0.2s ease-in-out;
}

.light-switch-handle::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #fff;
	border-radius: 9px;
	box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
	transition: all 0.2s ease-in-out;
	content: "";
}

.light-switch-checked .light-switch-handle {
	left: calc(100% - 18px - 2px);
}

.light-switch:not(.light-switch-disabled):active .light-switch-handle::before {
	right: -30%;
	left: 0;
}

.light-switch:not(.light-switch-disabled):active.light-switch-checked .light-switch-handle::before {
	right: 0;
	left: -30%;
}

.light-switch-loading-icon.anticon {
	position: relative;
	top: 2px;
	color: rgba(0, 0, 0, 0.65);
	vertical-align: top;
}

.light-switch-checked .light-switch-loading-icon {
	color: var(--light-primary-color);
}

.light-switch-small {
	min-width: 28px;
	height: 16px;
	line-height: 16px;
}

.light-switch-small .light-switch-inner {
	margin: 0 5px 0 18px;
	font-size: 12px;
}

.light-switch-small .light-switch-handle {
	width: 12px;
	height: 12px;
}

.light-switch-small .light-switch-loading-icon {
	top: 1.5px;
	font-size: 9px;
}

.light-switch-small.light-switch-checked .light-switch-inner {
	margin: 0 18px 0 5px;
}

.light-switch-small.light-switch-checked .light-switch-handle {
	left: calc(100% - 12px - 2px);
}

.light-switch-rtl {
	direction: rtl;
}

.light-switch-rtl .light-switch-inner {
	margin: 0 25px 0 7px;
}

.light-switch-rtl .light-switch-handle {
	right: 2px;
	left: auto;
}

.light-switch-rtl:not(.light-switch-rtl-disabled):active .light-switch-handle::before {
	right: 0;
	left: -30%;
}

.light-switch-rtl:not(.light-switch-rtl-disabled):active.light-switch-checked .light-switch-handle::before {
	right: -30%;
	left: 0;
}

.light-switch-rtl.light-switch-checked .light-switch-inner {
	margin: 0 7px 0 25px;
}

.light-switch-rtl.light-switch-checked .light-switch-handle {
	right: calc(100% - 18px - 2px);
}

.light-switch-rtl.light-switch-small.light-switch-checked .light-switch-handle {
	right: calc(100% - 12px - 2px);
}

.light-table.light-table-middle {
	font-size: 14px;
}

.light-table.light-table-middle .light-table-title,
.light-table.light-table-middle .light-table-footer,
.light-table.light-table-middle .light-table-thead>tr>th,
.light-table.light-table-middle .light-table-tbody>tr>td,
.light-table.light-table-middle tfoot>tr>th,
.light-table.light-table-middle tfoot>tr>td {
	padding: 12px 8px;
}

.light-table.light-table-middle .light-table-filter-trigger {
	margin-right: -4px;
}

.light-table.light-table-middle .light-table-expanded-row-fixed {
	margin: -12px -8px;
}

.light-table.light-table-middle .light-table-tbody .light-table-wrapper:only-child .light-table {
	margin: -12px -8px -12px 40px;
}

.light-table.light-table-middle .light-table-selection-column {
	padding-inline-start: 2px;
}

.light-table.light-table-small {
	font-size: 14px;
}

.light-table.light-table-small .light-table-title,
.light-table.light-table-small .light-table-footer,
.light-table.light-table-small .light-table-thead>tr>th,
.light-table.light-table-small .light-table-tbody>tr>td,
.light-table.light-table-small tfoot>tr>th,
.light-table.light-table-small tfoot>tr>td {
	padding: 8px 8px;
}

.light-table.light-table-small .light-table-filter-trigger {
	margin-right: -4px;
}

.light-table.light-table-small .light-table-expanded-row-fixed {
	margin: -8px -8px;
}

.light-table.light-table-small .light-table-tbody .light-table-wrapper:only-child .light-table {
	margin: -8px -8px -8px 40px;
}

.light-table.light-table-small .light-table-selection-column {
	padding-inline-start: 2px;
}

.light-table.light-table-bordered>.light-table-title {
	border: 1px solid var(--border-color);
	border-bottom: 0;
}

.light-table.light-table-bordered>.light-table-container {
	border-left: 1px solid var(--border-color);
}

.light-table.light-table-bordered>.light-table-container>.light-table-content>table>thead>tr>th,
.light-table.light-table-bordered>.light-table-container>.light-table-header>table>thead>tr>th,
.light-table.light-table-bordered>.light-table-container>.light-table-body>table>thead>tr>th,
.light-table.light-table-bordered>.light-table-container>.light-table-summary>table>thead>tr>th,
.light-table.light-table-bordered>.light-table-container>.light-table-content>table>tbody>tr>td,
.light-table.light-table-bordered>.light-table-container>.light-table-header>table>tbody>tr>td,
.light-table.light-table-bordered>.light-table-container>.light-table-body>table>tbody>tr>td,
.light-table.light-table-bordered>.light-table-container>.light-table-summary>table>tbody>tr>td,
.light-table.light-table-bordered>.light-table-container>.light-table-content>table>tfoot>tr>th,
.light-table.light-table-bordered>.light-table-container>.light-table-header>table>tfoot>tr>th,
.light-table.light-table-bordered>.light-table-container>.light-table-body>table>tfoot>tr>th,
.light-table.light-table-bordered>.light-table-container>.light-table-summary>table>tfoot>tr>th,
.light-table.light-table-bordered>.light-table-container>.light-table-content>table>tfoot>tr>td,
.light-table.light-table-bordered>.light-table-container>.light-table-header>table>tfoot>tr>td,
.light-table.light-table-bordered>.light-table-container>.light-table-body>table>tfoot>tr>td,
.light-table.light-table-bordered>.light-table-container>.light-table-summary>table>tfoot>tr>td {
	border-right: 1px solid var(--border-color);
}

.light-table.light-table-bordered>.light-table-container>.light-table-content>table>thead>tr:not(:last-child)>th,
.light-table.light-table-bordered>.light-table-container>.light-table-header>table>thead>tr:not(:last-child)>th,
.light-table.light-table-bordered>.light-table-container>.light-table-body>table>thead>tr:not(:last-child)>th,
.light-table.light-table-bordered>.light-table-container>.light-table-summary>table>thead>tr:not(:last-child)>th {
	border-bottom: 1px solid var(--border-color);
}

.light-table.light-table-bordered>.light-table-container>.light-table-content>table>thead>tr>th::before,
.light-table.light-table-bordered>.light-table-container>.light-table-header>table>thead>tr>th::before,
.light-table.light-table-bordered>.light-table-container>.light-table-body>table>thead>tr>th::before,
.light-table.light-table-bordered>.light-table-container>.light-table-summary>table>thead>tr>th::before {
	background-color: transparent !important;
}

.light-table.light-table-bordered>.light-table-container>.light-table-content>table>thead>tr>.light-table-cell-fix-right-first::after,
.light-table.light-table-bordered>.light-table-container>.light-table-header>table>thead>tr>.light-table-cell-fix-right-first::after,
.light-table.light-table-bordered>.light-table-container>.light-table-body>table>thead>tr>.light-table-cell-fix-right-first::after,
.light-table.light-table-bordered>.light-table-container>.light-table-summary>table>thead>tr>.light-table-cell-fix-right-first::after,
.light-table.light-table-bordered>.light-table-container>.light-table-content>table>tbody>tr>.light-table-cell-fix-right-first::after,
.light-table.light-table-bordered>.light-table-container>.light-table-header>table>tbody>tr>.light-table-cell-fix-right-first::after,
.light-table.light-table-bordered>.light-table-container>.light-table-body>table>tbody>tr>.light-table-cell-fix-right-first::after,
.light-table.light-table-bordered>.light-table-container>.light-table-summary>table>tbody>tr>.light-table-cell-fix-right-first::after,
.light-table.light-table-bordered>.light-table-container>.light-table-content>table>tfoot>tr>.light-table-cell-fix-right-first::after,
.light-table.light-table-bordered>.light-table-container>.light-table-header>table>tfoot>tr>.light-table-cell-fix-right-first::after,
.light-table.light-table-bordered>.light-table-container>.light-table-body>table>tfoot>tr>.light-table-cell-fix-right-first::after,
.light-table.light-table-bordered>.light-table-container>.light-table-summary>table>tfoot>tr>.light-table-cell-fix-right-first::after {
	border-right: 1px solid var(--border-color);
}

.light-table.light-table-bordered>.light-table-container>.light-table-content>table>tbody>tr>td>.light-table-expanded-row-fixed,
.light-table.light-table-bordered>.light-table-container>.light-table-header>table>tbody>tr>td>.light-table-expanded-row-fixed,
.light-table.light-table-bordered>.light-table-container>.light-table-body>table>tbody>tr>td>.light-table-expanded-row-fixed,
.light-table.light-table-bordered>.light-table-container>.light-table-summary>table>tbody>tr>td>.light-table-expanded-row-fixed {
	margin: -16px -17px;
}

.light-table.light-table-bordered>.light-table-container>.light-table-content>table>tbody>tr>td>.light-table-expanded-row-fixed::after,
.light-table.light-table-bordered>.light-table-container>.light-table-header>table>tbody>tr>td>.light-table-expanded-row-fixed::after,
.light-table.light-table-bordered>.light-table-container>.light-table-body>table>tbody>tr>td>.light-table-expanded-row-fixed::after,
.light-table.light-table-bordered>.light-table-container>.light-table-summary>table>tbody>tr>td>.light-table-expanded-row-fixed::after {
	position: absolute;
	top: 0;
	right: 1px;
	bottom: 0;
	border-right: 1px solid var(--border-color);
	content: "";
}

.light-table.light-table-bordered>.light-table-container>.light-table-content>table,
.light-table.light-table-bordered>.light-table-container>.light-table-header>table {
	border-top: 1px solid var(--border-color);
}

.light-table.light-table-bordered.light-table-scroll-horizontal>.light-table-container>.light-table-body>table>tbody>tr.light-table-expanded-row>td,
.light-table.light-table-bordered.light-table-scroll-horizontal>.light-table-container>.light-table-body>table>tbody>tr.light-table-placeholder>td {
	border-right: 0;
}

.light-table.light-table-bordered.light-table-middle>.light-table-container>.light-table-content>table>tbody>tr>td>.light-table-expanded-row-fixed,
.light-table.light-table-bordered.light-table-middle>.light-table-container>.light-table-body>table>tbody>tr>td>.light-table-expanded-row-fixed {
	margin: -12px -9px;
}

.light-table.light-table-bordered.light-table-small>.light-table-container>.light-table-content>table>tbody>tr>td>.light-table-expanded-row-fixed,
.light-table.light-table-bordered.light-table-small>.light-table-container>.light-table-body>table>tbody>tr>td>.light-table-expanded-row-fixed {
	margin: -8px -9px;
}

.light-table.light-table-bordered>.light-table-footer {
	border: 1px solid var(--border-color);
	border-top: 0;
}

.light-table-cell .light-table-container:first-child {
	border-top: 0;
}

.light-table-cell-scrollbar:not([rowspan]) {
	box-shadow: 0 1px 0 1px #fafafa;
}

.light-table-wrapper {
	clear: both;
	max-width: 100%;
}

.light-table-wrapper::before {
	display: table;
	content: "";
}

.light-table-wrapper::after {
	display: table;
	clear: both;
	content: "";
}

.light-table-wrapper::before {
	display: table;
	content: "";
}

.light-table-wrapper::after {
	display: table;
	clear: both;
	content: "";
}

.light-table {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	font-size: 14px;
	background: #fff;
	border-radius: 2px;
	border-bottom: 0;
}

.light-table table {
	width: 100%;
	text-align: left;
	border-radius: 2px 2px 0 0;
	border-collapse: separate;
	border-spacing: 0;
}

.light-table-thead>tr>th,
.light-table-tbody>tr>td,
.light-table tfoot>tr>th,
.light-table tfoot>tr>td {
	position: relative;
	padding: 16px 16px;
	overflow-wrap: break-word;
}

.light-table-cell-ellipsis {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	word-break: keep-all;
}

.light-table-cell-ellipsis.light-table-cell-fix-left-last,
.light-table-cell-ellipsis.light-table-cell-fix-right-first {
	overflow: visible;
}

.light-table-cell-ellipsis.light-table-cell-fix-left-last .light-table-cell-content,
.light-table-cell-ellipsis.light-table-cell-fix-right-first .light-table-cell-content {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
}

.light-table-cell-ellipsis .light-table-column-title {
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: keep-all;
}

.light-table-title {
	padding: 16px 16px;
}

.light-table-footer {
	padding: 16px 16px;
	color: rgba(0, 0, 0, 0.85);
	background: #fafafa;
}

.light-table-thead>tr>th {
	position: relative;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
	text-align: left;
	background: #fafafa;
	border-bottom: 1px solid var(--border-color);
	transition: background 0.3s ease;
}

.light-table-thead>tr>th[colspan]:not([colspan="1"]) {
	text-align: center;
}

.light-table-thead>tr>th:not(:last-child):not(.light-table-selection-column):not(.light-table-row-expand-icon-cell):not([colspan])::before {
	position: absolute;
	top: 50%;
	right: 0;
	width: 1px;
	height: 1.6em;
	background-color: var(--border-color);
	transform: translateY(-50%);
	transition: background-color 0.3s;
	/* content: ""; */
}

.light-table-thead>tr:not(:last-child)>th[colspan] {
	border-bottom: 0;
}

.light-table-tbody>tr>td {
	border-bottom: 1px solid var(--border-color);
	transition: background 0.3s;
}

.light-table-tbody>tr>td>.light-table-wrapper:only-child .light-table,
.light-table-tbody>tr>td>.light-table-expanded-row-fixed>.light-table-wrapper:only-child .light-table {
	margin: -16px -16px -16px 32px;
}

.light-table-tbody>tr>td>.light-table-wrapper:only-child .light-table-tbody>tr:last-child>td,
.light-table-tbody>tr>td>.light-table-expanded-row-fixed>.light-table-wrapper:only-child .light-table-tbody>tr:last-child>td {
	border-bottom: 0;
}

.light-table-tbody>tr>td>.light-table-wrapper:only-child .light-table-tbody>tr:last-child>td:first-child,
.light-table-tbody>tr>td>.light-table-expanded-row-fixed>.light-table-wrapper:only-child .light-table-tbody>tr:last-child>td:first-child,
.light-table-tbody>tr>td>.light-table-wrapper:only-child .light-table-tbody>tr:last-child>td:last-child,
.light-table-tbody>tr>td>.light-table-expanded-row-fixed>.light-table-wrapper:only-child .light-table-tbody>tr:last-child>td:last-child {
	border-radius: 0;
}

.light-table-tbody>tr.light-table-row:hover>td,
.light-table-tbody>tr>td.light-table-cell-row-hover {
	background: var(--hover-bg);
}

.light-table-tbody>tr.light-table-row-selected>td {
	background: var(--light-primary-1);
	border-color: rgba(0, 0, 0, 0.03);
}

.light-table-tbody>tr.light-table-row-selected:hover>td {
	background: var(--light-primary-color-active-deprecated-d-02);
}

.light-table-summary {
	position: relative;
	z-index: 2;
	background: #fff;
}

div.light-table-summary {
	box-shadow: 0 -1px 0 var(--border-color);
}

.light-table-summary>tr>th,
.light-table-summary>tr>td {
	border-bottom: 1px solid var(--border-color);
}

.light-table-pagination.light-pagination {
	margin: 16px 0;
}

.light-table-pagination {
	display: flex;
	flex-wrap: wrap;
	row-gap: 8px;
}

.light-table-pagination>* {
	flex: none;
}

.light-table-pagination-left {
	justify-content: flex-start;
}

.light-table-pagination-center {
	justify-content: center;
}

.light-table-pagination-right {
	justify-content: flex-end;
}

.light-table-thead th.light-table-column-has-sorters {
	outline: none;
	cursor: pointer;
	transition: all 0.3s;
}

.light-table-thead th.light-table-column-has-sorters:hover {
	background: rgba(0, 0, 0, 0.04);
}

.light-table-thead th.light-table-column-has-sorters:hover::before {
	background-color: transparent !important;
}

.light-table-thead th.light-table-column-has-sorters:focus-visible {
	color: var(--light-primary-color);
}

.light-table-thead th.light-table-column-has-sorters.light-table-cell-fix-left:hover,
.light-table-thead th.light-table-column-has-sorters.light-table-cell-fix-right:hover {
	background: #f5f5f5;
}

.light-table-thead th.light-table-column-sort {
	background: #f5f5f5;
}

.light-table-thead th.light-table-column-sort::before {
	background-color: transparent !important;
}

td.light-table-column-sort {
	background: #fafafa;
}

.light-table-column-title {
	position: relative;
	z-index: 1;
	flex: 1;
}

.light-table-column-sorters {
	display: flex;
	flex: auto;
	align-items: center;
	justify-content: space-between;
}

.light-table-column-sorters::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	content: "";
}

.light-table-column-sorter {
	margin-left: 4px;
	color: #bfbfbf;
	font-size: 0;
	transition: color 0.3s;
}

.light-table-column-sorter-inner {
	display: inline-flex;
	flex-direction: column;
	align-items: center;
}

.light-table-column-sorter-up,
.light-table-column-sorter-down {
	font-size: 11px;
}

.light-table-column-sorter-up.active,
.light-table-column-sorter-down.active {
	color: var(--light-primary-color);
}

.light-table-column-sorter-up+.light-table-column-sorter-down {
	margin-top: -0.3em;
}

.light-table-column-sorters:hover .light-table-column-sorter {
	color: #a6a6a6;
}

.light-table-filter-column {
	display: flex;
	justify-content: space-between;
}

.light-table-filter-trigger {
	position: relative;
	display: flex;
	align-items: center;
	margin: -4px -8px -4px 4px;
	padding: 0 4px;
	color: #bfbfbf;
	font-size: 12px;
	border-radius: 2px;
	cursor: pointer;
	transition: all 0.3s;
}

.light-table-filter-trigger:hover {
	color: rgba(0, 0, 0, 0.45);
	background: rgba(0, 0, 0, 0.04);
}

.light-table-filter-trigger.active {
	color: var(--light-primary-color);
}

.light-table-filter-dropdown {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	min-width: 120px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.light-table-filter-dropdown .light-dropdown-menu {
	max-height: 264px;
	overflow-x: hidden;
	border: 0;
	box-shadow: none;
}

.light-table-filter-dropdown .light-dropdown-menu:empty::after {
	display: block;
	padding: 8px 0;
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	text-align: center;
	content: "Not Found";
}

.light-table-filter-dropdown-tree {
	padding: 8px 8px 0;
}

.light-table-filter-dropdown-tree .light-tree-treenode .light-tree-node-content-wrapper:hover {
	background-color: #f5f5f5;
}

.light-table-filter-dropdown-tree .light-tree-treenode-checkbox-checked .light-tree-node-content-wrapper,
.light-table-filter-dropdown-tree .light-tree-treenode-checkbox-checked .light-tree-node-content-wrapper:hover {
	background-color: var(--light-primary-2);
}

.light-table-filter-dropdown-search {
	padding: 8px;
	border-bottom: 1px var(--border-color) solid;
}

.light-table-filter-dropdown-search-input input {
	min-width: 140px;
}

.light-table-filter-dropdown-search-input .anticon {
	color: rgba(0, 0, 0, 0.25);
}

.light-table-filter-dropdown-checkall {
	width: 100%;
	margin-bottom: 4px;
	margin-left: 4px;
}

.light-table-filter-dropdown-submenu>ul {
	max-height: calc(100vh - 130px);
	overflow-x: hidden;
	overflow-y: auto;
}

.light-table-filter-dropdown .light-checkbox-wrapper+span,
.light-table-filter-dropdown-submenu .light-checkbox-wrapper+span {
	padding-left: 8px;
}

.light-table-filter-dropdown-btns {
	display: flex;
	justify-content: space-between;
	padding: 7px 8px;
	overflow: hidden;
	background-color: inherit;
	border-top: 1px solid var(--border-color);
}

.light-table-selection-col {
	width: 32px;
}

.light-table-bordered .light-table-selection-col {
	width: 50px;
}

table tr th.light-table-selection-column,
table tr td.light-table-selection-column {
	padding-right: 8px;
	padding-left: 8px;
	text-align: center;
}

table tr th.light-table-selection-column .light-radio-wrapper,
table tr td.light-table-selection-column .light-radio-wrapper {
	margin-right: 0;
}

table tr th.light-table-selection-column.light-table-cell-fix-left {
	z-index: 3;
}

table tr th.light-table-selection-column::after {
	background-color: transparent !important;
}

.light-table-selection {
	position: relative;
	display: inline-flex;
	flex-direction: column;
}

.light-table-selection-extra {
	position: absolute;
	top: 0;
	z-index: 1;
	cursor: pointer;
	transition: all 0.3s;
	margin-inline-start: 100%;
	padding-inline-start: 4px;
}

.light-table-selection-extra .anticon {
	color: #bfbfbf;
	font-size: 10px;
}

.light-table-selection-extra .anticon:hover {
	color: #a6a6a6;
}

.light-table-expand-icon-col {
	width: 48px;
}

.light-table-row-expand-icon-cell {
	text-align: center;
}

.light-table-row-expand-icon-cell .light-table-row-expand-icon {
	display: inline-flex;
	float: none;
	vertical-align: sub;
}

.light-table-row-indent {
	float: left;
	height: 1px;
}

.light-table-row-expand-icon {
	color: var(--light-primary-color);
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
	position: relative;
	float: left;
	box-sizing: border-box;
	width: 17px;
	height: 17px;
	padding: 0;
	color: inherit;
	line-height: 17px;
	background: #fff;
	border: 1px solid var(--border-color);
	border-radius: 2px;
	transform: scale(0.94117647);
	transition: all 0.3s;
	user-select: none;
}

.light-table-row-expand-icon:focus-visible,
.light-table-row-expand-icon:hover {
	color: var(--light-primary-color-hover);
}

.light-table-row-expand-icon:active {
	color: var(--light-primary-color-active);
}

.light-table-row-expand-icon:focus,
.light-table-row-expand-icon:hover,
.light-table-row-expand-icon:active {
	border-color: currentcolor;
}

.light-table-row-expand-icon::before,
.light-table-row-expand-icon::after {
	position: absolute;
	background: currentcolor;
	transition: transform 0.3s ease-out;
	content: "";
}

.light-table-row-expand-icon::before {
	top: 7px;
	right: 3px;
	left: 3px;
	height: 1px;
}

.light-table-row-expand-icon::after {
	top: 3px;
	bottom: 3px;
	left: 7px;
	width: 1px;
	transform: rotate(90deg);
}

.light-table-row-expand-icon-collapsed::before {
	transform: rotate(-180deg);
}

.light-table-row-expand-icon-collapsed::after {
	transform: rotate(0deg);
}

.light-table-row-expand-icon-spaced {
	background: transparent;
	border: 0;
	visibility: hidden;
}

.light-table-row-expand-icon-spaced::before,
.light-table-row-expand-icon-spaced::after {
	display: none;
	content: none;
}

.light-table-row-indent+.light-table-row-expand-icon {
	margin-top: 2.5005px;
	margin-right: 8px;
}

tr.light-table-expanded-row>td,
tr.light-table-expanded-row:hover>td {
	background: #fbfbfb;
}

tr.light-table-expanded-row .light-descriptions-view {
	display: flex;
}

tr.light-table-expanded-row .light-descriptions-view table {
	flex: auto;
	width: auto;
}

.light-table .light-table-expanded-row-fixed {
	position: relative;
	margin: -16px -16px;
	padding: 16px 16px;
}

.light-table-tbody>tr.light-table-placeholder {
	text-align: center;
}

.light-table-empty .light-table-tbody>tr.light-table-placeholder {
	color: rgba(0, 0, 0, 0.25);
}

.light-table-tbody>tr.light-table-placeholder:hover>td {
	background: #fff;
}

.light-table-cell-fix-left,
.light-table-cell-fix-right {
	position: sticky !important;
	z-index: 2;
	background: #fff;
}

.light-table-cell-fix-left-first::after,
.light-table-cell-fix-left-last::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: -1px;
	width: 30px;
	transform: translateX(100%);
	transition: box-shadow 0.3s;
	content: "";
	pointer-events: none;
}

.light-table-cell-fix-left-all::after {
	display: none;
}

.light-table-cell-fix-right-first::after,
.light-table-cell-fix-right-last::after {
	position: absolute;
	top: 0;
	bottom: -1px;
	left: 0;
	width: 30px;
	transform: translateX(-100%);
	transition: box-shadow 0.3s;
	content: "";
	pointer-events: none;
}

.light-table .light-table-container::before,
.light-table .light-table-container::after {
	position: absolute;
	top: 0;
	bottom: 0;
	z-index: 2;
	width: 30px;
	transition: box-shadow 0.3s;
	content: "";
	pointer-events: none;
}

.light-table .light-table-container::before {
	left: 0;
}

.light-table .light-table-container::after {
	right: 0;
}

.light-table-ping-left:not(.light-table-has-fix-left)>.light-table-container {
	position: relative;
}

.light-table-ping-left:not(.light-table-has-fix-left)>.light-table-container::before {
	box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}

.light-table-ping-left .light-table-cell-fix-left-first::after,
.light-table-ping-left .light-table-cell-fix-left-last::after {
	box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}

.light-table-ping-left .light-table-cell-fix-left-last::before {
	background-color: transparent !important;
}

.light-table-ping-right:not(.light-table-has-fix-right)>.light-table-container {
	position: relative;
}

.light-table-ping-right:not(.light-table-has-fix-right)>.light-table-container::after {
	box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}

.light-table-ping-right .light-table-cell-fix-right-first::after,
.light-table-ping-right .light-table-cell-fix-right-last::after {
	box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}

.light-table-sticky-holder {
	position: sticky;
	z-index: calc(2 + 1);
	background: #fff;
}

.light-table-sticky-scroll {
	position: sticky;
	bottom: 0;
	z-index: calc(2 + 1);
	display: flex;
	align-items: center;
	background: rgba(204, 204, 204, 0.06);
	border-top: 1px solid var(--border-color);
	opacity: 0.6;
}

.light-table-sticky-scroll:hover {
	transform-origin: center bottom;
}

.light-table-sticky-scroll-bar {
	height: 8px;
	background-color: rgba(0, 0, 0, 0.35);
	border-radius: 4px;
}

.light-table-sticky-scroll-bar:hover {
	background-color: rgba(0, 0, 0, 0.8);
}

.light-table-sticky-scroll-bar-active {
	background-color: rgba(0, 0, 0, 0.8);
}

@media all and (-ms-high-contrast: none) {
	.light-table-ping-left .light-table-cell-fix-left-last::after {
		box-shadow: none !important;
	}

	.light-table-ping-right .light-table-cell-fix-right-first::after {
		box-shadow: none !important;
	}
}

.light-table {
	/* title + table */
	/* table */
	/* table + footer */
}

.light-table-title {
	border-radius: 2px 2px 0 0;
}

.light-table-title+.light-table-container {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.light-table-title+.light-table-container table {
	border-radius: 0;
}

.light-table-title+.light-table-container table>thead>tr:first-child th:first-child {
	border-radius: 0;
}

.light-table-title+.light-table-container table>thead>tr:first-child th:last-child {
	border-radius: 0;
}

.light-table-container {
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
}

.light-table-container table>thead>tr:first-child th:first-child {
	border-top-left-radius: 2px;
}

.light-table-container table>thead>tr:first-child th:last-child {
	border-top-right-radius: 2px;
}

.light-table-footer {
	border-radius: 0 0 2px 2px;
}

.light-table-wrapper-rtl {
	direction: rtl;
}

.light-table-rtl {
	direction: rtl;
}

.light-table-wrapper-rtl .light-table table {
	text-align: right;
}

.light-table-wrapper-rtl .light-table-thead>tr>th[colspan]:not([colspan="1"]) {
	text-align: center;
}

.light-table-wrapper-rtl .light-table-thead>tr>th:not(:last-child):not(.light-table-selection-column):not(.light-table-row-expand-icon-cell):not([colspan])::before {
	right: auto;
	left: 0;
}

.light-table-wrapper-rtl .light-table-thead>tr>th {
	text-align: right;
}

.light-table-tbody>tr .light-table-wrapper:only-child .light-table.light-table-rtl {
	margin: -16px 33px -16px -16px;
}

.light-table-wrapper.light-table-wrapper-rtl .light-table-pagination-left {
	justify-content: flex-end;
}

.light-table-wrapper.light-table-wrapper-rtl .light-table-pagination-right {
	justify-content: flex-start;
}

.light-table-wrapper-rtl .light-table-column-sorter {
	margin-right: 4px;
	margin-left: 0;
}

.light-table-wrapper-rtl .light-table-filter-column-title {
	padding: 16px 16px 16px 2.3em;
}

.light-table-rtl .light-table-thead tr th.light-table-column-has-sorters .light-table-filter-column-title {
	padding: 0 0 0 2.3em;
}

.light-table-wrapper-rtl .light-table-filter-trigger {
	margin: -4px 4px -4px -8px;
}

.light-dropdown-rtl .light-table-filter-dropdown .light-checkbox-wrapper+span,
.light-dropdown-rtl .light-table-filter-dropdown-submenu .light-checkbox-wrapper+span,
.light-dropdown-menu-submenu-rtl.light-table-filter-dropdown .light-checkbox-wrapper+span,
.light-dropdown-menu-submenu-rtl.light-table-filter-dropdown-submenu .light-checkbox-wrapper+span {
	padding-right: 8px;
	padding-left: 0;
}

.light-table-wrapper-rtl .light-table-selection {
	text-align: center;
}

.light-table-wrapper-rtl .light-table-row-indent {
	float: right;
}

.light-table-wrapper-rtl .light-table-row-expand-icon {
	float: right;
}

.light-table-wrapper-rtl .light-table-row-indent+.light-table-row-expand-icon {
	margin-right: 0;
	margin-left: 8px;
}

.light-table-wrapper-rtl .light-table-row-expand-icon::after {
	transform: rotate(-90deg);
}

.light-table-wrapper-rtl .light-table-row-expand-icon-collapsed::before {
	transform: rotate(180deg);
}

.light-table-wrapper-rtl .light-table-row-expand-icon-collapsed::after {
	transform: rotate(0deg);
}

.light-tabs-small>.light-tabs-nav .light-tabs-tab {
	padding: 8px 0;
	font-size: 14px;
}

.light-tabs-large>.light-tabs-nav .light-tabs-tab {
	padding: 16px 0;
	font-size: 16px;
}

.light-tabs-card.light-tabs-small>.light-tabs-nav .light-tabs-tab {
	padding: 6px 16px;
}

.light-tabs-card.light-tabs-large>.light-tabs-nav .light-tabs-tab {
	padding: 7px 16px 6px;
}

.light-tabs-rtl {
	direction: rtl;
}

.light-tabs-rtl .light-tabs-nav .light-tabs-tab {
	margin: 0 0 0 32px;
}

.light-tabs-rtl .light-tabs-nav .light-tabs-tab:last-of-type {
	margin-left: 0;
}

.light-tabs-rtl .light-tabs-nav .light-tabs-tab .anticon {
	margin-right: 0;
	margin-left: 12px;
}

.light-tabs-rtl .light-tabs-nav .light-tabs-tab .light-tabs-tab-remove {
	margin-right: 8px;
	margin-left: -4px;
}

.light-tabs-rtl .light-tabs-nav .light-tabs-tab .light-tabs-tab-remove .anticon {
	margin: 0;
}

.light-tabs-rtl.light-tabs-left>.light-tabs-nav {
	order: 1;
}

.light-tabs-rtl.light-tabs-left>.light-tabs-content-holder {
	order: 0;
}

.light-tabs-rtl.light-tabs-right>.light-tabs-nav {
	order: 0;
}

.light-tabs-rtl.light-tabs-right>.light-tabs-content-holder {
	order: 1;
}

.light-tabs-rtl.light-tabs-card.light-tabs-top>.light-tabs-nav .light-tabs-tab+.light-tabs-tab,
.light-tabs-rtl.light-tabs-card.light-tabs-bottom>.light-tabs-nav .light-tabs-tab+.light-tabs-tab,
.light-tabs-rtl.light-tabs-card.light-tabs-top>div>.light-tabs-nav .light-tabs-tab+.light-tabs-tab,
.light-tabs-rtl.light-tabs-card.light-tabs-bottom>div>.light-tabs-nav .light-tabs-tab+.light-tabs-tab {
	margin-right: 2px;
	margin-left: 0;
}

.light-tabs-rtl.light-tabs-card.light-tabs-top>.light-tabs-nav .light-tabs-nav-add,
.light-tabs-rtl.light-tabs-card.light-tabs-bottom>.light-tabs-nav .light-tabs-nav-add,
.light-tabs-rtl.light-tabs-card.light-tabs-top>div>.light-tabs-nav .light-tabs-nav-add,
.light-tabs-rtl.light-tabs-card.light-tabs-bottom>div>.light-tabs-nav .light-tabs-nav-add {
	margin-right: 2px;
	margin-left: 0;
}

.light-tabs-dropdown-rtl {
	direction: rtl;
}

.light-tabs-dropdown-rtl .light-tabs-dropdown-menu-item {
	text-align: right;
}

.light-tabs-top,
.light-tabs-bottom {
	flex-direction: column;
}

.light-tabs-top>.light-tabs-nav,
.light-tabs-bottom>.light-tabs-nav,
.light-tabs-top>div>.light-tabs-nav,
.light-tabs-bottom>div>.light-tabs-nav {
	/* margin: 0 0 16px 0; */
}

.light-tabs-top>.light-tabs-nav::before,
.light-tabs-bottom>.light-tabs-nav::before,
.light-tabs-top>div>.light-tabs-nav::before,
.light-tabs-bottom>div>.light-tabs-nav::before {
	position: absolute;
	right: 0;
	left: 0;
	/* border-bottom: 1px solid var(--border-color); */
	content: "";
}

.light-tabs-top>.light-tabs-nav .light-tabs-ink-bar,
.light-tabs-bottom>.light-tabs-nav .light-tabs-ink-bar,
.light-tabs-top>div>.light-tabs-nav .light-tabs-ink-bar,
.light-tabs-bottom>div>.light-tabs-nav .light-tabs-ink-bar {
	height: 2px;
}

.light-tabs-top>.light-tabs-nav .light-tabs-ink-bar-animated,
.light-tabs-bottom>.light-tabs-nav .light-tabs-ink-bar-animated,
.light-tabs-top>div>.light-tabs-nav .light-tabs-ink-bar-animated,
.light-tabs-bottom>div>.light-tabs-nav .light-tabs-ink-bar-animated {
	transition: width 0.3s, left 0.3s, right 0.3s;
}

.light-tabs-top>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-bottom>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-top>div>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-bottom>div>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-top>.light-tabs-nav .light-tabs-nav-wrap::after,
.light-tabs-bottom>.light-tabs-nav .light-tabs-nav-wrap::after,
.light-tabs-top>div>.light-tabs-nav .light-tabs-nav-wrap::after,
.light-tabs-bottom>div>.light-tabs-nav .light-tabs-nav-wrap::after {
	top: 0;
	bottom: 0;
	width: 30px;
}

.light-tabs-top>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-bottom>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-top>div>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-bottom>div>.light-tabs-nav .light-tabs-nav-wrap::before {
	left: 0;
	box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}

.light-tabs-top>.light-tabs-nav .light-tabs-nav-wrap::after,
.light-tabs-bottom>.light-tabs-nav .light-tabs-nav-wrap::after,
.light-tabs-top>div>.light-tabs-nav .light-tabs-nav-wrap::after,
.light-tabs-bottom>div>.light-tabs-nav .light-tabs-nav-wrap::after {
	right: 0;
	box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}

.light-tabs-top>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-left::before,
.light-tabs-bottom>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-left::before,
.light-tabs-top>div>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-left::before,
.light-tabs-bottom>div>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-left::before {
	opacity: 1;
}

.light-tabs-top>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-right::after,
.light-tabs-bottom>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-right::after,
.light-tabs-top>div>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-right::after,
.light-tabs-bottom>div>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-right::after {
	opacity: 1;
}

.light-tabs-top>.light-tabs-nav::before,
.light-tabs-top>div>.light-tabs-nav::before {
	bottom: 0;
}

.light-tabs-top>.light-tabs-nav .light-tabs-ink-bar,
.light-tabs-top>div>.light-tabs-nav .light-tabs-ink-bar {
	bottom: 0;
}

.light-tabs-bottom>.light-tabs-nav,
.light-tabs-bottom>div>.light-tabs-nav {
	order: 1;
	margin-top: 16px;
	margin-bottom: 0;
}

.light-tabs-bottom>.light-tabs-nav::before,
.light-tabs-bottom>div>.light-tabs-nav::before {
	top: 0;
}

.light-tabs-bottom>.light-tabs-nav .light-tabs-ink-bar,
.light-tabs-bottom>div>.light-tabs-nav .light-tabs-ink-bar {
	top: 0;
}

.light-tabs-bottom>.light-tabs-content-holder,
.light-tabs-bottom>div>.light-tabs-content-holder {
	order: 0;
}

.light-tabs-left>.light-tabs-nav,
.light-tabs-right>.light-tabs-nav,
.light-tabs-left>div>.light-tabs-nav,
.light-tabs-right>div>.light-tabs-nav {
	flex-direction: column;
	min-width: 50px;
}

.light-tabs-left>.light-tabs-nav .light-tabs-tab,
.light-tabs-right>.light-tabs-nav .light-tabs-tab,
.light-tabs-left>div>.light-tabs-nav .light-tabs-tab,
.light-tabs-right>div>.light-tabs-nav .light-tabs-tab {
	padding: 8px 24px;
	text-align: center;
}

.light-tabs-left>.light-tabs-nav .light-tabs-tab+.light-tabs-tab,
.light-tabs-right>.light-tabs-nav .light-tabs-tab+.light-tabs-tab,
.light-tabs-left>div>.light-tabs-nav .light-tabs-tab+.light-tabs-tab,
.light-tabs-right>div>.light-tabs-nav .light-tabs-tab+.light-tabs-tab {
	margin: 16px 0 0 0;
}

.light-tabs-left>.light-tabs-nav .light-tabs-nav-wrap,
.light-tabs-right>.light-tabs-nav .light-tabs-nav-wrap,
.light-tabs-left>div>.light-tabs-nav .light-tabs-nav-wrap,
.light-tabs-right>div>.light-tabs-nav .light-tabs-nav-wrap {
	flex-direction: column;
}

.light-tabs-left>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-right>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-left>div>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-right>div>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-left>.light-tabs-nav .light-tabs-nav-wrap::after,
.light-tabs-right>.light-tabs-nav .light-tabs-nav-wrap::after,
.light-tabs-left>div>.light-tabs-nav .light-tabs-nav-wrap::after,
.light-tabs-right>div>.light-tabs-nav .light-tabs-nav-wrap::after {
	right: 0;
	left: 0;
	height: 30px;
}

.light-tabs-left>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-right>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-left>div>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs-right>div>.light-tabs-nav .light-tabs-nav-wrap::before {
	top: 0;
	box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}

.light-tabs-left>.light-tabs-nav .light-tabs-nav-wrap::after,
.light-tabs-right>.light-tabs-nav .light-tabs-nav-wrap::after,
.light-tabs-left>div>.light-tabs-nav .light-tabs-nav-wrap::after,
.light-tabs-right>div>.light-tabs-nav .light-tabs-nav-wrap::after {
	bottom: 0;
	box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}

.light-tabs-left>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-top::before,
.light-tabs-right>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-top::before,
.light-tabs-left>div>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-top::before,
.light-tabs-right>div>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-top::before {
	opacity: 1;
}

.light-tabs-left>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-bottom::after,
.light-tabs-right>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-bottom::after,
.light-tabs-left>div>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-bottom::after,
.light-tabs-right>div>.light-tabs-nav .light-tabs-nav-wrap.light-tabs-nav-wrap-ping-bottom::after {
	opacity: 1;
}

.light-tabs-left>.light-tabs-nav .light-tabs-ink-bar,
.light-tabs-right>.light-tabs-nav .light-tabs-ink-bar,
.light-tabs-left>div>.light-tabs-nav .light-tabs-ink-bar,
.light-tabs-right>div>.light-tabs-nav .light-tabs-ink-bar {
	width: 2px;
}

.light-tabs-left>.light-tabs-nav .light-tabs-ink-bar-animated,
.light-tabs-right>.light-tabs-nav .light-tabs-ink-bar-animated,
.light-tabs-left>div>.light-tabs-nav .light-tabs-ink-bar-animated,
.light-tabs-right>div>.light-tabs-nav .light-tabs-ink-bar-animated {
	transition: height 0.3s, top 0.3s;
}

.light-tabs-left>.light-tabs-nav .light-tabs-nav-list,
.light-tabs-right>.light-tabs-nav .light-tabs-nav-list,
.light-tabs-left>div>.light-tabs-nav .light-tabs-nav-list,
.light-tabs-right>div>.light-tabs-nav .light-tabs-nav-list,
.light-tabs-left>.light-tabs-nav .light-tabs-nav-operations,
.light-tabs-right>.light-tabs-nav .light-tabs-nav-operations,
.light-tabs-left>div>.light-tabs-nav .light-tabs-nav-operations,
.light-tabs-right>div>.light-tabs-nav .light-tabs-nav-operations {
	flex: 1 0 auto;
	flex-direction: column;
}

.light-tabs-left>.light-tabs-nav .light-tabs-ink-bar,
.light-tabs-left>div>.light-tabs-nav .light-tabs-ink-bar {
	right: 0;
}

.light-tabs-left>.light-tabs-content-holder,
.light-tabs-left>div>.light-tabs-content-holder {
	margin-left: -1px;
	border-left: 1px solid var(--border-color);
}

.light-tabs-left>.light-tabs-content-holder>.light-tabs-content>.light-tabs-tabpane,
.light-tabs-left>div>.light-tabs-content-holder>.light-tabs-content>.light-tabs-tabpane {
	padding-left: 24px;
}

.light-tabs-right>.light-tabs-nav,
.light-tabs-right>div>.light-tabs-nav {
	order: 1;
}

.light-tabs-right>.light-tabs-nav .light-tabs-ink-bar,
.light-tabs-right>div>.light-tabs-nav .light-tabs-ink-bar {
	left: 0;
}

.light-tabs-right>.light-tabs-content-holder,
.light-tabs-right>div>.light-tabs-content-holder {
	order: 0;
	margin-right: -1px;
	border-right: 1px solid var(--border-color);
}

.light-tabs-right>.light-tabs-content-holder>.light-tabs-content>.light-tabs-tabpane,
.light-tabs-right>div>.light-tabs-content-holder>.light-tabs-content>.light-tabs-tabpane {
	padding-right: 24px;
}

.light-tabs-dropdown {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: absolute;
	top: -9999px;
	left: -9999px;
	z-index: 1050;
	display: block;
}

.light-tabs-dropdown-hidden {
	display: none;
}

.light-tabs-dropdown-menu {
	max-height: 200px;
	margin: 0;
	padding: 4px 0;
	overflow-x: hidden;
	overflow-y: auto;
	text-align: left;
	list-style-type: none;
	background-color: #fff;
	background-clip: padding-box;
	border-radius: 2px;
	outline: none;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.light-tabs-dropdown-menu-item {
	display: flex;
	align-items: center;
	min-width: 120px;
	margin: 0;
	padding: 5px 12px;
	overflow: hidden;
	color: rgba(0, 0, 0, 0.85);
	font-weight: normal;
	font-size: 14px;
	line-height: 22px;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
	transition: all 0.3s;
}

.light-tabs-dropdown-menu-item>span {
	flex: 1;
	white-space: nowrap;
}

.light-tabs-dropdown-menu-item-remove {
	flex: none;
	margin-left: 12px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	background: transparent;
	border: 0;
	cursor: pointer;
}

.light-tabs-dropdown-menu-item-remove:hover {
	color: var(--light-primary-5);
}

.light-tabs-dropdown-menu-item:hover {
	background: #f5f5f5;
}

.light-tabs-dropdown-menu-item-disabled,
.light-tabs-dropdown-menu-item-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
	background: transparent;
	cursor: not-allowed;
}

.light-tabs-card>.light-tabs-nav .light-tabs-tab,
.light-tabs-card>div>.light-tabs-nav .light-tabs-tab {
	margin: 0;
	padding: 8px 16px;
	background: #fafafa;
	border: 1px solid var(--border-color);
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-tabs-card>.light-tabs-nav .light-tabs-tab-active,
.light-tabs-card>div>.light-tabs-nav .light-tabs-tab-active {
	color: var(--light-primary-color);
	background: #fff;
}

.light-tabs-card>.light-tabs-nav .light-tabs-ink-bar,
.light-tabs-card>div>.light-tabs-nav .light-tabs-ink-bar {
	visibility: hidden;
}

.light-tabs-card.light-tabs-top>.light-tabs-nav .light-tabs-tab+.light-tabs-tab,
.light-tabs-card.light-tabs-bottom>.light-tabs-nav .light-tabs-tab+.light-tabs-tab,
.light-tabs-card.light-tabs-top>div>.light-tabs-nav .light-tabs-tab+.light-tabs-tab,
.light-tabs-card.light-tabs-bottom>div>.light-tabs-nav .light-tabs-tab+.light-tabs-tab {
	margin-left: 2px;
}

.light-tabs-card.light-tabs-top>.light-tabs-nav .light-tabs-tab,
.light-tabs-card.light-tabs-top>div>.light-tabs-nav .light-tabs-tab {
	border-radius: 2px 2px 0 0;
}

.light-tabs-card.light-tabs-top>.light-tabs-nav .light-tabs-tab-active,
.light-tabs-card.light-tabs-top>div>.light-tabs-nav .light-tabs-tab-active {
	border-bottom-color: #fff;
}

.light-tabs-card.light-tabs-bottom>.light-tabs-nav .light-tabs-tab,
.light-tabs-card.light-tabs-bottom>div>.light-tabs-nav .light-tabs-tab {
	border-radius: 0 0 2px 2px;
}

.light-tabs-card.light-tabs-bottom>.light-tabs-nav .light-tabs-tab-active,
.light-tabs-card.light-tabs-bottom>div>.light-tabs-nav .light-tabs-tab-active {
	border-top-color: #fff;
}

.light-tabs-card.light-tabs-left>.light-tabs-nav .light-tabs-tab+.light-tabs-tab,
.light-tabs-card.light-tabs-right>.light-tabs-nav .light-tabs-tab+.light-tabs-tab,
.light-tabs-card.light-tabs-left>div>.light-tabs-nav .light-tabs-tab+.light-tabs-tab,
.light-tabs-card.light-tabs-right>div>.light-tabs-nav .light-tabs-tab+.light-tabs-tab {
	margin-top: 2px;
}

.light-tabs-card.light-tabs-left>.light-tabs-nav .light-tabs-tab,
.light-tabs-card.light-tabs-left>div>.light-tabs-nav .light-tabs-tab {
	border-radius: 2px 0 0 2px;
}

.light-tabs-card.light-tabs-left>.light-tabs-nav .light-tabs-tab-active,
.light-tabs-card.light-tabs-left>div>.light-tabs-nav .light-tabs-tab-active {
	border-right-color: #fff;
}

.light-tabs-card.light-tabs-right>.light-tabs-nav .light-tabs-tab,
.light-tabs-card.light-tabs-right>div>.light-tabs-nav .light-tabs-tab {
	border-radius: 0 2px 2px 0;
}

.light-tabs-card.light-tabs-right>.light-tabs-nav .light-tabs-tab-active,
.light-tabs-card.light-tabs-right>div>.light-tabs-nav .light-tabs-tab-active {
	border-left-color: #fff;
}

.light-tabs {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: flex;
}

.light-tabs>.light-tabs-nav,
.light-tabs>div>.light-tabs-nav {
	position: relative;
	display: flex;
	flex: none;
	align-items: center;
}

.light-tabs>.light-tabs-nav .light-tabs-nav-wrap,
.light-tabs>div>.light-tabs-nav .light-tabs-nav-wrap {
	position: relative;
	display: inline-block;
	display: flex;
	flex: auto;
	align-self: stretch;
	overflow: hidden;
	white-space: nowrap;
	transform: translate(0);
}

.light-tabs>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs>div>.light-tabs-nav .light-tabs-nav-wrap::before,
.light-tabs>.light-tabs-nav .light-tabs-nav-wrap::after,
.light-tabs>div>.light-tabs-nav .light-tabs-nav-wrap::after {
	position: absolute;
	z-index: 1;
	opacity: 0;
	transition: opacity 0.3s;
	content: "";
	pointer-events: none;
}

.light-tabs>.light-tabs-nav .light-tabs-nav-list,
.light-tabs>div>.light-tabs-nav .light-tabs-nav-list {
	position: relative;
	display: flex;
	transition: transform 0.3s;
}

.light-tabs>.light-tabs-nav .light-tabs-nav-operations,
.light-tabs>div>.light-tabs-nav .light-tabs-nav-operations {
	display: flex;
	align-self: stretch;
}

.light-tabs>.light-tabs-nav .light-tabs-nav-operations-hidden,
.light-tabs>div>.light-tabs-nav .light-tabs-nav-operations-hidden {
	position: absolute;
	visibility: hidden;
	pointer-events: none;
}

.light-tabs>.light-tabs-nav .light-tabs-nav-more,
.light-tabs>div>.light-tabs-nav .light-tabs-nav-more {
	position: relative;
	padding: 8px 16px;
	background: transparent;
	border: 0;
}

.light-tabs>.light-tabs-nav .light-tabs-nav-more::after,
.light-tabs>div>.light-tabs-nav .light-tabs-nav-more::after {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	height: 5px;
	transform: translateY(100%);
	content: "";
}

.light-tabs>.light-tabs-nav .light-tabs-nav-add,
.light-tabs>div>.light-tabs-nav .light-tabs-nav-add {
	min-width: 40px;
	margin-left: 2px;
	padding: 0 8px;
	background: #fafafa;
	border: 1px solid var(--border-color);
	border-radius: 2px 2px 0 0;
	outline: none;
	cursor: pointer;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.light-tabs>.light-tabs-nav .light-tabs-nav-add:hover,
.light-tabs>div>.light-tabs-nav .light-tabs-nav-add:hover {
	color: var(--light-primary-5);
}

.light-tabs>.light-tabs-nav .light-tabs-nav-add:active,
.light-tabs>div>.light-tabs-nav .light-tabs-nav-add:active,
.light-tabs>.light-tabs-nav .light-tabs-nav-add:focus,
.light-tabs>div>.light-tabs-nav .light-tabs-nav-add:focus {
	color: var(--light-primary-7);
}

.light-tabs-extra-content {
	flex: none;
}

.light-tabs-centered>.light-tabs-nav .light-tabs-nav-wrap:not([class*="light-tabs-nav-wrap-ping"]),
.light-tabs-centered>div>.light-tabs-nav .light-tabs-nav-wrap:not([class*="light-tabs-nav-wrap-ping"]) {
	justify-content: center;
}

.light-tabs-ink-bar {
	position: absolute;
	background: var(--light-primary-color);
	pointer-events: none;
	border-radius: 25% !important;
	transform: scale(0.2, 1);
	height: 4px !important;
}

.light-tabs-tab {
	position: relative;
	display: inline-flex;
	align-items: center;
	/* padding: 12px 0; */
	padding-bottom: 8px;
	padding-top: 16px;
	font-size: 14px;
	background: transparent;
	border: 0;
	outline: none;
	cursor: pointer;
}

.light-tabs-tab-btn:focus,
.light-tabs-tab-remove:focus,
.light-tabs-tab-btn:active,
.light-tabs-tab-remove:active {
	color: var(--light-primary-7);
}

.light-tabs-tab-btn {
	outline: none;
	transition: all 0.3s;
}

.light-tabs-tab-remove {
	flex: none;
	margin-right: -4px;
	margin-left: 8px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 12px;
	background: transparent;
	border: none;
	outline: none;
	cursor: pointer;
	transition: all 0.3s;
}

.light-tabs-tab-remove:hover {
	color: rgba(0, 0, 0, 0.85);
}

.light-tabs-tab:hover {
	color: var(--light-primary-5);
}

.light-tabs-tab.light-tabs-tab-active .light-tabs-tab-btn {
	color: var(--light-primary-color);
	text-shadow: 0 0 0.25px currentcolor;
}

.light-tabs-tab.light-tabs-tab-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-tabs-tab.light-tabs-tab-disabled .light-tabs-tab-btn:focus,
.light-tabs-tab.light-tabs-tab-disabled .light-tabs-tab-remove:focus,
.light-tabs-tab.light-tabs-tab-disabled .light-tabs-tab-btn:active,
.light-tabs-tab.light-tabs-tab-disabled .light-tabs-tab-remove:active {
	color: rgba(0, 0, 0, 0.25);
}

.light-tabs-tab .light-tabs-tab-remove .anticon {
	margin: 0;
}

.light-tabs-tab .anticon {
	margin-right: 12px;
}

.light-tabs-tab+.light-tabs-tab {
	margin: 0 0 0 32px;
}

.light-tabs-content {
	position: relative;
	width: 100%;
}

.light-tabs-content-holder {
	flex: auto;
	min-width: 0;
	min-height: 0;
}

.light-tabs-tabpane-hidden {
	display: none;
}

.light-tabs-switch-appear,
.light-tabs-switch-enter {
	transition: none;
}

.light-tabs-switch-appear-start,
.light-tabs-switch-enter-start {
	opacity: 0;
}

.light-tabs-switch-appear-active,
.light-tabs-switch-enter-active {
	opacity: 1;
	transition: opacity 0.3s;
}

.light-tabs-switch-leave {
	position: absolute;
	transition: none;
	inset: 0;
}

.light-tabs-switch-leave-start {
	opacity: 1;
}

.light-tabs-switch-leave-active {
	opacity: 0;
	transition: opacity 0.3s;
}

.light-tag {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: inline-block;
	height: auto;
	margin-right: 8px;
	padding: 0 7px;
	font-size: 12px;
	line-height: 20px;
	white-space: nowrap;
	background: #fafafa;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	opacity: 1;
	transition: all 0.3s;
}

.light-tag,
.light-tag a,
.light-tag a:hover {
	color: rgba(0, 0, 0, 0.85);
}

.light-tag>a:first-child:last-child {
	display: inline-block;
	margin: 0 -8px;
	padding: 0 8px;
}

.light-tag-close-icon {
	margin-left: 3px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 10px;
	cursor: pointer;
	transition: all 0.3s;
}

.light-tag-close-icon:hover {
	color: rgba(0, 0, 0, 0.85);
}

.light-tag-has-color {
	border-color: transparent;
}

.light-tag-has-color,
.light-tag-has-color a,
.light-tag-has-color a:hover,
.light-tag-has-color .anticon-close,
.light-tag-has-color .anticon-close:hover {
	color: #fff;
}

.light-tag-checkable {
	background-color: transparent;
	border-color: transparent;
	cursor: pointer;
}

.light-tag-checkable:not(.light-tag-checkable-checked):hover {
	color: var(--light-primary-color);
}

.light-tag-checkable:active,
.light-tag-checkable-checked {
	color: #fff;
}

.light-tag-checkable-checked {
	background-color: var(--light-primary-6);
}

.light-tag-checkable:active {
	background-color: var(--light-primary-7);
}

.light-tag-hidden {
	display: none;
}

.light-tag-pink {
	color: #c41d7f;
	background: #fff0f6;
	border-color: #ffadd2;
}

.light-tag-pink-inverse {
	color: #fff;
	background: #eb2f96;
	border-color: #eb2f96;
}

.light-tag-magenta {
	color: #c41d7f;
	background: #fff0f6;
	border-color: #ffadd2;
}

.light-tag-magenta-inverse {
	color: #fff;
	background: #eb2f96;
	border-color: #eb2f96;
}

.light-tag-red {
	color: #cf1322;
	background: #fff1f0;
	border-color: #ffa39e;
}

.light-tag-red-inverse {
	color: #fff;
	background: #f5222d;
	border-color: #f5222d;
}

.light-tag-volcano {
	color: #d4380d;
	background: #fff2e8;
	border-color: #ffbb96;
}

.light-tag-volcano-inverse {
	color: #fff;
	background: #fa541c;
	border-color: #fa541c;
}

.light-tag-orange {
	color: #d46b08;
	background: #fff7e6;
	border-color: #ffd591;
}

.light-tag-orange-inverse {
	color: #fff;
	background: #fa8c16;
	border-color: #fa8c16;
}

.light-tag-yellow {
	color: #d4b106;
	background: #feffe6;
	border-color: #fffb8f;
}

.light-tag-yellow-inverse {
	color: #fff;
	background: #fadb14;
	border-color: #fadb14;
}

.light-tag-gold {
	color: #d48806;
	background: #fffbe6;
	border-color: #ffe58f;
}

.light-tag-gold-inverse {
	color: #fff;
	background: #faad14;
	border-color: #faad14;
}

.light-tag-cyan {
	color: #08979c;
	background: #e6fffb;
	border-color: #87e8de;
}

.light-tag-cyan-inverse {
	color: #fff;
	background: #13c2c2;
	border-color: #13c2c2;
}

.light-tag-lime {
	color: #7cb305;
	background: #fcffe6;
	border-color: #eaff8f;
}

.light-tag-lime-inverse {
	color: #fff;
	background: #a0d911;
	border-color: #a0d911;
}

.light-tag-green {
	color: #389e0d;
	background: #f6ffed;
	border-color: #b7eb8f;
}

.light-tag-green-inverse {
	color: #fff;
	background: #52c41a;
	border-color: #52c41a;
}

.light-tag-blue {
	color: #096dd9;
	background: #e6f7ff;
	border-color: #91d5ff;
}

.light-tag-blue-inverse {
	color: #fff;
	background: #1890ff;
	border-color: #1890ff;
}

.light-tag-geekblue {
	color: #1d39c4;
	background: #f0f5ff;
	border-color: #adc6ff;
}

.light-tag-geekblue-inverse {
	color: #fff;
	background: #2f54eb;
	border-color: #2f54eb;
}

.light-tag-purple {
	color: #531dab;
	background: #f9f0ff;
	border-color: #d3adf7;
}

.light-tag-purple-inverse {
	color: #fff;
	background: #722ed1;
	border-color: #722ed1;
}

.light-tag-success {
	color: var(--light-success-color);
	background: var(--light-success-color-deprecated-bg);
	border-color: var(--light-success-color-deprecated-border);
}

.light-tag-processing {
	color: var(--light-info-color);
	background: var(--light-info-color-deprecated-bg);
	border-color: var(--light-info-color-deprecated-border);
}

.light-tag-error {
	color: var(--light-error-color);
	background: var(--light-error-color-deprecated-bg);
	border-color: var(--light-error-color-deprecated-border);
}

.light-tag-warning {
	color: var(--light-warning-color);
	background: var(--light-warning-color-deprecated-bg);
	border-color: var(--light-warning-color-deprecated-border);
}

.light-tag>.anticon+span,
.light-tag>span+.anticon {
	margin-left: 7px;
}

.light-tag.light-tag-rtl {
	margin-right: 0;
	margin-left: 8px;
	direction: rtl;
	text-align: right;
}

.light-tag-rtl .light-tag-close-icon {
	margin-right: 3px;
	margin-left: 0;
}

.light-tag-rtl.light-tag>.anticon+span,
.light-tag-rtl.light-tag>span+.anticon {
	margin-right: 7px;
	margin-left: 0;
}

.light-timeline {
	box-sizing: border-box;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	font-feature-settings: "tnum";
	margin: 0;
	padding: 0;
	list-style: none;
}

.light-timeline-item {
	position: relative;
	margin: 0;
	padding-bottom: 20px;
	font-size: 14px;
	list-style: none;
}

.light-timeline-item-tail {
	position: absolute;
	top: 10px;
	left: 4px;
	height: calc(100% - 10px);
	border-left: 2px solid var(--border-color);
}

.light-timeline-item-pending .light-timeline-item-head {
	font-size: 12px;
	background-color: transparent;
}

.light-timeline-item-pending .light-timeline-item-tail {
	display: none;
}

.light-timeline-item-head {
	position: absolute;
	width: 10px;
	height: 10px;
	background-color: #fff;
	border: 2px solid transparent;
	border-radius: 100px;
}

.light-timeline-item-head-blue {
	color: var(--light-primary-color);
	border-color: var(--light-primary-color);
}

.light-timeline-item-head-red {
	color: var(--light-error-color);
	border-color: var(--light-error-color);
}

.light-timeline-item-head-green {
	color: var(--light-success-color);
	border-color: var(--light-success-color);
}

.light-timeline-item-head-gray {
	color: rgba(0, 0, 0, 0.25);
	border-color: rgba(0, 0, 0, 0.25);
}

.light-timeline-item-head-custom {
	position: absolute;
	top: 5.5px;
	left: 5px;
	width: auto;
	height: auto;
	margin-top: 0;
	padding: 3px 1px;
	line-height: 1;
	text-align: center;
	border: 0;
	border-radius: 0;
	transform: translate(-50%, -50%);
}

.light-timeline-item-content {
	position: relative;
	top: -7.001px;
	margin: 0 0 0 26px;
	word-break: break-word;
}

.light-timeline-item-last>.light-timeline-item-tail {
	display: none;
}

.light-timeline-item-last>.light-timeline-item-content {
	min-height: 48px;
}

.light-timeline.light-timeline-alternate .light-timeline-item-tail,
.light-timeline.light-timeline-right .light-timeline-item-tail,
.light-timeline.light-timeline-label .light-timeline-item-tail,
.light-timeline.light-timeline-alternate .light-timeline-item-head,
.light-timeline.light-timeline-right .light-timeline-item-head,
.light-timeline.light-timeline-label .light-timeline-item-head,
.light-timeline.light-timeline-alternate .light-timeline-item-head-custom,
.light-timeline.light-timeline-right .light-timeline-item-head-custom,
.light-timeline.light-timeline-label .light-timeline-item-head-custom {
	left: 50%;
}

.light-timeline.light-timeline-alternate .light-timeline-item-head,
.light-timeline.light-timeline-right .light-timeline-item-head,
.light-timeline.light-timeline-label .light-timeline-item-head {
	margin-left: -4px;
}

.light-timeline.light-timeline-alternate .light-timeline-item-head-custom,
.light-timeline.light-timeline-right .light-timeline-item-head-custom,
.light-timeline.light-timeline-label .light-timeline-item-head-custom {
	margin-left: 1px;
}

.light-timeline.light-timeline-alternate .light-timeline-item-left .light-timeline-item-content,
.light-timeline.light-timeline-right .light-timeline-item-left .light-timeline-item-content,
.light-timeline.light-timeline-label .light-timeline-item-left .light-timeline-item-content {
	left: calc(50% - 4px);
	width: calc(50% - 14px);
	text-align: left;
}

.light-timeline.light-timeline-alternate .light-timeline-item-right .light-timeline-item-content,
.light-timeline.light-timeline-right .light-timeline-item-right .light-timeline-item-content,
.light-timeline.light-timeline-label .light-timeline-item-right .light-timeline-item-content {
	width: calc(50% - 12px);
	margin: 0;
	text-align: right;
}

.light-timeline.light-timeline-right .light-timeline-item-right .light-timeline-item-tail,
.light-timeline.light-timeline-right .light-timeline-item-right .light-timeline-item-head,
.light-timeline.light-timeline-right .light-timeline-item-right .light-timeline-item-head-custom {
	left: calc(100% - 4px - 2px);
}

.light-timeline.light-timeline-right .light-timeline-item-right .light-timeline-item-content {
	width: calc(100% - 18px);
}

.light-timeline.light-timeline-pending .light-timeline-item-last .light-timeline-item-tail {
	display: block;
	height: calc(100% - 14px);
	border-left: 2px dotted var(--border-color);
}

.light-timeline.light-timeline-reverse .light-timeline-item-last .light-timeline-item-tail {
	display: none;
}

.light-timeline.light-timeline-reverse .light-timeline-item-pending .light-timeline-item-tail {
	top: 15px;
	display: block;
	height: calc(100% - 15px);
	border-left: 2px dotted var(--border-color);
}

.light-timeline.light-timeline-reverse .light-timeline-item-pending .light-timeline-item-content {
	min-height: 48px;
}

.light-timeline.light-timeline-label .light-timeline-item-label {
	position: absolute;
	top: -7.001px;
	width: calc(50% - 12px);
	text-align: right;
}

.light-timeline.light-timeline-label .light-timeline-item-right .light-timeline-item-label {
	left: calc(50% + 14px);
	width: calc(50% - 14px);
	text-align: left;
}

.light-timeline-rtl {
	direction: rtl;
}

.light-timeline-rtl .light-timeline-item-tail {
	right: 4px;
	left: auto;
	border-right: 2px solid var(--border-color);
	border-left: none;
}

.light-timeline-rtl .light-timeline-item-head-custom {
	right: 5px;
	left: auto;
	transform: translate(50%, -50%);
}

.light-timeline-rtl .light-timeline-item-content {
	margin: 0 18px 0 0;
}

.light-timeline-rtl.light-timeline.light-timeline-alternate .light-timeline-item-tail,
.light-timeline-rtl.light-timeline.light-timeline-right .light-timeline-item-tail,
.light-timeline-rtl.light-timeline.light-timeline-label .light-timeline-item-tail,
.light-timeline-rtl.light-timeline.light-timeline-alternate .light-timeline-item-head,
.light-timeline-rtl.light-timeline.light-timeline-right .light-timeline-item-head,
.light-timeline-rtl.light-timeline.light-timeline-label .light-timeline-item-head,
.light-timeline-rtl.light-timeline.light-timeline-alternate .light-timeline-item-head-custom,
.light-timeline-rtl.light-timeline.light-timeline-right .light-timeline-item-head-custom,
.light-timeline-rtl.light-timeline.light-timeline-label .light-timeline-item-head-custom {
	right: 50%;
	left: auto;
}

.light-timeline-rtl.light-timeline.light-timeline-alternate .light-timeline-item-head,
.light-timeline-rtl.light-timeline.light-timeline-right .light-timeline-item-head,
.light-timeline-rtl.light-timeline.light-timeline-label .light-timeline-item-head {
	margin-right: -4px;
	margin-left: 0;
}

.light-timeline-rtl.light-timeline.light-timeline-alternate .light-timeline-item-head-custom,
.light-timeline-rtl.light-timeline.light-timeline-right .light-timeline-item-head-custom,
.light-timeline-rtl.light-timeline.light-timeline-label .light-timeline-item-head-custom {
	margin-right: 1px;
	margin-left: 0;
}

.light-timeline-rtl.light-timeline.light-timeline-alternate .light-timeline-item-left .light-timeline-item-content,
.light-timeline-rtl.light-timeline.light-timeline-right .light-timeline-item-left .light-timeline-item-content,
.light-timeline-rtl.light-timeline.light-timeline-label .light-timeline-item-left .light-timeline-item-content {
	right: calc(50% - 4px);
	left: auto;
	text-align: right;
}

.light-timeline-rtl.light-timeline.light-timeline-alternate .light-timeline-item-right .light-timeline-item-content,
.light-timeline-rtl.light-timeline.light-timeline-right .light-timeline-item-right .light-timeline-item-content,
.light-timeline-rtl.light-timeline.light-timeline-label .light-timeline-item-right .light-timeline-item-content {
	text-align: left;
}

.light-timeline-rtl.light-timeline.light-timeline-right .light-timeline-item-right .light-timeline-item-tail,
.light-timeline-rtl.light-timeline.light-timeline-right .light-timeline-item-right .light-timeline-item-head,
.light-timeline-rtl.light-timeline.light-timeline-right .light-timeline-item-right .light-timeline-item-head-custom {
	right: 0;
	left: auto;
}

.light-timeline-rtl.light-timeline.light-timeline-right .light-timeline-item-right .light-timeline-item-content {
	width: 100%;
	margin-right: 18px;
	text-align: right;
}

.light-timeline-rtl.light-timeline.light-timeline-pending .light-timeline-item-last .light-timeline-item-tail {
	border-right: 2px dotted var(--border-color);
	border-left: none;
}

.light-timeline-rtl.light-timeline.light-timeline-reverse .light-timeline-item-pending .light-timeline-item-tail {
	border-right: 2px dotted var(--border-color);
	border-left: none;
}

.light-timeline-rtl.light-timeline.light-timeline-label .light-timeline-item-label {
	text-align: left;
}

.light-timeline-rtl.light-timeline.light-timeline-label .light-timeline-item-right .light-timeline-item-label {
	right: calc(50% + 14px);
	text-align: right;
}

.light-tooltip {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: absolute;
	z-index: 1070;
	display: block;
	width: max-content;
	width: intrinsic;
	max-width: 250px;
	visibility: visible;
}

.light-tooltip-content {
	position: relative;
}

.light-tooltip-hidden {
	display: none;
}

.light-tooltip-placement-top,
.light-tooltip-placement-topLeft,
.light-tooltip-placement-topRight {
	padding-bottom: 14.3137085px;
}

.light-tooltip-placement-right,
.light-tooltip-placement-rightTop,
.light-tooltip-placement-rightBottom {
	padding-left: 14.3137085px;
}

.light-tooltip-placement-bottom,
.light-tooltip-placement-bottomLeft,
.light-tooltip-placement-bottomRight {
	padding-top: 14.3137085px;
}

.light-tooltip-placement-left,
.light-tooltip-placement-leftTop,
.light-tooltip-placement-leftBottom {
	padding-right: 14.3137085px;
}

.light-tooltip-inner {
	min-width: 30px;
	min-height: 32px;
	padding: 6px 8px;
	color: #fff;
	text-align: left;
	text-decoration: none;
	word-wrap: break-word;
	background-color: rgba(0, 0, 0, 0.75);
	border-radius: 2px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.light-tooltip-arrow {
	position: absolute;
	z-index: 2;
	display: block;
	width: 22px;
	height: 22px;
	overflow: hidden;
	background: transparent;
	pointer-events: none;
}

.light-tooltip-arrow-content {
	--antd-arrow-background-color: linear-gradient(to right bottom,
			rgba(0, 0, 0, 0.65),
			rgba(0, 0, 0, 0.75));
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	width: 11.3137085px;
	height: 11.3137085px;
	margin: auto;
	content: "";
	pointer-events: auto;
	border-radius: 0 0 2px;
	pointer-events: none;
}

.light-tooltip-arrow-content::before {
	position: absolute;
	top: -11.3137085px;
	left: -11.3137085px;
	width: 33.9411255px;
	height: 33.9411255px;
	background: var(--antd-arrow-background-color);
	background-repeat: no-repeat;
	background-position: -10px -10px;
	content: "";
	clip-path: inset(33% 33%);
	clip-path: path("M 9.849242404917499 24.091883092036785 A 5 5 0 0 1 13.384776310850237 22.627416997969522 L 20.627416997969522 22.627416997969522 A 2 2 0 0 0 22.627416997969522 20.627416997969522 L 22.627416997969522 13.384776310850237 A 5 5 0 0 1 24.091883092036785 9.849242404917499 L 23.091883092036785 9.849242404917499 L 9.849242404917499 23.091883092036785 Z"
		);
}

.light-tooltip-placement-top .light-tooltip-arrow,
.light-tooltip-placement-topLeft .light-tooltip-arrow,
.light-tooltip-placement-topRight .light-tooltip-arrow {
	bottom: 0;
	transform: translateY(100%);
}

.light-tooltip-placement-top .light-tooltip-arrow-content,
.light-tooltip-placement-topLeft .light-tooltip-arrow-content,
.light-tooltip-placement-topRight .light-tooltip-arrow-content {
	box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
	transform: translateY(-11px) rotate(45deg);
}

.light-tooltip-placement-top .light-tooltip-arrow {
	left: 50%;
	transform: translateY(100%) translateX(-50%);
}

.light-tooltip-placement-topLeft .light-tooltip-arrow {
	left: 13px;
}

.light-tooltip-placement-topRight .light-tooltip-arrow {
	right: 13px;
}

.light-tooltip-placement-right .light-tooltip-arrow,
.light-tooltip-placement-rightTop .light-tooltip-arrow,
.light-tooltip-placement-rightBottom .light-tooltip-arrow {
	left: 0;
	transform: translateX(-100%);
}

.light-tooltip-placement-right .light-tooltip-arrow-content,
.light-tooltip-placement-rightTop .light-tooltip-arrow-content,
.light-tooltip-placement-rightBottom .light-tooltip-arrow-content {
	box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
	transform: translateX(11px) rotate(135deg);
}

.light-tooltip-placement-right .light-tooltip-arrow {
	top: 50%;
	transform: translateX(-100%) translateY(-50%);
}

.light-tooltip-placement-rightTop .light-tooltip-arrow {
	top: 5px;
}

.light-tooltip-placement-rightBottom .light-tooltip-arrow {
	bottom: 5px;
}

.light-tooltip-placement-left .light-tooltip-arrow,
.light-tooltip-placement-leftTop .light-tooltip-arrow,
.light-tooltip-placement-leftBottom .light-tooltip-arrow {
	right: 0;
	transform: translateX(100%);
}

.light-tooltip-placement-left .light-tooltip-arrow-content,
.light-tooltip-placement-leftTop .light-tooltip-arrow-content,
.light-tooltip-placement-leftBottom .light-tooltip-arrow-content {
	box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
	transform: translateX(-11px) rotate(315deg);
}

.light-tooltip-placement-left .light-tooltip-arrow {
	top: 50%;
	transform: translateX(100%) translateY(-50%);
}

.light-tooltip-placement-leftTop .light-tooltip-arrow {
	top: 5px;
}

.light-tooltip-placement-leftBottom .light-tooltip-arrow {
	bottom: 5px;
}

.light-tooltip-placement-bottom .light-tooltip-arrow,
.light-tooltip-placement-bottomLeft .light-tooltip-arrow,
.light-tooltip-placement-bottomRight .light-tooltip-arrow {
	top: 0;
	transform: translateY(-100%);
}

.light-tooltip-placement-bottom .light-tooltip-arrow-content,
.light-tooltip-placement-bottomLeft .light-tooltip-arrow-content,
.light-tooltip-placement-bottomRight .light-tooltip-arrow-content {
	box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
	transform: translateY(11px) rotate(225deg);
}

.light-tooltip-placement-bottom .light-tooltip-arrow {
	left: 50%;
	transform: translateY(-100%) translateX(-50%);
}

.light-tooltip-placement-bottomLeft .light-tooltip-arrow {
	left: 13px;
}

.light-tooltip-placement-bottomRight .light-tooltip-arrow {
	right: 13px;
}

.light-tooltip-pink .light-tooltip-inner {
	background-color: #eb2f96;
}

.light-tooltip-pink .light-tooltip-arrow-content::before {
	background: #eb2f96;
}

.light-tooltip-magenta .light-tooltip-inner {
	background-color: #eb2f96;
}

.light-tooltip-magenta .light-tooltip-arrow-content::before {
	background: #eb2f96;
}

.light-tooltip-red .light-tooltip-inner {
	background-color: #f5222d;
}

.light-tooltip-red .light-tooltip-arrow-content::before {
	background: #f5222d;
}

.light-tooltip-volcano .light-tooltip-inner {
	background-color: #fa541c;
}

.light-tooltip-volcano .light-tooltip-arrow-content::before {
	background: #fa541c;
}

.light-tooltip-orange .light-tooltip-inner {
	background-color: #fa8c16;
}

.light-tooltip-orange .light-tooltip-arrow-content::before {
	background: #fa8c16;
}

.light-tooltip-yellow .light-tooltip-inner {
	background-color: #fadb14;
}

.light-tooltip-yellow .light-tooltip-arrow-content::before {
	background: #fadb14;
}

.light-tooltip-gold .light-tooltip-inner {
	background-color: #faad14;
}

.light-tooltip-gold .light-tooltip-arrow-content::before {
	background: #faad14;
}

.light-tooltip-cyan .light-tooltip-inner {
	background-color: #13c2c2;
}

.light-tooltip-cyan .light-tooltip-arrow-content::before {
	background: #13c2c2;
}

.light-tooltip-lime .light-tooltip-inner {
	background-color: #a0d911;
}

.light-tooltip-lime .light-tooltip-arrow-content::before {
	background: #a0d911;
}

.light-tooltip-green .light-tooltip-inner {
	background-color: #52c41a;
}

.light-tooltip-green .light-tooltip-arrow-content::before {
	background: #52c41a;
}

.light-tooltip-blue .light-tooltip-inner {
	background-color: #1890ff;
}

.light-tooltip-blue .light-tooltip-arrow-content::before {
	background: #1890ff;
}

.light-tooltip-geekblue .light-tooltip-inner {
	background-color: #2f54eb;
}

.light-tooltip-geekblue .light-tooltip-arrow-content::before {
	background: #2f54eb;
}

.light-tooltip-purple .light-tooltip-inner {
	background-color: #722ed1;
}

.light-tooltip-purple .light-tooltip-arrow-content::before {
	background: #722ed1;
}

.light-tooltip-rtl {
	direction: rtl;
}

.light-tooltip-rtl .light-tooltip-inner {
	text-align: right;
}

.light-transfer-customize-list .light-transfer-list {
	flex: 1 1 50%;
	width: auto;
	height: auto;
	min-height: 200px;
}

.light-transfer-customize-list .light-table-wrapper .light-table-small {
	border: 0;
	border-radius: 0;
}

.light-transfer-customize-list .light-table-wrapper .light-table-small .light-table-selection-column {
	width: 40px;
	min-width: 40px;
}

.light-transfer-customize-list .light-table-wrapper .light-table-small>.light-table-content>.light-table-body>table>.light-table-thead>tr>th {
	background: #fafafa;
}

.light-transfer-customize-list .light-table-wrapper .light-table-small>.light-table-content .light-table-row:last-child td {
	border-bottom: 1px solid var(--border-color);
}

.light-transfer-customize-list .light-table-wrapper .light-table-small .light-table-body {
	margin: 0;
}

.light-transfer-customize-list .light-table-wrapper .light-table-pagination.light-pagination {
	margin: 16px 0 4px;
}

.light-transfer-customize-list .light-input[disabled] {
	background-color: transparent;
}

.light-transfer-status-error .light-transfer-list {
	border-color: var(--light-error-color);
}

.light-transfer-status-error .light-transfer-list-search:not([disabled]) {
	border-color: #d9d9d9;
}

.light-transfer-status-error .light-transfer-list-search:not([disabled]):hover {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
}

.light-input-rtl .light-transfer-status-error .light-transfer-list-search:not([disabled]):hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-transfer-status-error .light-transfer-list-search:not([disabled]):focus {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-rtl .light-transfer-status-error .light-transfer-list-search:not([disabled]):focus {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-transfer-status-warning .light-transfer-list {
	border-color: var(--light-warning-color);
}

.light-transfer-status-warning .light-transfer-list-search:not([disabled]) {
	border-color: #d9d9d9;
}

.light-transfer-status-warning .light-transfer-list-search:not([disabled]):hover {
	border-color: var(--light-primary-5);
	border-right-width: 1px;
}

.light-input-rtl .light-transfer-status-warning .light-transfer-list-search:not([disabled]):hover {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-transfer-status-warning .light-transfer-list-search:not([disabled]):focus {
	border-color: var(--light-primary-color-hover);
	box-shadow: 0 0 0 2px var(--light-primary-color-outline);
	border-right-width: 1px;
	outline: 0;
}

.light-input-rtl .light-transfer-status-warning .light-transfer-list-search:not([disabled]):focus {
	border-right-width: 0;
	border-left-width: 1px !important;
}

.light-transfer {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	display: flex;
	align-items: stretch;
}

.light-transfer-disabled .light-transfer-list {
	background: #f5f5f5;
}

.light-transfer-list {
	display: flex;
	flex-direction: column;
	width: 180px;
	height: 200px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
}

.light-transfer-list-with-pagination {
	width: 250px;
	height: auto;
}

.light-transfer-list-search .anticon-search {
	color: rgba(0, 0, 0, 0.25);
}

.light-transfer-list-header {
	display: flex;
	flex: none;
	align-items: center;
	height: 40px;
	padding: 8px 12px 9px;
	color: rgba(0, 0, 0, 0.85);
	background: #fff;
	border-bottom: 1px solid var(--border-color);
	border-radius: 2px 2px 0 0;
}

.light-transfer-list-header>*:not(:last-child) {
	margin-right: 4px;
}

.light-transfer-list-header>* {
	flex: none;
}

.light-transfer-list-header-title {
	flex: auto;
	overflow: hidden;
	white-space: nowrap;
	text-align: right;
	text-overflow: ellipsis;
}

.light-transfer-list-header-dropdown {
	font-size: 10px;
	transform: translateY(10%);
	cursor: pointer;
}

.light-transfer-list-header-dropdown[disabled] {
	cursor: not-allowed;
}

.light-transfer-list-body {
	display: flex;
	flex: auto;
	flex-direction: column;
	overflow: hidden;
	font-size: 14px;
}

.light-transfer-list-body-search-wrapper {
	position: relative;
	flex: none;
	padding: 12px;
}

.light-transfer-list-content {
	flex: auto;
	margin: 0;
	padding: 0;
	overflow: auto;
	list-style: none;
}

.light-transfer-list-content-item {
	display: flex;
	align-items: center;
	min-height: 32px;
	padding: 6px 12px;
	line-height: 20px;
	transition: all 0.3s;
}

.light-transfer-list-content-item>*:not(:last-child) {
	margin-right: 8px;
}

.light-transfer-list-content-item>* {
	flex: none;
}

.light-transfer-list-content-item-text {
	flex: auto;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.light-transfer-list-content-item-remove {
	position: relative;
	color: #d9d9d9;
	cursor: pointer;
	transition: all 0.3s;
}

.light-transfer-list-content-item-remove:hover {
	color: var(--light-primary-color-hover);
}

.light-transfer-list-content-item-remove::after {
	position: absolute;
	top: -6px;
	right: -50%;
	bottom: -6px;
	left: -50%;
	content: "";
}

.light-transfer-list-content-item:not(.light-transfer-list-content-item-disabled):hover {
	background-color: #f5f5f5;
	cursor: pointer;
}

.light-transfer-list-content-item:not(.light-transfer-list-content-item-disabled).light-transfer-list-content-item-checked:hover {
	background-color: var(--light-primary-color-active-deprecated-d-02);
}

.light-transfer-list-content-show-remove .light-transfer-list-content-item:not(.light-transfer-list-content-item-disabled):hover {
	background: transparent;
	cursor: default;
}

.light-transfer-list-content-item-checked {
	background-color: var(--light-primary-1);
}

.light-transfer-list-content-item-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-transfer-list-pagination {
	padding: 8px 0;
	text-align: right;
	border-top: 1px solid var(--border-color);
}

.light-transfer-list-body-not-found {
	flex: none;
	width: 100%;
	margin: auto 0;
	color: rgba(0, 0, 0, 0.25);
	text-align: center;
}

.light-transfer-list-footer {
	border-top: 1px solid var(--border-color);
}

.light-transfer-operation {
	display: flex;
	flex: none;
	flex-direction: column;
	align-self: center;
	margin: 0 8px;
	vertical-align: middle;
}

.light-transfer-operation .light-btn {
	display: block;
}

.light-transfer-operation .light-btn:first-child {
	margin-bottom: 4px;
}

.light-transfer-operation .light-btn .anticon {
	font-size: 12px;
}

.light-transfer .light-empty-image {
	max-height: -2px;
}

.light-transfer-rtl {
	direction: rtl;
}

.light-transfer-rtl .light-transfer-list-search {
	padding-right: 8px;
	padding-left: 24px;
}

.light-transfer-rtl .light-transfer-list-search-action {
	right: auto;
	left: 12px;
}

.light-transfer-rtl .light-transfer-list-header>*:not(:last-child) {
	margin-right: 0;
	margin-left: 4px;
}

.light-transfer-rtl .light-transfer-list-header {
	right: 0;
	left: auto;
}

.light-transfer-rtl .light-transfer-list-header-title {
	text-align: left;
}

.light-transfer-rtl .light-transfer-list-content-item>*:not(:last-child) {
	margin-right: 0;
	margin-left: 8px;
}

.light-transfer-rtl .light-transfer-list-pagination {
	text-align: left;
}

.light-transfer-rtl .light-transfer-list-footer {
	right: 0;
	left: auto;
}

@keyframes ant-tree-node-fx-do-not-use {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.light-tree.light-tree-directory .light-tree-treenode {
	position: relative;
}

.light-tree.light-tree-directory .light-tree-treenode::before {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 4px;
	left: 0;
	transition: background-color 0.3s;
	content: "";
	pointer-events: none;
}

.light-tree.light-tree-directory .light-tree-treenode:hover::before {
	background: #f5f5f5;
}

.light-tree.light-tree-directory .light-tree-treenode>* {
	z-index: 1;
}

.light-tree.light-tree-directory .light-tree-treenode .light-tree-switcher {
	transition: color 0.3s;
}

.light-tree.light-tree-directory .light-tree-treenode .light-tree-node-content-wrapper {
	border-radius: 0;
	user-select: none;
}

.light-tree.light-tree-directory .light-tree-treenode .light-tree-node-content-wrapper:hover {
	background: transparent;
}

.light-tree.light-tree-directory .light-tree-treenode .light-tree-node-content-wrapper.light-tree-node-selected {
	color: #fff;
	background: transparent;
}

.light-tree.light-tree-directory .light-tree-treenode-selected:hover::before,
.light-tree.light-tree-directory .light-tree-treenode-selected::before {
	background: var(--light-primary-color);
}

.light-tree.light-tree-directory .light-tree-treenode-selected .light-tree-switcher {
	color: #fff;
}

.light-tree.light-tree-directory .light-tree-treenode-selected .light-tree-node-content-wrapper {
	color: #fff;
	background: transparent;
}

.light-tree-checkbox {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	top: 0.2em;
	line-height: 1;
	white-space: nowrap;
	outline: none;
	cursor: pointer;
}

.light-tree-checkbox-wrapper:hover .light-tree-checkbox-inner,
.light-tree-checkbox:hover .light-tree-checkbox-inner,
.light-tree-checkbox-input:focus+.light-tree-checkbox-inner {
	border-color: var(--light-primary-color);
}

.light-tree-checkbox-checked::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid var(--light-primary-color);
	border-radius: 2px;
	visibility: hidden;
	animation: antCheckboxEffect 0.36s ease-in-out;
	animation-fill-mode: backwards;
	content: "";
}

.light-tree-checkbox:hover::after,
.light-tree-checkbox-wrapper:hover .light-tree-checkbox::after {
	visibility: visible;
}

.light-tree-checkbox-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 16px;
	height: 16px;
	direction: ltr;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	border-collapse: separate;
	transition: all 0.3s;
}

.light-tree-checkbox-inner::after {
	position: absolute;
	top: 50%;
	left: 21.5%;
	display: table;
	width: 5.71428571px;
	height: 9.14285714px;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(0) translate(-50%, -50%);
	opacity: 0;
	transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
	content: " ";
}

.light-tree-checkbox-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
}

.light-tree-checkbox-checked .light-tree-checkbox-inner::after {
	position: absolute;
	display: table;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(1) translate(-50%, -50%);
	opacity: 1;
	transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
	content: " ";
}

.light-tree-checkbox-checked .light-tree-checkbox-inner {
	background-color: var(--light-primary-color);
	border-color: var(--light-primary-color);
}

.light-tree-checkbox-disabled {
	cursor: not-allowed;
}

.light-tree-checkbox-disabled.light-tree-checkbox-checked .light-tree-checkbox-inner::after {
	border-color: rgba(0, 0, 0, 0.25);
	animation-name: none;
}

.light-tree-checkbox-disabled .light-tree-checkbox-input {
	cursor: not-allowed;
	pointer-events: none;
}

.light-tree-checkbox-disabled .light-tree-checkbox-inner {
	background-color: #f5f5f5;
	border-color: #d9d9d9 !important;
}

.light-tree-checkbox-disabled .light-tree-checkbox-inner::after {
	border-color: #f5f5f5;
	border-collapse: separate;
	animation-name: none;
}

.light-tree-checkbox-disabled+span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-tree-checkbox-disabled:hover::after,
.light-tree-checkbox-wrapper:hover .light-tree-checkbox-disabled::after {
	visibility: hidden;
}

.light-tree-checkbox-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: inline-flex;
	align-items: baseline;
	line-height: unset;
	cursor: pointer;
}

.light-tree-checkbox-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: "\a0";
}

.light-tree-checkbox-wrapper.light-tree-checkbox-wrapper-disabled {
	cursor: not-allowed;
}

.light-tree-checkbox-wrapper+.light-tree-checkbox-wrapper {
	margin-left: 8px;
}

.light-tree-checkbox-wrapper.light-tree-checkbox-wrapper-in-form-item input[type="checkbox"] {
	width: 14px;
	height: 14px;
}

.light-tree-checkbox+span {
	padding-right: 8px;
	padding-left: 8px;
}

.light-tree-checkbox-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: inline-block;
}

.light-tree-checkbox-group-item {
	margin-right: 8px;
}

.light-tree-checkbox-group-item:last-child {
	margin-right: 0;
}

.light-tree-checkbox-group-item+.light-tree-checkbox-group-item {
	margin-left: 0;
}

.light-tree-checkbox-indeterminate .light-tree-checkbox-inner {
	background-color: #fff;
	border-color: #d9d9d9;
}

.light-tree-checkbox-indeterminate .light-tree-checkbox-inner::after {
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	background-color: var(--light-primary-color);
	border: 0;
	transform: translate(-50%, -50%) scale(1);
	opacity: 1;
	content: " ";
}

.light-tree-checkbox-indeterminate.light-tree-checkbox-disabled .light-tree-checkbox-inner::after {
	background-color: rgba(0, 0, 0, 0.25);
	border-color: rgba(0, 0, 0, 0.25);
}

.light-tree-checkbox-rtl {
	direction: rtl;
}

.light-tree-checkbox-group-rtl .light-tree-checkbox-group-item {
	margin-right: 0;
	margin-left: 8px;
}

.light-tree-checkbox-group-rtl .light-tree-checkbox-group-item:last-child {
	margin-left: 0 !important;
}

.light-tree-checkbox-group-rtl .light-tree-checkbox-group-item+.light-tree-checkbox-group-item {
	margin-left: 8px;
}

.light-tree {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	background: #fff;
	border-radius: 2px;
	transition: background-color 0.3s;
}

.light-tree-focused:not(:hover):not(.light-tree-active-focused) {
	background: var(--light-primary-1);
}

.light-tree-list-holder-inner {
	align-items: flex-start;
}

.light-tree.light-tree-block-node .light-tree-list-holder-inner {
	align-items: stretch;
}

.light-tree.light-tree-block-node .light-tree-list-holder-inner .light-tree-node-content-wrapper {
	flex: auto;
}

.light-tree.light-tree-block-node .light-tree-list-holder-inner .light-tree-treenode.dragging {
	position: relative;
}

.light-tree.light-tree-block-node .light-tree-list-holder-inner .light-tree-treenode.dragging::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 4px;
	left: 0;
	border: 1px solid var(--light-primary-color);
	opacity: 0;
	animation: ant-tree-node-fx-do-not-use 0.3s;
	animation-play-state: running;
	animation-fill-mode: forwards;
	content: "";
	pointer-events: none;
}

.light-tree .light-tree-treenode {
	display: flex;
	align-items: flex-start;
	padding: 0 0 4px 0;
	outline: none;
}

.light-tree .light-tree-treenode-disabled .light-tree-node-content-wrapper {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-tree .light-tree-treenode-disabled .light-tree-node-content-wrapper:hover {
	background: transparent;
}

.light-tree .light-tree-treenode-active .light-tree-node-content-wrapper {
	background: #f5f5f5;
}

.light-tree .light-tree-treenode:not(.light-tree .light-tree-treenode-disabled).filter-node .light-tree-title {
	color: inherit;
	font-weight: 500;
}

.light-tree .light-tree-treenode-draggable .light-tree-draggable-icon {
	width: 24px;
	line-height: 24px;
	text-align: center;
	visibility: visible;
	opacity: 0.2;
	transition: opacity 0.3s;
}

.light-tree-treenode:hover .light-tree .light-tree-treenode-draggable .light-tree-draggable-icon {
	opacity: 0.45;
}

.light-tree .light-tree-treenode-draggable.light-tree-treenode-disabled .light-tree-draggable-icon {
	visibility: hidden;
}

.light-tree-indent {
	align-self: stretch;
	white-space: nowrap;
	user-select: none;
}

.light-tree-indent-unit {
	display: inline-block;
	width: 24px;
}

.light-tree-draggable-icon {
	visibility: hidden;
}

.light-tree-switcher {
	position: relative;
	flex: none;
	align-self: stretch;
	width: 24px;
	margin: 0;
	line-height: 24px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.light-tree-switcher .light-tree-switcher-icon,
.light-tree-switcher .light-select-tree-switcher-icon {
	display: inline-block;
	font-size: 10px;
	vertical-align: baseline;
}

.light-tree-switcher .light-tree-switcher-icon svg,
.light-tree-switcher .light-select-tree-switcher-icon svg {
	transition: transform 0.3s;
}

.light-tree-switcher-noop {
	cursor: default;
}

.light-tree-switcher_close .light-tree-switcher-icon svg {
	transform: rotate(-90deg);
}

.light-tree-switcher-loading-icon {
	color: var(--light-primary-color);
}

.light-tree-switcher-leaf-line {
	position: relative;
	z-index: 1;
	display: inline-block;
	width: 100%;
	height: 100%;
}

.light-tree-switcher-leaf-line::before {
	position: absolute;
	top: 0;
	right: 12px;
	bottom: -4px;
	margin-left: -1px;
	border-right: 1px solid #d9d9d9;
	content: " ";
}

.light-tree-switcher-leaf-line::after {
	position: absolute;
	width: 10px;
	height: 14px;
	border-bottom: 1px solid #d9d9d9;
	content: " ";
}

.light-tree-checkbox {
	top: initial;
	margin: 4px 8px 0 0;
}

.light-tree .light-tree-node-content-wrapper {
	position: relative;
	z-index: auto;
	min-height: 24px;
	margin: 0;
	padding: 0 4px;
	color: inherit;
	line-height: 24px;
	background: transparent;
	border-radius: 2px;
	cursor: pointer;
	transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}

.light-tree .light-tree-node-content-wrapper:hover {
	background-color: #f5f5f5;
}

.light-tree .light-tree-node-content-wrapper.light-tree-node-selected {
	background-color: var(--light-primary-2);
}

.light-tree .light-tree-node-content-wrapper .light-tree-iconEle {
	display: inline-block;
	width: 24px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	vertical-align: top;
}

.light-tree .light-tree-node-content-wrapper .light-tree-iconEle:empty {
	display: none;
}

.light-tree-unselectable .light-tree-node-content-wrapper:hover {
	background-color: transparent;
}

.light-tree-node-content-wrapper {
	line-height: 24px;
	user-select: none;
}

.light-tree-node-content-wrapper .light-tree-drop-indicator {
	position: absolute;
	z-index: 1;
	height: 2px;
	background-color: var(--light-primary-color);
	border-radius: 1px;
	pointer-events: none;
}

.light-tree-node-content-wrapper .light-tree-drop-indicator::after {
	position: absolute;
	top: -3px;
	left: -6px;
	width: 8px;
	height: 8px;
	background-color: transparent;
	border: 2px solid var(--light-primary-color);
	border-radius: 50%;
	content: "";
}

.light-tree .light-tree-treenode.drop-container>[draggable] {
	box-shadow: 0 0 0 2px var(--light-primary-color);
}

.light-tree-show-line .light-tree-indent-unit {
	position: relative;
	height: 100%;
}

.light-tree-show-line .light-tree-indent-unit::before {
	position: absolute;
	top: 0;
	right: 12px;
	bottom: -4px;
	border-right: 1px solid #d9d9d9;
	content: "";
}

.light-tree-show-line .light-tree-indent-unit-end::before {
	display: none;
}

.light-tree-show-line .light-tree-switcher {
	background: #fff;
}

.light-tree-show-line .light-tree-switcher-line-icon {
	vertical-align: -0.15em;
}

.light-tree .light-tree-treenode-leaf-last .light-tree-switcher-leaf-line::before {
	top: auto !important;
	bottom: auto !important;
	height: 14px !important;
}

.light-tree-rtl {
	direction: rtl;
}

.light-tree-rtl .light-tree-node-content-wrapper[draggable="true"] .light-tree-drop-indicator::after {
	right: -6px;
	left: unset;
}

.light-tree .light-tree-treenode-rtl {
	direction: rtl;
}

.light-tree-rtl .light-tree-switcher_close .light-tree-switcher-icon svg {
	transform: rotate(90deg);
}

.light-tree-rtl.light-tree-show-line .light-tree-indent-unit::before {
	right: auto;
	left: -13px;
	border-right: none;
	border-left: 1px solid #d9d9d9;
}

.light-tree-rtl .light-tree-checkbox {
	margin: 4px 0 0 8px;
}

.light-tree-select-dropdown-rtl .light-select-tree-checkbox {
	margin: 4px 0 0 8px;
}

.light-select-tree-checkbox {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	position: relative;
	top: 0.2em;
	line-height: 1;
	white-space: nowrap;
	outline: none;
	cursor: pointer;
}

.light-select-tree-checkbox-wrapper:hover .light-select-tree-checkbox-inner,
.light-select-tree-checkbox:hover .light-select-tree-checkbox-inner,
.light-select-tree-checkbox-input:focus+.light-select-tree-checkbox-inner {
	border-color: var(--light-primary-color);
}

.light-select-tree-checkbox-checked::after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 1px solid var(--light-primary-color);
	border-radius: 2px;
	visibility: hidden;
	animation: antCheckboxEffect 0.36s ease-in-out;
	animation-fill-mode: backwards;
	content: "";
}

.light-select-tree-checkbox:hover::after,
.light-select-tree-checkbox-wrapper:hover .light-select-tree-checkbox::after {
	visibility: visible;
}

.light-select-tree-checkbox-inner {
	position: relative;
	top: 0;
	left: 0;
	display: block;
	width: 16px;
	height: 16px;
	direction: ltr;
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	border-collapse: separate;
	transition: all 0.3s;
}

.light-select-tree-checkbox-inner::after {
	position: absolute;
	top: 50%;
	left: 21.5%;
	display: table;
	width: 5.71428571px;
	height: 9.14285714px;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(0) translate(-50%, -50%);
	opacity: 0;
	transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
	content: " ";
}

.light-select-tree-checkbox-input {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	cursor: pointer;
	opacity: 0;
}

.light-select-tree-checkbox-checked .light-select-tree-checkbox-inner::after {
	position: absolute;
	display: table;
	border: 2px solid #fff;
	border-top: 0;
	border-left: 0;
	transform: rotate(45deg) scale(1) translate(-50%, -50%);
	opacity: 1;
	transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
	content: " ";
}

.light-select-tree-checkbox-checked .light-select-tree-checkbox-inner {
	background-color: var(--light-primary-color);
	border-color: var(--light-primary-color);
}

.light-select-tree-checkbox-disabled {
	cursor: not-allowed;
}

.light-select-tree-checkbox-disabled.light-select-tree-checkbox-checked .light-select-tree-checkbox-inner::after {
	border-color: rgba(0, 0, 0, 0.25);
	animation-name: none;
}

.light-select-tree-checkbox-disabled .light-select-tree-checkbox-input {
	cursor: not-allowed;
	pointer-events: none;
}

.light-select-tree-checkbox-disabled .light-select-tree-checkbox-inner {
	background-color: #f5f5f5;
	border-color: #d9d9d9 !important;
}

.light-select-tree-checkbox-disabled .light-select-tree-checkbox-inner::after {
	border-color: #f5f5f5;
	border-collapse: separate;
	animation-name: none;
}

.light-select-tree-checkbox-disabled+span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-select-tree-checkbox-disabled:hover::after,
.light-select-tree-checkbox-wrapper:hover .light-select-tree-checkbox-disabled::after {
	visibility: hidden;
}

.light-select-tree-checkbox-wrapper {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: inline-flex;
	align-items: baseline;
	line-height: unset;
	cursor: pointer;
}

.light-select-tree-checkbox-wrapper::after {
	display: inline-block;
	width: 0;
	overflow: hidden;
	content: "\a0";
}

.light-select-tree-checkbox-wrapper.light-select-tree-checkbox-wrapper-disabled {
	cursor: not-allowed;
}

.light-select-tree-checkbox-wrapper+.light-select-tree-checkbox-wrapper {
	margin-left: 8px;
}

.light-select-tree-checkbox-wrapper.light-select-tree-checkbox-wrapper-in-form-item input[type="checkbox"] {
	width: 14px;
	height: 14px;
}

.light-select-tree-checkbox+span {
	padding-right: 8px;
	padding-left: 8px;
}

.light-select-tree-checkbox-group {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	display: inline-block;
}

.light-select-tree-checkbox-group-item {
	margin-right: 8px;
}

.light-select-tree-checkbox-group-item:last-child {
	margin-right: 0;
}

.light-select-tree-checkbox-group-item+.light-select-tree-checkbox-group-item {
	margin-left: 0;
}

.light-select-tree-checkbox-indeterminate .light-select-tree-checkbox-inner {
	background-color: #fff;
	border-color: #d9d9d9;
}

.light-select-tree-checkbox-indeterminate .light-select-tree-checkbox-inner::after {
	top: 50%;
	left: 50%;
	width: 8px;
	height: 8px;
	background-color: var(--light-primary-color);
	border: 0;
	transform: translate(-50%, -50%) scale(1);
	opacity: 1;
	content: " ";
}

.light-select-tree-checkbox-indeterminate.light-select-tree-checkbox-disabled .light-select-tree-checkbox-inner::after {
	background-color: rgba(0, 0, 0, 0.25);
	border-color: rgba(0, 0, 0, 0.25);
}

.light-select-tree-checkbox-rtl {
	direction: rtl;
}

.light-select-tree-checkbox-group-rtl .light-select-tree-checkbox-group-item {
	margin-right: 0;
	margin-left: 8px;
}

.light-select-tree-checkbox-group-rtl .light-select-tree-checkbox-group-item:last-child {
	margin-left: 0 !important;
}

.light-select-tree-checkbox-group-rtl .light-select-tree-checkbox-group-item+.light-select-tree-checkbox-group-item {
	margin-left: 8px;
}

.light-tree-select-dropdown {
	padding: 8px 4px;
}

.light-tree-select-dropdown-rtl {
	direction: rtl;
}

.light-tree-select-dropdown .light-select-tree {
	border-radius: 0;
}

.light-tree-select-dropdown .light-select-tree-list-holder-inner {
	align-items: stretch;
}

.light-tree-select-dropdown .light-select-tree-list-holder-inner .light-select-tree-treenode .light-select-tree-node-content-wrapper {
	flex: auto;
}

.light-select-tree {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	background: #fff;
	border-radius: 2px;
	transition: background-color 0.3s;
}

.light-select-tree-focused:not(:hover):not(.light-select-tree-active-focused) {
	background: var(--light-primary-1);
}

.light-select-tree-list-holder-inner {
	align-items: flex-start;
}

.light-select-tree.light-select-tree-block-node .light-select-tree-list-holder-inner {
	align-items: stretch;
}

.light-select-tree.light-select-tree-block-node .light-select-tree-list-holder-inner .light-select-tree-node-content-wrapper {
	flex: auto;
}

.light-select-tree.light-select-tree-block-node .light-select-tree-list-holder-inner .light-select-tree-treenode.dragging {
	position: relative;
}

.light-select-tree.light-select-tree-block-node .light-select-tree-list-holder-inner .light-select-tree-treenode.dragging::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 4px;
	left: 0;
	border: 1px solid var(--light-primary-color);
	opacity: 0;
	animation: ant-tree-node-fx-do-not-use 0.3s;
	animation-play-state: running;
	animation-fill-mode: forwards;
	content: "";
	pointer-events: none;
}

.light-select-tree .light-select-tree-treenode {
	display: flex;
	align-items: flex-start;
	padding: 0 0 4px 0;
	outline: none;
}

.light-select-tree .light-select-tree-treenode-disabled .light-select-tree-node-content-wrapper {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-select-tree .light-select-tree-treenode-disabled .light-select-tree-node-content-wrapper:hover {
	background: transparent;
}

.light-select-tree .light-select-tree-treenode-active .light-select-tree-node-content-wrapper {
	background: #f5f5f5;
}

.light-select-tree .light-select-tree-treenode:not(.light-select-tree .light-select-tree-treenode-disabled).filter-node .light-select-tree-title {
	color: inherit;
	font-weight: 500;
}

.light-select-tree .light-select-tree-treenode-draggable .light-select-tree-draggable-icon {
	width: 24px;
	line-height: 24px;
	text-align: center;
	visibility: visible;
	opacity: 0.2;
	transition: opacity 0.3s;
}

.light-select-tree-treenode:hover .light-select-tree .light-select-tree-treenode-draggable .light-select-tree-draggable-icon {
	opacity: 0.45;
}

.light-select-tree .light-select-tree-treenode-draggable.light-select-tree-treenode-disabled .light-select-tree-draggable-icon {
	visibility: hidden;
}

.light-select-tree-indent {
	align-self: stretch;
	white-space: nowrap;
	user-select: none;
}

.light-select-tree-indent-unit {
	display: inline-block;
	width: 24px;
}

.light-select-tree-draggable-icon {
	visibility: hidden;
}

.light-select-tree-switcher {
	position: relative;
	flex: none;
	align-self: stretch;
	width: 24px;
	margin: 0;
	line-height: 24px;
	text-align: center;
	cursor: pointer;
	user-select: none;
}

.light-select-tree-switcher .light-tree-switcher-icon,
.light-select-tree-switcher .light-select-tree-switcher-icon {
	display: inline-block;
	font-size: 10px;
	vertical-align: baseline;
}

.light-select-tree-switcher .light-tree-switcher-icon svg,
.light-select-tree-switcher .light-select-tree-switcher-icon svg {
	transition: transform 0.3s;
}

.light-select-tree-switcher-noop {
	cursor: default;
}

.light-select-tree-switcher_close .light-select-tree-switcher-icon svg {
	transform: rotate(-90deg);
}

.light-select-tree-switcher-loading-icon {
	color: var(--light-primary-color);
}

.light-select-tree-switcher-leaf-line {
	position: relative;
	z-index: 1;
	display: inline-block;
	width: 100%;
	height: 100%;
}

.light-select-tree-switcher-leaf-line::before {
	position: absolute;
	top: 0;
	right: 12px;
	bottom: -4px;
	margin-left: -1px;
	border-right: 1px solid #d9d9d9;
	content: " ";
}

.light-select-tree-switcher-leaf-line::after {
	position: absolute;
	width: 10px;
	height: 14px;
	border-bottom: 1px solid #d9d9d9;
	content: " ";
}

.light-select-tree-checkbox {
	top: initial;
	margin: 4px 8px 0 0;
}

.light-select-tree .light-select-tree-node-content-wrapper {
	position: relative;
	z-index: auto;
	min-height: 24px;
	margin: 0;
	padding: 0 4px;
	color: inherit;
	line-height: 24px;
	background: transparent;
	border-radius: 2px;
	cursor: pointer;
	transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}

.light-select-tree .light-select-tree-node-content-wrapper:hover {
	background-color: #f5f5f5;
}

.light-select-tree .light-select-tree-node-content-wrapper.light-select-tree-node-selected {
	background-color: var(--light-primary-2);
}

.light-select-tree .light-select-tree-node-content-wrapper .light-select-tree-iconEle {
	display: inline-block;
	width: 24px;
	height: 24px;
	line-height: 24px;
	text-align: center;
	vertical-align: top;
}

.light-select-tree .light-select-tree-node-content-wrapper .light-select-tree-iconEle:empty {
	display: none;
}

.light-select-tree-unselectable .light-select-tree-node-content-wrapper:hover {
	background-color: transparent;
}

.light-select-tree-node-content-wrapper {
	line-height: 24px;
	user-select: none;
}

.light-select-tree-node-content-wrapper .light-tree-drop-indicator {
	position: absolute;
	z-index: 1;
	height: 2px;
	background-color: var(--light-primary-color);
	border-radius: 1px;
	pointer-events: none;
}

.light-select-tree-node-content-wrapper .light-tree-drop-indicator::after {
	position: absolute;
	top: -3px;
	left: -6px;
	width: 8px;
	height: 8px;
	background-color: transparent;
	border: 2px solid var(--light-primary-color);
	border-radius: 50%;
	content: "";
}

.light-select-tree .light-select-tree-treenode.drop-container>[draggable] {
	box-shadow: 0 0 0 2px var(--light-primary-color);
}

.light-select-tree-show-line .light-select-tree-indent-unit {
	position: relative;
	height: 100%;
}

.light-select-tree-show-line .light-select-tree-indent-unit::before {
	position: absolute;
	top: 0;
	right: 12px;
	bottom: -4px;
	border-right: 1px solid #d9d9d9;
	content: "";
}

.light-select-tree-show-line .light-select-tree-indent-unit-end::before {
	display: none;
}

.light-select-tree-show-line .light-select-tree-switcher {
	background: #fff;
}

.light-select-tree-show-line .light-select-tree-switcher-line-icon {
	vertical-align: -0.15em;
}

.light-select-tree .light-select-tree-treenode-leaf-last .light-select-tree-switcher-leaf-line::before {
	top: auto !important;
	bottom: auto !important;
	height: 14px !important;
}

.light-tree-select-dropdown-rtl .light-select-tree .light-select-tree-switcher_close .light-select-tree-switcher-icon svg {
	transform: rotate(90deg);
}

.light-tree-select-dropdown-rtl .light-select-tree .light-select-tree-switcher-loading-icon {
	transform: scaleY(-1);
}

.light-typography {
	color: rgba(0, 0, 0, 0.85);
	overflow-wrap: break-word;
}

.light-typography.light-typography-secondary {
	color: rgba(0, 0, 0, 0.45);
}

.light-typography.light-typography-success {
	color: var(--light-success-color);
}

.light-typography.light-typography-warning {
	color: var(--light-warning-color);
}

.light-typography.light-typography-danger {
	color: var(--light-error-color);
}

a.light-typography.light-typography-danger:active,
a.light-typography.light-typography-danger:focus {
	color: var(--light-error-color-active);
}

a.light-typography.light-typography-danger:hover {
	color: var(--light-error-color-hover);
}

.light-typography.light-typography-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	user-select: none;
}

div.light-typography,
.light-typography p {
	margin-bottom: 1em;
}

h1.light-typography,
div.light-typography-h1,
div.light-typography-h1>textarea,
.light-typography h1 {
	margin-bottom: 0.5em;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	font-size: 38px;
	line-height: 1.23;
}

h2.light-typography,
div.light-typography-h2,
div.light-typography-h2>textarea,
.light-typography h2 {
	margin-bottom: 0.5em;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	font-size: 30px;
	line-height: 1.35;
}

h3.light-typography,
div.light-typography-h3,
div.light-typography-h3>textarea,
.light-typography h3 {
	margin-bottom: 0.5em;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	font-size: 24px;
	line-height: 1.35;
}

h4.light-typography,
div.light-typography-h4,
div.light-typography-h4>textarea,
.light-typography h4 {
	margin-bottom: 0.5em;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	font-size: 20px;
	line-height: 1.4;
}

h5.light-typography,
div.light-typography-h5,
div.light-typography-h5>textarea,
.light-typography h5 {
	margin-bottom: 0.5em;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 600;
	font-size: 16px;
	line-height: 1.5;
}

.light-typography+h1.light-typography,
.light-typography+h2.light-typography,
.light-typography+h3.light-typography,
.light-typography+h4.light-typography,
.light-typography+h5.light-typography {
	margin-top: 1.2em;
}

.light-typography div+h1,
.light-typography ul+h1,
.light-typography li+h1,
.light-typography p+h1,
.light-typography h1+h1,
.light-typography h2+h1,
.light-typography h3+h1,
.light-typography h4+h1,
.light-typography h5+h1,
.light-typography div+h2,
.light-typography ul+h2,
.light-typography li+h2,
.light-typography p+h2,
.light-typography h1+h2,
.light-typography h2+h2,
.light-typography h3+h2,
.light-typography h4+h2,
.light-typography h5+h2,
.light-typography div+h3,
.light-typography ul+h3,
.light-typography li+h3,
.light-typography p+h3,
.light-typography h1+h3,
.light-typography h2+h3,
.light-typography h3+h3,
.light-typography h4+h3,
.light-typography h5+h3,
.light-typography div+h4,
.light-typography ul+h4,
.light-typography li+h4,
.light-typography p+h4,
.light-typography h1+h4,
.light-typography h2+h4,
.light-typography h3+h4,
.light-typography h4+h4,
.light-typography h5+h4,
.light-typography div+h5,
.light-typography ul+h5,
.light-typography li+h5,
.light-typography p+h5,
.light-typography h1+h5,
.light-typography h2+h5,
.light-typography h3+h5,
.light-typography h4+h5,
.light-typography h5+h5 {
	margin-top: 1.2em;
}

a.light-typography-ellipsis,
span.light-typography-ellipsis {
	display: inline-block;
	max-width: 100%;
}

a.light-typography,
.light-typography a {
	color: var(--light-primary-color);
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
	text-decoration: none;
}

a.light-typography:focus-visible,
.light-typography a:focus-visible,
a.light-typography:hover,
.light-typography a:hover {
	color: var(--light-primary-color-hover);
}

a.light-typography:active,
.light-typography a:active {
	color: var(--light-primary-color-active);
}

a.light-typography:active,
.light-typography a:active,
a.light-typography:hover,
.light-typography a:hover {
	text-decoration: none;
}

a.light-typography[disabled],
.light-typography a[disabled],
a.light-typography.light-typography-disabled,
.light-typography a.light-typography-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

a.light-typography[disabled]:active,
.light-typography a[disabled]:active,
a.light-typography.light-typography-disabled:active,
.light-typography a.light-typography-disabled:active,
a.light-typography[disabled]:hover,
.light-typography a[disabled]:hover,
a.light-typography.light-typography-disabled:hover,
.light-typography a.light-typography-disabled:hover {
	color: rgba(0, 0, 0, 0.25);
}

a.light-typography[disabled]:active,
.light-typography a[disabled]:active,
a.light-typography.light-typography-disabled:active,
.light-typography a.light-typography-disabled:active {
	pointer-events: none;
}

.light-typography code {
	margin: 0 0.2em;
	padding: 0.2em 0.4em 0.1em;
	font-size: 85%;
	background: rgba(150, 150, 150, 0.1);
	border: 1px solid rgba(100, 100, 100, 0.2);
	border-radius: 3px;
}

.light-typography kbd {
	margin: 0 0.2em;
	padding: 0.15em 0.4em 0.1em;
	font-size: 90%;
	background: rgba(150, 150, 150, 0.06);
	border: 1px solid rgba(100, 100, 100, 0.2);
	border-bottom-width: 2px;
	border-radius: 3px;
}

.light-typography mark {
	padding: 0;
	background-color: #ffe58f;
}

.light-typography u,
.light-typography ins {
	text-decoration: underline;
	text-decoration-skip-ink: auto;
}

.light-typography s,
.light-typography del {
	text-decoration: line-through;
}

.light-typography strong {
	font-weight: 600;
}

.light-typography-expand,
.light-typography-edit,
.light-typography-copy {
	color: var(--light-primary-color);
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
	margin-left: 4px;
}

.light-typography-expand:focus-visible,
.light-typography-edit:focus-visible,
.light-typography-copy:focus-visible,
.light-typography-expand:hover,
.light-typography-edit:hover,
.light-typography-copy:hover {
	color: var(--light-primary-color-hover);
}

.light-typography-expand:active,
.light-typography-edit:active,
.light-typography-copy:active {
	color: var(--light-primary-color-active);
}

.light-typography-copy-success,
.light-typography-copy-success:hover,
.light-typography-copy-success:focus {
	color: var(--light-success-color);
}

.light-typography-edit-content {
	position: relative;
}

div.light-typography-edit-content {
	left: -12px;
	margin-top: -5px;
	margin-bottom: calc(1em - 4px - 1px);
}

.light-typography-edit-content-confirm {
	position: absolute;
	right: 10px;
	bottom: 8px;
	color: rgba(0, 0, 0, 0.45);
	font-weight: normal;
	font-size: 14px;
	font-style: normal;
	pointer-events: none;
}

.light-typography-edit-content textarea {
	height: 1em;
	margin: 0 !important;
	/* stylelint-disable-next-line property-no-vendor-prefix */
	-moz-transition: none;
}

.light-typography ul,
.light-typography ol {
	margin: 0 0 1em;
	padding: 0;
}

.light-typography ul li,
.light-typography ol li {
	margin: 0 0 0 20px;
	padding: 0 0 0 4px;
}

.light-typography ul {
	list-style-type: circle;
}

.light-typography ul ul {
	list-style-type: disc;
}

.light-typography ol {
	list-style-type: decimal;
}

.light-typography pre,
.light-typography blockquote {
	margin: 1em 0;
}

.light-typography pre {
	padding: 0.4em 0.6em;
	white-space: pre-wrap;
	word-wrap: break-word;
	background: rgba(150, 150, 150, 0.1);
	border: 1px solid rgba(100, 100, 100, 0.2);
	border-radius: 3px;
}

.light-typography pre code {
	display: inline;
	margin: 0;
	padding: 0;
	font-size: inherit;
	font-family: inherit;
	background: transparent;
	border: 0;
}

.light-typography blockquote {
	padding: 0 0 0 0.6em;
	border-left: 4px solid rgba(100, 100, 100, 0.2);
	opacity: 0.85;
}

.light-typography-single-line {
	white-space: nowrap;
}

.light-typography-ellipsis-single-line {
	overflow: hidden;
	text-overflow: ellipsis;
}

a.light-typography-ellipsis-single-line,
span.light-typography-ellipsis-single-line {
	vertical-align: bottom;
}

.light-typography-ellipsis-multiple-line {
	/* stylelint-disable-next-line value-no-vendor-prefix */
	display: -webkit-box;
	overflow: hidden;
	-webkit-line-clamp: 3;
	/*! autoprefixer: ignore next */
	-webkit-box-orient: vertical;
}

.light-typography-rtl {
	direction: rtl;
}

.light-typography-rtl .light-typography-expand,
.light-typography-rtl .light-typography-edit,
.light-typography-rtl .light-typography-copy {
	margin-right: 4px;
	margin-left: 0;
}

.light-typography-rtl .light-typography-expand {
	float: left;
}

div.light-typography-edit-content.light-typography-rtl {
	right: -12px;
	left: auto;
}

.light-typography-rtl .light-typography-edit-content-confirm {
	right: auto;
	left: 10px;
}

.light-typography-rtl.light-typography ul li,
.light-typography-rtl.light-typography ol li {
	margin: 0 20px 0 0;
	padding: 0 4px 0 0;
}

.light-upload {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.5715;
	list-style: none;
	font-feature-settings: "tnum";
	outline: 0;
}

.light-upload p {
	margin: 0;
}

.light-upload-btn {
	display: block;
	width: 100%;
	outline: none;
}

.light-upload input[type="file"] {
	cursor: pointer;
}

.light-upload.light-upload-select {
	display: inline-block;
}

.light-upload.light-upload-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.light-upload.light-upload-select-picture-card {
	width: 104px;
	height: 104px;
	margin-right: 8px;
	margin-bottom: 8px;
	text-align: center;
	vertical-align: top;
	background-color: #fafafa;
	border: 1px dashed #d9d9d9;
	border-radius: 2px;
	cursor: pointer;
	transition: border-color 0.3s;
}

.light-upload.light-upload-select-picture-card>.light-upload {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	text-align: center;
}

.light-upload.light-upload-select-picture-card:hover {
	border-color: var(--light-primary-color);
}

.light-upload-disabled.light-upload.light-upload-select-picture-card:hover {
	border-color: #d9d9d9;
}

.light-upload.light-upload-drag {
	position: relative;
	width: 100%;
	height: 100%;
	text-align: center;
	background: #fafafa;
	border: 1px dashed #d9d9d9;
	border-radius: 2px;
	cursor: pointer;
	transition: border-color 0.3s;
}

.light-upload.light-upload-drag .light-upload {
	padding: 16px 0;
}

.light-upload.light-upload-drag.light-upload-drag-hover:not(.light-upload-disabled) {
	border-color: var(--light-primary-7);
}

.light-upload.light-upload-drag.light-upload-disabled {
	cursor: not-allowed;
}

.light-upload.light-upload-drag .light-upload-btn {
	display: table;
	height: 100%;
}

.light-upload.light-upload-drag .light-upload-drag-container {
	display: table-cell;
	vertical-align: middle;
}

.light-upload.light-upload-drag:not(.light-upload-disabled):hover {
	border-color: var(--light-primary-5);
}

.light-upload.light-upload-drag p.light-upload-drag-icon {
	margin-bottom: 20px;
}

.light-upload.light-upload-drag p.light-upload-drag-icon .anticon {
	color: var(--light-primary-5);
	font-size: 48px;
}

.light-upload.light-upload-drag p.light-upload-text {
	margin: 0 0 4px;
	color: rgba(0, 0, 0, 0.85);
	font-size: 16px;
}

.light-upload.light-upload-drag p.light-upload-hint {
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
}

.light-upload.light-upload-drag .anticon-plus {
	color: rgba(0, 0, 0, 0.25);
	font-size: 30px;
	transition: all 0.3s;
}

.light-upload.light-upload-drag .anticon-plus:hover {
	color: rgba(0, 0, 0, 0.45);
}

.light-upload.light-upload-drag:hover .anticon-plus {
	color: rgba(0, 0, 0, 0.45);
}

.light-upload-picture-card-wrapper {
	display: inline-block;
	width: 100%;
}

.light-upload-picture-card-wrapper::before {
	display: table;
	content: "";
}

.light-upload-picture-card-wrapper::after {
	display: table;
	clear: both;
	content: "";
}

.light-upload-picture-card-wrapper::before {
	display: table;
	content: "";
}

.light-upload-picture-card-wrapper::after {
	display: table;
	clear: both;
	content: "";
}

.light-upload-list {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	color: rgba(0, 0, 0, 0.85);
	font-size: 14px;
	font-variant: tabular-nums;
	list-style: none;
	font-feature-settings: "tnum";
	line-height: 1.5715;
}

.light-upload-list::before {
	display: table;
	content: "";
}

.light-upload-list::after {
	display: table;
	clear: both;
	content: "";
}

.light-upload-list::before {
	display: table;
	content: "";
}

.light-upload-list::after {
	display: table;
	clear: both;
	content: "";
}

.light-upload-list-item {
	position: relative;
	height: 22.001px;
	margin-top: 8px;
	font-size: 14px;
}

.light-upload-list-item-name {
	display: inline-block;
	width: 100%;
	padding-left: 22px;
	overflow: hidden;
	line-height: 1.5715;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.light-upload-list-item-card-actions {
	position: absolute;
	right: 0;
}

.light-upload-list-item-card-actions-btn {
	opacity: 0;
}

.light-upload-list-item-card-actions-btn.light-btn-sm {
	height: 22.001px;
	line-height: 1;
	vertical-align: top;
}

.light-upload-list-item-card-actions.picture {
	top: 22px;
	line-height: 0;
}

.light-upload-list-item-card-actions-btn:focus,
.light-upload-list-item-card-actions.picture .light-upload-list-item-card-actions-btn {
	opacity: 1;
}

.light-upload-list-item-card-actions .anticon {
	color: rgba(0, 0, 0, 0.45);
	transition: all 0.3s;
}

.light-upload-list-item-card-actions:hover .anticon {
	color: rgba(0, 0, 0, 0.85);
}

.light-upload-list-item-info {
	height: 100%;
	transition: background-color 0.3s;
}

.light-upload-list-item-info>span {
	display: block;
	width: 100%;
	height: 100%;
}

.light-upload-list-item-info .anticon-loading .anticon,
.light-upload-list-item-info .light-upload-text-icon .anticon {
	position: absolute;
	top: 5px;
	color: rgba(0, 0, 0, 0.45);
	font-size: 14px;
}

.light-upload-list-item:hover .light-upload-list-item-info {
	background-color: #f5f5f5;
}

.light-upload-list-item:hover .light-upload-list-item-card-actions-btn {
	opacity: 1;
}

.light-upload-list-item-error,
.light-upload-list-item-error .light-upload-text-icon>.anticon,
.light-upload-list-item-error .light-upload-list-item-name {
	color: var(--light-error-color);
}

.light-upload-list-item-error .light-upload-list-item-card-actions .anticon {
	color: var(--light-error-color);
}

.light-upload-list-item-error .light-upload-list-item-card-actions-btn {
	opacity: 1;
}

.light-upload-list-item-progress {
	position: absolute;
	bottom: -12px;
	width: 100%;
	padding-left: 26px;
	font-size: 14px;
	line-height: 0;
}

.light-upload-list-picture .light-upload-list-item,
.light-upload-list-picture-card .light-upload-list-item {
	position: relative;
	height: 66px;
	padding: 8px;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
}

.light-upload-list-picture .light-upload-list-item:hover,
.light-upload-list-picture-card .light-upload-list-item:hover {
	background: transparent;
}

.light-upload-list-picture .light-upload-list-item-error,
.light-upload-list-picture-card .light-upload-list-item-error {
	border-color: var(--light-error-color);
}

.light-upload-list-picture .light-upload-list-item-info,
.light-upload-list-picture-card .light-upload-list-item-info {
	padding: 0;
}

.light-upload-list-picture .light-upload-list-item:hover .light-upload-list-item-info,
.light-upload-list-picture-card .light-upload-list-item:hover .light-upload-list-item-info {
	background: transparent;
}

.light-upload-list-picture .light-upload-list-item-uploading,
.light-upload-list-picture-card .light-upload-list-item-uploading {
	border-style: dashed;
}

.light-upload-list-picture .light-upload-list-item-thumbnail,
.light-upload-list-picture-card .light-upload-list-item-thumbnail {
	width: 48px;
	height: 48px;
	line-height: 60px;
	text-align: center;
	opacity: 0.8;
}

.light-upload-list-picture .light-upload-list-item-thumbnail .anticon,
.light-upload-list-picture-card .light-upload-list-item-thumbnail .anticon {
	font-size: 26px;
}

.light-upload-list-picture .light-upload-list-item-error .light-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"],
.light-upload-list-picture-card .light-upload-list-item-error .light-upload-list-item-thumbnail .anticon svg path[fill="#e6f7ff"] {
	fill: var(--light-error-color-deprecated-bg);
}

.light-upload-list-picture .light-upload-list-item-error .light-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"],
.light-upload-list-picture-card .light-upload-list-item-error .light-upload-list-item-thumbnail .anticon svg path[fill="#1890ff"] {
	fill: var(--light-error-color);
}

.light-upload-list-picture .light-upload-list-item-icon,
.light-upload-list-picture-card .light-upload-list-item-icon {
	position: absolute;
	top: 50%;
	left: 50%;
	font-size: 26px;
	transform: translate(-50%, -50%);
}

.light-upload-list-picture .light-upload-list-item-icon .anticon,
.light-upload-list-picture-card .light-upload-list-item-icon .anticon {
	font-size: 26px;
}

.light-upload-list-picture .light-upload-list-item-image,
.light-upload-list-picture-card .light-upload-list-item-image {
	max-width: 100%;
}

.light-upload-list-picture .light-upload-list-item-thumbnail img,
.light-upload-list-picture-card .light-upload-list-item-thumbnail img {
	display: block;
	width: 48px;
	height: 48px;
	overflow: hidden;
}

.light-upload-list-picture .light-upload-list-item-name,
.light-upload-list-picture-card .light-upload-list-item-name {
	display: inline-block;
	box-sizing: border-box;
	max-width: 100%;
	margin: 0 0 0 8px;
	padding-right: 8px;
	padding-left: 48px;
	overflow: hidden;
	line-height: 44px;
	white-space: nowrap;
	text-overflow: ellipsis;
	transition: all 0.3s;
}

.light-upload-list-picture .light-upload-list-item-uploading .light-upload-list-item-name,
.light-upload-list-picture-card .light-upload-list-item-uploading .light-upload-list-item-name {
	margin-bottom: 12px;
}

.light-upload-list-picture .light-upload-list-item-progress,
.light-upload-list-picture-card .light-upload-list-item-progress {
	bottom: 14px;
	width: calc(100% - 24px);
	margin-top: 0;
	padding-left: 56px;
}

.light-upload-list-picture-card-container {
	display: inline-block;
	width: 104px;
	height: 104px;
	margin: 0 8px 8px 0;
	vertical-align: top;
}

.light-upload-list-picture-card .light-upload-list-item {
	height: 100%;
	margin: 0;
}

.light-upload-list-picture-card .light-upload-list-item-info {
	position: relative;
	height: 100%;
	overflow: hidden;
}

.light-upload-list-picture-card .light-upload-list-item-info::before {
	position: absolute;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	opacity: 0;
	transition: all 0.3s;
	content: " ";
}

.light-upload-list-picture-card .light-upload-list-item:hover .light-upload-list-item-info::before {
	opacity: 1;
}

.light-upload-list-picture-card .light-upload-list-item-actions {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 10;
	white-space: nowrap;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: all 0.3s;
}

.light-upload-list-picture-card .light-upload-list-item-actions .anticon-eye,
.light-upload-list-picture-card .light-upload-list-item-actions .anticon-download,
.light-upload-list-picture-card .light-upload-list-item-actions .anticon-delete {
	z-index: 10;
	width: 16px;
	margin: 0 4px;
	color: rgba(255, 255, 255, 0.85);
	font-size: 16px;
	cursor: pointer;
	transition: all 0.3s;
}

.light-upload-list-picture-card .light-upload-list-item-actions .anticon-eye:hover,
.light-upload-list-picture-card .light-upload-list-item-actions .anticon-download:hover,
.light-upload-list-picture-card .light-upload-list-item-actions .anticon-delete:hover {
	color: #fff;
}

.light-upload-list-picture-card .light-upload-list-item-info:hover+.light-upload-list-item-actions,
.light-upload-list-picture-card .light-upload-list-item-actions:hover {
	opacity: 1;
}

.light-upload-list-picture-card .light-upload-list-item-thumbnail,
.light-upload-list-picture-card .light-upload-list-item-thumbnail img {
	position: static;
	display: block;
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.light-upload-list-picture-card .light-upload-list-item-name {
	display: none;
	margin: 8px 0 0;
	padding: 0;
	line-height: 1.5715;
	text-align: center;
}

.light-upload-list-picture-card .light-upload-list-item-file+.light-upload-list-item-name {
	position: absolute;
	bottom: 10px;
	display: block;
}

.light-upload-list-picture-card .light-upload-list-item-uploading.light-upload-list-item {
	background-color: #fafafa;
}

.light-upload-list-picture-card .light-upload-list-item-uploading .light-upload-list-item-info {
	height: auto;
}

.light-upload-list-picture-card .light-upload-list-item-uploading .light-upload-list-item-info::before,
.light-upload-list-picture-card .light-upload-list-item-uploading .light-upload-list-item-info .anticon-eye,
.light-upload-list-picture-card .light-upload-list-item-uploading .light-upload-list-item-info .anticon-delete {
	display: none;
}

.light-upload-list-picture-card .light-upload-list-item-progress {
	bottom: 32px;
	width: calc(100% - 14px);
	padding-left: 0;
}

.light-upload-list-text-container,
.light-upload-list-picture-container {
	transition: opacity 0.3s, height 0.3s;
}

.light-upload-list-text-container::before,
.light-upload-list-picture-container::before {
	display: table;
	width: 0;
	height: 0;
	content: "";
}

.light-upload-list-text-container .light-upload-span,
.light-upload-list-picture-container .light-upload-span {
	display: block;
	flex: auto;
}

.light-upload-list-text .light-upload-span,
.light-upload-list-picture .light-upload-span {
	display: flex;
	align-items: center;
}

.light-upload-list-text .light-upload-span>*,
.light-upload-list-picture .light-upload-span>* {
	flex: none;
}

.light-upload-list-text .light-upload-list-item-name,
.light-upload-list-picture .light-upload-list-item-name {
	flex: auto;
	margin: 0;
	padding: 0 8px;
}

.light-upload-list-text .light-upload-list-item-card-actions,
.light-upload-list-picture .light-upload-list-item-card-actions {
	position: static;
}

.light-upload-list-text .light-upload-text-icon .anticon {
	position: static;
}

.light-upload-list .light-upload-animate-inline-appear,
.light-upload-list .light-upload-animate-inline-enter,
.light-upload-list .light-upload-animate-inline-leave {
	animation-duration: 0.3s;
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	animation-fill-mode: forwards;
}

.light-upload-list .light-upload-animate-inline-appear,
.light-upload-list .light-upload-animate-inline-enter {
	animation-name: uploadAnimateInlineIn;
}

.light-upload-list .light-upload-animate-inline-leave {
	animation-name: uploadAnimateInlineOut;
}

@keyframes uploadAnimateInlineIn {
	from {
		width: 0;
		height: 0;
		margin: 0;
		padding: 0;
		opacity: 0;
	}
}

@keyframes uploadAnimateInlineOut {
	to {
		width: 0;
		height: 0;
		margin: 0;
		padding: 0;
		opacity: 0;
	}
}

.light-upload-rtl {
	direction: rtl;
}

.light-upload-rtl.light-upload.light-upload-select-picture-card {
	margin-right: auto;
	margin-left: 8px;
}

.light-upload-list-rtl {
	direction: rtl;
}

.light-upload-list-rtl .light-upload-list-item-list-type-text:hover .light-upload-list-item-name-icon-count-1 {
	padding-right: 22px;
	padding-left: 14px;
}

.light-upload-list-rtl .light-upload-list-item-list-type-text:hover .light-upload-list-item-name-icon-count-2 {
	padding-right: 22px;
	padding-left: 28px;
}

.light-upload-list-rtl .light-upload-list-item-name {
	padding-right: 22px;
	padding-left: 0;
}

.light-upload-list-rtl .light-upload-list-item-name-icon-count-1 {
	padding-left: 14px;
}

.light-upload-list-rtl .light-upload-list-item-card-actions {
	right: auto;
	left: 0;
}

.light-upload-list-rtl .light-upload-list-item-card-actions .anticon {
	padding-right: 0;
	padding-left: 5px;
}

.light-upload-list-rtl .light-upload-list-item-info {
	padding: 0 4px 0 12px;
}

.light-upload-list-rtl .light-upload-list-item-error .light-upload-list-item-card-actions .anticon {
	padding-right: 0;
	padding-left: 5px;
}

.light-upload-list-rtl .light-upload-list-item-progress {
	padding-right: 26px;
	padding-left: 0;
}

.light-upload-list-picture .light-upload-list-item-info,
.light-upload-list-picture-card .light-upload-list-item-info {
	padding: 0;
}

.light-upload-list-rtl.light-upload-list-picture .light-upload-list-item-thumbnail,
.light-upload-list-rtl.light-upload-list-picture-card .light-upload-list-item-thumbnail {
	right: 8px;
	left: auto;
}

.light-upload-list-rtl.light-upload-list-picture .light-upload-list-item-icon,
.light-upload-list-rtl.light-upload-list-picture-card .light-upload-list-item-icon {
	right: 50%;
	left: auto;
	transform: translate(50%, -50%);
}

.light-upload-list-rtl.light-upload-list-picture .light-upload-list-item-name,
.light-upload-list-rtl.light-upload-list-picture-card .light-upload-list-item-name {
	margin: 0 8px 0 0;
	padding-right: 48px;
	padding-left: 8px;
}

.light-upload-list-rtl.light-upload-list-picture .light-upload-list-item-name-icon-count-1,
.light-upload-list-rtl.light-upload-list-picture-card .light-upload-list-item-name-icon-count-1 {
	padding-right: 48px;
	padding-left: 18px;
}

.light-upload-list-rtl.light-upload-list-picture .light-upload-list-item-name-icon-count-2,
.light-upload-list-rtl.light-upload-list-picture-card .light-upload-list-item-name-icon-count-2 {
	padding-right: 48px;
	padding-left: 36px;
}

.light-upload-list-rtl.light-upload-list-picture .light-upload-list-item-progress,
.light-upload-list-rtl.light-upload-list-picture-card .light-upload-list-item-progress {
	padding-right: 0;
	padding-left: 0;
}

.light-upload-list-rtl .light-upload-list-picture-card-container {
	margin: 0 0 8px 8px;
}

.light-upload-list-rtl.light-upload-list-picture-card .light-upload-list-item-actions {
	right: 50%;
	left: auto;
	transform: translate(50%, -50%);
}

.light-upload-list-rtl.light-upload-list-picture-card .light-upload-list-item-file+.light-upload-list-item-name {
	margin: 8px 0 0;
	padding: 0;
}