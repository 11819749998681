html {
	--blue: #1451FA;
	--orange: rgb(250, 130, 38);
}

.app {
	position: fixed;
	top: 50%;
	left: 50%;
	/* width: 1200px;
	height: 800px; */
	width: calc(100% - 15px);
	height: calc(100% - 15px);
	transform: translate(-50%, -50%);
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.7);
	/* rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px; */
}

/* 登录界面 */
.app.login {
	width: 375px;
	height: 667px;
	border-radius: 15px;
}

/* 通话悬浮弹窗 */
.app.fw {
	width: 288px;
	border-radius: 8px;
	transition: height 0.01s;
}

.app.fw.leisure {
	height: 204px;
}

.app.fw.big {
	height: 452px;
}

.app.fw.outandcon {
	height: 250px;
}

.app.fwinc {
	width: 376px;
	height: 88px;
	border-radius: 16px;
}

.ant-tree.ant-tree-directory .ant-tree-treenode {
	color: var(--primary-font-color);
}

.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
	background: var(--primary-color-hover);
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
	background: var(--primary-color-hover);
}

.ant-message-notice-content {
	background: var(--modal-bg);
	color: var(--primary-font-color);
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
	color: var(--primary-color);
}

[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 16px;
  overflow: hidden;
  background: var(--main-bg);
  border: 0px;
  border-radius: 8px !important;
}

/* 抽离 ant 公用样式 */

html,
body {
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	font-size: 16px;
	margin: 0;
	color: var(--primary-font-color);
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
		"Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
		"Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
	font-variant: tabular-nums;
	line-height: 1.5715;
	font-feature-settings: "tnum";
}

input::-ms-clear,
input::-ms-reveal {
	display: none;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-family: sans-serif;
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
	width: device-width;
}

[tabindex="-1"]:focus {
	outline: none !important;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.5em;
	color: rgba(0, 0, 0, 0.85);
	font-weight: 500;
}

p {
	margin-top: 0;
	margin-bottom: 1em;
}

abbr[title],
abbr[data-original-title] {
	text-decoration: underline;
	text-decoration: underline dotted;
	border-bottom: 0;
	cursor: help;
}

address {
	margin-bottom: 1em;
	font-style: normal;
	line-height: inherit;
}

input[type="text"],
input[type="password"],
input[type="number"],
textarea {
	-webkit-appearance: none;
	appearance: none;
}

ol,
ul,
dl {
	margin: 0;
	padding: 0;
	list-style: none;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

dt {
	font-weight: 500;
}

dd {
	margin-bottom: 0.5em;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1em;
}

dfn {
	font-style: italic;
}

b,
strong {
	font-weight: bolder;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

a {
	color: var(--primary-color);
	text-decoration: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
	-webkit-text-decoration-skip: objects;
}

a:hover {
	color: var(--light-primary-color-hover);
}

a:active {
	color: var(--light-primary-color-active);
}

a:active,
a:hover {
	text-decoration: none;
	outline: 0;
}

a:focus {
	text-decoration: none;
	outline: 0;
}

a[disabled] {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

pre,
code,
kbd,
samp {
	font-size: 1em;
	font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier,
		monospace;
}

pre {
	margin-top: 0;
	margin-bottom: 1em;
	overflow: auto;
}

figure {
	margin: 0 0 1em;
}

img {
	vertical-align: middle;
	border-style: none;
}

a,
area,
button,
[role="button"],
input:not([type="range"]),
label,
select,
summary,
textarea {
	touch-action: manipulation;
}

table {
	border-collapse: collapse;
}

caption {
	padding-top: 0.75em;
	padding-bottom: 0.3em;
	color: rgba(0, 0, 0, 0.45);
	text-align: left;
	caption-side: bottom;
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	color: inherit;
	font-size: inherit;
	font-family: inherit;
	line-height: inherit;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
	appearance: none;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
	box-sizing: border-box;
	padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
	-webkit-appearance: listbox;
	appearance: none;
}

textarea {
	overflow: auto;
	resize: vertical;
}

fieldset {
	min-width: 0;
	margin: 0;
	padding: 0;
	border: 0;
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	margin-bottom: 0.5em;
	padding: 0;
	color: inherit;
	font-size: 1.5em;
	line-height: inherit;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;
	appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
	appearance: none;
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
	appearance: none;
}

output {
	display: inline-block;
}

summary {
	display: list-item;
}

template {
	display: none;
}

[hidden] {
	display: none !important;
}

mark {
	padding: 0.2em;
	background-color: #feffe6;
}

::selection {
	color: #fff;
	background: var(--light-primary-color);
}

.clearfix::before {
	display: table;
	content: "";
}

.clearfix::after {
	display: table;
	clear: both;
	content: "";
}

.anticon {
	display: inline-block;
	color: inherit;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
	text-rendering: optimizelegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.anticon>* {
	line-height: 1;
}

.anticon svg {
	display: inline-block;
}

.anticon::before {
	display: none;
}

.anticon .anticon-icon {
	display: block;
}

.anticon>.anticon {
	line-height: 0;
	vertical-align: 0;
}

.anticon[tabindex] {
	cursor: pointer;
}

.anticon-spin,
.anticon-spin::before {
	display: inline-block;
	animation: loadingCircle 1s infinite linear;
}