.app {
	.ant-layout {
		background: var(--body-bg);
	}
	.ant-layout-header {
		background: var(--main-bg);
		padding: 8px 16px 16px;
		display: flex;
		height: auto;
		line-height: initial;
		justify-content: space-between;
	}
}

.dark-pro-table-list-toolbar-title {
	color: rgba(255, 255, 255, 0.65);
}
.dark-pro .dark-pro-query-filter,
.light-pro .light-pro-query-filter {
	padding: 16px 24px;
}
.light-pro-table .light-pro-table-search,
.dark-pro-table .dark-pro-table-search {
	margin-block-end: 16px;
	background: var(--main-bg);
}
.light-pro-card,
.dark-pro-card {
	background: var(--main-bg);
}

.light-pro-table-list-toolbar-container {
	display: flex;
	justify-content: space-between;
	padding-block: 4px;
	padding-inline: 0;
}
.light-pro-table-list-toolbar-left {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}
.light-pro-card .light-pro-card-body,
.dark-pro-card .dark-pro-card-body {
	padding-inline: 0;
}
.dark-pro-table-list-toolbar-setting-item,
.light-pro-table-list-toolbar-setting-item {
	margin-inline: 4px;
}
.dark-pro-table-list-toolbar-setting-item {
	color: rgba(255, 255, 255, 0.65);
}

.light-pro-table-list-toolbar,
.dark-pro-table-list-toolbar {
	border-bottom: 1px solid var(--border-color);
	padding: 0 16px;
	margin-bottom: 16px;
}

.light-pro-table-list-toolbar-setting-item:hover,
.dark-pro-table-list-toolbar-setting-item:hover {
	color: var(--primary-color);
}

.dark-pro-table-column-setting-list-item-option > span > span.anticon {
	color: var(--primary-color);
}
.light-pro .light-pro-query-filter-row,
.dark-pro .dark-pro-query-filter-row {
	row-gap: 24px;
}

.light-pro-card .light-table-wrapper,
.dark-pro-card .dark-table-wrapper {
	padding: 0 16px;
}

.dark-pro-core-label-tip,
.light-pro-core-label-tip {
	display: inline-flex;
	align-items: center;
	max-width: 100%;
}

.dark-pro-table-column-setting-list-title {
	color: rgba(255, 255, 255, 0.65);
}

.dark-pro-card.dark-pro-table-search .dark-col:last {
	flex: auto;
}
